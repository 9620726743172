/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RideOptionsDto } from './RideOptionsDto';

export type UpdateTripGroupDto = {
    privacy?: UpdateTripGroupDto.privacy;
    title?: string;
    notes?: string;
    rideOptions?: RideOptionsDto;
}

export namespace UpdateTripGroupDto {

    export enum privacy {
        PUBLIC = 'PUBLIC',
        PRIVATE = 'PRIVATE',
    }


}
