import { Browser } from '@capacitor/browser';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { observer } from 'mobx-react-latest';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AppUrlListener from './AppUrlListener';
import { VersionTooLowError, getAppVersion, getVersionTooLowError } from './AppVersion';
import JerryLoading from './components/JerryLoading';
import LogoPage from './components/LogoPage';
import { OpenBrowser } from './components/OpenBrowser';
import { OpenNative } from './components/OpenNative';
import ProtectedRoute from './components/ProtectedRoute';
import { setNotificationToken } from './dataflow/mutators/UserStoreMutators';
import { fetchResortCondition } from './dataflow/orchestrators/ResortConditionOrchestrators';
import { userRestore } from './dataflow/orchestrators/UserStoreOrchestrators';
import { getUserStore } from './dataflow/stores/UserStore';
import { logger } from './logging/winston';
import { initializedNotification } from './notifications/initializer';
import { Activity } from './pages/Activity/Activity';
import './pages/App.scss';
import Calendar from './pages/Calendar/Calendar';
import { LobbyPage } from './pages/Carpool/CarpoolLobbyPage';
import { RouteIntentDetailPage } from './pages/Carpool/IntentDetailPage';
import { NewIntentPage } from './pages/Carpool/NewIntentPage';
import { RoleSelectionPage } from './pages/Carpool/RoleSelectionPage';
import Chat from './pages/Chat/Chat';
import ChatRoom from './pages/Chat/ChatRoom';
import ChatRoomDetail from './pages/Chat/ChatRoomDetail';
import DiscoverPeople from './pages/DiscoverPeople/DiscoverPeople';
import { Friend } from './pages/Friend/Friend';
import Login from './pages/Login';
import { Signup } from './pages/Signup';
import TripDetail from './pages/TripDetail/TripDetail';
import { RouteRequestToJoinTripGroup } from './pages/TripGroup/RequestToJoinTripGroup';
import MyProfile from './pages/UserProfile/MyProfile';
import UserProfile from './pages/UserProfile/UserProfile';
import { SideBySide } from './pages/VideoAnalysis/SideBySide';
import { Disclaimer } from './pages/profile/Disclaimer';
import { Important } from './pages/profile/Important';
import { MyInfo } from './pages/profile/MyInfo';
import { MyPreference } from './pages/profile/MyPreference';
import { MyVehicle } from './pages/profile/MyVehicles';
import { Privacy } from './pages/profile/Privacy';
import { Support } from './pages/profile/Support';
import { TermsOfService } from './pages/profile/TermsOfService';
import {
  activityPath,
  carpoolCreateIntentPathLiteral,
  carpoolIntentDetailPagePathLiteral,
  carpoolLobbyPathLiteral,
  carpoolRoleSelectionPathLiteral,
  chatPath,
  chatRoomDetailPathLiteral,
  chatRoomPathLiteral,
  disclaimerPath,
  discoverPeoplePath,
  friendPath,
  legalPath,
  myInfoPath,
  myPreferencePath,
  myProfilePath,
  myVehiclesPath,
  privacyPath,
  qrLanding20231015,
  requestToJoinTripGroupLiteral,
  sideBySideComparison,
  signUpPathLiteral,
  signUpPathWithRedirectLiteral,
  supportPath,
  termsOfServicePath,
  tripDetailPathLiteral,
  tripDetailWithIdPathLiteral,
  userProfileLiteral
} from './pages/routes';
import { initFacebook } from './services/facebook';
import './theme/font.css';
/* Theme variables */
import './App.scss';
import { loadRemoteConfig } from './services/firebase';
import './theme/variables.css';
import { browserHistory } from './utils/history';
import initializeResortList from './utils/initializeResortList';
import { isUnsupportedEmbeddedBrowser } from './utils/platform';

const App: React.FC = observer(() => {
  const [ initDone, setInitDone ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ versionTooLowError, setVersionTooLowError ] = useState<VersionTooLowError | undefined>(undefined);

  setupIonicReact({
    swipeBackEnabled: true,
  });

  const hideContent = isUnsupportedEmbeddedBrowser();

  useEffect(() => {
    if (!hideContent) {
      logger.info('Initializing app...');
      initFacebook();
      const setupNotificationAndRestoreUser = initializedNotification().then((token) => {
        setNotificationToken(token);
        userRestore(token);
      });
      const loadRemoteConfigPromise = loadRemoteConfig();
      getVersionTooLowError().then((error) => {
        if (error) {
          setVersionTooLowError(error);
          const appVersion = getAppVersion();
          logger.error(`App version is too low: ${appVersion.toString()}`, { version: appVersion.toString(), requiredVersion: error.requiredVersion.toString() });
        } else {
          Promise.all([ setupNotificationAndRestoreUser, loadRemoteConfigPromise ]).then(() => {
            setInitDone(true);
            logger.info('App initialization done.');
            fetchResortCondition();
            initializeResortList();
          });
        }
      });
    }
  }, []); // run only once

  const isLoaded = (): boolean => {
    if (loaded) return true;
    if (initDone && !getUserStore().loading) {
      setLoaded(true);
      return true;
    }
    return false;
  };

  const openAppLink = () => {
    if (versionTooLowError) {
      Browser.open({ url: versionTooLowError.getAppLink() });
    }
  };

  return (
    <div className="appRoot">
      <IonApp>
        <OpenNative></OpenNative>
        {hideContent ?
          <OpenBrowser />
          :
          <>
            {!isLoaded() &&
              <LogoPage>
                {!versionTooLowError &&
                  <JerryLoading />
                }
                {versionTooLowError &&
                  <div className='appVersionTooLow'>
                    <p>
                      { `Current App version ${getAppVersion().toString()} is not supported anymore.
                      Please download new App with version greater than ${versionTooLowError.requiredVersion.toString()}.`}
                    </p>
                    <p>See you back soon!</p>
                    <a className='appVersionTooLowAppLink' onClick={openAppLink}>Download latest version here</a><span>⬅️</span>
                  </div>
                }
              </LogoPage>
            }
            {isLoaded() &&
              <IonReactRouter history={browserHistory}>
                <AppUrlListener />
                <IonRouterOutlet className={'router'}>
                  <ProtectedRoute path='/home' component={Calendar} />
                  <ProtectedRoute path={chatPath} component={Chat} />
                  <ProtectedRoute path={activityPath} component={Activity} />
                  <ProtectedRoute path={friendPath} component={Friend} />
                  <ProtectedRoute path={myProfilePath} component={MyProfile} />
                  <ProtectedRoute path={userProfileLiteral} component={UserProfile} />
                  <ProtectedRoute path={myInfoPath} component={MyInfo} />
                  <ProtectedRoute path={myPreferencePath} component={MyPreference} />
                  <ProtectedRoute path={myVehiclesPath} component={MyVehicle} />
                  <ProtectedRoute path={discoverPeoplePath} component={DiscoverPeople} />
                  <ProtectedRoute exact path={signUpPathLiteral} component={Signup} />
                  <ProtectedRoute exact path={signUpPathWithRedirectLiteral} component={Signup} />
                  <ProtectedRoute exact path={requestToJoinTripGroupLiteral} component={RouteRequestToJoinTripGroup} />
                  <ProtectedRoute exact path={tripDetailWithIdPathLiteral} component={TripDetail} />
                  <ProtectedRoute exact path={tripDetailPathLiteral} component={TripDetail} />
                  <ProtectedRoute exact path={carpoolLobbyPathLiteral} component={LobbyPage} />
                  <ProtectedRoute exact path={carpoolRoleSelectionPathLiteral} component={RoleSelectionPage} />
                  <ProtectedRoute exact path={carpoolCreateIntentPathLiteral} component={NewIntentPage} />
                  <ProtectedRoute exact path={carpoolIntentDetailPagePathLiteral} component={RouteIntentDetailPage} />
                  <Route exact path={legalPath} component={Important} />
                  <Route path={termsOfServicePath} component={TermsOfService} />
                  <Route path={privacyPath} component={Privacy} />
                  <Route path={disclaimerPath} component={Disclaimer} />
                  <Route path={supportPath} component={Support} />
                  <Route path={qrLanding20231015} component={Support} />
                  <ProtectedRoute exact path={chatRoomPathLiteral} component={ChatRoom} />
                  <ProtectedRoute exact path={chatRoomDetailPathLiteral} component={ChatRoomDetail} />
                  <ProtectedRoute exact path={sideBySideComparison} component={SideBySide} />
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/login/:redirectPath">
                    <Login />
                  </Route>
                  <Route exact path="/" render={() => <Redirect to="/home" />} />
                  <Route exact render={() => <Redirect to="/home" />} />
                </IonRouterOutlet>
              </IonReactRouter>
            }
          </>
        }
      </IonApp>
    </div>
  );
});

export default App;
