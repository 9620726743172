/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PreviewUserDto } from './PreviewUserDto';
import type { TripDto } from './TripDto';

export type ChatDto = {
    id: string;
    type: ChatDto.type;
    otherParticipants?: Array<PreviewUserDto>;
    trip?: TripDto;
    tripGroup?: TripDto;
}

export namespace ChatDto {

    export enum type {
        ONE_TO_ONE = 'OneToOne',
        TRIP = 'Trip',
        TRIP_GROUP = 'TripGroup',
    }


}
