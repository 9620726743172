import { useState } from 'react';
import { UserDto } from './../../../services/openapi/models/UserDto';
export enum EditType {
  'input',
  'select'
}

interface Validation {
  state: [string, React.Dispatch<React.SetStateAction<string>>];
  validationFunc: (value: string, setValidationError: React.Dispatch<React.SetStateAction<string>>) => void;
}

export interface EditableUserFieldProps {
  propertyDisplayName: string;
  propertyKeys: string[];
  editType: EditType;
  labelSize?: string;
  valueSize?: string;
  readonly?: boolean;
  validation?: Validation;
  options?: {key: string, value: string}[];
}

export function getUserPrivateFieldInputProps(): EditableUserFieldProps[] {
  return [
    {
      propertyDisplayName: 'Email',
      propertyKeys: [ 'email' ],
      editType: EditType.input,
      labelSize: '4',
      valueSize: '8',
      readonly: true
    },
  ];
}

export function getUserPreferenceFieldInputProps(): EditableUserFieldProps[] {
  return [
    {
      propertyDisplayName: 'First Name',
      propertyKeys: [ 'firstName' ],
      validation: {
        state: useState<string>(''),
        validationFunc: (value: string, setValidationError: React.Dispatch<React.SetStateAction<string>>) => {
          if (value.length == 0) {
            setValidationError('First name cannot be empty');
          } else {
            setValidationError('');
          }
        }
      },
      editType: EditType.input,
    },
    {
      propertyDisplayName: 'Last Name',
      propertyKeys: [ 'lastName' ],
      validation: {
        state: useState<string>(''),
        validationFunc: (value: string, setValidationError: React.Dispatch<React.SetStateAction<string>>) => {
          if (value.length == 0) {
            setValidationError('Last name cannot be empty');
          } else {
            setValidationError('');
          }
        }
      },
      editType: EditType.input,
    },
    {
      propertyDisplayName: 'Username',
      propertyKeys: [ 'username' ],
      editType: EditType.input,
      readonly: true,
    },
    {
      propertyDisplayName: 'Perferred Sport(s)',
      propertyKeys: [ 'preferredSport' ],
      editType: EditType.select,
      options: [
        {
          key: UserDto.preferredSport.SKI,
          value: UserDto.preferredSport.SKI,
        },
        {
          key: UserDto.preferredSport.SNOWBOARD,
          value: UserDto.preferredSport.SNOWBOARD,
        },
        {
          key: UserDto.preferredSport.BOTH,
          value: UserDto.preferredSport.BOTH,
        }
      ]
    },
    {
      propertyDisplayName: 'Preferred Terrain',
      propertyKeys: [ 'abilityLevel' ],
      editType: EditType.select,
      options:[
        {
          key: UserDto.abilityLevel.MAGIC_CARPET,
          value: "Magic Carpet"
        },
        {
          key: UserDto.abilityLevel.GREEN,
          value: "Green"
        },
        {
          key: UserDto.abilityLevel.BLUE,
          value: "Blue"
        },
        {
          key: UserDto.abilityLevel.BLACK,
          value: "Black"
        },
        {
          key: UserDto.abilityLevel.DOUBLE_BLACK,
          value: "Double Black"
        }
      ]
    }
  ];
}