import { computed, IComputedValue } from 'mobx';
import moment from 'moment';
import { createStore } from 'satcheljs';
import { PreviewUserDto, RideDetailDto, TripDetailDto, TripGroupDetailDto } from '../../services/openapi';

export interface ReplyTo {
  messageId: string;
  preview: string;
  toUserId: string;
}

export interface Message {
  id: string,
  sentTime: Date,
  fromUser: string,
  content: string,
  replyTo?: ReplyTo,
  taggedUsers?: string[],
  SYSTEM_MESSAGE?: string,
}

export enum ChatKind {
  RIDE = 'RIDE',
  PEOPLE = 'PEOPLE',
  TRIP = 'TRIP',
  TRIP_GROUP = 'TRIP_GROUP',
}

interface ChatBase {
  kind: ChatKind,
  id: string,
  messages: Message[],
  lastReadMessageId?: string | undefined,
}

export interface PeopleChat extends ChatBase {
  kind: ChatKind.PEOPLE,
  otherParticipants: PreviewUserDto[],
}

export interface RideChat extends ChatBase {
  kind: ChatKind.RIDE,
  ride: RideDetailDto,
}

export interface TripChat extends ChatBase {
  kind: ChatKind.TRIP,
  trip: TripDetailDto,
}

export interface TripGroupChat extends ChatBase {
  kind: ChatKind.TRIP_GROUP,
  tripGroup: TripGroupDetailDto,
}

export type Chat = PeopleChat | RideChat | TripChat | TripGroupChat;

export interface ChatStore {
  rideChats: RideChat[];
  peopleChats: PeopleChat[];
  tripChats: TripChat[];
  tripGroupChats: TripGroupChat[];
  tripChatsLoaded: boolean;
  peopleChatsLoaded: boolean;
  tripGroupChatsLoaded: boolean;
}

export const getChatStore = createStore<ChatStore>('ChatStore', {
  rideChats: [], // TODO: save ride chat entity in backend
  peopleChats: [],
  tripChats: [],
  tripGroupChats: [],
  tripChatsLoaded: false,
  peopleChatsLoaded: false,
  tripGroupChatsLoaded: false,
});

export function getLatestChatMessage(chat: Chat | undefined): Message | undefined {
  let latestMessage: Message | undefined = undefined;
  for (const message of chat?.messages || []) {
    if (!latestMessage || message.sentTime > latestMessage?.sentTime) {
      latestMessage = message;
    }
  }
  return latestMessage;
}

function getAllChats(): Chat[] {
  const { rideChats, peopleChats, tripChats, tripGroupChats } = getChatStore();
  return [ ...rideChats, ...peopleChats, ...tripChats, ...tripGroupChats ];
}

export function getChat(chatId: string): Chat | undefined {
  return getAllChats().find((chat) => chat.id == chatId);
}

export function allChatsLoaded(): boolean {
  const { peopleChatsLoaded, tripChatsLoaded, tripGroupChatsLoaded } = getChatStore();
  return peopleChatsLoaded && tripChatsLoaded && tripGroupChatsLoaded;
}

export function hasUnread(chat: Chat): IComputedValue<boolean> {
  const latestMessage = getLatestChatMessage(chat);
  return computed(() => !!latestMessage && latestMessage.id != chat.lastReadMessageId);
}

function countUnreadMessage(chat: Chat): number {
  if (!chat.lastReadMessageId) {
    return chat.messages.length;
  }
  const lastReadMessage = chat.messages.find((msg) => msg.id == chat.lastReadMessageId);
  return chat.messages.filter((msg) => !lastReadMessage || msg.sentTime > lastReadMessage.sentTime).length;
}

export function countTotalUnreadMessage(): IComputedValue<number> {
  return computed(() => {
    const chats = getAllChats();
    return chats.map((chat) => countUnreadMessage(chat)).reduce((a, b) => a + b, 0);
  });
}

export function hasUnreadMessage(): IComputedValue<boolean> {
  return computed(() => {
    for (const chat of getAllChats()) {
      const latestMessage = getLatestChatMessage(chat);
      if (!!latestMessage && latestMessage.id != chat.lastReadMessageId) {
        return true;
      }
    }
    return false;
  });
}

export function getSortedChatList(): IComputedValue<Chat[]> {
  return computed(() => {
    const chats = getAllChats();
    return chats.map((chat): [Chat, Message | undefined] => {
      const latestMessage = getLatestChatMessage(chat);
      return [ chat, latestMessage ];
    })
      .filter(([ _, latestMessage ]) => !!latestMessage)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .sort(([ _1, latestMessage1 ], [ _2, latestMessage2 ]) => latestMessage2!.sentTime.getTime() - latestMessage1!.sentTime.getTime())
      .map(([ chat, _ ]) => chat);
  });
}

export function getChatTitle(chat: Chat): string {
  switch (chat.kind) {
    case ChatKind.PEOPLE:
      if (chat.otherParticipants.length == 1) {
        const otherUser = chat.otherParticipants[0]; // assuming only 1 other user
        return `${otherUser.firstName} ${otherUser.lastName}`;
      }
      return 'Unknown User';
    case ChatKind.RIDE:
      const ride = chat.ride;
      const rideDate = moment(ride.departureTime.split('T')[0]);
      return `${rideDate.format('MM/DD')} - ${ride.destinationLocation.name}`;
    case ChatKind.TRIP:
      const trip = chat.trip;
      const tripDate = moment(trip.date.split('T')[0]);
      return `${tripDate.format('dddd MM/DD')} - ${trip.resort.location.name}`;
    case ChatKind.TRIP_GROUP:
      return chat.tripGroup.title;
    default:
      return 'UnknownChatKind';
  }
}

export function getChatSubtitle(chat: Chat): string | undefined {
  switch (chat.kind) {
    case ChatKind.TRIP_GROUP:
      const trip = chat.tripGroup.trip;
      const tripDate_ = moment(trip.date.split('T')[0]);
      return `${tripDate_.format('MM/DD')} ${trip.resort.location.name}`;
    default:
      return undefined;
  }
}

export function getChatMembers(chat: Chat): PreviewUserDto[] {
  switch (chat.kind) {
    case ChatKind.PEOPLE:
      return [ ...chat.otherParticipants ];
    case ChatKind.RIDE:
      return [ chat.ride.driver, ...chat.ride.passengers ];
    case ChatKind.TRIP:
      const participants = chat.trip.participants;
      return [ ...participants.friends, ...participants.others ].map(p => p.user);
    case ChatKind.TRIP_GROUP:
      return chat.tripGroup.members.map(m => m.user);
    default:
      return [];
  }
}