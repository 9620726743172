import { IonBackButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import { useEffect } from 'react';
import { catchUpAllActivities, fetchActivities } from '../../dataflow/orchestrators/ActivityStoreOrchestrators';
import { getActivityStore } from '../../dataflow/stores/ActivityStore';
import { homeRedirectPath } from '../routes';
import './Activity.scss';
import { ActivityCard } from './ActivityCard';

export const Activity: React.FC = observer(() => {
  const { activities } = getActivityStore();

  const loadMoreActivities = () => {
    const now = new Date();
    const aWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    fetchActivities(aWeekAgo);
  };

  useEffect(() => {
    loadMoreActivities();
    catchUpAllActivities();
  }, []);

  const orderedActivities = activities
    .map(activity => Object.assign({}, activity))
    .sort((a, b) => {
      return new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1;
    })
    .map((activity) => {
      return (
        <ActivityCard key={activity.id} activity={activity} />
      );
    });

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className="fusendPageToolbar">
          <IonButtons slot="start">
            <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
          </IonButtons>
          <IonTitle className='fusendPageTitle' onClick={() => loadMoreActivities()}>
            Activity
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="fusendPageContent">
        <IonList>
          {orderedActivities}
        </IonList>
      </IonContent>
    </IonPage>
  );
});
