import { ObservableSet } from 'mobx';
import { createStore } from 'satcheljs';
import { PreviewRideDto, RideDetailDto } from '../../services/openapi';
import { getUserStore } from './UserStore';

export interface RideStore {
  myRides: RideDetailDto[];
  searchResults: (PreviewRideDto)[];
  loadingMyRides: boolean;
  loadingSearchResults: boolean;
  loadingRide: ObservableSet<string>;
}

export enum Status {
  InProgress = 0,
  Upcoming = 1,
  Completed = 2,
}

export const getRideStore = createStore<RideStore>('RideStore', {
  myRides: [],
  searchResults: [],
  loadingMyRides: false,
  loadingSearchResults: false,
  loadingRide: new ObservableSet(),
});

export function getRideStatus(ride: RideDetailDto): Status {
  const now = new Date();
  const depatureTime = new Date(ride.departureTime);
  const returnTime = new Date(ride.returnTime);

  if (depatureTime > now) {
    return Status.Upcoming;
  }
  if (returnTime < now) {
    return Status.Completed;
  }
  return Status.InProgress;
}

function filterActiveRides(selectUpcoming: boolean): RideDetailDto[] {
  return getRideStore().myRides.filter((ride) => {
    if (ride.removed) return false;

    return selectUpcoming ? getRideStatus(ride) != Status.Completed : getRideStatus(ride) == Status.Completed;
  });
}

export function getUpcomingRides(): RideDetailDto[] {
  return filterActiveRides(true);
}

export function getCompletedRides(): RideDetailDto[] {
  return filterActiveRides(false);
}

export function getRideFromSearch(rideId: string): PreviewRideDto | undefined {
  return getRideStore().searchResults.find((r) => r.id == rideId);
}

export function isRideLoading(rideId: string): boolean {
  return getRideStore().loadingRide.has(rideId);
}

export function getRide(rideId: string): RideDetailDto | PreviewRideDto | undefined {
  const { myRides, searchResults } = getRideStore();
  return myRides.find((ride) => ride.id == rideId) || searchResults.find((ride) => ride.id == rideId);
}

export function isUserDriver(ride: RideDetailDto | PreviewRideDto): boolean {
  const { currentUser } = getUserStore();
  return ride?.driver.id == currentUser?.id;
}

export function isUserPassenger(ride: RideDetailDto | PreviewRideDto): boolean {
  const { currentUser } = getUserStore();
  return !!ride?.passengers?.find((p) => p.id == currentUser?.id);
}
