import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core';
import {
  IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonNote, IonPage, IonSegment,
  IonSegmentButton, IonTextarea, IonTitle, IonToolbar, SegmentCustomEvent, useIonAlert
} from '@ionic/react';
import { informationCircle } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import React from 'react';
import { TripGroupDetailDto, TripParticipantDto } from '../../services/openapi';
import './TripGroupEditorModal.scss';
import TripGroupParticipantCard from './TripGroupParticipantCard';

export const MAX_CARPOOL_SIZE = 8;

export enum TripGroupEditorOptions {
  Name = 'Name',
  Privacy = 'Privacy',
  MaxSize = 'MaxSize',
  Notes = 'Notes',
  Members = 'Members',
}

interface TripGroupEditorModalProps {
  title: string;
  showCreateOptions: TripGroupEditorOptions[];
  confirmLabel: string;
  onDismiss: () => void;
  onConfirm: (
    selectedUserIds : string[],
    groupName: string | undefined,
    groupPrivacy: TripGroupDetailDto.privacy,
    notes: string | undefined,
    maxSize: number | undefined) => void;
  friendsList:  TripParticipantDto[];
  othersList: TripParticipantDto[];
  exclusionUserIds?: string[] | undefined
  notesHint?: string | undefined;
}


const TripGroupEditorModal = observer((props: TripGroupEditorModalProps) => {
  const [ isNameValid, setIsNameValid ] = React.useState<boolean>(false);
  const [ hasSelection, setHasSelection ] = React.useState<boolean>(false);
  const [ groupName, setGroupName ] = React.useState<string>('');
  const [ groupNotes, setGroupNotes ] = React.useState<string>('');
  const [ groupMaxSize, setGroupMaxSize ] = React.useState<number| undefined>(undefined);
  const [ isMaxSizeValid, setIsMaxSizeValid ] = React.useState<boolean>(false);
  const [ isMaxSizeTouched, setIsMaxSizeTouched ] = React.useState<boolean>(false);
  const [ groupPrivacy, setGroupPrivacy ] = React.useState(TripGroupDetailDto.privacy.PUBLIC);
  const [ newGroupParticipant, _setNewGroupParticipant ] = React.useState<Set<string>>(new Set());
  const [ presentAlert ] = useIonAlert();
  const { title, showCreateOptions, confirmLabel, onDismiss, friendsList, othersList, onConfirm, exclusionUserIds } = props;
  let participantList = friendsList.concat(othersList);
  if (exclusionUserIds) {
    participantList = participantList.filter((p) => exclusionUserIds.indexOf(p.user.id) == -1);
  }
  const participantComponent = participantList.map((participant) => {
    return (
      <TripGroupParticipantCard
        key={participant.id}
        participant={participant}
        onCheckboxChecked={(id: string, isChecked: boolean) => {
          if (isChecked) {
            newGroupParticipant.add(id);
          } else {
            newGroupParticipant.delete(id);
          }
          setHasSelection(newGroupParticipant.size > 0);
        }}
      />
    );
  });

  const confirmDisabled = showCreateOptions.length == 0
    ? !hasSelection
    : (showCreateOptions.includes(TripGroupEditorOptions.Name) && !isNameValid)
      || (showCreateOptions.includes(TripGroupEditorOptions.MaxSize) && !isMaxSizeValid);



  const onConfirmClick = () => {
    onConfirm(Array.from(newGroupParticipant), groupName, groupPrivacy, groupNotes, groupMaxSize);
  };

  const onClickPrivacyInfo = () => {
    presentAlert({
      header: 'Group Privacy',
      message: 'Public group is visible to all users. Private group is only visible to group members.',
      buttons: [ 'OK' ]
    });
  };

  const validateMaxSize = (ev: IonInputCustomEvent<InputChangeEventDetail>) => {
    const value = ev.detail.value;
    if (!value) {
      setIsMaxSizeValid(false);
      return;
    }
    try {
      const size = parseInt(value);
      if (size > 0 && size == parseFloat(value) && size <= MAX_CARPOOL_SIZE) {
        setIsMaxSizeValid(true);
        setGroupMaxSize(size + 1);
      } else {
        setIsMaxSizeValid(false);
      }
    } catch (_e) {
      setIsMaxSizeValid(false);
    }
  };

  const onClickMemberInfo = () => {
    presentAlert({
      header: 'Group Members',
      message: 'Select from the list below, and add people in the trip as members of this group.',
      buttons: [ 'OK' ]
    });
  };

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonButton className='tripGroupEditorCancelBtn' fill='clear' onClick={onDismiss}>Cancel</IonButton>
          </IonButtons>
          <IonTitle className='tripGroupEditorTitle'>{title}</IonTitle>
          <IonButtons slot='end'>
            <IonButton className='tripGroupEditorCancelBtn' disabled={confirmDisabled} fill='clear' onClick={onConfirmClick}>{confirmLabel}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        <div className='createGroupOptions'>
          { showCreateOptions.includes(TripGroupEditorOptions.Name) &&
          <IonItem className='createGroupOptionItem'>
            <IonLabel className='createGroupLabel'>Group Name</IonLabel>
            <IonInput slot='end'
              className='createGroupValue createGroupInput'
              placeholder='Enter a group name'
              onIonChange={(e) => {
                if(e.detail.value) {
                  setGroupName(e.detail.value);
                  setIsNameValid(true);
                } else {
                  setIsNameValid(false);
                }
              }}/>
          </IonItem>
          }
          { showCreateOptions.includes(TripGroupEditorOptions.Privacy) &&
          <IonItem className='createGroupOptionItem'>
            <IonLabel className='createGroupLabel' onClick={onClickPrivacyInfo}>
              <span>Privacy</span>
              <IonIcon className='createGroupLabelIcon' icon={informationCircle}></IonIcon>
            </IonLabel>

            <IonSegment slot='end'
              className='createGroupValue createGroupPrivacyOptions'
              value={groupPrivacy}
              onIonChange={(e: SegmentCustomEvent) => setGroupPrivacy(e.detail.value as TripGroupDetailDto.privacy)}>
              <IonSegmentButton value={TripGroupDetailDto.privacy.PUBLIC}>
                <IonLabel>Public</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={TripGroupDetailDto.privacy.PRIVATE}>
                <IonLabel>Private</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonItem>
          }
          {showCreateOptions.includes(TripGroupEditorOptions.MaxSize) &&
          <IonItem className={`createGroupOptionItem ${isMaxSizeValid && 'ion-valid'} ${!isMaxSizeValid && groupMaxSize !== undefined && 'ion-invalid'} ${isMaxSizeTouched && 'ion-touched'}`}>
            <IonLabel className='createGroupLabel'>Total passengers</IonLabel>
            <IonInput slot='end'
              type='number'
              min={1}
              max={8}
              placeholder={'Number of seats for passengers'}
              className='createGroupValue createGroupInput'
              onIonChange={validateMaxSize}/>
            <IonNote slot='error'>Invalid value for max size(greater than 0 less than 8)</IonNote>
          </IonItem>
          }
          {showCreateOptions.includes(TripGroupEditorOptions.Notes) &&
          <IonItem className='createGroupOptionItem'>
            <IonLabel className='createGroupLabel'>Notes</IonLabel>
            <IonTextarea  slot='end'
              className='createGroupValue createGroupInput'
              placeholder={props.notesHint || 'Enter notes'}
              rows={3}
              onIonBlur={() => setIsMaxSizeTouched(true)}
              onIonChange={(e) => {
                if(e.detail.value) {
                  setGroupNotes(e.detail.value);
                }
              }}/>
          </IonItem>
          }
          {showCreateOptions.includes(TripGroupEditorOptions.Members) &&
          <IonItem className='createGroupOptionItem' lines='none'>
            <IonLabel className='createGroupLabel' onClick={onClickMemberInfo}>
              <span>Members (Optional)</span>
              <IonIcon className='createGroupLabelIcon' icon={informationCircle}></IonIcon>
            </IonLabel>
          </IonItem>
          }
          {/* always show member list */}
          { participantList.length > 0 ? participantComponent : <div className='tripGroupEditorCancelNoMoreToAdd'>Everyone in the trip has been added to this group!</div> }
        </div>
        <div className="Submit"></div>
      </IonContent>
    </IonPage>
  );
});

export default TripGroupEditorModal;