import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonCol, IonItem, IonLabel, IonRow, IonIcon } from '@ionic/react';
import { people } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { PreviewUserDto } from '../../services/openapi';
import '../page.scss';
import MemberListItem from './MemberListItem';

interface AtMentionModalProps {
  memberList: PreviewUserDto[];
  onDismiss: () => void;
  onAtAll: () => void;
  onItemClicked: (member: PreviewUserDto | string) => void;
}

const AtMentionModal = observer((props: AtMentionModalProps) => {
  const { onDismiss, onItemClicked, onAtAll } = props;
  const { currentUser } = getUserStore();
  const memberList = props.memberList.filter((m) => m.id != currentUser?.id);

  const memberListComponent = memberList.map((m: PreviewUserDto) => {
    return (
      <MemberListItem key={m.id} member={m} onItemClicked={onItemClicked}/>
    );
  });

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonButton onClick={onDismiss}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        {memberList.length > 1 &&
        <IonItem className='participantItem' onClick={onAtAll}>
          <IonRow className='participantRow'>
            <IonCol size='2'>
              <div className='participantProfilePic atAll'><IonIcon icon={people}/></div>
            </IonCol>
            <IonCol size='10' className='atAllLabel'>
              <IonLabel>All</IonLabel>
            </IonCol>
          </IonRow>
        </IonItem>
        }
        {memberListComponent}
      </IonContent>
    </IonPage>
  );
});

export default AtMentionModal;