import { logger } from "../../logging/winston";
import { CancelablePromise } from "../../services/openapi";

export interface Entity {
  id: string
}

export type ErrorHandlingFn = (error: Error) => void;

export function upsert(list: Entity[], entity: Entity) {
  const itemIndex = list.findIndex((r) => r.id == entity.id);
  if (itemIndex != -1) {
    logger.info(`Found existing entity ${entity.id}, performing update rather instead of add`, { id: entity.id });
    list.splice(itemIndex, 1, entity);
  } else {
    list.push(entity);
  }
}

export function upsertLoadedResult(resultsInStore: Entity[], loadedResults: Entity[]) {
  const entityIdToIndex = new Map<string, number>();
  resultsInStore.forEach((r, index) => entityIdToIndex.set(r.id, index));
  loadedResults.forEach((r) => {
    const index = entityIdToIndex.get(r.id);
    if (index == undefined) {
      // new ride id, push the the end
      resultsInStore.push(r);
    } else {
      // existing ride id, update in place
      resultsInStore.splice(index, 1, r);
    }
  });
}

export function removeFromList(list: Entity[], id: string) {
  const rideIndex = list.findIndex((r) => r.id == id);
  if (rideIndex == -1) {
    logger.warn(`Cannot remove entity ${id}, as it does not exist`, { id: id });
  } else {
    list.splice(rideIndex, 1);
  }
}

interface AsyncRequestResult<T> {
  request: CancelablePromise<T>,
  response: Promise<T>,
}

export async function makeAsyncRequest<T>(
  pendingRequest: CancelablePromise<T> | undefined,
  getRequestFn: () => Promise<() => CancelablePromise<T>>,
  setLoadingFn: () => void,
  unsetLoadingFn: () => void): Promise<AsyncRequestResult<T>> {

  setLoadingFn();
  if (pendingRequest) {
    pendingRequest.cancel();
  }

  try {
    const request = (await getRequestFn())();
    return {
      request,
      response: request
        .then((resp) => {
          setTimeout(() => unsetLoadingFn(), 0);
          return resp;
        })
        .catch((exception) => {
          if (exception instanceof DOMException && exception.name == 'AbortError') {
            // ignore abort error
            throw undefined;
          } else {
            logger.error('Request failed', { exception: exception.toString() });
            setTimeout(() => unsetLoadingFn(), 0);
            throw exception;
          }
        })
    };
  } catch (exception: any) {
    unsetLoadingFn();
    logger.error('Failed to get request function', { exception: exception.toString() });
    throw exception;
  }
}
