import moment, { Moment } from 'moment';
import { action, orchestrator } from 'satcheljs';
import { logger } from '../../logging/winston';
import { getValueFromNotificationArgs } from '../../notifications/NotificationUtils';
import { onNotificationClickedRouter } from '../../notifications/routers';
import { getTripDetailWithIdPath } from '../../pages/routes';
import {
  ActivityDto, CancelablePromise, CreateTripDto, CreateTripGroupDto, ParticipantsDto, Service,
  TripDetailDto, TripGroupDetailDto, TripInvitationDto, UpdateTripGroupDto, UserListDto
} from '../../services/openapi';
import { browserHistory } from '../../utils/history';
import { setTripChatsLoaded, setTripGroupChatsLoaded } from '../mutators/ChatStoreMutators';
import { addError } from '../mutators/ErrorStoreMutators';
import { ChatKind, TripChat, getChatStore } from '../stores/ChatStore';
import { getCurrentCommunity } from '../stores/CommunityStore';
import { newError } from '../stores/ErrorStore';
import { getMyTrips, getTrip } from '../stores/TripStore';
import { getUserFavoriteResortsIds, getUserStore } from '../stores/UserStore';
import { makeAsyncRequest } from '../utils/utils';
import {
  addSentTripInvitations,
  addTrip, resetTrips, setChangingTripStatus,
  setIsLoadingInvitationsForTrip,
  setMyTripLoading, setTripGroupsForTrip, setTripsLoading,
  setTripsLoadingBackground, unsetChangingTripStatus,
  unsetMyTripLoading,
  unsetTripsLoading, unsetTripsLoadingBackground, upsertSearchedTrip, upsertTripGroups
} from './../mutators/TripStoreMutators';
import { joinChat, leaveChat } from './ChatStoreOrchestrators';
import { subscribeToTripGroupUpdate, subscribeToTripUpdate, unsubscribeToTripGroupUpdate, unsubscribeToTripUpdate } from './DataPushSubscription';

const REQUEST_TO_JOIN_TRIP_GROUP = 'REQUEST_TO_JOIN_TRIP_GROUP';
const RESET_ALL_TRIPS = 'RESET_ALL_TRIPS';

export const searchTrip = action('SEARCH_TRIP', (startDate: Moment, daysCount: number, isBackgroundSearch?: boolean) => ({ startDate, daysCount, isBackgroundSearch }));
export const fetchMyTrip = action('FETCH_MY_TRIP');
export const fetchTrip = action(
  'FETCH_TRIP',
  (tripId: string, callback?: (trip: TripDetailDto | undefined) => void) => ({ tripId, callback })
);
export const resetAllTrips = action(RESET_ALL_TRIPS);
export const fetchMyTripGroups = action('FETCH_MY_TRIP_GROUPS');
export const fetchTripGroupsOfTrip = action('FETCH_TRIP_GROUPS_OF_TRIP', (tripId: string, callback?: (tripGroupList: TripGroupDetailDto[]) => void) => ({ tripId, callback }));
export const fetchTripGroup = action('FETCH_TRIP_GROUP', (tripGroupId: string, callback?: () => void) => ({ tripGroupId, callback }));
export const createTripGroup = action('CREATE_TRIP_GROUP', (tripId: string, requestBody: CreateTripGroupDto, callback?: () => void ) => ({ tripId, requestBody, callback }));
export const updateTripGroup = action(
  'UPDATE_TRIP_GROUP',
  (tripId: string, groupId: string, requestBody: UpdateTripGroupDto, callback?: (tripGroup: TripGroupDetailDto) => void ) => ({ tripId, groupId, requestBody, callback })
);
export const requestToJoinTripGroup = action(REQUEST_TO_JOIN_TRIP_GROUP, (tripGroupId: string, onsuccess?: () => void, onerror?: (err: string) => void ) => ({ tripGroupId, onsuccess, onerror }));
export const joinTripGroup = action('JOIN_TRIP_GROUP', (groupId: string, requestBody: UserListDto, callback?: () => void ) => ({ groupId, requestBody, callback }));
export const leaveTripGroup = action('LEAVE_TRIP_GROUP', (groupId: string, tripId: string, callback?: () => void ) => ({ groupId, tripId, callback }));
export const createTrip = action('CREATE_TRIP', (
  date: string,
  resort: string,
  callback?: () => void) => ({ date, resort, callback }));
export const changeTripStatus = action('CHANGE_TRIP_STATUS', (
  date: string,
  resortId: string,
  tripStatus: CreateTripDto.participationStatus,
  callback?: (trip: TripDetailDto) => void) => ({ date, resortId, tripStatus, callback }));
export const fetchSentTripInvitations = action('FETCH_SENT_TRIP_INVITATIONS', (
  tripId: string,
  callback?: () => void) => ({ tripId, callback }));
export const inviteToTrip = action('INVITE_TO_TRIP', (
  tripId: string,
  inviteeIds: string[],
  callback?: () => void) => ({ tripId, inviteeIds, callback }));

let loadTripPromise: CancelablePromise<TripDetailDto[]> | undefined = undefined;
let fetchMyTripPromise: CancelablePromise<TripDetailDto[]> | undefined = undefined;
let joinTripPromise: CancelablePromise<TripDetailDto> | undefined = undefined;
let fetchMyTripGroupsPromise: CancelablePromise<TripGroupDetailDto[]> | undefined = undefined;
const fetchSentTripInvitationsPromises: {[tripId: string]: CancelablePromise<TripInvitationDto[]>} = {};
const inviteToTripPromises: {[tripId: string]: CancelablePromise<TripInvitationDto[]>} = {};

function createTripChat(trip: TripDetailDto): TripChat {
  return {
    kind: ChatKind.TRIP,
    id: trip.id,
    trip,
    messages: [],
  };
}

function updateTripChats() {
  const { tripChats } = getChatStore();
  const myTrips = getMyTrips();
  tripChats.forEach((chat) => {
    if (!myTrips.find((trip) => trip.id === chat.trip.id)) {
      leaveChat(chat.id);
    }
  });
  myTrips.forEach((trip) => {
    const existingChat = tripChats.find((chat) => chat.trip.id === trip.id);
    if (!existingChat) {
      joinChat(createTripChat(trip));
    } else {
      existingChat.trip = trip;
    }
  });
}

function joinTripGroupChat(tripGroup: TripGroupDetailDto) {
  const { tripGroupChats } = getChatStore();
  const existingChat = tripGroupChats.find((chat) => chat.tripGroup.id === tripGroup.id);
  if (!existingChat) {
    joinChat({
      kind: ChatKind.TRIP_GROUP,
      id: tripGroup.id,
      tripGroup,
      messages: [],
    });
  } else {
    existingChat.tripGroup = tripGroup;
  }
}

function leaveTripGroupChatIfSelfIsNotInGroup(tripGroup: TripGroupDetailDto, selfUserId: string): boolean {
  if (!tripGroup.members.some((m) => m.user.id === selfUserId)) {
    leaveChat(tripGroup.id);
    return true;
  }
  return false;
}

function joinTripGroupChatIfSelfIsInGroup(tripGroup: TripGroupDetailDto, selfUserId: string): boolean {
  if (tripGroup.members.some((m) => m.user.id === selfUserId)) {
    joinTripGroupChat(tripGroup);
    return true;
  }
  return false;
}

function updateAllMyTripGroupChats(allMyTripGroups: TripGroupDetailDto[]) {
  const { tripGroupChats } = getChatStore();
  tripGroupChats.forEach((chat) => {
    if (!allMyTripGroups.find((tripGroup) => tripGroup.id === chat.tripGroup.id)) {
      leaveChat(chat.id);
    }
  });
  allMyTripGroups.forEach((tripGroup) => {
    joinTripGroupChat(tripGroup);
  });
}

function updateMyTripGroupChatsForTrip(tripId: string, selfUserId: string, tripGroups: TripGroupDetailDto[]) {
  const tripGroupsWithMe = tripGroups.filter((tripGroup) => tripGroup.members.some((member) => member.user.id === selfUserId));
  const { tripGroupChats } = getChatStore();
  tripGroupChats.forEach((chat) => {
    if (chat.tripGroup.trip.id == tripId && !tripGroupsWithMe.find((tripGroup) => tripGroup.id === chat.tripGroup.id)) {
      leaveChat(chat.id);
    }
  });
  tripGroupsWithMe.forEach((tripGroup) => {
    joinTripGroupChat(tripGroup);
  });
}

orchestrator(searchTrip, async ({ startDate, daysCount, isBackgroundSearch }: { startDate: Moment, daysCount: number, isBackgroundSearch?: boolean }) => {
  const currentCommunity = getCurrentCommunity();
  if (!currentCommunity) {
    logger.error('Failed fetch trip. Current community is missing.');
    addError(newError('SEARCH_TRIP.MissingCommunity'));
    return;
  }
  const favoriteResortsIds = getUserFavoriteResortsIds();
  try {
    setTripChatsLoaded(false);
    const { request, response } = await makeAsyncRequest(
      loadTripPromise,
      () => Promise.resolve(() => Service.tripsControllerSearch(
        [ currentCommunity.community.id ],
        startDate.format('YYYY/MM/DD'),
        daysCount,
        favoriteResortsIds,
        '')),
      () => {
        isBackgroundSearch ? setTripsLoadingBackground() : setTripsLoading();
      },
      () => {
        unsetTripsLoading();
        unsetTripsLoadingBackground();
      });
    loadTripPromise = request;

    const trips = await response;
    upsertSearchedTrip(trips);
    updateTripChats();
    trips.forEach((t) => subscribeToTripUpdate(t.id));
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to search trip. Service request error.`, { exception, startDate, daysCount });
      addError(newError('SEARCH_TRIP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  } finally {
    setTripChatsLoaded(true);
  }
});

orchestrator(fetchMyTrip, async () => {
  const currentCommunity = getCurrentCommunity();
  if (!currentCommunity) {
    logger.error('Failed fetch my trip. Current community is missing.');
    addError(newError('FETCH_MY_TRIP.MissingCommunity'));
    return;
  }
  try {
    setTripChatsLoaded(false);
    const { request, response } = await makeAsyncRequest(
      fetchMyTripPromise,
      () => Promise.resolve(() => Service.meControllerGetTrips(moment().format('YYYY/MM/DD'), 30, [])),
      () => setMyTripLoading(),
      () => unsetMyTripLoading());
    fetchMyTripPromise = request;

    const trips = (await response).filter((trip) => trip.communityId === currentCommunity.community.id);
    upsertSearchedTrip(trips);
    updateTripChats();
    trips.forEach((t) => subscribeToTripUpdate(t.id));
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to search trip. Service request error.`, { exception });
      addError(newError('FETCH_MY_TRIP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  } finally {
    setTripChatsLoaded(true);
  }
});

orchestrator(resetAllTrips, () => {
  resetTrips();
  fetchMyTrip();
  searchTrip(moment(), 30, true /* isBackgroundSearch */);
});

orchestrator(fetchSentTripInvitations, async ({ tripId, callback }) => {
  try {
    const { request, response } = await makeAsyncRequest(
      fetchSentTripInvitationsPromises[tripId],
      () => Promise.resolve(() => Service.meControllerGetSentTripInvites(tripId)),
      () => setIsLoadingInvitationsForTrip(tripId, true),
      () => setIsLoadingInvitationsForTrip(tripId, false));
    fetchSentTripInvitationsPromises[tripId] = request;

    const tripInvitations = await response;
    addSentTripInvitations(tripInvitations);
    callback && callback();
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to fetch sent trip invitations. Service request error.`, { exception });
      addError(newError('FETCH_SENT_TRIP_INVITATIONS.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(inviteToTrip, async ({ tripId, inviteeIds, callback }) => {
  try {
    const { request, response } = await makeAsyncRequest(
      inviteToTripPromises[tripId],
      () => Promise.resolve(() => Service.tripsControllerInviteToTrip(tripId, { userIds: inviteeIds })),
      () => setIsLoadingInvitationsForTrip(tripId, true),
      () => setIsLoadingInvitationsForTrip(tripId, false));
    inviteToTripPromises[tripId] = request;

    const tripInvitations = await response;
    addSentTripInvitations(tripInvitations);
    callback && callback();
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to invite trip. Service request error.`, { exception });
      addError(newError('INVITE_TO_TRIP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(fetchTrip, async ({ tripId, callback }) => {
  try {
    const trip = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerGet(tripId)),
      () => {},
      () => {})).response;
    addTrip(trip);
    if ([ ParticipantsDto.selfAttending.INTERESTED, ParticipantsDto.selfAttending.GOING ].includes(trip.participants.selfAttending)) {
      joinChat(createTripChat(trip));
      subscribeToTripUpdate(trip.id);
    } else {
      unsubscribeToTripUpdate(trip.id);
    }
    callback && callback(trip);
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to fetch trip. Service request error.`, { exception, tripId });
      addError(newError('FETCH_TRIP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
    callback && callback(undefined);
  }
});

orchestrator(fetchMyTripGroups, async () => {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    logger.error('Failed to fetch my trip groups. Current user is missing.');
    addError(newError('FETCH_MY_TRIP_GROUPS.MissingUser'));
    return;
  }
  try {
    setTripGroupChatsLoaded(false);
    const { request, response } = await makeAsyncRequest(
      fetchMyTripGroupsPromise,
      () => Promise.resolve(() => Service.meControllerGetTripGroups(moment().format('YYYY/MM/DD'), 30)),
      () => {},
      () => {});
    fetchMyTripGroupsPromise = request;
    const myTripGroups = await response;

    updateAllMyTripGroupChats(myTripGroups);
    upsertTripGroups(myTripGroups);
    myTripGroups.forEach((g) => subscribeToTripGroupUpdate(g.id));
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to fetch my trip groups. Service request error.`, { exception });
      addError(newError('FETCH_MY_TRIP_GROUPS.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  } finally {
    setTripGroupChatsLoaded(true);
  }
});

orchestrator(fetchTripGroupsOfTrip, async ({ tripId, callback }) => {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    logger.error('Failed to fetch trip group. Current user is missing.');
    addError(newError('FETCH_TRIP_GROUPS_OF_TRIP.MissingUser'));
    return;
  }
  try {
    setTripGroupChatsLoaded(false);
    const tripGroups = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerListTripGroups(tripId, [
        TripGroupDetailDto.purpose.GENERAL, TripGroupDetailDto.purpose.CARPOOL, TripGroupDetailDto.purpose.RIDE,
      ], [
        TripGroupDetailDto.privacy.PUBLIC, TripGroupDetailDto.privacy.PRIVATE
      ])),
      () => {},
      () => {})).response;
    updateMyTripGroupChatsForTrip(tripId, currentUser.id, tripGroups);
    setTripGroupsForTrip(tripId, tripGroups);
    callback && callback(tripGroups);
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to fetch trip group. Service request error.`, { exception, tripId });
      addError(newError('FETCH_TRIP_GROUPS_OF_TRIP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  } finally {
    setTripGroupChatsLoaded(true);
  }
});

orchestrator(fetchTripGroup, async ({ tripGroupId, callback }) => {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    logger.error('Failed to fetch trip group. Current user is missing.');
    addError(newError('FETCH_TRIP_GROUP.MissingUser'));
    return;
  }
  try {
    const tripGroup = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerGetTripGroup(tripGroupId)),
      () => {},
      () => {})).response;
    upsertTripGroups([ tripGroup ]);
    if (!leaveTripGroupChatIfSelfIsNotInGroup(tripGroup, currentUser.id)) {
      // only attempt join if didn't leave
      joinTripGroupChatIfSelfIsInGroup(tripGroup, currentUser.id);
    }
    callback && callback();
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to fetch trip group. Service request error.`, { exception, tripGroupId });
      addError(newError('FETCH_TRIP_GROUP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(createTripGroup, async ({ tripId, requestBody, callback }) => {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    logger.error('Failed to create trip group. Current user is missing.');
    addError(newError('CREATE_TRIP_GROUP.MissingUser'));
    return;
  }
  try {
    const tripGroup = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerCreateTripGroup(tripId, requestBody)),
      () => {},
      () => {})).response;
    upsertTripGroups([ tripGroup ]);
    joinTripGroupChatIfSelfIsInGroup(tripGroup, currentUser.id);
    subscribeToTripGroupUpdate(tripGroup.id);
    callback && callback();
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to create trip group. Service request error.`, { exception, tripId });
      addError(newError('CREATE_TRIP_GROUP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(updateTripGroup, async ({ tripId, groupId, requestBody, callback }) => {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    logger.error('Failed to update trip group. Current user is missing.');
    addError(newError('UPDATE_TRIP_GROUP.MissingUser'));
    return;
  }
  try {
    const tripGroup = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerUpdateTripGroup(tripId, groupId, requestBody)),
      () => {},
      () => {})).response;
    upsertTripGroups([ tripGroup ]);
    callback && callback(tripGroup);
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to create trip group. Service request error.`, { exception, tripId });
      addError(newError('CREATE_TRIP_GROUP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(requestToJoinTripGroup, async ({ tripGroupId, onsuccess, onerror }) => {
  try {
    await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerRequestToJoinTripGroup(tripGroupId)),
      () => {},
      () => {})).response;
    onsuccess && onsuccess();
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to request to join trip group. Service request error.`, { exception, tripGroupId });
      addError(newError(`${REQUEST_TO_JOIN_TRIP_GROUP}.ServiceError`, `${exception.toString()} ${JSON.stringify(exception)}`));
      onerror && onerror(JSON.stringify(exception));
    }
  }
});

orchestrator(joinTripGroup, async ({ groupId, requestBody, callback }) => {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    logger.error('Failed to join trip group. Current user is missing.');
    addError(newError('JOIN_TRIP_GROUP.MissingUser'));
    return;
  }
  try {
    await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerJoinTripGroup(groupId, requestBody)),
      () => {},
      () => {})).response;
    subscribeToTripGroupUpdate(groupId);
    fetchTripGroup(groupId, callback);
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to join trip group. Service request error.`, { exception, groupId });
      addError(newError('JOIN_TRIP_GROUP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(leaveTripGroup, async ({ groupId, tripId, callback }) => {
  try {
    await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerLeaveTripGroup(groupId)),
      () => {},
      () => {})).response;
    unsubscribeToTripGroupUpdate(groupId);
    fetchTripGroupsOfTrip(tripId, callback);
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to fetch trip. Service request error.`, { exception, tripId });
      addError(newError('LEAVE_TRIP_GROUP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(createTrip, async ({ date, resort, callback }) => {
  const currentCommunity = getCurrentCommunity();
  if (!currentCommunity) {
    logger.error('Failed create trip . Current community is missing.');
    addError(newError('CREATE_TRIP.MissingCommunity'));
    return;
  }
  try {
    const { request, response } = await makeAsyncRequest(
      joinTripPromise,
      () => Promise.resolve(() => Service.tripsControllerCreate({
        communityId: currentCommunity.community.id,
        date,
        resortId: resort
      })),
      () => {},
      () => {});
    joinTripPromise = request;

    const trip = await response;
    addTrip(trip);
    callback && callback();
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to create trip. Service request error.`, { exception, date, resort });
      addError(newError('CREATE_TRIP.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(changeTripStatus, async ({ date, resortId, tripStatus, callback }: {
  date: string,
  resortId: string,
  tripStatus: CreateTripDto.participationStatus,
  callback?: (trip: TripDetailDto) => void
}) => {
  const currentCommunity = getCurrentCommunity();
  if (!currentCommunity) {
    logger.error('Failed change trip status. Current community is missing.');
    addError(newError('CHANGE_TRIP_STATUS.MissingCommunity'));
    return;
  }
  try {
    if (tripStatus == CreateTripDto.participationStatus.NOT_GOING) {
      const existingTrip = getTrip(date, resortId);
      if (existingTrip) {
        leaveChat(existingTrip.id);
      }
    }

    const { request, response } = await makeAsyncRequest(
      joinTripPromise,
      () => Promise.resolve(() => Service.tripsControllerCreate({
        communityId: currentCommunity.community.id,
        date,
        resortId,
        participationStatus: tripStatus
      })),
      () => setChangingTripStatus(),
      () => unsetChangingTripStatus());
    joinTripPromise = request;

    const trip = await response;
    addTrip(trip);
    if (tripStatus != CreateTripDto.participationStatus.NOT_GOING) {
      joinChat(createTripChat(trip));
      subscribeToTripUpdate(trip.id);
    } else {
      unsubscribeToTripUpdate(trip.id);
    }
    callback && callback(trip);
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to change trip status trip. Service request error.`, { exception, date, resortId });
      addError(newError('CHANGE_TRIP_STATUS.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

export function fetchAndGoToTrip(tripId: string) {
  fetchTrip(tripId, () => browserHistory.push({
    pathname: getTripDetailWithIdPath(tripId),
  }));
}

[ ActivityDto.type.TRIP_FRIEND_GOING,
  ActivityDto.type.TRIP_FRIEND_INTERESTED,
  ActivityDto.type.TRIP_INVITEE_GOING,
  ActivityDto.type.TRIP_INVITEE_INTERESTED,
  ActivityDto.type.TRIP_INVITE_NEW,
  ActivityDto.type.TRIP_CARPOOL_UPDATED,
  ActivityDto.type.TRIP_GROUP_NEW,
].forEach((activityType) => {
  onNotificationClickedRouter.addRoute(activityType, args => {
    logger.debug('Handling clicked new message notification', { args });
    const tripId = getValueFromNotificationArgs(args, 'trip.id');
    if (!tripId) {
      logger.error(`Cannot resolve trip.id arg for '${activityType}'`, { args });
    } else {
      fetchAndGoToTrip(tripId);
    }
  });
});