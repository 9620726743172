import moment from "moment";
import { action, orchestrator } from "satcheljs";
import { logger } from "../../logging/winston";
import { Service } from "../../services/openapi";
import { addError } from "../mutators/ErrorStoreMutators";
import { newError } from "../stores/ErrorStore";
import { getResortStore } from '../stores/ResortStore';
import { ResortConditionDto } from './../../services/openapi/models/ResortConditionDto';
import { upsertResortCondition, upsertResortRoadCondition } from './../mutators/ResortConditionStoreMutators';

export const fetchResortCondition = action('FETCH_RESORT_CONDITION');
export const fetchResortRoadCondition = action('FETCH_RESORT_ROAD_CONDITION', (resortId: string, onCompletion: () => void) => ({ resortId, onCompletion }));

orchestrator(fetchResortCondition, async () => {
  try {
    const resortList = getResortStore().resortList;
    // TODO: hard code conditions for now
    const requests = await Service.catalogControllerSearchResortConditions(
      moment().format('YYYY/MM/DD'),
      30,
      resortList?.map((r) => r.id) || []
    );
    requests.forEach((condition: ResortConditionDto) => {
      upsertResortCondition(condition);
    });
  } catch (exception: any) {
    logger.error(`Failed to fetch resort condition`);
    addError(newError('FETCH_RESORT_CONDITION.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
});

orchestrator(fetchResortRoadCondition, async ({ resortId, onCompletion }) => {
  try {
    const conditions = await Service.catalogControllerGetResortRoadConditions(resortId, new Date().toISOString(), 6);
    upsertResortRoadCondition(resortId, conditions);
  } catch (exception: any) {
    logger.error(`Failed to fetch resort road condition`);
    addError(newError('FETCH_RESORT_ROAD_CONDITION.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
  } finally {
    onCompletion();
  }
});