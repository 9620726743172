import { ObservableMap, ObservableSet } from 'mobx';
import { createStore } from 'satcheljs';
import { CommunityDto, CommunityUserDto } from '../../services/openapi';
import { getUserStore } from './UserStore';

interface CommunityStore {
  myCommunities: CommunityUserDto[],
  allCommunities: CommunityDto[],
  communityUsers: ObservableMap<string, CommunityUserDto[]>
  loadingAllCommunities: boolean,
  loadingMyCommunities: boolean,
  loadingCommunityUsers: ObservableSet<string>
}

export const getCommunityStore = createStore<CommunityStore>('CommunityStore', {
  myCommunities: [],
  allCommunities: [],
  communityUsers: new ObservableMap<string, CommunityUserDto[]>(),
  loadingAllCommunities: false,loadingMyCommunities: false,
  loadingCommunityUsers: new ObservableSet<string>(),
});

export function getCurrentCommunity(): CommunityUserDto | undefined {
  const { currentUser } = getUserStore();
  const { myCommunities } = getCommunityStore();
  const currentCommunityId = currentUser?.preference.currentCommunityId;
  return currentCommunityId ? myCommunities.find(uc => uc.community.id === currentCommunityId) : undefined;
}
