import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { ResortDto } from "../../services/openapi";
import { SelectOption } from "../Shared/MultiSelectExpanded";
import { ReactComponent as Tick } from "./assets/tick.svg";
import "./ResortFilter.scss";

export interface ResortFilterProps {
  onDismiss: (data?: string[] | undefined) => void;
  resorts: ResortDto[];
  initialResortsState: Map<string, boolean>;
}

export const ResortFilter = ({
  onDismiss,
  resorts,
  initialResortsState
}: ResortFilterProps) => {
  const [ resortState, setResortState ] = useState<Map<string, boolean>>(new Map(initialResortsState));
  const [ isInvalid, setIsInvalid ] = useState<boolean>(Array.from(resortState.values()).filter(value => value).length == 0);
  const handleResortClick = (resortId: string) => () => {
    resortState.set(resortId, !resortState.get(resortId));
    setResortState(new Map(resortState));
    setIsInvalid(Array.from(resortState.values()).filter(value => value).length == 0);
  };
  const handleResetClick = () => {
    setResortState(new Map(initialResortsState));
  };
  const handleSelectAllClick = () => {
    const newResortState = new Map(resorts.map(resort => [ resort.id, true ]));
    setResortState(newResortState);
  };
  const perStateOptions = resorts.reduce((acc, resort, index) => {
    const state = resort.state;
    if (!acc.has(state)) {
      acc.set(state, [ {
        index,
        label: resort.location?.name || 'Resort Name',
        value: resort.id,
      } ]);
    } else {
      acc.get(state)?.push({
        index,
        label: resort.location?.name || 'Resort Name',
        value: resort.id,
      });
    }
    return acc;
  }, new Map<string, SelectOption[]>());
  const selectOptionsElem = Array.from(perStateOptions.entries()).map(([ state, options ], index) => {
    const resortOptions = options.map((option) => {
      return (
        <div key={option.index} className="resortFilterResortOption" onClick={handleResortClick(option.value)}>
          <div className="resortFilterResortName">{option.label}</div>
          {
            <div className="resortFilterSelected">
              {
                resortState.get(option.value) ?
                  <Tick /> :
                  <></>
              }
            </div>
          }
        </div>
      );
    });
    return (
      <div key={index}>
        <div className="resortFilterStateTitle">{state}</div>
        <div className="resortFilterResortOptions">
          {resortOptions}
        </div>
      </div>
    );
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="resortFilterToolbar">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>Cancel</IonButton>
          </IonButtons>
          <IonTitle className="resortFilterModalTitle">Filters</IonTitle>
          <IonButtons  slot="end">
            <IonButton onClick={handleResetClick}>Reset</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding resortFilterContent">
        <div className="resortFilterFilterContainer">
          <div className="resortFilterResortTitle">Resorts</div>
          <div>{selectOptionsElem}</div>
          <div className="resortFilterAllResorts" onClick={handleSelectAllClick}>All Resorts</div>
          {
            isInvalid && <div className="resortFilterErrorMessage">*Please select at least one resort to continue.</div>
          }
        </div>
        <div className="resortFilterApplyContainer">
          <IonButton
            className="resortFilterApplyButton"
            disabled={isInvalid}
            onClick={() => onDismiss(Array.from(resortState.entries()).filter(([ _, selected ]) => selected).map(([ resortId, _ ]) => resortId))}>
            Apply
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};