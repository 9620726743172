import { getPlatforms } from "@ionic/core";
import { useIonToast } from "@ionic/react";
import { useEffect } from "react";
import { logger } from "../logging/winston";
import { isUnsupportedEmbeddedBrowser } from "../utils/platform";
import "./OpenNative.scss";

export const OpenNative: React.FC = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isIos = /iPad|iPhone|iPod/.test(userAgent);
  const isAndroid = /Android/.test(userAgent);
  const isEmbeddedBrowser = isUnsupportedEmbeddedBrowser();
  const platforms = getPlatforms();
  logger.info('Deciding if to open native app', { userAgent, isIos, isAndroid, isEmbeddedBrowser, platform: platforms });
  if (isEmbeddedBrowser) {
    return (<></>);
  }
  const isNativeApp = platforms.includes('cordova') || platforms.includes('capacitor');
  if ((isIos || isAndroid) && !isNativeApp) {
    const [ present, dismiss ] = useIonToast();
    const presentFn = () => present({
      message: ` with ${isIos ? "iOS" : "Android"} Fusend App`,
      duration: 0,
      cssClass: 'openNativeToast',
      position: 'bottom',
      buttons: [
        {
          handler: () => dismiss(),
          role: 'cancel',
          text: 'Close'
        },
        {
          text: 'Open',
          handler: () => {
            let path = window.location.href.replace(window.location.origin, '');
            if (path.startsWith('/')) {
              path = path.substring(1);
            }
            if (isAndroid) {
              logger.info('Opening Android app', { path });
              window.location.replace(`intent://fusend.app/${path}#Intent;scheme=https;package=com.fusend.snoqualmiepass;end`);
            }
            if (isIos) {
              const href = `https://deeplink.fusend.app/${path}`;
              logger.info('Opening iOS app', { 'href': href });
              window.location.replace(href);
            }
          },
          side: 'start',
        }
      ],
    });
    useEffect(() => {
      presentFn();
    });
  }
  return (<></>);
};