import { Camera } from '@capacitor/camera';
import { IonButton, IonButtons, IonContent, IonHeader, IonLoading, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react";
import "cropperjs/dist/cropper.css";
import React from "react";
import { default as Cropper } from "react-cropper";
import { setCurrentUser } from '../../dataflow/mutators/UserStoreMutators';
import { logger } from '../../logging/winston';
import { UserDto } from '../../services/openapi';
import { request } from '../../services/openapi/core/request';
import './ChangeProfilePicModal.scss';

interface ChangeProfilePicModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const ChangeProfilePicModal: React.FC<ChangeProfilePicModalProps> = (props: ChangeProfilePicModalProps) => {
  const modalRef = React.useRef<HTMLIonModalElement>(null);
  const cropperRef = React.createRef<HTMLImageElement>();
  const croppedImgRef = React.createRef<HTMLImageElement>();
  const [ imgUrl, setImgUrl ] = React.useState<string | undefined>();
  const [ prevImgUrl, setPrevImgUrl ] = React.useState<string | undefined>();
  const [ isCropping, setIsCropping ] = React.useState<boolean>(false);
  const [ cropper, setCropper ] = React.useState<Cropper>();
  const [ isUploading, setIsUploading ] = React.useState<boolean>(false);
  const [ presentAlert ] = useIonAlert();

  const choosePhoto = async () => {
    const image = await Camera.pickImages({
      quality: 90,
      limit: 1
    });
    const imageUrl = image.photos[0].webPath;
    // Can be set to the src of an image now
    if(image && imageUrl) {
      if(imgUrl) {
        setPrevImgUrl(imgUrl);
      }
      setImgUrl(imageUrl);
      setIsCropping(true);
    }
  };

  const takePicture = async () => {
    let cameraPermissions = await Camera.checkPermissions();
    if (cameraPermissions.photos != 'granted') {
      logger.warn('Camera permissions not granted, going to ask for permission', {
        cameraPermissions
      });
      cameraPermissions = await Camera.requestPermissions();
      if (cameraPermissions.photos != 'granted') {
        logger.warn('Camera permissions not granted after asking', {
          cameraPermissions
        });
        presentAlert('Camera permissions not granted, please grant us permission in order to upload a profile photo');
        return;
      }
    }
    choosePhoto();
  };

  const onCancelCrop = () => {
    setImgUrl(prevImgUrl);
    setIsCropping(false);
  };

  const onCrop = () => {
    if (typeof cropper !== "undefined") {
      setIsCropping(false);
      setImgUrl(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const onUpload = () => {
    if (!imgUrl) {
      return;
    }

    setIsUploading(true);
    fetch(imgUrl).then(res => res.blob()).then(blob => {
      logger.debug(`blob ${blob.size} ${blob.type}`);
      return request<UserDto>({
        method: 'POST',
        path: '/v1/me/pictures/profile',
        formData: {
          'file': blob
        },
      });
    }).then(res => {
      setIsUploading(false);
      setCurrentUser(res);
      setImgUrl(undefined);
      props.onDismiss();
      modalRef.current?.dismiss();
    }).catch(err => {
      logger.error('Error uploading profile picture', { exception: err });
    });
  };

  return (
    <IonModal ref={modalRef} isOpen={props.isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Change Profile Picture</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => {
              modalRef.current?.dismiss();
              props.onDismiss();
            }}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={isUploading} message="Uploading Picture"/>
        <div className="cropperContainer">
          {imgUrl && isCropping?
            <Cropper
              src={imgUrl}
              style={{ height: "100%" }}
              // Cropper.js options
              aspectRatio={1}
              ref={cropperRef}
              viewMode={1}
              dragMode="move"
              cropBoxResizable={false}
              cropBoxMovable={false}
              minCanvasHeight={400}
              minCanvasWidth={400}
              minCropBoxHeight={400}
              minCropBoxWidth={400}
              autoCropArea={1}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
            : imgUrl &&
            <img ref={croppedImgRef} src={imgUrl}/>
          }
        </div>
        { isCropping?
          <div className="uploadImageBtnGroup">
            <IonButton className="uploadImageBtn" onClick={onCrop}>Confirm</IonButton>
            <IonButton className="uploadImageBtn" onClick={onCancelCrop}>Cancel</IonButton>
          </div>
          :
          <div className="uploadImageBtnGroup">
            {imgUrl && <IonButton className="uploadImageBtn" onClick={onUpload}>Upload</IonButton> }
            <IonButton className="uploadImageBtn" onClick={takePicture}>Choose Profile from Gallery</IonButton>
          </div>
        }
      </IonContent>
    </IonModal>
  );
};

export default ChangeProfilePicModal;
