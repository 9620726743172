import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';
import { UserDto } from '../../services/openapi';
import './EditableUserField.scss';
import { EditableUserFieldProps, EditType } from './utils/getUserFieldInputProps';

interface EditableUserFieldComponentProps extends EditableUserFieldProps {
  user: UserDto;
  labelSize?: string;
  valueSize?: string;
  readonly?: boolean;
  modifiedField?: any;
  onChange?: (newValue: any) => void;
}

const EditableInput: React.FC<EditableUserFieldComponentProps> = ({
  user,
  propertyDisplayName,
  propertyKeys,
  readonly,
  labelSize,
  valueSize,
  modifiedField,
  onChange,
  validation,
}: EditableUserFieldComponentProps) => {
  const errorMessage = validation?.state[0];
  const inputComponent = propertyKeys.map((key: string) => {
    let displayValue = modifiedField && modifiedField[key] != undefined ? modifiedField[key]: user[(key as keyof UserDto)];
    if(typeof displayValue != 'string') {
      displayValue = "";
    }
    const onInputChange = (e: any) => {
      const newObj: any = {};
      newObj[key] = e.detail.value;
      onChange && onChange(newObj);
      validation && validation.validationFunc(e.detail.value, validation.state[1]);
    };
    return <IonCol key={key} size={valueSize}>
      <IonInput
        readonly={readonly}
        onIonChange={onInputChange}
        className={readonly ? 'userProfileEditableInputOnly': 'userProfileEditableInput'}
        value={displayValue}/>
      {errorMessage && <IonRow className="userProfileEditableRowError">{errorMessage}</IonRow>}
    </IonCol>;
  });
  return (
    <IonItem className="userProfileEditableItem">
      <IonRow className="userProfileEditableRow">
        <IonCol size={labelSize}>
          <IonLabel className='userProfileEditableLabel'>{propertyDisplayName}</IonLabel>
        </IonCol>
        {inputComponent}
      </IonRow>
    </IonItem>
  );
};

const EditableSelect: React.FC<EditableUserFieldComponentProps> = (props: EditableUserFieldComponentProps) => {
  const { user, propertyDisplayName, propertyKeys, options, readonly, onChange, modifiedField } = props;
  const inputComponent = propertyKeys.map((key: string) => {
    const displayValue = (modifiedField && modifiedField[key]? modifiedField[key]: user[(key as keyof UserDto)]) || '';
    if(typeof displayValue == 'string' && options) {
      const optionsComponent = options.map((op) => <IonSelectOption key={op.key} value={op.key}>{op.value}</IonSelectOption>);
      if(readonly) {
        return <IonCol key={key}><p className='userProfileEditableReadonly'>{displayValue}</p></IonCol>;
      } else {
        const onSelectChange = (e: any) => {
          const newObj: any = {};
          newObj[key] = e.detail.value;
          onChange && onChange(newObj);
        };
        return <IonCol size={'6'} key={key}>
          <IonSelect
            interface="action-sheet"
            className='userProfileEditableSelect'
            onIonChange={onSelectChange}
            key={key}
            value={displayValue}>
            {optionsComponent}
          </IonSelect>
        </IonCol>;
      }
    }
  });
  return (
    <IonItem className="userProfileEditableItem">
      <IonRow className="userProfileEditableRow">
        <IonCol size={'6'}>
          <IonLabel className='userProfileEditableLabel'>{propertyDisplayName}</IonLabel>
        </IonCol>
        {inputComponent}
      </IonRow>
    </IonItem>
  );
};

const EditableUserField: React.FC<EditableUserFieldComponentProps> = (props: EditableUserFieldComponentProps) => {
  switch(props.editType) {
    case EditType.input:
      return (<EditableInput {...props}/>);
    case EditType.select:
      return (<EditableSelect {...props}/>);
  }
};

export default EditableUserField;