import { observer } from 'mobx-react-latest';
import { useHistory } from 'react-router';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { Chat, ChatKind, getChatSubtitle, getChatTitle, getLatestChatMessage, hasUnread, Message } from '../../dataflow/stores/ChatStore';
import { TripGroupDetailDto } from '../../services/openapi';
import { getChatMessageTimestampText } from '../../utils/chat';
import TripGroupThumbnail from '../TripDetail/TripGroupThumbnail';
import { getChatRoomPath } from '../routes';
import './ChatCard.scss';

interface ChatCardProps {
  chat: Chat;
}

interface ChatCardRenderInfo {
  picture: JSX.Element;
  title: string;
  subtitle: string | undefined;
  message:  Message | undefined;
  unread: boolean;
}

function getRenderInfo(chat: Chat): ChatCardRenderInfo {
  const title = getChatTitle(chat);
  const subtitle = getChatSubtitle(chat);
  const message = getLatestChatMessage(chat);
  const unread = hasUnread(chat).get();
  switch (chat.kind) {
    case ChatKind.PEOPLE:
      const otherUser = chat.otherParticipants[0]; // assuming only 1 other user
      return {
        picture: <ProfilePicture
          className='chatCardProfilePicture'
          key={chat.id}
          user={otherUser}
          overrideDefaultClick={true}
        />,
        title,
        subtitle,
        message,
        unread,
      };
    case ChatKind.RIDE:
      return {
        picture: (
          <div className='chatCardIconContainer ride'>
            <img src="/assets/icon/car.svg"/>
          </div>
        ),
        title,
        subtitle,
        message,
        unread,
      };
    case ChatKind.TRIP:
      return {
        picture: (
          <div className='chatCardIconContainer trip'>
            <img src="/assets/icon/gondola.svg"/>
          </div>
        ),
        title,
        subtitle,
        message,
        unread,
      };
    case ChatKind.TRIP_GROUP:
      const tripGroup = chat.tripGroup;
      return {
        picture: tripGroup.purpose == TripGroupDetailDto.purpose.RIDE
          ? (
            <div className='chatCardIconContainer ride'>
              <img src="/assets/icon/car.svg"/>
            </div>
          )
          : (
            <div className='chatCardIconContainer tripGroup'>
              <TripGroupThumbnail purpose={tripGroup.purpose} members={tripGroup.members}/>
            </div>
          ),
        title,
        subtitle,
        message,
        unread,
      };
    default:
      return {
        picture: <div></div>,
        title,
        subtitle,
        message: undefined,
        unread: false,
      };
  }
}


export const ChatCard: React.FC<ChatCardProps> = observer((props: ChatCardProps) => {
  const history = useHistory();
  const { chat }= props;

  const renderInfo = getRenderInfo(chat);
  const unread = renderInfo.unread;

  return (
    <div className='chatCard' onClick={() => history.push(getChatRoomPath(chat.id))}>
      <div className='chatCardPic'>{renderInfo.picture}</div>
      <div className={`chatCardBody ${unread ? 'unread': ''}`}>
        <div className='chatCardTitle'>{renderInfo.title}</div>
        {renderInfo.subtitle &&
          <div className='chatCardSubtitle'>{renderInfo.subtitle}</div>
        }
        {
          renderInfo.message != undefined &&
          <div className='chatCardMessage'>
            <div className='chatCardMessageContent'>{renderInfo?.message?.content}</div>
            <div className='chatCardMessageTime'>{getChatMessageTimestampText(renderInfo.message.sentTime)}</div>
          </div>
        }
      </div>
      <div className='chatCardUnread'>
        {
          unread && <div className='chatCardUnreadDot'></div>
        }
      </div>
    </div>
  );
});
