import { getPlatforms } from '@ionic/react';

export function isUnsupportedEmbeddedBrowser(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor;
  // micromessenger - wechat
  // fban, fbav - facebook
  return /micromessenger|fban|fbav/i.test(userAgent);
}

export function isWechat(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /micromessenger/i.test(userAgent);
}

export function isFacebook(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /fban|fbav/i.test(userAgent);
}

export async function runOnPlatform<T>(nativeAction: () => Promise<T>, webAction: () => Promise<T>): Promise<T> {
  if (getPlatforms().findIndex(p => p == 'mobileweb' || p == 'desktop') == -1 || isUnsupportedEmbeddedBrowser()) {
    return await nativeAction();
  } else {
    return await webAction();
  }
}
