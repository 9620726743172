import { mutatorAction } from 'satcheljs';
import { Error, getErrorStore, Warning } from '../stores/ErrorStore';

export const addError = mutatorAction('ADD_ERROR', (error: Error) => {
  getErrorStore().errors.push(error);
});

export const addWarning = mutatorAction('ADD_WARNING', (warning: Warning) => {
  getErrorStore().warnings.push(warning);
});
