import moment from 'moment';
import { mutatorAction } from 'satcheljs';
import { ResortRoadConditionDto } from '../../services/openapi';
import { ResortConditionDto } from '../../services/openapi/models/ResortConditionDto';
import { getResortConditionStore } from '../stores/ResortConditionStore';

export const upsertResortCondition = mutatorAction('upsertResortCondition', (condition: ResortConditionDto) => {
  const resortId = condition.resort.id;
  const byResortId = getResortConditionStore().weather[resortId] || {};
  byResortId[moment(condition.date.split('T')[0]).format('YYYY/MM/DD')] = condition;
  getResortConditionStore().weather[resortId] = byResortId;
});

export const upsertResortRoadCondition = mutatorAction('upsertResortRoadCondition', (resortId: string, conditions: ResortRoadConditionDto[]) => {
  getResortConditionStore().roadCondition[resortId] = conditions;
});