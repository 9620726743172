import { IonButton, IonIcon, IonSpinner, useIonAlert } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import React from "react";
import { useHistory } from "react-router";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { changeTripStatus, joinTripGroup } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { getChat, getLatestChatMessage } from "../../dataflow/stores/ChatStore";
import { getTripStore } from "../../dataflow/stores/TripStore";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { logger } from "../../logging/winston";
import { CreateTripDto, MemberInfoDto, TripGroupDetailDto } from "../../services/openapi";
import { getChatMessageTimestampText } from "../../utils/chat";
import { getChatRoomPath } from "../routes";
import './TripGroupCard.scss';
import TripGroupThumbnail from "./TripGroupThumbnail";

interface TripGroupCardProps {
  id: string;
  title: string;
  tripId: string;
  purpose: TripGroupDetailDto.purpose;
  privacy: TripGroupDetailDto.privacy;
  members: MemberInfoDto[];
  isPlaceholder: boolean;
  isPartOfTrip: boolean;
  date: string | undefined | null;
  resortId: string | undefined | null;
}

const TripGroupCard = observer((props: TripGroupCardProps) => {
  const history = useHistory();
  const [ presentInterestTripAlert ] = useIonAlert();
  const { id, title, tripId, purpose, members, isPartOfTrip, isPlaceholder, date, resortId } = props;
  const tripGroupObj = getTripStore().tripGroupMap.get(tripId)?.filter(g => g.id == id)[0];
  const { currentUser } = getUserStore();
  let isPartOfGroup = false;
  if (purpose == TripGroupDetailDto.purpose.TRIP) {
    isPartOfGroup = isPartOfTrip;
  } else {
    isPartOfGroup = !!tripGroupObj?.members?.filter(m => m.user.id == currentUser?.id).length;
  }
  const shouldShowCreateBtn = isPartOfTrip && !isPartOfGroup;
  const shouldGoToChatPage = isPartOfTrip && !!isPartOfGroup;
  const [ isJoiningGroup, setIsJoiningGroup ] = React.useState<boolean>(false);
  const maxVisibleMemberCount = 6;
  const moreMemberCountText = members.length > maxVisibleMemberCount ? `+${members.length - maxVisibleMemberCount }`: '';

  let chatMsgSender = '';
  let chatMsgContent = '';
  let chatMsgTimestamp = '';
  if (shouldGoToChatPage) {
    const latestMessage = getLatestChatMessage(getChat(id));
    if (latestMessage) {
      chatMsgContent = latestMessage.content;
      const sender = members.find((m) => m.user.id == latestMessage.fromUser)?.user;
      if (sender) {
        chatMsgSender = sender ? `${sender.firstName} ${sender.lastName}`.substring(0, 10).trim() + ': ' : '';
      }
      chatMsgTimestamp = getChatMessageTimestampText(latestMessage.sentTime);
    }
  }

  const joinGroup = (e: any) => {
    e.stopPropagation();
    if (!isPartOfTrip) {
      presentInterestTripAlert({
        header: "Interested in Trip ?",
        message: "Joining group chat will automatically join the current trip. Do you want to preceed?",
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              logger.info('User decided to stay in this group');
            }
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              if (date && resortId) {
                changeTripStatus(
                  date,
                  resortId,
                  CreateTripDto.participationStatus.INTERESTED,
                  () => {
                    if (purpose != TripGroupDetailDto.purpose.TRIP && currentUser) {
                      joinTripGroup(id, { userIds: [ currentUser.id ] }, () => {
                        setIsJoiningGroup(false);
                      });
                    } else if (purpose == TripGroupDetailDto.purpose.TRIP) {
                      setIsJoiningGroup(false);
                    }
                  });
                setIsJoiningGroup(true);
              }
            }
          }
        ],
      });
    } else {
      if (!isPlaceholder) { // do noting for placeholder
        currentUser && joinTripGroup(id, { userIds: [ currentUser.id ] }, () => {
          setIsJoiningGroup(false);
        });
        setIsJoiningGroup(true);
      }
    }
  };

  return (
    <div className="tripGroupCard" onClick={() => {
      if (shouldGoToChatPage) {
        if (id) {
          history.push(getChatRoomPath(id));
        }
      }
    }}>
      <div className="TripGroupCardPic">
        <TripGroupThumbnail purpose={purpose} members={members}/>
      </div>
      <div className="tripGroupCardBody">
        <div className="tripGroupCardTitle">{ title }</div>
        { shouldShowCreateBtn &&
           <div className="tripGroupMemberList">
             {
               members.slice(0, maxVisibleMemberCount).map((m) => {
                 return <ProfilePicture key={m.user.id} className="tripGroupCardMemberProfile" user={m.user} overrideDefaultClick={false}/>;
               })
             }
             {moreMemberCountText &&
             <span className="tripGroupMemberPlus">{moreMemberCountText}</span>
             }
           </div>
        }
        { shouldGoToChatPage &&
          <div className="tripGroupCardChat">
            <div className="tripGroupCardChatMessage">
              <span className="tripGroupCardChatMessageSender">{chatMsgSender}</span>
              <span className="tripGroupCardChatMessageContent">{chatMsgContent}</span>
            </div>
            <div className="tripGroupCardChatTimestamp">{chatMsgTimestamp}</div>
          </div>
        }
      </div>
      { !isPartOfGroup &&
        <IonButton onClick={joinGroup} className="tripGroupCardJoinBtn" size="small">
          { isJoiningGroup ? <IonSpinner name='crescent'/>:  "Join" }
        </IonButton>
      }
      { shouldGoToChatPage &&
        <IonIcon className="tripGroupCardForwardChevron" icon={chevronForward} />
      }
    </div>
  );
});

export default TripGroupCard;