import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed, PushNotifications, PushNotificationSchema, Token
} from '@capacitor/push-notifications';
import { addError, addWarning } from '../dataflow/mutators/ErrorStoreMutators';
import { newError, newWarning } from '../dataflow/stores/ErrorStore';
import { logger } from '../logging/winston';
import { onNotificationClickedRouter, onNotificationReceivedRouter } from './routers';


async function registerNotificationWithCapacitor(): Promise<string> {
  return new Promise(async (resolve, reject) => {
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        logger.info(`Notification registered successfully with token: ${token.value}`);
        resolve(token.value);
      });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error) => {
        logger.error('Failed to register notification', { error });
        addError(newError('Notification.RegisterError', JSON.stringify(error)));
        reject(error);
      });

    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        logger.info('Notification received', { notification });
        onNotificationReceivedRouter.navigateTo(notification?.data?.TYPE, notification?.data?.TYPE_ARGS);
      });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (action: ActionPerformed) => {
        logger.info('Notification action performed', { action });
        onNotificationClickedRouter.navigateTo(action?.notification?.data?.TYPE, action?.notification?.data?.TYPE_ARGS);
      });

    try {
      const status = await PushNotifications.requestPermissions();
      if (status.receive !== 'granted') {
        logger.warn('Notification permission is not granted', { status });
        addWarning(newWarning('Notification is now allowed. Some app functionalities may not work well.'));
      }
      logger.info('Notification permission granted');

      logger.info('Registering with Apple / Google to receive push via APNS/FCM');
      await PushNotifications.register();
    } catch (err) {
      logger.error('Failed to request notification permission', err);
      addError(newError('Notification.PermissionRequestError', JSON.stringify(err)));
      reject(err);
    }
  });
}

export async function initializedNotification(): Promise<string | undefined> {
  if (Capacitor.isPluginAvailable('PushNotifications')) {
    const token = await registerNotificationWithCapacitor();
    logger.info('PushNotifications initialized.');
    return token;
  } else {
    logger.warn('PushNotifications plug in is not available. SW based notification may not be working.');
    return undefined;
  }
}
