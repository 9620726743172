import { IonContent, IonPage } from "@ionic/react";
import { alertCircleOutline, documentOutline, lockClosedOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { GoBack } from "../../components/navigation-header/GoBack";
import { disclaimerPath, privacyPath, myProfilePath, termsOfServicePath } from "../routes";
import "./Important.css";
import { MenuItem } from "./MenuItem";

export const Important: React.FC = () => {
  const history = useHistory();
  const menuItems = [
    {
      icon: documentOutline,
      title: "Terms of Service",
      hasNextLevel: true,
      onClick: () => history.push(termsOfServicePath)
    },
    {
      icon: lockClosedOutline,
      title: "Privacy",
      hasNextLevel: true,
      onClick: () => history.push(privacyPath)
    },
    {
      icon: alertCircleOutline,
      title: "Disclaimer",
      hasNextLevel: true,
      onClick: () => history.push(disclaimerPath)
    },
  ].map((props, index) => <MenuItem key={index} {...props} />);

  return (
    <IonPage className="legal-page">
      <GoBack title="The Important Stuff ⚖️" backUrl={myProfilePath}></GoBack>
      <IonContent className="legal-ion-content">
        {menuItems}
      </IonContent>
    </IonPage>
  );
};