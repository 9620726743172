import { isPlatform } from '@ionic/react';
import { logger } from './logging/winston';
import { Service } from './services/openapi';

const MAJOR = 0;
const MINOR = 4;
const PATCH = 0;

export class AppVersion {
  private readonly versionText: string;

  constructor(
    private readonly major: number,
    private readonly minor: number,
    private readonly patch: number,
    commitId?: string) {
    this.versionText = `${major}.${minor}.${patch}${commitId ? '-' + commitId.substring(0, 8) : ''}`;
  }

  public toString(): string {
    return this.versionText;
  }

  static compare(a: AppVersion, b: AppVersion): number {
    if (a.major !== b.major) {
      return a.major - b.major;
    }
    if (a.minor !== b.minor) {
      return a.minor - b.minor;
    }
    return a.patch - b.patch;
  }
}

const APP_VERSION = new AppVersion(MAJOR, MINOR, PATCH, process.env.REACT_APP_COMMIT_ID);

export function getAppVersion(): AppVersion {
  return APP_VERSION;
}

const appStoreLink = 'https://apps.apple.com/in/app/fusend/id1590694427';
const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.fusend.snoqualmiepass';

export class VersionTooLowError {
  constructor(public readonly requiredVersion: AppVersion) { }

  public getAppLink(): string {
    return isPlatform('android') ? googlePlayLink : appStoreLink;
  }
}

export async function getVersionTooLowError(): Promise<VersionTooLowError | undefined> {
  try {
    const dto = await Service.meControllerGetMinAppVersion();
    const requiredVersion = new AppVersion(dto.major, dto.minor, dto.patch);
    if (AppVersion.compare(APP_VERSION, requiredVersion) < 0) {
      return new VersionTooLowError(requiredVersion);
    }
  } catch (e: any) {
    logger.warn('Failed to get min app version', { exception: e.toString() });
    return;
  }
}