import { IonBackButton, IonButtons, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import "../../pages/page.scss";

interface GoBackProps {
  backUrl: string;
  title: string;
  detailsIcon?: string;
  onDetailsClicked?: () => void;
}

export const GoBack: React.FC<GoBackProps> = ({ backUrl, title, detailsIcon, onDetailsClicked }: GoBackProps) => {
  return (
    <IonHeader className='fusendPageHeader'>
      <IonToolbar className='fusendPageToolbar'>
        <IonButtons slot="start">
          <IonBackButton className="discoverPeoplePageBackBtn" defaultHref={backUrl} text="" icon={chevronBack} />
        </IonButtons>
        <IonTitle className='fusendPageTitle'>
          {title}
          {
            detailsIcon &&
              <div className="goback-icon" onClick={onDetailsClicked}></div>
          }
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};