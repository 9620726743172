import { createStore } from 'satcheljs';
import { ResortRoadConditionDto } from '../../services/openapi';
import { ResortConditionDto } from '../../services/openapi/models/ResortConditionDto';

export interface ResortConditionStore {
  weather: { [resortId: string]: { [date: string]: ResortConditionDto } };
  roadCondition: { [resortId: string]: ResortRoadConditionDto[] };
}

const initialResortConditionStore = {
  weather: {},
  roadCondition: {},
};

export const getResortConditionStore = createStore<ResortConditionStore>('ResortConditionStore', initialResortConditionStore);

export const getResortWeather = (resortId: string, date: string): ResortConditionDto | undefined => {
  const byResortId = getResortConditionStore().weather[resortId];
  if (!byResortId) return undefined;
  return byResortId[date];
};

export const getResortRoadCondition = (resortId: string): ResortRoadConditionDto[] | undefined => {
  const conditions = getResortConditionStore().roadCondition[resortId];
  if (!conditions) return undefined;
  return conditions;
};