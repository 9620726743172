import _ from "lodash";
import { observer } from "mobx-react-latest";
import React from "react";
import { useHistory } from "react-router";
import { updateUser } from "../../dataflow/orchestrators/UserStoreOrchestrators";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { UserDto } from "../../services/openapi";
import { ValueType } from "../Shared/EditableValue";
import { SelectOption } from "../Shared/MultiSelectExpanded";
import { loginPath, myProfilePath } from "../routes";
import { EditableDetail } from "./EditableDetail";

const preferredSports = [
  UserDto.preferredSport.SKI,
  UserDto.preferredSport.SNOWBOARD,
  UserDto.preferredSport.BOTH,
  UserDto.preferredSport.OTHER,
].map((level, index) => ({
  label: level,
  value: level,
  index
}));

const abilityLevels = [
  UserDto.abilityLevel.MAGIC_CARPET,
  UserDto.abilityLevel.GREEN,
  UserDto.abilityLevel.BLUE,
  UserDto.abilityLevel.BLACK,
  UserDto.abilityLevel.DOUBLE_BLACK,
].map((level, index) => ({
  label: level,
  value: level,
  index
}));

export const MyPreference: React.FC = observer(() => {
  const user = getUserStore().currentUser;
  const isLoading = getUserStore().loading;
  const history = useHistory();

  if (!user) {
    history.push(loginPath(myProfilePath, window.location.search));
    return <div />;
  }

  const [ localUser, setLocalUser ] = React.useState(_.cloneDeep(user));

  const editableItems = [
    {
      title: "Preferred Sports",
      value: localUser.preferredSport,
      editableDetail: {
        type: ValueType.Select,
        selectOptions: {
          options: preferredSports,
          onChange: (value: SelectOption) => setLocalUser({ ...localUser, preferredSport: value.value as any }),
        }
      }
    },
    {
      title: "Preferred Terrain",
      value: localUser.abilityLevel,
      editableDetail: {
        type: ValueType.Select,
        selectOptions: {
          options: abilityLevels,
          onChange: (value: SelectOption) => setLocalUser({ ...localUser, abilityLevel: value.value as any }),
        }
      }
    }
  ];
  const onSave = () => {
    updateUser(localUser);
  };
  return <EditableDetail pageTitle="My Preference" editableItems={editableItems} isSaving={isLoading} onSave={onSave} />;
});