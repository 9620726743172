import { IonButton, IonButtons, IonContent, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { observer } from 'mobx-react-latest';
import { useEffect } from 'react';
import { fetchAllCommunities, fetchCommunityUsers, fetchMyCommunities, joinCommunity, updateCurrentCommunity } from '../../dataflow/orchestrators/CommunityStoreOrchestrators';
import { getCommunityStore, getCurrentCommunity } from '../../dataflow/stores/CommunityStore';
import { CommunityDto, CommunityUserDto, PreviewUserDto } from '../../services/openapi';
import './CommunityInfo.scss';

export interface CommunityInfoProps {
  onDismiss: () => void;
}

interface CommunityEntry {
  community: CommunityDto;
  isMyCommunity: boolean;
  owner: PreviewUserDto | undefined;
  communityUsers: CommunityUserDto[];
}

export const CommunityInfo = observer((props: CommunityInfoProps) => {
  const currentCommunity = getCurrentCommunity();
  const { allCommunities, myCommunities, loadingAllCommunities, communityUsers, loadingMyCommunities, loadingCommunityUsers } = getCommunityStore();
  const { onDismiss } = props;

  useEffect(() => {
    fetchAllCommunities();
    fetchMyCommunities();
  }, []);

  useEffect(() => {
    allCommunities.forEach((community) => {
      fetchCommunityUsers(community.id);
    });
  }, [ allCommunities, myCommunities ]);

  if (loadingAllCommunities || loadingMyCommunities || loadingCommunityUsers.size > 0) {
    return <IonPage><IonLoading isOpen={true} message={'Loading communities...'} /></IonPage>;
  }

  const communityEntries: CommunityEntry[] = allCommunities.map((community) => {
    return {
      community,
      isMyCommunity: myCommunities.some((myCommunity) => myCommunity.community.id == community.id),
      owner: communityUsers.get(community.id)?.find((communityUser) => communityUser.role == CommunityUserDto.role.OWNER)?.user,
      communityUsers: communityUsers.get(community.id) || [],
    };
  });
  communityEntries.sort((a, b) => {
    if (a.isMyCommunity && !b.isMyCommunity) {
      return -1;
    } else if (!a.isMyCommunity && b.isMyCommunity) {
      return 1;
    } else {
      return a.community.name.localeCompare(b.community.name);
    }
  });

  return (
    <IonPage className='communityInfoPage'>
      <IonHeader>
        <IonToolbar className='communityInfoToolbar'>
          <IonButtons slot='start' className='communityInfoToolbarButtons'>
            <IonButton onClick={() => onDismiss()}>Done</IonButton>
          </IonButtons>
          <IonTitle className='communityInfoToolbarTitle'>Communities</IonTitle>
          <div slot='end' className='communityInfoToolbarButtons'></div>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding communityInfoContent'>
        {
          communityEntries.map((entry) => {
            return (
              <div key={entry.community.id} className='communityEntry'>
                <div className='communityEntryName'>{entry.community.name}</div>
                <div className='communityEntryUserCount'>{entry.communityUsers.length} members</div>
                {!entry.isMyCommunity &&
                <IonButton className='communityEntryJoinButton' onClick={() => joinCommunity(entry.community.id)}>Join</IonButton>}
                {entry.isMyCommunity && currentCommunity?.community.id != entry.community.id &&
                <IonButton className='communityEntrySelectButton' onClick={() => updateCurrentCommunity(entry.community.id)}>Switch</IonButton>}
                {currentCommunity?.community.id == entry.community.id &&
                <IonButton className='communityEntrySelectButton' disabled={true} fill="outline">Current</IonButton>}
              </div>
            );
          })
        }
      </IonContent>
    </IonPage>
  );
});
