import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonToolbar, useIonModal } from '@ionic/react';
import {
  CalendarNav, CalendarNext, CalendarPrev, CalendarToday, Eventcalendar, MbscCalendarEvent, MbscCalendarEventData, MbscEventcalendarView, SegmentedGroup,
  SegmentedItem, setOptions
} from '@mobiscroll/react';
import { chatboxEllipsesOutline, chevronDown, notificationsOutline } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import React, { RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import SideMenu from '../../components/SideMenu/SideMenu';
import { joinCommunity } from '../../dataflow/orchestrators/CommunityStoreOrchestrators';
import { resetAllTrips } from '../../dataflow/orchestrators/TripStoreOrchestrators';
import { updateUser } from '../../dataflow/orchestrators/UserStoreOrchestrators';
import { countNewActivities } from '../../dataflow/stores/ActivityStore';
import { countTotalUnreadMessage } from '../../dataflow/stores/ChatStore';
import { getCurrentCommunity } from '../../dataflow/stores/CommunityStore';
import { getResortConditionStore } from '../../dataflow/stores/ResortConditionStore';
import { getResortStore } from '../../dataflow/stores/ResortStore';
import { getTripStore } from '../../dataflow/stores/TripStore';
import { getUserFavoriteResortsIds, getUserStore } from '../../dataflow/stores/UserStore';
import { PreferenceDto, UserDto } from '../../services/openapi';
import { TripFilterButtonType, generateTrips } from '../../utils/generateTrips';
import { activityPath, chatPath } from '../routes';
import './Calendar.scss';
import { CommunityInfo } from './CommunityInfo';
import { ResortFilter } from './ResortFilter';
import Trip from './Trip';
import { getTripLoaderEvents } from './TripLoader';

setOptions({
  theme: 'ios',
  themeVariant: 'light'
});

const Calendar = observer((props: RouteComponentProps) => {
  const [ view, setView ] = React.useState('week');
  const [ events, setEvents ] = React.useState<MbscCalendarEvent[]>([]);
  const { currentUser } = getUserStore();
  const communityUser = getCurrentCommunity();
  const sideMenuRef = React.createRef<HTMLIonMenuElement>();
  const calRef = React.createRef<Eventcalendar>();
  const agendaRef = React.createRef<Eventcalendar>();
  const pageRef = React.useRef(undefined);
  const [ filterState, setFilterState ] = React.useState<TripFilterButtonType>(TripFilterButtonType.ALLTRIPS);

  const [ calView, setCalView ] = React.useState<MbscEventcalendarView>(
    {
      calendar: { type: 'week', popover: false, labels: false, count: false  },
    }
  );
  const agendaView = React.useMemo<MbscEventcalendarView>(() => {
    return {
      agenda: { type: 'month', size: 3 }
    };
  }, []);

  const { isLoadingTrips, isChangingTripStatus, markedDates } = getTripStore();
  const { weather: conditions } = getResortConditionStore();
  const { resortList } = getResortStore();
  const newActivityCount = countNewActivities();
  const newChatMessageCount = countTotalUnreadMessage().get();
  const favoriteResortsIds = getUserFavoriteResortsIds();

  const [ presentResortFilter, dismissResortFilter ] = useIonModal(ResortFilter, {
    onDismiss: (selectedResort?: string[] | undefined) => {
      if (selectedResort) {
        if (communityUser) {
          joinCommunity(communityUser.community.id, selectedResort);
        } else {
          // this code path is only hit when user does not have a community
          const partialUpdate: Partial<UserDto> = {
            preference: {
              favoriteResortsIds: selectedResort,
            } as PreferenceDto
          };
          updateUser(partialUpdate);
        }
      }
      dismissResortFilter();
    },
    resorts: resortList,
    initialResortsState: new Map(resortList?.map((resort) => [ resort.id, favoriteResortsIds.includes(resort.id) ]) || [])
  });

  const [ presentCommunityInfo, dismissCommunityInfo ] = useIonModal(CommunityInfo, {
    onDismiss: () => {
      resetAllTrips();
      dismissCommunityInfo();
    }
  });

  React.useEffect(() => {
    if(isLoadingTrips) {
      setEvents(getTripLoaderEvents());
    } else {
      if(!isChangingTripStatus) {
        const generatedTripObj = generateTrips(filterState, conditions, pageRef);
        generatedTripObj && setEvents(generatedTripObj);
      }
    }
  }, [ isLoadingTrips, filterState, favoriteResortsIds ]);

  const changeView = (event: any) => {
    let view = calView;
    switch (event.target.value) {
      case 'month':
        view = {
          calendar: { type: 'month', popover: false, labels: false, count: false  },
        };
        break;
      case 'week':
        view = {
          calendar: { type: 'week', popover: false, labels: false, count: false  },
        };
        break;
    }

    setView(event.target.value);
    setCalView(view);
  };

  const navigate = (inst: Eventcalendar | null, val: any) => {
    if (inst) {
      inst.navigate(val, true);
    }
  };

  const getOnSelectedDateChange = (calendarRef: RefObject<Eventcalendar>) => {
    return React.useCallback((event) => {
      navigate(calendarRef?.current, event.date);
    }, [ calRef, agendaRef ]);
  };

  const renderEventContent = React.useCallback((data: MbscCalendarEventData) => {
    return (
      <React.Fragment>
        <Trip tripDetail={data.original}/>
      </React.Fragment>
    );
  }, [ isLoadingTrips ]);

  const customWithNavButtons = () => {
    return <div className="calendarHeaderWrapper">
      <CalendarNav className="calendarNav" />
      <div className="calendarViewPicker">
        <SegmentedGroup value={view} onChange={changeView}>
          <SegmentedItem value="week" icon="material-date-range" />
          <SegmentedItem value="month" icon="material-event-note" />
        </SegmentedGroup>
      </div>
      <div className="calendarNavBtns">
        <CalendarPrev className="calendarPrev" />
        <CalendarToday className="calendarToday"/>
        <CalendarNext className="calendarNext" />
      </div>
    </div>;
  };

  const onProfilePicClicked = () => {
    sideMenuRef.current?.open();
  };

  const getFillFromButton = (buttonType: TripFilterButtonType): "solid" | "outline" => {
    return filterState == buttonType ? "solid": "outline";
  };

  const onClickFilterToggle = (buttonType: TripFilterButtonType) => {
    return () => {
      setFilterState(buttonType);
    };
  };

  return (
    <IonPage className='homePageWrapper' ref={pageRef}>
      <IonHeader className='homePageHeader'>
        <IonToolbar className='homePageToolbar'>
          <IonButtons slot='start'>
            {currentUser &&
              <ProfilePicture
                className='homeHeaderProfilePic'
                user={{ ...currentUser, upcomingTrips: [] }}
                onProfileClicked={ onProfilePicClicked }
                overrideDefaultClick={true}
              />
            }
          </IonButtons>
          <IonButton className="communitySelectorBtn"fill="clear" onClick={() => presentCommunityInfo({ animated: true })}>
            {communityUser &&
                <div className='homeHeaderCommunity'>
                  <span>{`${communityUser.community.name}️`}</span> <IonIcon icon={chevronDown}/>
                </div>
            }
            {!communityUser &&
                <div className='homeHeaderCommunity heartbeat'>
                  {`👉 Join a Community! 👈`}
                </div>
            }
          </IonButton>
          <IonButtons className='icons' slot='primary'>
            <IonButton className='iconButton' onClick={() => props.history.push(activityPath)}>
              <IonIcon className='icon' icon={notificationsOutline} />
              {newActivityCount > 0 &&
               <IonBadge className='iconBadge' color="danger">{newActivityCount}</IonBadge>}
            </IonButton>
            <IonButton className='iconButton' onClick={() => props.history.push(chatPath)}>
              <IonIcon className='icon' icon={chatboxEllipsesOutline} />
              {newChatMessageCount > 0 &&
               <IonBadge className='iconBadge' color="danger">{newChatMessageCount}</IonBadge>}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="HomePageContainer fusendPageContent" scrollY={false}>
        <Eventcalendar
          className='HomePageCalender'
          ref={calRef}
          view={calView}
          data={events}
          marked={[ ...markedDates.values() ]}
          renderHeader={customWithNavButtons}
          onSelectedDateChange={getOnSelectedDateChange(agendaRef)}
        />
        <IonButtons className="HomePageFilterToggleContainer">
          <div className="HomePageFilterLeft">
            <IonButton
              className="HomePageFilterToggle"
              color="primary"
              fill={getFillFromButton(TripFilterButtonType.ALLTRIPS)}
              onClick={onClickFilterToggle(TripFilterButtonType.ALLTRIPS)}
            >All</IonButton>
            <IonButton
              className="HomePageFilterToggle"
              color="primary"
              fill={getFillFromButton(TripFilterButtonType.FRIENDSTRIPS)}
              onClick={onClickFilterToggle(TripFilterButtonType.FRIENDSTRIPS)}
            >Friend&apos;s Trips</IonButton>
            <IonButton
              className="HomePageFilterToggle"
              color="primary"
              fill={getFillFromButton(TripFilterButtonType.MYTRIPS)}
              onClick={onClickFilterToggle(TripFilterButtonType.MYTRIPS)}
            >My Trips</IonButton>
          </div>
          <IonButton
            className="resortFilter"
            onClick={() => presentResortFilter({
              animated: true
            })}>
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              {
              // eslint-disable-next-line max-len
                <path fillRule="evenodd" clipRule="evenodd" d="M10.1707 2C10.5825 0.834808 11.6938 0 13 0C14.3062 0 15.4175 0.834808 15.8293 2H17C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H15.8293C15.4175 5.16519 14.3062 6 13 6C11.6938 6 10.5825 5.16519 10.1707 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H10.1707ZM13 2C12.4477 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2Z" fill="#E4E7EB"/>
              }
              {
              // eslint-disable-next-line max-len
                <path fillRule="evenodd" clipRule="evenodd" d="M2.17071 12H1C0.447715 12 0 11.5523 0 11C0 10.4477 0.447715 10 1 10H2.17071C2.58254 8.83481 3.69378 8 5 8C6.30622 8 7.41746 8.83481 7.82929 10H17C17.5523 10 18 10.4477 18 11C18 11.5523 17.5523 12 17 12H7.82929C7.41746 13.1652 6.30622 14 5 14C3.69378 14 2.58254 13.1652 2.17071 12ZM4 11C4 10.4477 4.44772 10 5 10C5.55228 10 6 10.4477 6 11C6 11.5523 5.55228 12 5 12C4.44772 12 4 11.5523 4 11Z" fill="#E4E7EB"/>
              }
            </svg>
          </IonButton>
        </IonButtons>
        <Eventcalendar
          className='HomePageAgenda'
          ref={agendaRef}
          view={agendaView}
          data={events}
          renderEvent={renderEventContent}
          dateFormatLong="DDDD, D MMMM YYYY"
          actionableEvents={false}
          onSelectedDateChange={getOnSelectedDateChange(calRef)}
          showControls={false}
        />
      </IonContent>
      <SideMenu menuRef={ sideMenuRef }/>
      {isChangingTripStatus && <IonLoading isOpen={isChangingTripStatus} message={'Updating Trip'} duration={5000}/> }
    </IonPage>
  );
});

export default withRouter(Calendar);
