import { IonButton, IonItem } from "@ionic/react";
import { observer } from "mobx-react-latest";
import { useEffect, useRef, useState } from "react";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { listCarpools } from "../../dataflow/orchestrators/CarpoolStoreOrchestrator";
import { getActiveCarpoolsForTrip } from "../../dataflow/stores/CarpoolStore";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { logger } from "../../logging/winston";
import { TripCarpoolDto } from "../../services/openapi";
import './CarpoolLobby.scss';
import { IntentDetailPage, IntentDetailParams } from "./IntentDetailPage";
import { ReactComponent as HitchHike } from './icon/HitchHike.svg';

interface IntentCardProps {
  intent: TripCarpoolDto;
  currentUserId: string | undefined;
  openIntentDetail?: (tripId: string, intentId: string) => void;
  isChatCard?: boolean;
}

export const IntentCard = (props: IntentCardProps) => {
  const { intent, currentUserId, isChatCard } = props;
  const user = intent.initiator;
  const note = intent.notes;
  const selfIntent = intent.initiator.id === currentUserId;
  const tripId = intent.trip.id;
  const intentId = intent.id;

  const intentDetail = () => {
    props.openIntentDetail?.(tripId, intentId);
  };
  return (
    <IonItem className='intentCard' button onClick={intentDetail}>
      <div className="intentCardDetails">
        {isChatCard &&
        <div className='chatCardTitle'>
          <div className='roleLabel'><HitchHike className='roleLabelIcon' />I am looking for a carpool</div>
        </div>
        }
        <div className="userCard">
          <div className="userInfo">
            <ProfilePicture user={user} overrideDefaultClick={true}/>
            <div className="userCardInfo">
              <div className="userFullName">
                {user.firstName} {user.lastName}
              </div>
              <div className="username">{user.username}</div>
            </div>
          </div>
          {selfIntent && <div className="mineLabel">My Intent</div>}
        </div>
        <div className="note">{note}</div>
      </div>
    </IonItem>
  );
};

interface IntentsProps {
  tripId: string;
}

export const Intents = observer((intentsProps: IntentsProps) => {
  const { currentUser } = getUserStore();
  const intents = getActiveCarpoolsForTrip(intentsProps.tripId);
  const [ loadedIntents, setLoadedIntents ] = useState(false);
  const [ showAll, setShowAll ] = useState(false);
  const [ currentIntent, setCurrentIntent ] = useState<IntentDetailParams | null>(null);
  const modal = useRef<HTMLIonModalElement>(null);

  const openIntentDetail = (tripId: string, intentId: string) => {
    setCurrentIntent({ tripId, intentId });
    modal.current?.present();
  };

  useEffect(() => {
    if (intents.length > 0 || loadedIntents) {
      return;
    }

    listCarpools(intentsProps.tripId, (carpoolsOrError) => {
      setLoadedIntents(true);
      if (!Array.isArray(carpoolsOrError)) {
        logger.error('Failed to load carpools for trip', { exception: carpoolsOrError, tripId: intentsProps.tripId });
      }
    });
  }, [ intents ]);

  const intentsComponents = intents.map((intent: TripCarpoolDto) => {
    return <IntentCard
      key={intent.id}
      intent={intent}
      currentUserId={currentUser?.id}
      openIntentDetail={openIntentDetail}
    />;
  }).sort((_a, b) => {
    if (b.props.selfIntent) {
      return 1;
    }
    return -1;
  });
  const intentsCount = intentsComponents.length;

  return (<div className="carpoolLobbySection">
    <div className="carpoolLobbyTitleWrapper">
      <div className="carpoolLobbyTitle">Looking for carpool</div>
      {intentsCount > 3 && <IonButton fill="clear" onClick={() => setShowAll(!showAll)}>{showAll ? 'Collapse' : `View All (${intentsCount})`} </IonButton>}
    </div>
    <div className="previews">
      {intentsCount > 0 && (showAll ? intentsComponents : intentsComponents.slice(0, 3))}
      {intentsCount === 0 && <div className="noIntents">No one is looking for a carpool.</div>}
    </div>
    <IntentDetailPage tripId={currentIntent?.tripId} intentId={currentIntent?.intentId} modal={modal}/>
  </div>);
});