import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from '@ionic/react';
import { chevronBack, chevronBackOutline, chevronForwardOutline, close, pauseOutline, playOutline } from 'ionicons/icons';
import React, { ChangeEvent, useRef } from 'react';
import { homeRedirectPath } from '../routes';
import './SideBySide.scss';
import { VideoPlayer } from './VideoPlayer';

interface SharedTimelineProps {
  videoRef1: React.RefObject<HTMLVideoElement>;
  videoRef2: React.RefObject<HTMLVideoElement>;
  videoOffset2: number;
}

export const SharedTimeline: React.FC<SharedTimelineProps> = ({ videoRef1, videoRef2 }) => {
  const [ isPlaying, setIsPlaying ] = React.useState(false);
  const [ timestamp, setTimestamp ] = React.useState(videoRef1.current?.currentTime || 0);

  const setVideoTimestamp = (video1TimestampS: number) => {
    setTimestamp(video1TimestampS);
    const video1PrevTime = videoRef1.current?.currentTime || 0;

    if (videoRef1.current) {
      videoRef1.current.currentTime = timestamp;
    }

    if (videoRef2.current) {
      let videoTime2 = videoRef2.current.currentTime + video1TimestampS - video1PrevTime;
      if (videoTime2 < 0) {
        videoTime2 = 0;
      } else if (videoTime2 > videoRef2.current.duration) {
        videoTime2 = videoRef2.current.duration;
      }
      videoRef2.current.currentTime = videoTime2;
    }
  };

  if (videoRef1.current) {
    videoRef1.current.ontimeupdate = (event: Event) => {
      if (isPlaying) {
        const target = event.target as HTMLVideoElement;
        setTimestamp(target?.currentTime || 0);
      }
    };
  }

  const handleTimelineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideoTimestamp(parseFloat(event.target.value));
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef1.current?.pause();
      videoRef2.current?.pause();
    } else {
      videoRef1.current?.play();
      videoRef2.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const nextFrame = () => {
    if (videoRef1.current) {
      const nextFrameTimestampS = Math.min(timestamp + 0.03, videoRef1.current.duration);
      setVideoTimestamp(nextFrameTimestampS); // assumes 30 fps
    }
  };

  const prevFrame = () => {
    const prevFrameTimestampS = Math.max(timestamp - 0.03, 0);
    setVideoTimestamp(prevFrameTimestampS); // assumes 30 fps
  };

  return (
    <div className="sharedControls">
      <IonButton onClick={handlePlayPause} size="small">
        <IonIcon icon={isPlaying ? pauseOutline : playOutline}/>
      </IonButton>
      <IonButton size="small" onClick={prevFrame} disabled={isPlaying}>
        <IonIcon icon={chevronBackOutline}/>
      </IonButton>
      <IonButton size="small" onClick={nextFrame} disabled={isPlaying}>
        <IonIcon icon={chevronForwardOutline}/>
      </IonButton>
      <input
        className='timeline'
        type="range"
        min="0"
        max={videoRef1.current?.duration || 0}
        value={timestamp}
        step="any"
        onChange={handleTimelineChange}
      />
    </div>
  );
};


export const SideBySide: React.FC = () => {
  const [ isSync, setIsSync ] = React.useState(false);
  const [ isCompare, setIsCompare ] = React.useState(false);
  const [ video1, setVideo1 ] = React.useState<string | null>(null);
  const [ video2, setVideo2 ] = React.useState<string | null>(null);
  const videoRef1 = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);
  const videoInputRef1 = useRef<HTMLInputElement>(null);
  const videoInputRef2 = useRef<HTMLInputElement>(null);
  const [ videoOffset2, setVideoOffset2 ] = React.useState(0);

  const handleVideoUpload = (setState: React.Dispatch<React.SetStateAction<string | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const url = URL.createObjectURL(file);
    setState(url);
  };

  const handleOnSync = () => {
    videoRef1.current?.pause();
    videoRef2.current?.pause();
    if (!isSync) {
      const videoTime1 = videoRef1?.current?.currentTime || 0;
      const videoTime2 = videoRef2?.current?.currentTime || 0;
      setVideoOffset2(videoTime1 - videoTime2);
    }
    setIsSync(!isSync);
  };

  const removeVideo2 = () => {
    setVideo2(null);
    setIsCompare(false);
  };

  return (
    <IonPage className="fusendPage">
      <IonHeader className="fusendPageHeader">
        <IonToolbar className="fusendPageToolbar">
          <IonButtons slot="start">
            <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
          </IonButtons>
          <IonButtons slot="end">
            {video2 && <IonButton onClick={handleOnSync}>{isSync ? 'Unsync' : 'Sync Videos'}</IonButton>}
            {!video2 && !isCompare && <IonButton onClick={() => setIsCompare(true)}>Compare</IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="fusendPageContent profileHeaderContent sideBySideComparison">
        <div className={"videosContainer " + `${isCompare ? 'twoVideos' : 'oneVideo'}`}>
          <div className="videoContainer">
            <div className="uploadControl">
              <IonButton onClick={() => videoInputRef1.current?.click()} size="small" disabled={isSync}>Select Video</IonButton>
              <input ref={videoInputRef1} style={{ display: 'none' }} className="uploadButton" type="file" accept="video/*" onChange={handleVideoUpload(setVideo1)} />
            </div>
            {video1 && <VideoPlayer videoSrc={video1} isSync={isSync} videoRef={videoRef1} />}
          </div>
          {isCompare && <div className="videoContainer">
            <div className="uploadControl">
              <IonButton onClick={() => videoInputRef2.current?.click()} size="small" disabled={isSync}>Select Video</IonButton>
              <input ref={videoInputRef2} style={{ display: 'none' }} className="uploadButton" type="file" accept="video/*" onChange={handleVideoUpload(setVideo2)} />
              <IonButton onClick={removeVideo2} size="small" disabled={isSync}><IonIcon icon={close}></IonIcon></IonButton>
            </div>
            {video2 && <VideoPlayer videoSrc={video2} isSync={isSync} videoRef={videoRef2} />}
          </div>}
        </div>
        {isSync && <SharedTimeline videoRef1={videoRef1} videoRef2={videoRef2} videoOffset2={videoOffset2} />}
      </IonContent>
    </IonPage>
  );
};