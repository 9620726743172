import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonTextarea, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { chevronBack, shareOutline } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import moment from "moment";
import { RefObject, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { getCarpool, updateCarpool } from "../../dataflow/orchestrators/CarpoolStoreOrchestrator";
import { createOneOnOneChat } from "../../dataflow/orchestrators/ChatStoreOrchestrators";
import { fetchTrip } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { getCarpoolForTrip } from "../../dataflow/stores/CarpoolStore";
import { getRemoteConfig } from "../../dataflow/stores/RemoteConfigStore";
import { getTripById } from "../../dataflow/stores/TripStore";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { TripCarpoolDto } from "../../services/openapi";
import { calendarDateFormat } from "../../utils/carpool";
import { getShareUrl, share } from "../../utils/share";
import { getCarpoolIntentDetailPagePath, getCarpoolLobbyPath, getChatRoomPath, getProfilePagePath, homeRedirectPath } from "../routes";
import './IntentDetailPage.scss';

interface IntentDetailProps {
  tripId?: string;
  intentId?: string;
  modal?: RefObject<HTMLIonModalElement>;
}

export const IntentDetailPage = observer((props: IntentDetailProps) => {
  const isEnabled = getRemoteConfig('carpool')?.asBoolean();
  const history = useHistory();
  if (!isEnabled) {
    history.push(homeRedirectPath);
    return null;
  }

  const { tripId, intentId, modal } = props;
  const presentAsModal = !!modal;

  const trip = tripId ? getTripById(tripId) : undefined;
  const intent = (tripId && intentId) ? getCarpoolForTrip(tripId, intentId) : undefined;
  const { currentUser } = getUserStore();
  const [ isUpdating, setUpdating ] = useState(false);
  const [ isEditingNote, setIsEditingNote ] = useState(false);
  const [ actionError, setActionError ] = useState('');
  const [ isDirty, setDirty ] = useState(false);
  const [ newNote, setNewNote ] = useState(intent?.notes || '');
  const presentToast = useIonToast()[0];
  const isMyIntent = intent?.initiator.id === currentUser?.id;

  useEffect(() => {
    if (!trip && tripId) {
      fetchTrip(tripId);
    }

    if (!intent) {
      if (tripId && intentId) {
        getCarpool(tripId, intentId, (carpool) => {
          if (!(carpool as TripCarpoolDto).id) {
            history.push(getCarpoolLobbyPath(tripId));
          }
        });
      }
    } else {
      setNewNote(intent.notes);
      setDirty(false);
    }
  }, [ trip, intent ]);

  const deleteIntent = () => {
    if (!intent) {
      setActionError('Unable to update intent due to intent not being loaded, please try again later.');
      return;
    }
    setUpdating(true);
    updateCarpool(intent.id, { isActive: !intent.isActive }, (carpoolOrError) => {
      setUpdating(false);
      modal?.current?.dismiss();
      if (!(carpoolOrError as TripCarpoolDto).id) {
        setActionError(`Failed to update intent due to: ${JSON.stringify(carpoolOrError)}`);
      }
    });
  };
  const saveNote = () => {
    if (!intent) {
      setActionError('Unable to update intent due to intent not being loaded, please try again later.');
      return;
    }
    setUpdating(true);
    updateCarpool(intent.id, { notes: newNote }, (carpoolOrError) => {
      setUpdating(false);
      if (!(carpoolOrError as TripCarpoolDto).id) {
        setActionError(`Failed to update intent due to: ${JSON.stringify(carpoolOrError)}`);
      }
      setIsEditingNote(false);
    });
  };
  const chatWithParticipant = () => {
    if (!intent) {
      setActionError('Unable to create chat due to intent not being loaded, please try again later.');
      return;
    }
    setUpdating(true);
    // eslint-disable-next-line max-len
    const message = `Hi ${intent.initiator.firstName}, I wanted to chat with you about your carpool intent for ${moment.utc(intent.trip.date).calendar(calendarDateFormat)} at ${intent.trip.resort.location.name}.`;
    createOneOnOneChat(intent.initiator.id, message, (chatDto) => {
      setUpdating(false);
      props.modal?.current?.dismiss();
      history.push(getChatRoomPath(chatDto.id));
    }, (error) => {
      setUpdating(false);
      setActionError(`Failed to create chat due to: ${JSON.stringify(error)}`);
    });
  };

  const shareAction = () => {
    if (!tripId || !intentId) {
      return;
    }
    const url = getShareUrl(getCarpoolIntentDetailPagePath(tripId, intentId), currentUser);
    const shareText = `Looking for carpool\non ${moment(trip?.date?.split('T')[0]).format("dddd, MMMM Do")} \nat ${trip?.resort.location.name}.\n${intent?.notes}\n${url}`;
    share(shareText, {
      title: shareText,
      text: shareText,
      dialogTitle: shareText,
    }, () => presentToast('Carpool link copied', 2000));
  };
  const toolbarEl = (
    <IonToolbar className='fusendPageToolbar'>
      { !presentAsModal &&
            <IonButtons slot='start'>
              <IonBackButton defaultHref={tripId ? getCarpoolLobbyPath(tripId) : homeRedirectPath} icon={chevronBack} />
            </IonButtons>
      }
      <IonTitle>Passenger details</IonTitle>
      <IonButtons slot="end">
        <IonButton disabled={!tripId || !intentId} onClick={shareAction}>
          <IonIcon icon={shareOutline}/>
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
  const contentEl = (
    <IonContent className='fusendPageContent'>
      {presentAsModal && toolbarEl}
      {trip && intent &&
          <div className="intentSection">
            <div className="userInfo" onClick={() => history.push(getProfilePagePath(intent.initiator.username))}>
              <ProfilePicture
                className="intentDetailProfile"
                user={intent.initiator}/>
              <div className="nameAndUsername">
                <div className="name">{intent.initiator.firstName} {intent.initiator.lastName}</div>
                <div className="username">@{intent.initiator.username}</div>
              </div>
            </div>
            <div className="intentInfo">
              {isMyIntent && !isEditingNote &&
                <>
                  <div className="nonEditableNote">{newNote}</div>
                  <IonButton className="intentEditButton" color="secondary" fill="clear" onClick={() => setIsEditingNote(true)}>Edit</IonButton>
                </>
              }
              {!isMyIntent && <div className="nonEditableNote">{newNote}</div>}
              {isMyIntent && isEditingNote &&
                <>
                  <IonTextarea
                    className="intentEditTextarea"
                    value={newNote}
                    onIonChange={(e: any) => {
                      setNewNote(e.detail.value);
                      setDirty(true);
                    }}
                    rows={3}
                  />
                  <IonButton className="intentEditButton" color="secondary" fill="clear" onClick={() => saveNote()} disabled={!isDirty || isUpdating || !newNote}>Update</IonButton>
                </>
              }
            </div>
            <div className="intentActions">
              {!isMyIntent && intent.isActive &&
              <IonButton
                className="intentActionButton"
                color="secondary"
                onClick={() => chatWithParticipant()}
                disabled={isUpdating}>Chat with {intent.initiator.firstName}</IonButton>}
              <IonButton className="intentActionButton" onClick={shareAction}>Share</IonButton>
              {isMyIntent && intent.isActive &&
                <IonButton
                  className="intentActionButton"
                  color="danger"
                  fill="outline"
                  onClick={() => deleteIntent()}
                  disabled={isUpdating}>Delete Intent</IonButton>}
              {isMyIntent && !intent.isActive &&
                <IonButton
                  className="intentActionButton"
                  color="danger"
                  fill="clear"
                  disabled={true}>Intent is Deleted</IonButton>
              }
              {actionError && <div className="actionError">{actionError}</div>}
            </div>
          </div>
      }
    </IonContent>
  );
  if(presentAsModal) {
    return (
      <IonModal className="fusendPage" ref={modal} initialBreakpoint={0.7}>
        {contentEl}
      </IonModal>
    );
  }
  return (
    <IonPage className='fusendPage'>

      <IonHeader className='fusendPageHeader'>
        {toolbarEl}
      </IonHeader>
      {contentEl}
    </IonPage>
  );
});

export interface IntentDetailParams {
  tripId: string;
  intentId: string;
}

export const RouteIntentDetailPage = withRouter(observer((props: RouteComponentProps<IntentDetailParams>) => {
  // '/trip/:tripId/carpoolIntentDetail/:intentId';
  const parts = props.match.url.split('/');
  const intentId = parts[parts.length - 1];
  const tripId = parts[parts.length - 3];

  return (
    <IntentDetailPage tripId={tripId} intentId={intentId} />
  );
}));