import { MbscCalendarMarked } from '@mobiscroll/react';
import { ObservableMap, ObservableSet } from 'mobx';
import moment from 'moment';
import { createStore } from 'satcheljs';
import { ParticipantsDto, TripDetailDto, TripDto, TripGroupDetailDto, TripInvitationDto } from '../../services/openapi';
import { getUserStore } from './UserStore';

interface TripStore {
  markedDates: ObservableMap<string, MbscCalendarMarked>;
  tripDisplayMap:  ObservableMap<string, TripDetailDto>;
  sentTripInvitations: ObservableMap<string, TripInvitationDto[]>;
  tripGroupMap: ObservableMap<string, TripGroupDetailDto[]>;
  isLoadingTrips: boolean;
  isLoadingTripsBackground: boolean;
  isChangingTripStatus: boolean;
  isLoadingMyTrips: boolean;
  tripIdsWithLoadingSentInvitations: ObservableSet<string>;
}

export const getTripStore = createStore<TripStore>('TripStore', {
  markedDates: new ObservableMap<string, MbscCalendarMarked>(),
  tripDisplayMap: new ObservableMap<string, TripDetailDto>(),
  sentTripInvitations: new ObservableMap<string, TripInvitationDto[]>(),
  tripGroupMap: new ObservableMap<string, TripGroupDetailDto[]>(),
  isLoadingTrips: false,
  isLoadingTripsBackground: false,
  isChangingTripStatus: false,
  isLoadingMyTrips: false,
  tripIdsWithLoadingSentInvitations: new ObservableSet<string>(),
});

export interface UpcomingTrip {
  trip: TripDto;
  dateText: string;
}

export function getUpcomingTrips(trips: TripDto[], count: number): UpcomingTrip[] {
  const upcomingTrips = [];
  const today = moment().startOf('day');
  const firstSunday = moment().isoWeekday(7).startOf('day');
  const secondSunday = moment().add(1, 'weeks').isoWeekday(7).startOf('day');
  for (let i = 0; i <  trips.length; i++) {
    const t = trips[i];
    const tripDay = moment(t.date.split('T')[0]).startOf('day');
    if (tripDay.isSameOrAfter(today) && tripDay.isSameOrBefore(secondSunday)) {
      upcomingTrips.push({
        trip: t,
        dateText:
          tripDay.isSame(today) ? 'Today'
            : tripDay.isSame(today.add(1, 'day')) ? 'Tomorrow'
              : `${tripDay.format('dddd')}${tripDay > firstSunday ? ' next week' : ''}`,
      });

      if (upcomingTrips.length >= count) {
        break;
      }
    }
  }
  return upcomingTrips;
}

export function isLoadingInvitationsForTrip(tripId: string): boolean {
  return getTripStore().tripIdsWithLoadingSentInvitations.has(tripId);
}

export function getTrip(date: string, resortId: string): TripDetailDto | undefined {
  return [ ...getTripStore().tripDisplayMap.values() ].find((t) => {
    const tripDate = t.date.split('T')[0];
    return moment(date).isSame(moment(tripDate)) && t.resort.id == resortId;
  });
}

export function getTripById(tripId: string): TripDetailDto | undefined {
  return [ ...getTripStore().tripDisplayMap.values() ].find((t) => t.id === tripId);
}

export function getTrips(): TripDetailDto[] {
  return [ ...getTripStore().tripDisplayMap.values() ];
}

export function getMyTrips(): TripDetailDto[] {
  return [ ...getTripStore().tripDisplayMap.values() ].filter((t) => [ ParticipantsDto.selfAttending.GOING, ParticipantsDto.selfAttending.INTERESTED ].includes(t.participants.selfAttending));
}

export function getTripGroups(tripId: string): TripGroupDetailDto[] {
  return getTripStore().tripGroupMap.get(tripId) || [];
}

export function getTripRideGroups(tripId: string): TripGroupDetailDto[] {
  return getTripGroups(tripId).filter((g) => g.purpose == TripGroupDetailDto.purpose.RIDE && g.members.length > 0);
}

export function getTripGroup(tripId: string, tripGroupId: string): TripGroupDetailDto | undefined {
  return getTripGroups(tripId).find((tg) => tg.id === tripGroupId);
}

export function getMyRideGroup(tripId: string): TripGroupDetailDto | undefined {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    return undefined;
  }
  return getTripGroups(tripId).find((tg) => tg.purpose == TripGroupDetailDto.purpose.RIDE && tg.members.some((m) => m.user.id == currentUser.id));
}