import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonLabel, IonModal, IonSpinner, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import { PreviewUserDto, Service, UserDto } from "../../../services/openapi";

interface ProfilePicModalAddFriendProps {
  currentUser: UserDto;
  targetUser: PreviewUserDto,
  onSubmit: () => void;
}

const ProfilePicModalAddFriend: React.FC<ProfilePicModalAddFriendProps> = (props: ProfilePicModalAddFriendProps) => {
  const { currentUser, targetUser, onSubmit } = props;
  const [ followChecked, setFollowChecked ] = React.useState<boolean>(true);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ message, setMessage ] = React.useState<string>(`Hi, I'm ${currentUser.firstName} ${currentUser.lastName}`);
  const addFriendModalRef = React.useRef<HTMLIonModalElement>(null);

  const dismiss = async () => {
    await addFriendModalRef.current?.dismiss();
  };

  const onAddFriendClick = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await Service.friendRequestsControllerAdd({
      recipientUserId: targetUser.id,
      note: message,
      follow: followChecked,
    }).then(async () => {
      await dismiss();
      setIsLoading(false);
      onSubmit();
    });
  };

  return (
    <>
      {
        isLoading
          ? <IonButton color="secondary" disabled={true}><IonSpinner name="crescent"/></IonButton>
          : <IonButton color="secondary" id="addFriendTrigger">Add Friend</IonButton>
      }
      <IonModal ref={addFriendModalRef} trigger="addFriendTrigger" className="fusendPage">
        <IonHeader>
          <IonToolbar className="fusendPageToolbar">
            <IonTitle>Add Friend</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={dismiss}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="fusendPageContent addFriendModalContent">
          <IonTextarea className="profilePicModalAddFriendTextarea" value={message} rows={5} onIonChange={e => setMessage(e.detail.value || "")}></IonTextarea>
          <div className="profilePicModalAddFriendFollow">
            <IonCheckbox mode="md" checked={followChecked} onIonChange={e => setFollowChecked(e.detail.checked)} />
            <IonLabel>Follow {targetUser.firstName}&apos;s snow updates</IonLabel>
          </div>
          {
            isLoading
              ? <IonButton color="secondary" disabled={true} className="addFriendButton"><IonSpinner name="crescent"/></IonButton>
              : <IonButton color="secondary" className="addFriendButton" onClick={onAddFriendClick}>Add Friend</IonButton>
          }
        </IonContent>
      </IonModal>
    </>
  );
};

export default ProfilePicModalAddFriend;