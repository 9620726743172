import { IonButton, IonSpinner } from '@ionic/react';
import { observer } from 'mobx-react-latest';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getCarpool } from '../../dataflow/orchestrators/CarpoolStoreOrchestrator';
import { fetchTripGroup, joinTripGroup } from '../../dataflow/orchestrators/TripStoreOrchestrators';
import { getCarpoolForTrip } from '../../dataflow/stores/CarpoolStore';
import { Message } from '../../dataflow/stores/ChatStore';
import { getTripGroup } from '../../dataflow/stores/TripStore';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { logger } from '../../logging/winston';
import { PreviewUserDto } from '../../services/openapi';
import { CarpoolCard } from '../Carpool/Carpools';
import { IntentCard } from '../Carpool/Intents';
import { getCarpoolIntentDetailPagePath, getRequestToJoinTripGroupPath } from '../routes';

const systemMessageHandlers = new Map<string, (message: Message, payload: any, chatId: string, chatMembers: PreviewUserDto[]) => JSX.Element | undefined>();
const onBehalfMessageHandlers = new Map<string, (message: Message, payload: any, chatId: string, chatMembers: PreviewUserDto[]) => JSX.Element | undefined>();

export function getSystemMessageComponent(type: string, message: Message, payload: any, chatId: string, chatMembers: PreviewUserDto[]) : JSX.Element | undefined {
  let component = undefined;
  if (systemMessageHandlers.has(type)) {
    component = systemMessageHandlers.get(type)?.(message, payload, chatId, chatMembers);
  }
  return component || <>
    <div key={message.id} className='systemMessage'>-{message.content}-</div>
  </>;
}

export function getOnBehalfMessageComponent(type: string, message: Message, payload: any, chatId: string, chatMembers: PreviewUserDto[]) : JSX.Element | undefined {
  if (onBehalfMessageHandlers.has(type)) {
    return onBehalfMessageHandlers.get(type)?.(message, payload, chatId, chatMembers);
  }
  return undefined;
}

const registerSystemMessageHandler = (type: string, handler: (message: Message, payload: any, chatId: string, chatMembers: PreviewUserDto[]) => JSX.Element | undefined) => {
  systemMessageHandlers.set(type, handler);
};

const registerOnBehalfMessageHandlers = (type: string, handler: (message: Message, payload: any, chatId: string, chatMembers: PreviewUserDto[]) => JSX.Element | undefined) => {
  onBehalfMessageHandlers.set(type, handler);
};

registerSystemMessageHandler('USER_REQUEST_TO_JOIN_TRIP_GROUP', (message: Message, payload: any, chatId: string, chatMembers: PreviewUserDto[]) => {
  const requesterUserId = payload?.userId;
  const requesterUsername = payload?.username;
  if (requesterUserId && requesterUsername) {
    if (!chatMembers.some((m) => m.id == requesterUserId)) {
      return (
        <>
          <div key={message.id} className='systemMessage'>-{message.content}-</div>
          <div className='systemMessageAction'>
            <IonButton className='systemMessageActionButton'
              onClick={() => {
                joinTripGroup(chatId, { userIds: [ requesterUserId ] });
              }}>
              {`Add @${requesterUsername} to Group`}
            </IonButton>
          </div>
        </>
      );
    }
  }
  return undefined;
});

interface CarpoolIntentCreatedMessageContentProps {
  carpoolId: string;
  tripId: string;
}

const CarpoolIntentCreatedMessageContent: React.FC<CarpoolIntentCreatedMessageContentProps> = observer((props: CarpoolIntentCreatedMessageContentProps) => {
  const { carpoolId, tripId } = props;
  const history = useHistory();
  const currentUserId = getUserStore().currentUser?.id;

  const carpoolIntent = getCarpoolForTrip(tripId, carpoolId);

  useEffect(() => {
    if (!carpoolIntent) {
      getCarpool(tripId, carpoolId);
    }
  }, [ carpoolId, tripId ]);

  const openIntentDetail = (tripId: string, tripGroupId: string) => {
    history.push(getCarpoolIntentDetailPagePath(tripId, tripGroupId));
  };

  return (
    <div className='richChatMessage'>
      {carpoolIntent
        ? <IntentCard
          intent={carpoolIntent}
          currentUserId={currentUserId}
          openIntentDetail={openIntentDetail}
          isChatCard={true} />
        : <IonSpinner className='richMessageLoading' name='crescent' />
      }
    </div>
  );
});

registerOnBehalfMessageHandlers('CARPOOL_INTENT_CREATED', (message: Message, payload: any, _chatId: string, _chatMembers: PreviewUserDto[]) => {
  const carpoolId = payload?.carpoolId;
  const tripId = payload?.tripId;

  if (tripId && carpoolId) {
    return <CarpoolIntentCreatedMessageContent carpoolId={carpoolId} tripId={tripId} />;
  }

  logger.warn('CARPOOL_INTENT_CREATED system message has invalid payload', { systemMessage: message.SYSTEM_MESSAGE });
  return undefined;
});

interface RideGroupCreatedMessageContentProps {
  tripGroupId: string;
  tripId: string;
}

const RideGroupCreatedMessageContent: React.FC<RideGroupCreatedMessageContentProps> = observer((props: RideGroupCreatedMessageContentProps) => {
  const { tripGroupId, tripId } = props;
  const history = useHistory();
  const currentUserId = getUserStore().currentUser?.id;

  const tripGroup = getTripGroup(tripId, tripGroupId);

  useEffect(() => {
    if (!tripGroup) {
      fetchTripGroup(tripGroupId);
    }
  }, [ tripGroupId, tripId ]);

  const openCarpoolDetail = (tripId: string, tripGroupId: string) => {
    history.push(getRequestToJoinTripGroupPath(tripId, tripGroupId));
  };

  if ((tripGroup?.members?.length || 0) > 0) {
    return (
      <div className='richChatMessage'>
        {tripGroup
          ? <CarpoolCard
            group={tripGroup}
            currentUserId={currentUserId}
            openCarpoolDetail={openCarpoolDetail}
            isChatCard={true} />
          : <IonSpinner className='richMessageLoading' name='crescent' />
        }
      </div>
    );
  } else {
    return (
      <div className='richChatMessage'>
        <div className='rideDeleted'>Carpool: {tripGroup?.title} (deleted)</div>
      </div>
    );
  }
});

registerOnBehalfMessageHandlers('RIDE_GROUP_CREATED', (message: Message, payload: any, _chatId: string, _chatMembers: PreviewUserDto[]) => {
  const tripGroupId = payload?.tripGroupId;
  const tripId = payload?.tripId;

  if (tripId && tripGroupId) {
    return <RideGroupCreatedMessageContent tripGroupId={tripGroupId} tripId={tripId} />;
  }

  logger.warn('RIDE_GROUP_CREATED system message has invalid payload', { systemMessage: message.SYSTEM_MESSAGE });
  return undefined;
});