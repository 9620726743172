import { mutatorAction } from 'satcheljs';
import { UserDto } from '../../services/openapi';
import { getUserStore } from '../stores/UserStore';

export const setCurrentUser = mutatorAction('SET_CURRENT_USER', (user: UserDto) => {
  getUserStore().currentUser = user;
});

export const setAccessToken = mutatorAction('SET_ACCESS_TOKEN', (accessToken: string) => {
  getUserStore().accessToken = accessToken;
});

export const setNotificationToken = mutatorAction('SET_NOTIFICATION_TOKEN', (notificationToken: string | undefined) => {
  getUserStore().notificationToken = notificationToken;
});

export const clearCurrentUser = mutatorAction('CLEAR_CURRENT_USER', () => {
  getUserStore().currentUser = undefined;
});

export const clearAccessToken = mutatorAction('CLEAR_ACCESS_TOKEN', () => {
  getUserStore().accessToken = undefined;
});

export const setUserLoading = mutatorAction('SET_USER_LOADING', () => {
  getUserStore().loading = true;
});

export const unsetUserLoading = mutatorAction('UNSET_USER_LOADING', () => {
  getUserStore().loading = false;
});

export const setUserProfilePicUrl = mutatorAction('SET_USER_PROFILE_URL', (url: string) => {
  const { currentUser } = getUserStore();
  if(currentUser) {
    currentUser.profilePictureUrl = url;
  }
});