import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import { PreviewJoinRequestDto } from '../../services/openapi';

interface RideRequestStore {
  rideRequestMap: ObservableMap<string, PreviewJoinRequestDto[]>;
  loadingRideRequests: boolean;
}

export const getRideRequestStore = createStore<RideRequestStore>('RideRequestStore', {
  rideRequestMap: new ObservableMap(),
  loadingRideRequests: false,
});

export function getAllRequestsForRide(rideId: string): PreviewJoinRequestDto[] {
  return getRideRequestStore().rideRequestMap.get(rideId) || [];
}
