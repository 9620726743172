import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { getPlatforms, IonButton, isPlatform } from '@ionic/react';
import { observer } from 'mobx-react-latest';
import { useEffect, useRef } from 'react';
import { LoginProvider, userLogin } from '../../dataflow/orchestrators/UserStoreOrchestrators';
import { ErrorHandlingFn } from '../../dataflow/utils/utils';
import { logger } from '../../logging/winston';
import './Google.scss';

interface CredentialResponse {
  credential?: string
  select_by?:
  | "auto"
  | "user"
  | "user_1tap"
  | "user_2tap"
  | "btn"
  | "btn_confirm"
  | "brn_add_session"
  | "btn_confirm_add_session"
  clientId?: string
}

export function getGoogleAuthClientId(): string {
  const googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '745560566383-v43vk3e7u4a16s79ni7coc3b97crgg29.apps.googleusercontent.com';
  logger.info("Loading Google Sign In script", { googleAuthClientId });
  return googleAuthClientId;
}

function webLogin(errorHandlingFn: ErrorHandlingFn, referrerId: string | undefined): JSX.Element {
  const loadScript = (src: any) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) {
        return resolve(undefined);
      }
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(undefined);
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    });

  const handleSuccess = (res: CredentialResponse) => {
    logger.info('Google login success', { credentialLength: res.credential?.length || 0 });
    const credentialJwt = res.credential || '';
    userLogin(LoginProvider.GOOGLE, () => Promise.resolve(credentialJwt), errorHandlingFn, referrerId);
  };

  const handleError = (err: any) => {
    logger.error('Google login failed', { err });
  };

  const googleButton: React.Ref<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    const googleAuthClientId = getGoogleAuthClientId();

    loadScript(src)
      .then(() => {
        const google = window.google as any;
        google.accounts.id.initialize({
          client_id: googleAuthClientId,
          callback: handleSuccess,
        });
        google.accounts.id.renderButton(
          googleButton.current,
          { theme: 'filled_black', size: 'small', width: '225' }
        );
      })
      .then(() => {
        setInterval(() => {
          const iframes = googleButton?.current?.getElementsByTagName('iframe') as any;
          if (iframes && iframes.length > 0) {
            const iframe = iframes[0];
            iframe.style.height = '33px';
            iframe.style.margin = '0px -10px';
            (googleButton as any).current.style.visibility = 'visible';
          }
        }, 33);
      })
      .catch(handleError);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  logger.info('Using web google sign in');

  return (
    <div className="googleLoginContainer">
      <div className='googleWebLogin' ref={googleButton} style={ { visibility: "hidden" } }></div>
      <IonButton className="googleLoginButtonOverlay loginButton">
        <div className="buttonContainer">
          <img src='./assets/googleLogo.png' className='loginLogo'/>
          <span>Login with Google</span>
          <div className="loginLogoFiller"></div>
        </div>
      </IonButton>
    </div>
  );
}

function nativeLogin(errorHandlingFn: ErrorHandlingFn, referrerId: string | undefined): JSX.Element {
  useEffect(() => {
    const googleAuthClientId = getGoogleAuthClientId();
    logger.info('Initializing native google sign in');
    // initialize native google sign in only if it's a native platform, otherwise use web sign in
    GoogleAuth.initialize({
      clientId: googleAuthClientId,
      scopes: [ 'profile', 'email' ],
    });
  }, []);

  const googleLoginFailed = (error: any) => {
    logger.error('Failed to login with Google', {
      error
    });
  };

  return (<IonButton
    className='loginButton'
    onClick={() => {
      logger.info('Google login', {
        platforms: getPlatforms(),
      });

      logger.info('Logging in using native Google Sign In');
      GoogleAuth.signIn().then(user => {
        userLogin(LoginProvider.GOOGLE, () => Promise.resolve(user.authentication.idToken), errorHandlingFn, referrerId);
      }).catch(googleLoginFailed);
    }}>
    <div className="buttonContainer">
      <img src='./assets/googleLogo.png' className='loginLogo google'/>
      <span>Sign in with Google</span>
      <div className="loginLogoFiller"></div>
    </div>
  </IonButton>);
}

interface GoogleLoginProps {
  errorHandlingFn: ErrorHandlingFn
  referrerId?: string | undefined
}

export const GoogleLogin: React.FC<GoogleLoginProps> = observer(({ errorHandlingFn, referrerId }) => {
  if (isPlatform('capacitor')) {
    return nativeLogin(errorHandlingFn, referrerId);
  } else {
    return webLogin(errorHandlingFn, referrerId);
  }
});