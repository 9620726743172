/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateTripDto = {
    communityId: string;
    date: string;
    resortId: string;
    participationStatus?: CreateTripDto.participationStatus;
}

export namespace CreateTripDto {

    export enum participationStatus {
        GOING = 'Going',
        INTERESTED = 'Interested',
        NOT_GOING = 'NotGoing',
    }


}
