/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MemberInfoDto } from './MemberInfoDto';
import type { PreviewUserDto } from './PreviewUserDto';
import type { RideOptionsDto } from './RideOptionsDto';
import type { TripDto } from './TripDto';

export type TripGroupDetailDto = {
    id: string;
    trip: TripDto;
    initiator?: PreviewUserDto;
    purpose: TripGroupDetailDto.purpose;
    privacy: TripGroupDetailDto.privacy;
    title: string;
    notes: string;
    createdOn: string;
    updatedOn: string;
    members: Array<MemberInfoDto>;
    rideOptions?: RideOptionsDto;
}

export namespace TripGroupDetailDto {

    export enum purpose {
        GENERAL = 'GENERAL',
        CARPOOL = 'CARPOOL',
        RIDE = 'RIDE',
        TRIP = 'TRIP',
    }

    export enum privacy {
        PUBLIC = 'PUBLIC',
        PRIVATE = 'PRIVATE',
    }


}
