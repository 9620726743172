import { IonList, IonSpinner } from "@ionic/react";
import { PreviewUserDto } from "../../services/openapi";
import DiscoverPeopleResultListItem from "./DiscoverPeopleResultListItem";

interface DiscoverPeopleSearchResult {
  searchResultList: PreviewUserDto[];
  isSearching: boolean;
}

const DiscoverPeopleSearchResult = (props: DiscoverPeopleSearchResult) => {
  const { searchResultList, isSearching } = props;
  const searchResultItemComponent = searchResultList.map((r) => {
    return (
      <DiscoverPeopleResultListItem key={r.id} user={r}/>
    );
  });
  if (isSearching) {
    return <div className="discoverSearchSpinner">
      <IonSpinner name="crescent" />
    </div>;
  } else {
    return <IonList>{ searchResultItemComponent }</IonList>;
  }
};

export default DiscoverPeopleSearchResult;