import { mutatorAction } from 'satcheljs';
import { getActivityStore } from '../stores/ActivityStore';
import { upsertLoadedResult } from '../utils/utils';
import { ActivityDto } from './../../services/openapi/models/ActivityDto';

export const upsertActivities = mutatorAction('UPSERT_ACTIVITIES', (newActivities: ActivityDto[]) => {
  const { activities } = getActivityStore();
  upsertLoadedResult(activities, newActivities);
});

export const setActivitiesLoading = mutatorAction('SET_ACTIVITIES_LOADING', () => {
  getActivityStore().isLoadingActivities = true;
});

export const unsetActivitiesLoading = mutatorAction('UNSET_ACTIVITIES_LOADING', () => {
  getActivityStore().isLoadingActivities = false;
});

export const setActivitiesCaughtUpTimestamp = mutatorAction('SET_ACTIVITIES_CAUGHT_UP_TIMESTAMP', (caughtUpTimestamp: Date | undefined) => {
  getActivityStore().caughtUpTimestamp = caughtUpTimestamp;
});
