// Taken from https://github.com/winstonjs/winston/issues/287#issuecomment-647196496
import { TransformableInfo } from 'logform';
import TransportStream from 'winston-transport';
import { getExtras } from './utils';

// enumeration to assign color values to
enum LevelColors {
  DEBUG = 'gray',
  INFO = 'darkturquoise',
  WARN = 'khaki',
  ERROR = 'tomato',
}

// type levels used for setting color and shutting typescript up
type Levels = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';

const levelMap: {[key: string]: string} = {
  DEBUG: '🔍',
  INFO: '😊',
  WARN: '👀',
  ERROR: '❗'
};

const defaultColor = 'color: inherit';

//! Overriding winston console transporter
export class BrowserConsole extends TransportStream {
  constructor(options = {}, private sessionId: string) {
    super(options);

    this.setMaxListeners(30);
  }

  log(info: TransformableInfo, next: () => void) {
    const level = info.level.toUpperCase() in levelMap ? levelMap[info.level.toUpperCase()] : '😕';
    const args: any[] = [
      `%c${new Date().toISOString()} - %c${level} - `,
      `color: ${LevelColors[info.level.toUpperCase() as Levels]};`,
      defaultColor,
      // message will be included after stylings
      // through this objects and arrays will be expandable
      info.message
    ];
    const extras = getExtras(info) || Object.create(null);
    extras['sessionId'] = this.sessionId;
    // styles a console log statement accordingly to the log level
    // log level colors are taken from levelcolors enum
    // eslint-disable-next-line no-console
    console.log(...args, extras);

    // must call the next function here
    // or otherwise you'll only be able to send one message
    next();
  }
}
