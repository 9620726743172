import { IonCol, IonContent, IonHeader, IonItem, IonLabel, IonMenu, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { observer } from "mobx-react-latest";
import { createRef } from "react";
import { useHistory } from "react-router";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { PreviewUserDto } from "../../services/openapi";
import { getProfilePagePath } from "../routes";
import "./ChatRoomRightMenu.scss";

interface ChatRoomRightMenuProps {
  members: PreviewUserDto[];
}

const ChatRoomRightMenu = observer((props: ChatRoomRightMenuProps) => {
  const { members } = props;
  const history = useHistory();
  const menuRef = createRef<HTMLIonMenuElement>();

  const memberListComponent = members.map((m: PreviewUserDto) => {
    const profileUrl = getProfilePagePath(m.username);
    return (
      <IonItem className="participantItem" key={m.id} onClick={() => {
        history.push(profileUrl);
      }}>
        <IonRow className="participantRow">
          <IonCol size="2">
            <ProfilePicture className="participantProfilePic" user={m}/>
          </IonCol>
          <IonCol className="participantName" size="10">
            <IonLabel>{m.firstName} {m.lastName}</IonLabel>
            <p className="participantUsername">{m.username}</p>
          </IonCol>
        </IonRow>
      </IonItem>
    );
  });

  return (
    <IonMenu type='reveal' contentId="main-content" side='end' ref={menuRef}>
      <IonHeader className="fusendPageHeader">
        <IonToolbar  className="fusendPageToolbar">
          <IonTitle>Chat Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        <div>
          <h5 className="chatParticipantsTitle">Participants</h5>
          <div>
            {memberListComponent}
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
});

export default ChatRoomRightMenu;