import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar, useIonModal } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import { useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { createTripGroup, fetchTrip } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { isFeatureEnabled } from "../../dataflow/stores/RemoteConfigStore";
import { getTripById, getMyRideGroup } from "../../dataflow/stores/TripStore";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { CreateTripGroupDto, TripGroupDetailDto } from "../../services/openapi";
import TripGroupEditorModal, { TripGroupEditorOptions } from "../TripDetail/TripGroupEditorModal";
import { getCarpoolLobbyPath, getRequestToJoinTripGroupPath, homeRedirectPath } from "../routes";
import { NewIntentPage } from "./NewIntentPage";
import { ReactComponent as HitchHike } from './icon/HitchHike.svg';
import { ReactComponent as SteeringWheel } from './icon/SteeringWheel.svg';
import './RoleSelectionPage.scss';

interface RoleSelectionParams {
  tripId: string;
}

export const RoleSelectionPage = observer(() => {
  const isEnabled = isFeatureEnabled('carpool');
  const { tripId } = useParams<RoleSelectionParams>();

  const { currentUser } = getUserStore();
  const trip = getTripById(tripId);
  const myRideGroup = getMyRideGroup(tripId);
  const history = useHistory();

  const [ isCreatingRideGroup, setIsCreatingRideGroup ] = useState(false);

  if (!isEnabled) {
    return <Redirect to={homeRedirectPath} />;
  }

  const [ presentCreateIntent, dismissCreateIntent ] = useIonModal(NewIntentPage, {
    tripId: tripId,
    dismiss: (isCreatedIntent: boolean) => {
      dismissCreateIntent();
      isCreatedIntent && history.goBack();
    }
  });
  const [ presentCreateGroup, dismissCreateGroup ] = useIonModal(TripGroupEditorModal, {
    title: 'New Carpool',
    showCreateOptions: [
      TripGroupEditorOptions.Name,
      TripGroupEditorOptions.MaxSize,
      TripGroupEditorOptions.Notes,
      TripGroupEditorOptions.Members,
    ],
    notesHint: 'Add notes, such as meetup location and time.',
    confirmLabel: 'Create',
    onDismiss: () => dismissCreateGroup(),
    onConfirm: (
      selectedUserIds: string[],
      groupName: string | undefined,
      _groupPrivacy: TripGroupDetailDto.privacy,
      notes: string | undefined,
      maxSize: number | undefined,
    ) => {
      if (!groupName) {
        return;
      }
      dismissCreateGroup();
      const createDto: CreateTripGroupDto = {
        purpose: CreateTripGroupDto.purpose.RIDE,
        privacy: CreateTripGroupDto.privacy.PRIVATE,
        title: groupName,
        memberUserIds: currentUser ? selectedUserIds.concat([ currentUser.id ]) : selectedUserIds,
        notes,
        rideOptions: maxSize ? {
          maxSize: maxSize,
        } : undefined
      };
      setIsCreatingRideGroup(true);
      createTripGroup(tripId, createDto, () => {
        fetchTrip(tripId, () => {
          setIsCreatingRideGroup(false);
          history.goBack();
        });
      });
    },
    friendsList: trip?.participants.friends ?? [],
    othersList: trip?.participants.others ?? [],
  });
  const goToRideGroup = () => {
    if (myRideGroup) {
      history.push(getRequestToJoinTripGroupPath(tripId, myRideGroup.id));
    }
  };

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonBackButton defaultHref={getCarpoolLobbyPath(tripId)} icon={chevronBack} />
          </IonButtons>
          <IonTitle>Role Selection</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        {!trip && <IonLoading isOpen={!trip} message={'Loading Trip'} />}
        {trip &&
        <div>
          {myRideGroup
            ?
            <div className="roleSelectionSection">
              <IonButton className="roleAction" color="primary" onClick={() => goToRideGroup()}><SteeringWheel />{`Go to my carpool "${myRideGroup.title}"`}</IonButton>
            </div>
            :
            <>
              <div className="roleSelectionSection">
                <IonButton className="roleAction" color="secondary" onClick={() => presentCreateGroup()}><SteeringWheel />I am a driver</IonButton>
              </div>
              <div className="roleSelectionSection">
                <IonButton className="roleAction" color="secondary" onClick={() => presentCreateIntent()}><HitchHike />I am a rider</IonButton>
              </div>
            </>
          }
        </div>}
        { isCreatingRideGroup && <IonLoading isOpen={isCreatingRideGroup} message={'Creating Carpool'} /> }
      </IonContent>
    </IonPage>
  );
});