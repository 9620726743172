import _ from "lodash";
import { observer } from "mobx-react-latest";
import React from "react";
import { useHistory } from "react-router";
import { updateUser } from "../../dataflow/orchestrators/UserStoreOrchestrators";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { sessionId } from "../../logging/winston";
import { ValueType } from "../Shared/EditableValue";
import { loginPath, myProfilePath } from "../routes";
import { EditableDetail } from "./EditableDetail";

export const MyInfo: React.FC = observer(() => {
  const user = getUserStore().currentUser;
  const accessToken = getUserStore().accessToken;
  const isLoading = getUserStore().loading;

  if (!user || !accessToken) {
    const history = useHistory();
    history.push(loginPath(myProfilePath, window.location.search));
    return <div />;
  }

  const [ localUser, setLocalUser ] = React.useState(_.cloneDeep(user));

  const editableItems = [
    {
      title: "First Name",
      value: localUser.firstName,
      editableDetail: {
        type: ValueType.String,
        onChange: (value: string) => setLocalUser({ ...localUser, firstName: value }),
      }
    },
    {
      title: "Last Name",
      value: localUser.lastName,
      editableDetail: {
        type: ValueType.String,
        onChange: (value: string) => setLocalUser({ ...localUser, lastName: value }),
      }
    }
  ];
  const copiableItems = [
    {
      title: "Access Token",
      value: accessToken,
    },
    {
      title: "User ID",
      value: user.id,
    },
    {
      title: "Session ID",
      value: sessionId()
    }
  ];
  const onSave = () => {
    updateUser(localUser);
  };
  return <EditableDetail pageTitle="My Info" editableItems={editableItems} copiableItems={copiableItems} isSaving={isLoading} onSave={onSave}/>;
});