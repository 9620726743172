import { useEffect, useState } from 'react';
import { getAppVersion } from '../AppVersion';
import './JerryLoading.css';
import './JerryLoading.dark.css';

const JerryLoading: React.FC = () => {
  const goggles = '🥽';
  const [ gogglesBar, setGogglesBar ] = useState('');

  let count = 0;
  const max = 9;

  useEffect(() => {
    const interval = setInterval(() => {
      count = (count + 1)%max;
      setGogglesBar(goggles.repeat(count));
    }, 120);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='jerryContainer'>
      <div className='jerryText'>🏂⛷️Jerry is gearing up...</div>
      <div className='jerryGoggles'>{gogglesBar}</div>
      <div className='jerryVersion'>{getAppVersion().toString()}</div>
    </div>
  );
};

export default JerryLoading;