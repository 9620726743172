import { logger } from '../../logging/winston';
import { ReactComponent as Clear } from './Icons/clear.svg';
import { ReactComponent as Cloudy } from './Icons/cloudy.svg';
import { ReactComponent as Fog } from './Icons/fog.svg';
import { ReactComponent as Haze } from './Icons/haze.svg';
import { ReactComponent as HeavyRain } from './Icons/heavy-rain.svg';
import { ReactComponent as HeavySnow } from './Icons/heavy-snow.svg';
import { ReactComponent as LightRain } from './Icons/light-rain.svg';
import { ReactComponent as LightSnow } from './Icons/light-snow.svg';
import { ReactComponent as PartlySunny } from './Icons/partly-cloudy.svg';
import { ReactComponent as RainShower } from './Icons/rain-shower.svg';
import { ReactComponent as Rain } from './Icons/rain.svg';
import { ReactComponent as Sleet } from './Icons/sleet.svg';
import { ReactComponent as Snow } from './Icons/snow.svg';
import { ReactComponent as Sunny } from './Icons/sunny.svg';
import './WeatherIcon.scss';

interface WeatherIconProps {
  condition: string;
}

const WeatherIcon: React.FC<WeatherIconProps> = (props: WeatherIconProps) => {
  const { condition } = props;
  const parsedCondiction = condition.toLowerCase().split(' ').join('_');
  let icon = null;
  switch(parsedCondiction) {
    case "slight_chance_light_rain":
    case "chance_light_rain":
    case "light_rain_likely":
    case "light_rain":
      icon = <LightRain />;
      break;
    case "rain_likely":
    case "rain":
    case "chance_rain":
      icon = <Rain />;
      break;
    case "slight_chance_rain_showers":
    case "chance_rain_showers":
    case "rain_showers_likely":
    case "scattered_rain_showers":
    case "rain_showers":
      icon = <RainShower />;
      break;
    case "heavy_rain":
      icon = <HeavyRain />;
      break;
    case "partly_cloudy":
    case "mostly_cloudy":
    case "partly_sunny":
    case "mostly_sunny":
      icon = <PartlySunny />;
      break;
    case "cloudy":
    case "mostly_cloudy":
      icon = <Cloudy />;
      break;
    case "areas_of_fog":
      icon = <Cloudy />;
      break;
    case "clear":
    case "mostly_clear":
      icon = <Clear />;
      break;
    case "sunny":
      icon = <Sunny />;
      break;
    case "slight_chance_rain_and_snow_showers":
    case "slight_chance_rain_and_snow":
    case "chance_rain_and_snow":
    case "rain_and_snow":
    case "rain_and_snow_likely":
    case "rain_and_snow_showers":
    case "slight_chance_freezing_rain":
      icon = <Sleet />;
      break;
    case "slight_chance_light_snow":
    case "chance_light_snow":
    case "light_snow_likely":
    case "light_snow":
      icon = <LightSnow />;
      break;
    case "slight_chance_snow_showers":
    case "slight_chance_snow":
    case "chance_snow_showers":
    case "snow_showers_likely":
    case "snow_showers":
    case "snow_likely":
    case "chance_snow":
    case "snow":
      icon = <Snow />;
      break;
    case "heavy_snow":
    case "chance_heavy_snow":
    case "heavy_snow_likely":
      icon = <HeavySnow />;
      break;
    case "fog":
    case "areas_of_fog":
    case "patchy_fog":
    case "areas_of_freezing_fog":
    case "patchy_freezing_fog":
      icon = <Fog />;
      break;
    case "haze":
      icon = <Haze />;
      break;
    default: 
      logger.error(`MISSING WEATHER CODE: ${parsedCondiction}`);

  }
  return (
    <span className="iconWrapper">
      { icon }
    </span>
  );
};

export default WeatherIcon;