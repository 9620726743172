import { IonItem, IonRow, IonCol, IonLabel } from "@ionic/react";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { PreviewUserDto } from "../../services/openapi";

interface MemberListItemProps {
  member: PreviewUserDto;
  onItemClicked: (member: PreviewUserDto) => void;
}

const MemberListItem = (props: MemberListItemProps) => {
  const { member, onItemClicked } = props;
  return (
    <IonItem className="participantItem" key={member.id} onClick={() => onItemClicked(member)}>
      <IonRow className="participantRow">
        <IonCol size="2">
          <ProfilePicture className="participantProfilePic" user={member} overrideDefaultClick={true}/>
        </IonCol>
        <IonCol size="10">
          <IonLabel>{member.firstName} {member.lastName}</IonLabel>
          <p className="participantUsername">{member.username}</p>
        </IonCol>
      </IonRow>
    </IonItem>
  );
};

export default MemberListItem;