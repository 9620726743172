import { CheckboxChangeEventDetail, IonCheckbox, IonItem } from "@ionic/react";
import { observer } from "mobx-react-latest";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { TripParticipantDto } from "../../services/openapi";

interface TripGroupParticipantCardProps {
  participant: TripParticipantDto;
  onCheckboxChecked: (id: string, isChecked: boolean) => void;
}

const TripGroupParticipantCard = observer((props: TripGroupParticipantCardProps) => {
  const { participant, onCheckboxChecked } = props;
  const { user } = participant;
  return (
    <IonItem className="tripGroupParticipantCard">
      <ProfilePicture
        className='tripGroupParticipantCardProfilePicture'
        key={user.id}
        user={user}
        overrideDefaultClick={true}
      />
      <div className='tripGroupParticipantCardContent'>
        <div className='tripGroupParticipantCardFullName'>{`${user.firstName} ${user.lastName}`}</div>
        <div className='tripGroupParticipantCardUsername'>{user.username}</div>
      </div>
      <IonCheckbox
        slot="end"
        onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => onCheckboxChecked && onCheckboxChecked(user.id, e.detail.checked)} />
    </IonItem>
  );
});

export default TripGroupParticipantCard;