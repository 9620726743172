import { IonSpinner } from "@ionic/react";
import React from "react";
import { getRelationship, Relationship } from "../../../dataflow/utils/getRelationShip";
import { PreviewUserDto, RelationshipDto, Service, UserDto } from "../../../services/openapi";
import ProfileHeaderAddFriend from "./ProfileHeaderAddFriend";
import ProfileHeaderButtonsFriend from "./ProfileHeaderButtonsFriend";
import './ProfileHeaderContent.scss';
import ProfileHeaderFriendRequested from "./ProfileHeaderFriendRequested";
import ProfileHeaderHandleFriendRequest from "./ProfileHeaderHandleFriendRequest";

interface ProfileHeaderContentProps {
  currentUser: UserDto;
  targetUser: PreviewUserDto;
}

const ProfileHeaderContent: React.FC<ProfileHeaderContentProps> = (props: ProfileHeaderContentProps) => {
  const { currentUser, targetUser } = props;
  const { firstName, id: userId } = targetUser;

  const [ relationship, setRelationship ] = React.useState<Relationship | undefined>(undefined);

  const refreshRelationship = () => {
    Service.meControllerGetRelationship(userId).then((relationship: RelationshipDto) => {
      setRelationship(getRelationship(relationship, props.currentUser.id));
    });
  };

  React.useEffect(() => {
    refreshRelationship();
  }, []);

  if (!relationship) {
    return (
      <div className="ProfileHeaderContentContainer">
        <IonSpinner name="crescent"/>
      </div>
    );
  }
  let profilePictureModalContent = <></>;
  if (relationship) {
    switch (relationship) {
      case(Relationship.Stranger):
        profilePictureModalContent = <ProfileHeaderAddFriend currentUser={currentUser} targetUser={targetUser} onSubmit={refreshRelationship}/>;
        break;
      case(Relationship.FriendRequesting):
        profilePictureModalContent = <ProfileHeaderFriendRequested userId={userId} onSubmit={refreshRelationship}/>;
        break;
      case(Relationship.FriendRequested):
        profilePictureModalContent = <ProfileHeaderHandleFriendRequest userId={userId} onSubmit={refreshRelationship} firstName={firstName}/>;
        break;
      case(Relationship.Friend):
        profilePictureModalContent = <ProfileHeaderButtonsFriend userId={userId} onSubmit={refreshRelationship} firstName={firstName} isFollowing={false}/>;
        break;
      case(Relationship.FriendAndFollowing):
        profilePictureModalContent = <ProfileHeaderButtonsFriend userId={userId} onSubmit={refreshRelationship} firstName={firstName} isFollowing={true}/>;
        break;
    }
  }
  return (
    <div className="ProfileHeaderContentContainer">
      { profilePictureModalContent }
    </div>
  );
};

export default ProfileHeaderContent;
