import { observer } from "mobx-react-latest";
import moment from "moment";
import WeatherIcon from "../../components/WeatherIcon/WeatherIcon";
import { WeatherForecastDto } from "../../services/openapi";

interface WeatherDetailProps {
  isToday: boolean;
  hourlyCondition?: WeatherForecastDto[];
}

const WeatherDetail = observer((props: WeatherDetailProps) => {
  const { hourlyCondition, isToday } = props;
  const currentHour = isToday ? moment().hour(): 9;
  const currentHourCondition = hourlyCondition?.filter((c) => moment(c.startTime).hour() == currentHour)[0];
  let weatherCodeSize = '';
  const weatherCodeWordCount = currentHourCondition?.weatherCode.split(' ').length || 0;
  if (weatherCodeWordCount >= 4) {
    weatherCodeSize = 'md';
  }

  if (weatherCodeWordCount >= 6) {
    weatherCodeSize = 'sm';
  }

  const hourlyConditionComponent = hourlyCondition?.slice().sort((c1: WeatherForecastDto, c2: WeatherForecastDto) => {
    return moment(c1.startTime).diff(moment(c2.startTime));
  }).map((condition: WeatherForecastDto) => {
    if(moment(condition.startTime).hour() >= 8) {
      return (
        <div className="hourlyWeatherWrapper" key={`${condition.startTime}-${condition.endTime}`}>
          <div className="hourlyWeatherHour">{moment(condition.startTime).format('h a')}</div>
          <WeatherIcon condition={condition.weatherCode}/>
          <div>{condition.temperatureF} ℉</div>
          <div>{condition.windDirection} {condition.windSpeedMph.toString().split(' ')[0]}</div>
        </div>
      );
    }
  });

  return (
    <div className='tripDetailSection'>
      <h3 className='tripDetailSectionTitle'>Weather</h3>
      <div className='weatherBody tripSectionBody'>
        {currentHourCondition &&
          <div className="currentHourWrapper">
            <div className='summary'>
              <WeatherIcon condition={currentHourCondition?.weatherCode}/>
              <span className='temperature'>{currentHourCondition?.temperatureF}℉</span>
            </div>
            <div className={`weatherCode  ${weatherCodeSize}`}>
              <span className={'weatherCodeText'}>
                {currentHourCondition?.weatherCode}
              </span>
            </div>
          </div>
        }
        {hourlyCondition &&
          <div className="weatherByHour">
            <div className="weatherByHourStrip">
              {hourlyConditionComponent}
            </div>
          </div>
        }
        {!hourlyCondition &&
          <div className="weatherByHour emptyWrapper">
            <span>Not available yet. Check again later.</span>
          </div>
        }
      </div>
    </div>
  );
});

export default WeatherDetail;