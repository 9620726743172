/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CarDto } from './CarDto';
import type { PreferenceDto } from './PreferenceDto';

export type UserDto = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    completedSignup: boolean;
    preferredSport: UserDto.preferredSport;
    abilityLevel: UserDto.abilityLevel;
    cars: Array<CarDto>;
    loginProvider: UserDto.loginProvider;
    profilePictureUrl: string;
    activityCaughtUpTimestamp: string;
    preference: PreferenceDto;
}

export namespace UserDto {

    export enum preferredSport {
        SKI = 'Ski',
        SNOWBOARD = 'Snowboard',
        BOTH = 'Both',
        OTHER = 'Other',
    }

    export enum abilityLevel {
        MAGIC_CARPET = 'MagicCarpet',
        GREEN = 'Green',
        BLUE = 'Blue',
        BLACK = 'Black',
        DOUBLE_BLACK = 'DoubleBlack',
    }

    export enum loginProvider {
        FACEBOOK = 'Facebook',
        APPLE = 'Apple',
        LOCAL_TEST = 'LocalTest',
        GOOGLE = 'Google',
    }


}
