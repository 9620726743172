/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CarDto = {
    licensePlate: string;
    year: number;
    make: string;
    model: string;
    color: string;
    tire: CarDto.tire;
    roofAccessory: CarDto.roofAccessory;
    chains: boolean;
}

export namespace CarDto {

    export enum tire {
        WINTER_STUDLESS = 'WinterStudless',
        WINTER_STUD = 'WinterStud',
        ALL_SEASON_MUD_SNOW = 'AllSeasonMudSnow',
        ALL_SEASON = 'AllSeason',
        SUMMER = 'Summer',
    }

    export enum roofAccessory {
        RACK = 'Rack',
        BOX = 'Box',
    }


}
