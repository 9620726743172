import { mutatorAction } from 'satcheljs';
import { FriendDto } from '../../services/openapi';
import { Friend, getFriendStore } from '../stores/FriendStore';
import { upsert } from '../utils/utils';

function toFriend(dto: FriendDto): Friend {
  const friend = { ...dto, id: dto.friend.id };
  if (!friend.friend.upcomingTrips) {
    friend.friend.upcomingTrips = [];
  }
  return friend;
}

export const setFriends = mutatorAction('SET_FRIENDS', (newFriends: FriendDto[]) => {
  const newFriendsWithId = newFriends.map(friend => (toFriend(friend))).sort((a, b) => {
    return a.friendSince > b.friendSince ? -1 : 1;
  });
  getFriendStore().friends = newFriendsWithId;
});

export const upsertFriend = mutatorAction('UPSERT_FRIEND', (friend: FriendDto) => {
  const { friends } = getFriendStore();
  upsert(friends, toFriend(friend));
});

export const setFriendsLoading = mutatorAction('SET_FRIENDS_LOADING', () => {
  getFriendStore().isLoadingFriends = true;
});

export const unsetFriendsLoading = mutatorAction('UNSET_FRIENDS_LOADING', () => {
  getFriendStore().isLoadingFriends = false;
});
