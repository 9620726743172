import { Clipboard } from '@capacitor/clipboard';
import { useIonAlert } from '@ionic/react';
import { observer } from "mobx-react-latest";
import React from "react";
import { Error, getErrorStore, Warning } from "../../dataflow/stores/ErrorStore";
import { sessionId } from "../../logging/winston";
import "./DebugSection.scss";

interface ErrorProps {
  error: Error;
}

function ErrorElem({ error }: ErrorProps): JSX.Element {
  return (
    <div className="errorDetails">
      <div>{error.code}</div>
      <div>{error.message}</div>
      <div>{JSON.stringify(error.details, undefined, 2)}</div>
      { error.innerError && ErrorElem({ error: error.innerError }) }
    </div>
  );
}

interface WarningProps {
  warning: Warning;
}

function WarningElem({ warning }: WarningProps): JSX.Element {
  return (
    <div className="errorDetails">
      <div>{warning.message}</div>
    </div>
  );
}

export const DebugSection: React.FC = observer(() => {
  const errorStore = getErrorStore();
  const sessId = sessionId();
  const errors = errorStore.errors.map((error, index) => {
    return <ErrorElem key={index} error={error} />;
  });
  const warnings = errorStore.warnings.map((warning, index) => {
    return <WarningElem key={index} warning={warning} />;
  });
  const [ present, _dismiss ] = useIonAlert();
  return (
    <div className="debugSection">
      <div className="sessionIdSection">
        <div className="sessionIdLabel">Session ID:</div>
        <div className="sessionIdValueSection">
          <div className="sessionId">{sessId}</div>
          <button className="sessionIdCopyBtn" onClick={() => {
            Clipboard.write({ string: sessId });
            present({ header: 'Session ID copied', buttons: [ 'OK' ] });
          }}>Copy</button>
        </div>
      </div>
      <button className='sessionIdCopyBtn' onClick={() => {
        Clipboard.write({ string: JSON.stringify(errorStore, undefined, 2) });
        present({ header: 'Errors and warnings copied', buttons: [ 'OK' ] });
      }}>Copy Errors and Warnings</button>
      <div className="errorSection">
        <div className="errorLabel">Errors:</div>
        {errors}
      </div>
      <div className="warningSection">
        <div className="warningLabel">Warnings:</div>
        {warnings}
      </div>
    </div>
  );
});