import { IonIcon, IonItem } from "@ionic/react";
import { observer } from "mobx-react-latest";
import { SideMenuItemProps } from "./getMenuItems";
import './SideMenuItem.scss';

const SideMenuItem: React.FC<SideMenuItemProps> = observer((props: SideMenuItemProps) => {
  return (
    <IonItem className="nobackground sideMenuItem" routerLink={props.linkPath} onClick={props.onClick}>
      <IonIcon className="sideMenuItemIcon" icon={props.icon}/>
      <span className="sideMenuItemText">{props.displayText}</span>
    </IonItem>
  );
});

export default SideMenuItem;