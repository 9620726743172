import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonSpinner, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useState } from "react";
import { createCarpool } from "../../dataflow/orchestrators/CarpoolStoreOrchestrator";
import { fetchTrip } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { getTripById } from "../../dataflow/stores/TripStore";
import { TripCarpoolDto } from "../../services/openapi";
import { calendarDateFormat } from "../../utils/carpool";
import { ReactComponent as HitchHike } from './icon/HitchHike.svg';
import './NewIntentPage.scss';

interface NewIntentProps {
  tripId: string;
  dismiss: (isCreatedIntent: boolean) => void;
}

export const NewIntentPage = observer((props: NewIntentProps) => {
  const { tripId, dismiss } = props;
  const currentTrip = getTripById(tripId);
  const [ location, setLocation ] = useState<string | null | undefined>('');
  const [ note, setNote ] = useState('');
  const [ submitError, setSubmitError ] = useState('');
  const [ submitting, setSubmitting ] = useState(false);

  const onCreateClicked = () => {
    if (!currentTrip) {
      setSubmitError('Unable to create intent because trip information is not available, please refresh the page and try again');
      return;
    }
    setSubmitting(true);
    createCarpool({
      communityId: currentTrip.communityId,
      date: currentTrip.date,
      lookForDriver: true,
      lookForRider: false,
      resortId: currentTrip.resort.id,
      notes: [ `Location: ${location}`, note ].filter(s => !!s).join('\n')
    }, (intentOrError) => {
      fetchTrip(tripId, () => {
        setSubmitting(false);
        if (!(intentOrError as TripCarpoolDto).id) {
          setSubmitError(`Unable to create intent due to ${JSON.stringify(intentOrError)}}`);
        }
        dismiss(true);
      });
    });
  };

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonButton onClick={() => dismiss(false)}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>Create Intent</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        {currentTrip &&
          <div className="createIntentSection">
            <div className="intentInformation">
              <div><HitchHike/> Looking for carpool</div>
              <div><b>{moment.utc(currentTrip.date).calendar(calendarDateFormat)}</b> at <b>{currentTrip.resort.location.name}</b>.</div>
            </div>
            <IonItem className='createIntentOptionItem'>
              <IonLabel className='createIntentLabel'>Your Location</IonLabel>
              <IonInput slot='end'
                className='createIntentValue createIntentInput'
                placeholder='Zip code/City/Address'
                onIonChange={(e) => {
                  setLocation(e.detail.value);
                }}/>
            </IonItem>
            <IonItem className='createIntentOptionItem'>
              <IonLabel className='createIntentLabel'>Notes</IonLabel>
              <IonTextarea  slot='end'
                className='createIntentValue createIntentInput'
                placeholder={'Additional information you would like to share'}
                rows={3}
                onIonChange={(e) => {
                  if(e.detail.value) {
                    setNote(e.detail.value);
                  }
                }}/>
            </IonItem>
            <IonButton className="createIntentButton" color="secondary" disabled={!location || submitting} onClick={() => onCreateClicked()}>
              {submitting ? <IonSpinner name="crescent"></IonSpinner> : 'Submit' }
            </IonButton>
            {submitError && <div className="createIntentError">{submitError}</div>}
          </div>
        }
      </IonContent>
    </IonPage>
  );
});