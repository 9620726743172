import { IonBackButton, IonButtons, IonCol, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonRow, IonSpinner, IonToolbar } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import moment from "moment";
import React from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { logger } from "../../logging/winston";
import { ParticipantsDto, PreviewUserDto, Service, TripStatusDto } from "../../services/openapi";
import getTripRelativeTime from "../../utils/getTripRelativeTime";
import '../page.scss';
import { homeRedirectPath, myProfilePath } from "../routes";
import ProfileHeader from "./ProfileHeader";
import './UserProfile.scss';

interface UserProfileParam {

}

const UserProfile = observer((props: RouteComponentProps<UserProfileParam>) => {
  // We have an issue somewhere in our router setup such that params are not getting populated correctly.
  // We should spend more time later to try to figure out where the issue is.
  // NOTE: do not use `.at(-1)` to get the last element since Safari does not support `Array.prototype.at()` https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
  const parts = props.match.url.split('/');
  const username = parts[parts.length - 1];
  const [ user, setUser ] = React.useState<PreviewUserDto>();
  const [ userNotFound, setUserNotFound ] = React.useState(false);

  const currentUser = getUserStore().currentUser;
  const history = useHistory();
  if (username == currentUser?.username || username == currentUser?.id) {
    React.useEffect(() => {
      history.replace(myProfilePath);
    }, []);
    return <></>;
  }

  React.useEffect(() => {
    Service.usersControllerFindByUsername(username).then((userResult) => {
      if (userResult && !user) {
        setUser(userResult);
      }
    }).catch((err) => {
      if (err.message != 'Not Found') {
        logger.error('Failed to fetch user', { exception: JSON.stringify(err) });
      }
      setUserNotFound(true);
    });
  }, []);

  const upcomingTripsComponent = user?.upcomingTrips?.filter(
    (t: TripStatusDto) => moment(t.date.split('T')[0]).diff(moment(), 'day') >= 0
  ).sort((a, b) => moment(a.date.split('T')[0]).diff(moment(b.date.split('T')[0]), 'day'))
    .map((trip: TripStatusDto) => {
      const isMyGoingTrip = trip?.status === ParticipantsDto.selfAttending.GOING;
      const isMyInterestedTrip = trip?.status === ParticipantsDto.selfAttending.INTERESTED;
      return (
        <IonItem className="profileUpcomingTripItem" lines="none" key={trip.id} routerLink={`/trip/${trip.id}`}>
          <IonRow>
            <IonCol>
              <IonLabel className="profileUpcomingTripDate">
                {getTripRelativeTime(trip)}
                {isMyGoingTrip &&
                <span className="myTripBadge myTripJoinBadge">Going</span>
                }
                {isMyInterestedTrip &&
                <span className="myTripBadge myTripInterestedBadge">Interested</span>
                }
              </IonLabel>
              <IonLabel className="profileUpcomingTripTitle">{trip.resort.location.name}</IonLabel>
            </IonCol>
          </IonRow>
        </IonItem>
      );
    });

  return (
    <IonPage className="fusendPage">
      <IonHeader className="fusendPageHeader">
        <IonToolbar className="fusendPageToolbar">
          <IonButtons slot="start">
            <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="fusendPageContent profileHeaderContent">
        { !!user &&
          <>
            <ProfileHeader user={user} />
            <div className="profilePageDivider"></div>
            { upcomingTripsComponent?.length ?
              <>
                <h4 className="profileUpcomingTripHeader">Upcoming Trips</h4>
                {upcomingTripsComponent}
              </> :
              <div className="profileNoTripContainer">
                <img className="profileNoTripIcon" src="/assets/icon/calendar-event.svg"/>
                <p className="profileNoTripText">No Upcoming Trips</p>
              </div>
            }
          </>
        }
        { !user && !userNotFound && <IonSpinner className="userProfileLoading" name="crescent" /> }
        { userNotFound && <p>{`User "${username}" not found`}</p> }
      </IonContent>
    </IonPage>
  );
});

export default withRouter(UserProfile);
