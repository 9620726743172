const encode = (str: string): string => Buffer.from(str, 'binary').toString('base64');
export const decode = (str: string): string => Buffer.from(str, 'base64').toString('binary');
export function loginPath(redirectPath: string, url: string): string {
  const searchParams = new URLSearchParams(url);
  const referrer = searchParams.get('referrer');
  if (!redirectPath.startsWith('/Login')) {
    return `/Login/${encode(redirectPath)}${referrer ? '?referrer=' + referrer : ''}`;
  }
  return redirectPath;
}
export function signUpPath(redirectPath: string): string {
  if (!redirectPath.startsWith('/signup')) {
    return `/signup/${encode(redirectPath)}`;
  }
  return redirectPath;
}
export const rideDetailPathLiteral = '/rideDetail/:rideId';
export const getRideDetailPath = (rideId: string) => `/rideDetail/${rideId}`;
export const chatRoomPathLiteral = '/chatRoom/:chatId';
export const getChatRoomPath = (chatId: string) => `/chatRoom/${chatId}`;
export const chatRoomDetailPathLiteral = '/chatRoom/:chatId/detail';
export const getChatRoomDetailPath = (chatId: string) => `/chatRoom/${chatId}/detail`;
export const userProfileLiteral = '/profile/:username';
export const getProfilePagePath = (username: string) => `/profile/${username}`;
export const tripDetailPathLiteral = '/trip';
export const tripDetailWithIdPathLiteral = '/trip/:tripId';
export const getTripDetailWithIdPath = (tripId: string) => `/trip/${tripId}`;
export const getTripDetailWithoutIdPath = (date: string, resortId: string) => `/trip?date=${date}&resortId=${resortId}`;
export const requestToJoinTripGroupLiteral = '/trip/:tripId/group/:tripGroupId/request-to-join';
export const getRequestToJoinTripGroupPath = (tripId: string, tripGroupId: string) => `/trip/${tripId}/group/${tripGroupId}/request-to-join`;
export const carpoolLobbyPathLiteral = '/trip/:tripId/carpoolLobby';
export const getCarpoolLobbyPath = (tripId: string) => `/trip/${tripId}/carpoolLobby`;
export const carpoolRoleSelectionPathLiteral = '/trip/:tripId/carpoolRoleSelection';
export const getCarpoolRoleSelectionPath = (tripId: string) => `/trip/${tripId}/carpoolRoleSelection`;
export const carpoolCreateIntentPathLiteral = '/trip/:tripId/carpoolCreateIntent';
export const getCarpoolCreateIntentPath = (tripId: string) => `/trip/${tripId}/carpoolCreateIntent`;
export const carpoolIntentDetailPagePathLiteral = '/trip/:tripId/carpoolIntentDetail/:intentId';
export const getCarpoolIntentDetailPagePath = (tripId: string, intentId: string) => `/trip/${tripId}/carpoolIntentDetail/${intentId}`;

export const signUpPathLiteral = '/signup';
export const signUpPathWithRedirectLiteral = '/signup/:redirectPath';

export const myProfilePath = "/my-profile";
export const chatPath = "/chat";
export const activityPath = "/activity";
export const friendPath = "/friend";

export const myInfoPath = "/my-info";
export const myPreferencePath = "/my-preference";
export const myVehiclesPath = "/my-vehicles";

export const discoverPeoplePath = "/discover-people";

export const legalPath = "/legal";
export const termsOfServicePath = "/legal/tos";
export const privacyPath = "/legal/privacy";
export const disclaimerPath = "/legal/disclaimer";

export const supportPath = "/support";

export const homeRedirectPath = "/home";

export const sideBySideComparison = "/side-by-side-comparison";

// QR Landing Pages
// DO NOT CHANGE THESE PATHS as they are used in the QR code
//
// 20231015: chairlift ads via Kris
export const qrLanding20231015 = "/qr-landing/20231015";
