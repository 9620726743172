import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { TransformableInfo } from 'logform';
import TransportStream from 'winston-transport';
import { getExtras } from './utils';

const COMMIT_ID = process.env.REACT_APP_COMMIT_ID;
const BUILD_ID = process.env.REACT_APP_BUILD_ID;

export function initAppInsights(browserHistory: History<unknown>): ApplicationInsights {
  const appInsiteReactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: 'a2085ddd-ebb6-47d5-a7eb-c23cbb5737a6',
      loggingLevelConsole: 2,
      autoTrackPageVisitTime: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
      enableUnhandledPromiseRejectionTracking: true,
      disableFetchTracking: false,
      extensionConfig: {
        [appInsiteReactPlugin.identifier]: { history: browserHistory }
      }
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
  appInsights.trackTrace;
  return appInsights;
}

// Remapping the popular levels to Application Insights
function getMessageLevel(winstonLevel: string): SeverityLevel {
  const levels: {[key: string]: SeverityLevel} = {
    emerg: SeverityLevel.Critical,
    alert: SeverityLevel.Critical,
    crit: SeverityLevel.Critical,
    error: SeverityLevel.Error,
    warning: SeverityLevel.Warning,
    warn: SeverityLevel.Warning,
    notice: SeverityLevel.Information,
    info: SeverityLevel.Information,
    verbose: SeverityLevel.Verbose,
    debug: SeverityLevel.Verbose,
    silly: SeverityLevel.Verbose,
  };

  return winstonLevel in levels ? levels[winstonLevel] : levels.info;
}

export class ApplicationInsightsLogger extends TransportStream {
  constructor(private client: ApplicationInsights, private sessionId: string) {
    super({});

    this.setMaxListeners(30);
  }

  log(info: TransformableInfo, next: () => void) {
    const { level, message } = info;
    const severity = getMessageLevel(level);
    if (severity < SeverityLevel.Information) {
      next();
      return;
    }
    const exception: Error | undefined = 'exception' in info ? info['exception'] : undefined;

    this.handleTrace(severity, info, message, exception);
    if (severity >= getMessageLevel('error') && exception) {
      this.handleException(message, exception);
    }

    next();
  }

  private handleTrace(severityLevel: SeverityLevel, info: TransformableInfo, message: string, exception: Error | undefined) {
    const traceProps = getExtras(info) || Object.create(null);
    traceProps['sessionId'] = this.sessionId;
    if (COMMIT_ID) {
      traceProps['commitId'] = COMMIT_ID;
    }
    if (BUILD_ID) {
      traceProps['buildId'] = BUILD_ID;
    }

    if (exception) {
      // If info, message or logMeta is an error, trim it and set the properties:
      Object.assign(traceProps, { ...exception });
    }

    this.client.trackTrace({
      message: message,
      severityLevel,
      properties: traceProps,
    });
  }

  private handleException(message: string, exception: Error) {
    const properties = {
      properties: {
        message,
        sessionId: this.sessionId
      }
    };
    this.client.trackException({
      exception,
      properties,
    });
  }
}