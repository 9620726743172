import { createStore } from 'satcheljs';
import { ActivityDto } from '../../services/openapi';

interface ActivityStore {
  activities: ActivityDto[];
  isLoadingActivities: boolean;
  caughtUpTimestamp: Date | undefined;
}

export const getActivityStore = createStore<ActivityStore>('ActivityStore', {
  activities: [],
  isLoadingActivities: false,
  caughtUpTimestamp: undefined,
});

export function countNewActivities(): number {
  const { activities, caughtUpTimestamp } = getActivityStore();
  if (caughtUpTimestamp === undefined) {
    return activities.length;
  }

  return activities.filter(activity => new Date(activity.timestamp).getTime() > caughtUpTimestamp.getTime()).length;
}
