import { mutatorAction } from 'satcheljs';
import { CommunityDto, CommunityUserDto } from '../../services/openapi';
import { getCommunityStore } from '../stores/CommunityStore';

export const setMyCommunities = mutatorAction('SET_MY_COMMUNITIES', (communityUsers: CommunityUserDto[]) => {
  getCommunityStore().myCommunities = communityUsers;
});

export const setAllCommunities = mutatorAction('SET_ALL_COMMUNITIES', (communities: CommunityDto[]) => {
  getCommunityStore().allCommunities = communities;
});

export const setCommunityUsers = mutatorAction('SET_COMMUNITY_USERS', (communityId: string, users: CommunityUserDto[]) => {
  getCommunityStore().communityUsers.set(communityId, users);
});

export const setLoadingAllCommunities = mutatorAction('SET_LOADING_ALL_COMMUNITIES', (loading: boolean) => {
  getCommunityStore().loadingAllCommunities = loading;
});

export const setLoadingMyCommunities = mutatorAction('SET_LOADING_MY_COMMUNITIES', (loading: boolean) => {
  getCommunityStore().loadingMyCommunities = loading;
});

export const addLoadingCommunityUsers = mutatorAction('ADD_LOADING_COMMUNITY_USERS', (communityId: string) => {
  getCommunityStore().loadingCommunityUsers.add(communityId);
});

export const removeLoadingCommunityUsers = mutatorAction('REMOVE_LOADING_COMMUNITY_USERS', (communityId: string) => {
  getCommunityStore().loadingCommunityUsers.delete(communityId);
});
