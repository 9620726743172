import { createStore } from 'satcheljs';
import { FriendDto } from '../../services/openapi';

export interface Friend extends FriendDto {
  id: string
}

interface FriendStore {
  friends: Friend[];
  isLoadingFriends: boolean;
}

export const getFriendStore = createStore<FriendStore>('FriendStore', {
  friends: [],
  isLoadingFriends: false,
});

export function findFriends(keyword: string): Friend[] {
  const { friends } = getFriendStore();
  const normalizedKeyword = keyword.toLowerCase();
  return friends.filter(friend => friend.nickname && friend.nickname.toLowerCase().includes(normalizedKeyword)
    || friend.friend.username && friend.friend.username.toLowerCase().includes(normalizedKeyword)
    || friend.friend.firstName.toLowerCase().includes(normalizedKeyword)
    || friend.friend.lastName.toLowerCase().includes(normalizedKeyword)
    || `${friend.friend.firstName} ${friend.friend.lastName}`.toLowerCase().includes(normalizedKeyword));
}
