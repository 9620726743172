import {
  IonAccordion, IonAccordionGroup, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel,
  IonPage, IonSpinner, IonTitle, IonToolbar, useIonAlert
} from "@ionic/react";
import { chevronBack, warning } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import React from "react";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { deleteAccount, updateUser, userLogout } from "../../dataflow/orchestrators/UserStoreOrchestrators";
import { getUserStore } from "../../dataflow/stores/UserStore";
import '../page.scss';
import { homeRedirectPath } from "../routes";
import ChangeProfilePicModal from "./ChangeProfilePicModal";
import { DebugSection } from "./DebugSection";
import EditableUserField from "./EditableUserField";
import './MyProfile.scss';
import {
  getUserPreferenceFieldInputProps, getUserPrivateFieldInputProps
} from "./utils/getUserFieldInputProps";

const MyProfile: React.FC = observer(() => {
  const [ presentAlert ] = useIonAlert();
  const { currentUser } = getUserStore();
  const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false);
  const [ isReadonly, setIsReadOnly ] = React.useState<boolean>(true);
  const [ isEdited, setIsEdited ] = React.useState<boolean>(false);
  const [ profileInfo, setProfileInfo ] = React.useState<any>({});
  const [ developerModeCount, setDeveloperModeCount ] = React.useState<number>(0);
  const [ isDeletingAccount, setIsDeletingAccount ] = React.useState<boolean>(false);
  const openUploadModal = () => {
    setIsModalOpen(true);
  };

  const onModalDismiss = () => {
    setIsModalOpen(false);
  };

  let encounteredError = false;

  const editableUserPreferenceFieldComponents = currentUser && getUserPreferenceFieldInputProps().map((prop) => {
    const errorMessage = prop.validation?.state[0];
    if (errorMessage !== undefined) {
      encounteredError = encounteredError || !!errorMessage;
    }
    const readonly = prop.readonly !== undefined ? prop.readonly : isReadonly;
    return <EditableUserField
      {...prop}
      key={prop.propertyDisplayName}
      user={currentUser}
      readonly={readonly}
      onChange={(newValue: any) => {
        setProfileInfo((info: any) => ({
          ...info,
          ...newValue
        }));
        setIsEdited(true);
      }}
      modifiedField={profileInfo}
      validation={prop.validation}/>;
  });

  const editableUserPrivateFieldComponents = currentUser && getUserPrivateFieldInputProps().map((prop) => {
    const readonly = prop.readonly !== undefined ? prop.readonly : isReadonly;
    return <EditableUserField
      {...prop}
      key={prop.propertyDisplayName}
      user={currentUser}
      readonly={readonly}
      onChange={(newValue: any) => {
        setProfileInfo((info: any) => ({
          ...info,
          ...newValue
        }));
        setIsEdited(true);
      }}/>;
  });

  const onDeleteClick = () => {
    presentAlert({
      header: 'Delete Account',
      subHeader: 'CAUTIOUS: This will delete your user account with Fusend. All the content associated with the account will be gone. There is no way to recover from deletion!',
      message: 'Are you sure you want to delete your account?',
      inputs: [
        {
          name: 'username',
          placeholder: 'Confirm your username',
        },
      ],
      buttons: [
        {
          text: 'Delete my account',
          role: 'delete',
          cssClass: 'deleteConfirmButton',
          handler: (alertData) => {
            return currentUser && alertData.username === currentUser.username;
          },
        },
        {
          text: 'Keep my account',
          role: 'cancel',
          cssClass: 'deleteCancelButton',
          handler: () => {},
        },
      ],
      onDidDismiss: (e: CustomEvent) => {
        if (currentUser && e.detail.role == 'delete') {
          setIsDeletingAccount(true);
          deleteAccount(currentUser.id, () => {
            setIsDeletingAccount(false);
            userLogout();
            window.location.assign('/');
          });
        }
      },
      cssClass: 'userProfileDeleteAlert',
    });
  };

  return (
    <IonPage className="fusendPage userProfilePage">
      <IonHeader className="fusendPageHeader userProfileHeader">
        <IonToolbar className="fusendPageToolbar userProfileToolbar">
          <IonButtons slot="start">
            <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
          </IonButtons>
          <IonTitle className="fusendPageTitle userProfileTitle" onClick={() => setDeveloperModeCount(developerModeCount + 1)}>
            My Profile
          </IonTitle>
          {!isDeletingAccount &&
          <IonButtons slot="end">
            { isEdited
              ?<IonButton disabled={encounteredError} onClick={() => {
                updateUser(profileInfo);
                setIsEdited(false);
                setIsReadOnly(true);
              }}>Save</IonButton>
              :<IonButton onClick={() => setIsReadOnly(!isReadonly)}>{isReadonly? "Edit": "Cancel"}</IonButton>
            }
          </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      {isDeletingAccount &&
        <IonContent fullscreen className="fusendPageContent userProfileContent">
          <div className="userProfileDeletingContainer">
            <div>😢😢😢</div>
            <div>Sorry to see you leaving.</div>
            <div>Fusend will miss you.</div>
            <br></br>
            <div>Your account is being deleted...</div>
            <IonSpinner className="userProfileDeleting" name="crescent" />
          </div>
        </IonContent>
      }
      {!isDeletingAccount &&
      <IonContent fullscreen className="fusendPageContent userProfileContent">
        <div className="userProfilePicSection">
          {currentUser &&
            <ProfilePicture
              className="userProfilePicture"
              user={{ ...currentUser, upcomingTrips: [] }}
              overrideDefaultClick={true} />
          }
          <p className="userProfileChangeProfilePicLink" onClick={openUploadModal}>{"Change my profile picture"}</p>
          <ChangeProfilePicModal isOpen={isModalOpen} onDismiss={onModalDismiss}/>
        </div>
        <div>
          { editableUserPreferenceFieldComponents }
        </div>
        <div className="userProfilePrivateInfoSection">
          <IonLabel className="userProfilePrivateInfoLabel">Private Information</IonLabel>
          { editableUserPrivateFieldComponents }
        </div>
        <IonAccordionGroup>
          <IonAccordion className="userProfileAdvanced" toggleIconSlot="start">
            <IonItem slot="header" className="userProfileAdvancedLabel">
              <IonLabel>Advanced options</IonLabel>
            </IonItem>
            <div  slot="content" className="userProfileDeleteButtonContent">
              <IonButton className="userProfileDeleteButton" onClick={onDeleteClick}>
                <IonIcon slot="start" icon={warning}></IonIcon>
                Delete Account (Danger!)
              </IonButton>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
        { developerModeCount >= 10 && (
          <DebugSection />
        )}
      </IonContent>
      }
    </IonPage>
  );
});

export default MyProfile;