import { action, orchestrator } from "satcheljs";
import { logger } from "../../logging/winston";
import { getValueFromNotificationArgs } from "../../notifications/NotificationUtils";
import { onNotificationClickedRouter } from "../../notifications/routers";
import { getCarpoolLobbyPath } from "../../pages/routes";
import { ActivityDto, CreateTripCarpoolDto, Service, TripCarpoolDto, UpdateTripCarpoolDto } from "../../services/openapi";
import { browserHistory } from "../../utils/history";
import { setCarpoolsForTrip, upsertCarpoolForTrip } from "../mutators/CarpoolStoreMutators";
import { addError } from "../mutators/ErrorStoreMutators";
import { newError } from "../stores/ErrorStore";
import { makeAsyncRequest } from "../utils/utils";

export const createCarpool = action('CREATE_CARPOOL', (carpool: CreateTripCarpoolDto, callback?: (carpool: TripCarpoolDto | Error) => void) => ({ intent: carpool, callback }));
export const listCarpools = action('LIST_CARPOOLS', (tripId: string, callback?: (carpools: TripCarpoolDto[] | Error) => void) => ({ tripId, callback }));
export const getCarpool = action('GET_CARPOOL', (tripId: string, carpoolId: string, callback?: (carpool: TripCarpoolDto | Error) => void) => ({ tripId, carpoolId, callback }));
export const updateCarpool = action('UPDATE_CARPOOL', (carpoolId: string, carpool: UpdateTripCarpoolDto, callback?: (carpool: TripCarpoolDto | Error) => void) => ({ carpoolId, carpool, callback }));

orchestrator(createCarpool, async ({ intent: carpool, callback }) => {
  try {
    const createdCarpool = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerCreateTripCarpool(carpool)),
      () => {},
      () => {})).response;
    upsertCarpoolForTrip(createdCarpool.trip.id, createdCarpool);
    callback && callback(createdCarpool);
  } catch (error) {
    logger.error('Failed to fetch trip group.', error);
    addError(newError('FETCH_TRIP_GROUPS_OF_TRIP.Failed'));
    callback && callback(error as Error);
  }
});

orchestrator(listCarpools, async ({ tripId, callback }) => {
  try {
    const carpools = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerListTripCarpools(tripId)),
      () => {},
      () => {})).response;
    setCarpoolsForTrip(tripId, carpools);
    callback && callback(carpools);
  } catch (error) {
    logger.error('Failed to fetch trip group.', error);
    addError(newError('FETCH_TRIP_GROUPS_OF_TRIP.Failed'));
    callback && callback(error as Error);
  }
});

orchestrator(getCarpool, async ({ tripId, carpoolId, callback }) => {
  try {
    const carpool = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerGetTripCarpool(carpoolId)),
      () => {},
      () => {})).response;
    upsertCarpoolForTrip(tripId, carpool);
    callback && callback(carpool);
  } catch (error) {
    logger.error('Failed to fetch trip group.', error);
    addError(newError('FETCH_TRIP_GROUPS_OF_TRIP.Failed'));
    callback && callback(error as Error);
  }
});

orchestrator(updateCarpool, async ({ carpoolId, carpool, callback }) => {
  try {
    const updatedCarpool = await (await makeAsyncRequest(
      undefined,
      () => Promise.resolve(() => Service.tripsControllerUpdateTripCarpool(carpoolId, carpool)),
      () => {},
      () => {})).response;
    upsertCarpoolForTrip(updatedCarpool.trip.id, updatedCarpool);
    callback && callback(updatedCarpool);
  } catch (error) {
    logger.error('Failed to fetch trip group.', error);
    addError(newError('FETCH_TRIP_GROUPS_OF_TRIP.Failed'));
    callback && callback(error as Error);
  }
});

export function fetchAndGoToCarpoolLobby(tripId: string) {
  listCarpools(tripId, () => browserHistory.push({
    pathname: getCarpoolLobbyPath(tripId),
  }));
}

onNotificationClickedRouter.addRoute(ActivityDto.type.TRIP_CARPOOL_UPDATED, args => {
  logger.debug('Handling clicked carpool intent update notification', args);
  const tripId = getValueFromNotificationArgs(args, 'trip.id');
  if (!tripId) {
    logger.error(`Cannot resolve trip.id arg for '${ActivityDto.type.TRIP_CARPOOL_UPDATED}'`, { args });
  } else {
    fetchAndGoToCarpoolLobby(tripId);
  }
});