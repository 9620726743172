import { FriendRequestDto } from '../../services/openapi';
import { RelationshipDto } from '../../services/openapi/models/RelationshipDto';

export enum Relationship {
  Friend = 'FRIEND',
  Stranger = 'STRANGER',
  FriendAndFollowing = 'FRIEND_AND_FOLLOWING',
  FriendRequesting = 'FRIEND_REQUESTING',
  FriendRequested = 'FRIEND_REQUESTED',
}

export function getRelationship(r: RelationshipDto, currentUserId: string) : Relationship {
  if(r.friendship){
    if(r.friendship.follow) {
      return Relationship.FriendAndFollowing;
    } else {
      return Relationship.Friend;
    }
  }
  const hasPendingRequest = r.friendRequests.some((req: FriendRequestDto) => req.status == FriendRequestDto.status.PENDING);
  if(hasPendingRequest) {
    const hasMyRequest = r.friendRequests.some((req: FriendRequestDto) => req.status == FriendRequestDto.status.PENDING && req.requester.id == currentUserId);
    if(hasMyRequest) {
      return Relationship.FriendRequesting;
    } else {
      return Relationship.FriendRequested;
    }
  }
  return Relationship.Stranger;
}