import {
  IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonLoading, IonNote,
  IonPage, IonSpinner, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonModal
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { fetchTrip, joinTripGroup, leaveTripGroup, updateTripGroup } from '../../dataflow/orchestrators/TripStoreOrchestrators';
import { ChatKind, getChat, getChatMembers, getChatSubtitle } from '../../dataflow/stores/ChatStore';
import { getTripById } from '../../dataflow/stores/TripStore';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { logger } from '../../logging/winston';
import { TripGroupDetailDto } from '../../services/openapi';
import TripGroupEditorModal, { MAX_CARPOOL_SIZE } from '../TripDetail/TripGroupEditorModal';
import { getChatRoomPath } from '../routes';
import { ChatRoomParam } from './ChatRoom';
import './ChatRoomDetail.scss';

const ChatRoomDetail = observer((props: RouteComponentProps<ChatRoomParam>) => {
  const history = useHistory();
  const parts = props.match.url.split('/');
  const chatId = parts[parts.length - 2];
  const chat = getChat(chatId);
  const chatRoomRoute = getChatRoomPath(chatId);
  const [ presentLeaveChatConfirm ] = useIonAlert();
  const [ isLeavingTripGroup, setIsLeavingTripGroup ] = useState(false);
  const [ showLoaderForAdd, setShowLoaderForAdd ] = useState<boolean>(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const currentUser = getUserStore().currentUser;
  const [ isUpdating, setIsUpdating ] = useState(false);

  if (!chat || chat.kind !== ChatKind.TRIP_GROUP) {
    useEffect(() => {
      history.replace(chatRoomRoute);
    }, []);
    return <></>;
  }
  const [ carpoolMaxSize, setCarpoolMaxSize ] = useState(chat.tripGroup.rideOptions?.maxSize ?? 0);
  const [ carpoolNote, setCarpoolNote ] = useState(chat.tripGroup.notes ?? '');
  const notEditableCss = isEditing ? 'notEditable' : '';
  const isCarpoolSizeValid = carpoolMaxSize >= 0 && carpoolMaxSize <= MAX_CARPOOL_SIZE;

  const tripDto = chat.tripGroup.trip;
  const trip = getTripById(tripDto.id);
  const isOwner = chat.kind === ChatKind.TRIP_GROUP && chat.tripGroup.initiator?.id == currentUser?.id;

  useEffect(() => {
    if (!trip) {
      fetchTrip(tripDto.id);
    }
  }, []);

  const members = getChatMembers(chat);

  const onLeaveGroupClicked = () => {
    presentLeaveChatConfirm({
      header: isOwner ? 'Leaving Group' : 'Deleting Group',
      message: `Are you sure you want to ${isOwner ? 'delete' : 'leave'} this group?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            logger.info('User decided to stay in this group');
          }
        },
        {
          text: isOwner ? 'Delete' : 'Leave',
          role: 'confirm',
          handler: () => {
            setIsLeavingTripGroup(true);
            logger.info(`User decided to leave this group. IsOwner=${isOwner}`, { isOwner });
            setTimeout(() => {
              leaveTripGroup(chat.tripGroup.id, tripDto.id, () => {
                setIsLeavingTripGroup(false);
              });
            }, 200); // do it after navigated back
            history.goBack(); // back to chatroom
            history.goBack(); // back to page before chatroom
          }
        }
      ],
    });
  };

  const onSaveClicked = () => {
    if (isEditing) {
      setIsUpdating(true);
      updateTripGroup(tripDto.id, chat.tripGroup.id, { notes: carpoolNote, rideOptions: { maxSize: carpoolMaxSize } }, (tripGroup) => {
        chat.tripGroup = tripGroup;
        setIsUpdating(false);
        setIsEditing(false);
      });
    } else {
      setIsEditing(true);
    }
  };

  const [ presentEditor, dismiss ] = useIonModal(TripGroupEditorModal, {
    title: 'Choose people',
    showCreateOptions: [],
    confirmLabel: 'Add',
    onDismiss: () => dismiss(),
    onConfirm: (
      selectedUserIds: string[],
      _groupName: string | undefined,
      _groupPrivacy: TripGroupDetailDto.privacy,
      _notes: string | undefined,
      _maxSize: number | undefined,
    ) => {
      if (selectedUserIds.length == 0) {
        return;
      }
      dismiss();
      setShowLoaderForAdd(true);
      joinTripGroup(chat.tripGroup.id, {
        userIds: selectedUserIds
      }, () => {
        setShowLoaderForAdd(false);
      });
    },
    friendsList: trip?.participants.friends || [],
    othersList: trip?.participants.others || [],
    exclusionUserIds: members.map((m) => m.id),
  });

  const titlePrefix = chat.tripGroup.purpose == TripGroupDetailDto.purpose.RIDE ? 'Carpool' : 'Group';
  let canInviteToGroup = true;
  if(chat.tripGroup.rideOptions?.maxSize &&  chat.tripGroup.rideOptions?.maxSize <= members.length) {
    canInviteToGroup = false;
  }

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonBackButton defaultHref={chatRoomRoute} icon={chevronBack} />
          </IonButtons>
          <IonTitle className='fusendPageTitle'>
            <div className='chatRoomDetailTitle'>{`${titlePrefix} (${members.length})`}</div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        <div className='chatRoomDetailTripGroup'>
          <div className='chatRoomDetailTripGroupMemberList'>
            { canInviteToGroup && <div className='chatRoomDetailTripGroupMember'>
              { showLoaderForAdd
                ?
                <IonSpinner className='chatRoomDetailTripGroupMemberAddLoading' name='crescent' />
                :
                <div className='chatRoomDetailTripGroupMemberAdd' onClick={() => presentEditor()}>
                  <div className='chatRoomDetailTripGroupMemberAddLine1'></div>
                  <div className='chatRoomDetailTripGroupMemberAddLine2'></div>
                </div>
              }
            </div> }
            {
              members.map((m, i) => {
                return (
                  <div className='chatRoomDetailTripGroupMember' key={`${m.id}${i}`}>
                    <div className='chatRoomDetailTripGroupMemberContainer'>
                      <ProfilePicture className="chatRoomDetailTripGroupMemberPic" user={m} overrideDefaultClick={false} />
                      <div className='chatRoomDetailTripGroupMemberName'>
                        {`${m.firstName} ${m.lastName}`}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className='chatRoomDetailTripInfo'>
            <div className={`chatRoomDetailTripInfoLabel ${notEditableCss}`}>Name</div>
            <div className={`chatRoomDetailTripInfoValue ${notEditableCss}`}>{chat.tripGroup.title}</div>
          </div>
          {chat.tripGroup.purpose == TripGroupDetailDto.purpose.RIDE && <>
            <div className='chatRoomDetailTripInfo'>
              <div className='chatRoomDetailTripInfoLabel'>Total passengers</div>
              {!isEditing &&<div className='chatRoomDetailTripInfoValue'>{chat.tripGroup.rideOptions?.maxSize ? chat.tripGroup.rideOptions?.maxSize - 1 : 'Unlimited'}</div>}
              {isEditing && <div className="chatRoomDetailTripInfoValue">
                <IonInput type="number" min={0} max={MAX_CARPOOL_SIZE} value={carpoolMaxSize - 1} debounce={300} onIonChange={e => {
                  let size = 0;
                  try {
                    size = parseInt(e.detail.value ?? '0') + 1;
                  } catch {
                  }
                  setCarpoolMaxSize(size);
                }} />
                {!isCarpoolSizeValid && <IonNote slot="error">Carpool size must be between 0 and 8.</IonNote>}
              </div>}
            </div>
            <div className='chatRoomDetailTripInfo'>
              <div className='chatRoomDetailTripInfoLabel'>Notes</div>
              <IonTextarea
                className='chatRoomDetailTripInfoValue'
                rows={3}
                readonly={!isEditing}
                value={carpoolNote}
                onIonChange={e => setCarpoolNote(e.detail.value ?? '')}
              />
            </div>
          </>}
          <div className='chatRoomDetailTripInfo'>
            <div className={`chatRoomDetailTripInfoLabel ${notEditableCss}`}>Trip</div>
            <div className={`chatRoomDetailTripInfoValue ${notEditableCss}`}>{getChatSubtitle(chat)}</div>
          </div>
          <div className='chatRoomDetailTripGroupActions'>
            {isOwner && <IonButton className='chatRoomDetailTripGroupActionButton' fill="clear" onClick={onSaveClicked}
              disabled={isUpdating || !isCarpoolSizeValid}>{isEditing ? 'Save' : 'Edit'}</IonButton>}
            {!isOwner && <IonButton className='chatRoomDetailTripGroupActionButton' fill="clear" color="danger" onClick={onLeaveGroupClicked}
              disabled={isUpdating}>Leave</IonButton>}
          </div>
          {isOwner && <div className='chatRoomDetailTripGroupActions'>
            <IonButton className='chatRoomDetailTripGroupActionButton' fill="clear" color="danger" onClick={onLeaveGroupClicked}
              disabled={isUpdating}>Delete (cannot undo)</IonButton>
          </div>}
        </div>
        { isLeavingTripGroup && <IonLoading isOpen={isLeavingTripGroup} message={'Leaving Trip'} duration={4000}/> }
      </IonContent>
    </IonPage>
  );
});

export default withRouter(ChatRoomDetail);