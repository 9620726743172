import { IonIcon } from "@ionic/react";
import { warning } from 'ionicons/icons';
import moment from "moment";
import { useRef } from "react";
import { useHistory } from "react-router";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { Message, ReplyTo } from "../../dataflow/stores/ChatStore";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { logger } from "../../logging/winston";
import { PreviewUserDto } from "../../services/openapi";
import { getProfilePagePath } from "../routes";
import { mentionAllLiteral } from "./ChatRoom";
import './ChatRoomMessageSection.dark.scss';
import './ChatRoomMessageSection.scss';
import { getOnBehalfMessageComponent, getSystemMessageComponent } from "./SystemMessage";

const SYSTEM_MESSAGE_USER_ID = '_SYSTEM_';

interface SystemMessageMetadata {
  type: string;
  payload: any;
}

interface ChatRoomMessageSectionProps {
  chatId: string;
  messages: Message[];
  members: PreviewUserDto[];
  setReply: (reply: ReplyTo | undefined) => void;
}

const ChatRoomMessageSection = (props: ChatRoomMessageSectionProps) => {
  const history = useHistory();
  const { chatId, messages, members, setReply } = props;
  const { currentUser } = getUserStore();
  const longPressTimerRef = useRef<any>();
  const sortedMessages = [ ...messages ].sort((a, b) => {
    if (a.sentTime > b.sentTime) return 1;
    if (a.sentTime < b.sentTime) return -1;
    return 0;
  });

  const startLongPressReplyTimer = (message: Message, replyToText: string) => {
    return () => {
      longPressTimerRef.current = setTimeout(() => {
        setReply({
          messageId: message.id,
          preview: `${replyToText}${message.content.slice(0, 50)}${message.content.length > 50 ? '...' : ''}`,
          toUserId: message.fromUser
        });
      }, 500);
    };
  };

  const clearLongPressReplyTimer = () => {
    clearTimeout(longPressTimerRef.current);
  };

  const shouldAddClass = (tag: string) => {
    const username = tag.slice(1);
    return username == mentionAllLiteral || members.filter((e) => {
      return e.username === username;
    }).length > 0;
  };

  const getMessageContentComponent = (message: Message) => {
    const content = message.content;
    const atRegex = /@\w+/g;
    const messageSeg = content.split(atRegex);
    const tags = content.match(atRegex);
    return (
      <div>
        {messageSeg.map((m, index) => {
          if(tags && tags.length > index) {
            const shouldAddTagClass = shouldAddClass(tags[index]);
            return (
              <span key={index}>
                {m}
                <span onClick={() => {
                  if(shouldAddTagClass && tags[index] != '@All') {
                    history.push(getProfilePagePath(tags[index].slice(1)));
                  }
                } } className={shouldAddTagClass ? "messageTag": ""}>
                  {tags[index]}
                </span>
              </span>
            );
          }
          return <span key={index}>{m}</span>;
        })}
      </div>
    );
  };

  return (
    <>
      {sortedMessages.map((message, index) => {
        const isMyMessage = message.fromUser == currentUser?.id;
        const lastMsg = sortedMessages[index - 1];
        const showTimeStamp = index == 0 ||  moment(message.sentTime).diff(moment(sortedMessages[index - 1]?.sentTime), 'seconds') > 600;
        const sender = members.find((m) => m.id == message.fromUser);
        const timeStamp = moment(message.sentTime).calendar({
          sameDay: 'LT',
          lastDay: '[Yesterday at] LT',
          lastWeek: 'MMM Do LT',
          sameElse: 'YYYY MMM Do LT'
        });
        const replyTo = isMyMessage ? currentUser?.firstName : sender?.firstName;
        const replyToText = replyTo ? `${replyTo}: ` : '';
        let sysMsgMetadata: SystemMessageMetadata | undefined = undefined;
        if (message.SYSTEM_MESSAGE) {
          // TODO: refactor SystemMessage to a component
          try {
            sysMsgMetadata = JSON.parse(message.SYSTEM_MESSAGE || '{}');
          } catch (e) {
            logger.error(`Failed to parse system message metadata: ${e}`);
          }
        }

        if (message.fromUser == SYSTEM_MESSAGE_USER_ID) {
          if (sysMsgMetadata) {
            const component = getSystemMessageComponent(sysMsgMetadata.type, message, sysMsgMetadata.payload, chatId, members);
            if (component) {
              return component;
            }
          }
          logger.info(`No system message handler found for type: ${sysMsgMetadata?.type}`, { systemMessage: message.SYSTEM_MESSAGE });
        }

        let chatCard: JSX.Element | undefined = undefined;
        if (sysMsgMetadata) {
          chatCard = getOnBehalfMessageComponent(sysMsgMetadata.type, message, sysMsgMetadata.payload, chatId, members);
          if (!chatCard) {
            logger.info(`No system message handler found for type: ${sysMsgMetadata?.type}`, { systemMessage: message.SYSTEM_MESSAGE });
          }
        }
        const hideProfilePic = chatCard == undefined &&  // do not hide profile pic for card messages
          (lastMsg?.fromUser == message.fromUser && moment(message.sentTime).diff(moment(lastMsg?.sentTime), 'minutes') < 10);

        return (
          <div
            key={message.id}
            className={`${isMyMessage ? 'myMessage': 'otherMessage'} messageContainer`}>
            {showTimeStamp &&
              <div className='systemMessage'>{timeStamp}</div>
            }
            {!isMyMessage && sender && !hideProfilePic &&
              <div className='messageUserName'>
                {`${sender.firstName} ${sender.lastName}`}
              </div>
            }
            <div className={`messageRow ${isMyMessage ? 'right' : 'left'}`} >
              {!isMyMessage && sender &&
                <div>
                  <ProfilePicture
                    className={`messageProfilePic ${hideProfilePic? 'hide': ''}`}
                    user={sender}/>
                </div>
              }
              {!isMyMessage && !sender &&
                <div className={`messageProfilePicMissingSender ${hideProfilePic? 'hide': ''}`}>
                  <IonIcon icon={warning}></IonIcon>
                </div>
              }
              <div className='messageTextContainer' >
                {message.replyTo && <div className='messageReply'>{message.replyTo?.preview}</div>}
                {chatCard ? chatCard :
                  <div className={ `messageRow ${isMyMessage ? 'right' : 'left'}` }>
                    <div
                      className='messageText'
                      onMouseDown={startLongPressReplyTimer(message, replyToText)}
                      onTouchStart={startLongPressReplyTimer(message, replyToText)}
                      onMouseUp={clearLongPressReplyTimer}
                      onTouchEnd={clearLongPressReplyTimer}
                    >{getMessageContentComponent(message)}</div>
                  </div>
                }
              </div>
              {isMyMessage &&
                <div>
                  <ProfilePicture
                    className={`messageProfilePic ${hideProfilePic? 'hide': ''}`}
                    user={currentUser}/>
                </div>
              }
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ChatRoomMessageSection;