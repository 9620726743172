import { MbscCalendarMarked } from '@mobiscroll/react';
import { ObservableMap } from 'mobx';
import moment from 'moment';
import { mutatorAction } from 'satcheljs';
import { getTripStore } from '../stores/TripStore';
import { upsert } from '../utils/utils';
import { ParticipantsDto, TripDetailDto, TripGroupDetailDto, TripInvitationDto } from './../../services/openapi';

export const resetTrips = mutatorAction('RESET_TRIPS', () => {
  getTripStore().tripDisplayMap = new ObservableMap<string, TripDetailDto>();
  getTripStore().markedDates = new ObservableMap<string, MbscCalendarMarked>();
});

export const upsertSearchedTrip = mutatorAction('UPSERT_SEARCHED_TRIP', (searchedTrips: TripDetailDto[]) => {
  getTripStore().markedDates = new ObservableMap<string, MbscCalendarMarked>();
  searchedTrips.forEach((t: TripDetailDto) => {
    getTripStore().tripDisplayMap.set(`${t.resort.id}-${moment(t.date.split('T')[0]).format('YYYY/MM/DD')}`, t);
    if([ ParticipantsDto.selfAttending.GOING, ParticipantsDto.selfAttending.INTERESTED ].includes(t.participants.selfAttending)){
      getTripStore().markedDates.set(`${moment(t.date.split('T')[0]).format('YYYY/MM/DD')}-${t.participants.selfAttending}`, {
        date: moment(t.date.split('T')[0]).toDate(),
        markCssClass: t.participants?.selfAttending == ParticipantsDto.selfAttending.GOING? 'goingMark': 'interestedMark'
      });
    }
  });
});

export const addTrip = mutatorAction('ADD_TRIP', (trip: TripDetailDto) => {
  getTripStore().tripDisplayMap.set(`${trip.resort.id}-${moment(trip.date.split('T')[0]).format('YYYY/MM/DD')}`, trip);
  getTripStore().markedDates = new ObservableMap<string, MbscCalendarMarked>();
  [ ...getTripStore().tripDisplayMap.values() ].forEach((t: TripDetailDto) => {
    if([ ParticipantsDto.selfAttending.GOING, ParticipantsDto.selfAttending.INTERESTED ].includes(t.participants.selfAttending)){
      getTripStore().markedDates.set(`${moment(t.date.split('T')[0]).format('YYYY/MM/DD')}-${t.participants.selfAttending}`, {
        date: moment(t.date.split('T')[0]).toDate(),
        markCssClass: t.participants?.selfAttending == ParticipantsDto.selfAttending.GOING? 'goingMark': 'interestedMark'
      });
    }
  });
});

export const upsertTripGroups = mutatorAction('UPSERT_TRIP_GROUPS', (tripGroups: TripGroupDetailDto[]) => {
  const { tripGroupMap } = getTripStore();
  tripGroups.forEach((tripGroup) => {
    const tripId = tripGroup.trip.id;
    const existing = tripGroupMap.get(tripId);
    if (!existing) {
      tripGroupMap.set(tripId, [ tripGroup ]);
    } else {
      upsert(existing, tripGroup);
      tripGroupMap.set(tripId, existing);
    }
  });
});

export const setTripGroupsForTrip = mutatorAction('SET_TRIP_GROUPS_FOR_TRIP', (tripId: string, tripGroups: TripGroupDetailDto[]) => {
  getTripStore().tripGroupMap.set(tripId, tripGroups);
});

export const addSentTripInvitations = mutatorAction('ADD_SENT_TRIP_INVITATIONS', (tripInvitations: TripInvitationDto[]) => {
  const { sentTripInvitations } = getTripStore();
  tripInvitations.forEach((tripInvitation) => {
    const tripId = tripInvitation.trip.id;
    const existing = sentTripInvitations.get(tripId);
    if (!existing) {
      sentTripInvitations.set(tripId, [ tripInvitation ]);
    } else {
      upsert(existing, tripInvitation);
    }
  });
});

export const setIsLoadingInvitationsForTrip = mutatorAction('SET_IS_LOADING_INVITATIONS_FOR_TRIP', (tripId: string, isLoading: boolean) => {
  const { tripIdsWithLoadingSentInvitations } = getTripStore();
  if (isLoading) {
    tripIdsWithLoadingSentInvitations.add(tripId);
  } else {
    tripIdsWithLoadingSentInvitations.delete(tripId);
  }
});

export const setMyTripLoading = mutatorAction('SET_MY_TRIPS_LOADING', () => {
  getTripStore().isLoadingMyTrips = true;
});

export const unsetMyTripLoading = mutatorAction('UNSET_MY_TRIPS_LOADING', () => {
  getTripStore().isLoadingMyTrips = false;
});

export const setTripsLoading = mutatorAction('SET_TRIPS_LOADING', () => {
  getTripStore().isLoadingTrips = true;
});

export const unsetTripsLoading = mutatorAction('UNSET_RIDES_LOADING', () => {
  getTripStore().isLoadingTrips = false;
});

export const setTripsLoadingBackground = mutatorAction('SET_TRIPS_LOADING_BACKGROUND', () => {
  getTripStore().isLoadingTripsBackground = true;
});

export const unsetTripsLoadingBackground = mutatorAction('UNSET_RIDES_LOADING_BACKGROUND', () => {
  getTripStore().isLoadingTripsBackground = false;
});

export const setChangingTripStatus = mutatorAction('SET_JOINING_TRIP', () => {
  getTripStore().isChangingTripStatus = true;
});

export const unsetChangingTripStatus = mutatorAction('UNSET_JOINING_TRIP', () => {
  getTripStore().isChangingTripStatus = false;
});

export const setTripDisplayMapItem = mutatorAction('SET_TRIP_DISPLAY_MAP_ITEM', (id: string, trip: TripDetailDto) => {
  getTripStore().tripDisplayMap.set(id, trip);
});
