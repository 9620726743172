/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CarDetailDto } from './CarDetailDto';
import type { LocationDto } from './LocationDto';
import type { MeetupAgreementDto } from './MeetupAgreementDto';

export type UpdateRideDto = {
    id: string;
    description: string;
    driverLocation: LocationDto;
    departureTime: string;
    pickupAgreements: Array<MeetupAgreementDto>;
    destinationLocation: LocationDto;
    arrivalTime: string;
    returnTime: string;
    dropoffAgreements: Array<MeetupAgreementDto>;
    carDetail: CarDetailDto;
    cost: number;
    visibility: UpdateRideDto.visibility;
    driver: string;
}

export namespace UpdateRideDto {

    export enum visibility {
        PRIVATE = 'Private',
        PUBLIC = 'Public',
    }


}
