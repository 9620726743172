import { action, orchestrator } from 'satcheljs';
import { logger } from '../../logging/winston';
import { CancelablePromise, CommunityDto, CommunityUserDto, Service } from '../../services/openapi';
import {
  addLoadingCommunityUsers, removeLoadingCommunityUsers, setAllCommunities,
  setCommunityUsers, setLoadingAllCommunities, setLoadingMyCommunities, setMyCommunities
} from '../mutators/CommunityStoreMutators';
import { addError } from '../mutators/ErrorStoreMutators';
import { setCurrentUser } from '../mutators/UserStoreMutators';
import { newError } from '../stores/ErrorStore';
import { getUserStore } from '../stores/UserStore';
import { makeAsyncRequest } from '../utils/utils';

const FETCH_MY_COMMUNITIES = 'FETCH_MY_COMMUNITIES';
const FETCH_ALL_COMMUNITIES = 'FETCH_ALL_COMMUNITIES';
const FETCH_COMMUNITY_USERS = 'FETCH_COMMUNITY_USERS';
const JOIN_COMMUNITY = 'JOIN_COMMUNITY';
const UPDATE_CURRENT_COMMUNITY = 'UPDATE_CURRENT_COMMUNITY';

export const fetchMyCommunities = action(FETCH_MY_COMMUNITIES);
export const fetchAllCommunities = action(FETCH_ALL_COMMUNITIES);
export const fetchCommunityUsers = action(FETCH_COMMUNITY_USERS, (communityId: string) => ({ communityId }));
export const joinCommunity = action(JOIN_COMMUNITY, (communityId: string, favoriteResortsIds?: string[]) => ({ communityId, favoriteResortsIds }));
export const updateCurrentCommunity = action(UPDATE_CURRENT_COMMUNITY, (communityId: string) => ({ communityId }));

let fetchMyCommunityUsersPromise: CancelablePromise<CommunityUserDto[]> | undefined = undefined;
let fetchAllCommunitiesPromise: CancelablePromise<CommunityDto[]> | undefined = undefined;
const fetchCommunityUsersPromises: { [tripId: string]: CancelablePromise<CommunityUserDto[]> } = {};

export const fetchMyCommunitiesOrchestrator = orchestrator(fetchMyCommunities, async () => {
  try {
    const { request, response } = await makeAsyncRequest(
      fetchMyCommunityUsersPromise,
      () => Promise.resolve(() => Service.meControllerFindCommunities()),
      () => setLoadingMyCommunities(true),
      () => setLoadingMyCommunities(false));
    fetchMyCommunityUsersPromise = request;

    const communityUsers = await response;
    setMyCommunities(communityUsers);
  } catch (exception) {
    logger.error(`Failed to fetch my communities`, { error: exception });
    addError(newError(`${FETCH_MY_COMMUNITIES}.ServiceError`, `${exception} ${JSON.stringify(exception)}`));
  }
});

export const fetchAllCommunitiesOrchestrator = orchestrator(fetchAllCommunities, async () => {
  try {
    const { request, response } = await makeAsyncRequest(
      fetchAllCommunitiesPromise,
      () => Promise.resolve(() => Service.communitiesControllerFindAll()),
      () => setLoadingAllCommunities(true),
      () => setLoadingAllCommunities(false));
    fetchAllCommunitiesPromise = request;

    const communities = await response;
    setAllCommunities(communities);
  } catch (exception) {
    logger.error(`Failed to fetch all communities`, { error: exception });
    addError(newError(`${FETCH_ALL_COMMUNITIES}.ServiceError`, `${exception} ${JSON.stringify(exception)}`));
  }
});

export const fetchCommunityUsersOrchestrator = orchestrator(fetchCommunityUsers, async ({ communityId }) => {
  try {
    const { request, response } = await makeAsyncRequest(
      fetchCommunityUsersPromises[communityId],
      () => Promise.resolve(() => Service.communitiesControllerFindAllUsers(communityId)),
      () => addLoadingCommunityUsers(communityId),
      () => removeLoadingCommunityUsers(communityId));
    fetchCommunityUsersPromises[communityId] = request;

    const users = await response;
    setCommunityUsers(communityId, users);
  } catch (exception) {
    logger.error(`Failed to fetch community users`, { error: exception });
    addError(newError(`${FETCH_COMMUNITY_USERS}.ServiceError`, `${exception} ${JSON.stringify(exception)}`));
  }
});

export const joinCommunityOrchestrator = orchestrator(joinCommunity, async ({ communityId, favoriteResortsIds }) => {
  try {
    await Service.communitiesControllerJoin(communityId, {
      favoriteResortsIds,
    });
    fetchMyCommunities();
    updateCurrentCommunity(communityId);
  } catch (exception) {
    logger.error(`Failed to join community`, { error: exception });
    addError(newError(`${JOIN_COMMUNITY}.ServiceError`, `${exception} ${JSON.stringify(exception)}`));
  }
});

export const updateCurrentCommunityOrchestrator = orchestrator(updateCurrentCommunity, async ({ communityId }) => {
  const { currentUser } = getUserStore();
  if (!currentUser) {
    logger.error('Failed to fetch trip. Current user is missing.');
    addError(newError('SEARCH_TRIP.MissingUser'));
    return;
  }
  try {
    currentUser.preference.currentCommunityId = communityId;
    const user = await Service.meControllerUpdate(currentUser);
    setCurrentUser(user);
  } catch (exception) {
    logger.error(`Failed to fetch community users`, { error: exception });
    addError(newError(`${FETCH_COMMUNITY_USERS}.ServiceError`, `${exception} ${JSON.stringify(exception)}`));
  }
});
