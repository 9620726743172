import { IonContent, IonHeader, IonIcon, IonList, IonMenu, IonToolbar, useIonModal } from '@ionic/react';
import { qrCode } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import { RefObject } from 'react';
import { useHistory } from 'react-router';
import { getAppVersion } from '../../AppVersion';
import { getFriendStore } from '../../dataflow/stores/FriendStore';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { friendPath, homeRedirectPath, loginPath } from '../../pages/routes';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import QRCodeModal from '../QRCodeModal/QRCodeModal';
import SideMenuItem from './SideMenuItem';
import getMenuItemProps, { SideMenuItemProps } from './getMenuItems';
import './SideMenu.scss';

interface SideMenuProp {
  menuRef: RefObject<HTMLIonMenuElement>;
}

const SideMenu: React.FC<SideMenuProp> = observer((props: SideMenuProp) => {
  const history = useHistory();
  const { currentUser } = getUserStore();
  const friendCount = getFriendStore().friends.length;

  if (!currentUser) {
    history.push(loginPath(homeRedirectPath, window.location.search));
    return <div />;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const onDismiss = () => dismiss();
  const [ present, dismiss ] = useIonModal(QRCodeModal, {
    onDismiss,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    profileUrl: currentUser.profilePictureUrl,
    username: currentUser.username,
    userId: currentUser.id
  });

  const openQRCode = () => {
    present();
  };

  const sideMenuItems = getMenuItemProps().map((menuProps: SideMenuItemProps) => {
    return <SideMenuItem
      key={menuProps.icon}
      icon={menuProps.icon}
      displayText={menuProps.displayText}
      onClick={menuProps.onClick}
      linkPath={menuProps.linkPath}/>;
  });
  return (
    <IonMenu ref={props.menuRef} side='start' contentId='sideMenuId' className='sideMenuContainer'>
      <IonHeader className='sideMenuHeader'>
        <IonToolbar className='sideMenuToolbar'>
          <div className='sideMenuHeaderContainer'>
            <div className='sideMenuHeaderPicture'>
              { currentUser?.firstName && currentUser?.lastName &&
                <ProfilePicture
                  className="sideMenuProfilePic"
                  user={{ ...currentUser, upcomingTrips: [] }}
                  overrideDefaultClick={true}
                />
              }
              <div className="qrIconWrapper" onClick={openQRCode}>
                <IonIcon icon={qrCode}/>
              </div>
            </div>
            <div className='sideMenuHeaderTitle'>
              {`${currentUser?.firstName} ${currentUser?.lastName}`}
            </div>
            <div className='sideMenuHeaderSubtitle'>
              {`@${currentUser?.username}`}
            </div>
            <div className='sideMenuHeaderFriendInfo' onClick={() => history.push(friendPath)}>
              <p>{friendCount}</p><p className='sideMenuHeaderFriendInfoLabel'>Friends</p>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className='nobackground' scrollY={false}>
        <IonList className='nobackground'>
          {sideMenuItems}
        </IonList>
        <div className='sideMenuVersionString'>
          Version {getAppVersion().toString()}
        </div>
      </IonContent>
    </IonMenu>
  );
});

export default SideMenu;
