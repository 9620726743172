import { createStore } from 'satcheljs';

export interface Error {
  code: string,
  message?: string,
  details?: { [key: string]: string },
  innerError?: Error,
  handled: boolean,
}

export interface Warning {
  message: string,
  handled: boolean,
}

interface ErrorStore {
  errors: Error[];
  warnings: Warning[];
  developerMode: boolean;
}

export const getErrorStore = createStore<ErrorStore>('ErrorStore', {
  errors: [],
  warnings: [],
  developerMode: false,
});

export function newError(code: string, message?: string, details?: { [key: string]: string }, innerError?: Error) {
  return {
    code, message, details, innerError, handled: false
  };
}

export function newWarning(message: string) {
  return { message, handled: false };
}
