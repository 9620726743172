import { v4 } from 'uuid';
import { createLogger } from "winston";
import { browserHistory } from "../utils/history";
import { ApplicationInsightsLogger, initAppInsights } from "./appinsights";
import { BrowserConsole } from "./console";

export const appInsights = initAppInsights(browserHistory);

const currentSessionId = v4();
export const logger = createLogger({
  level: 'debug',
  transports: [
    new BrowserConsole(undefined, currentSessionId),
    new ApplicationInsightsLogger(appInsights, currentSessionId)
  ],
});

export function sessionId(): string {
  return currentSessionId;
}