import { IonItem, IonLabel } from "@ionic/react";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { PreviewUserDto } from "../../services/openapi";
import { getProfilePagePath } from "../routes";

interface DiscoverPeopleResultListItemProps {
  user: PreviewUserDto;
}

const DiscoverPeopleResultListItem = ({ user }: DiscoverPeopleResultListItemProps) => {
  const profileUrl = getProfilePagePath(user.username);
  return (
    <IonItem className="searchResultListItem" routerLink={ profileUrl }>
      <ProfilePicture
        user={user}
        overrideDefaultClick={true}
      />
      <IonLabel>
        <h3>{`${user.firstName} ${user.lastName}`}</h3>
        <p>{user.username}</p>
      </IonLabel>
    </IonItem>
  );
};

export default DiscoverPeopleResultListItem;