import { Clipboard } from '@capacitor/clipboard';
import { Share, ShareOptions } from '@capacitor/share';
import moment from 'moment';
import { requestToJoinTripGroupLiteral } from '../pages/routes';
import { TripGroupDetailDto, UserDto } from '../services/openapi';
import { runOnPlatform } from './platform';

export function getShareUrl(path: string, currentUser: UserDto | undefined): string {
  const urlString = `${process.env.REACT_APP_FUSEND_DOMAIN}/${path.replace(/^\//, '')}`;
  const url = new URL(urlString);
  if (currentUser) {
    url.searchParams.set('referrer', currentUser.id);
  }
  return url.toString();
}

export async function share(clipboardMessage: string, shareOption: ShareOptions, presentToast: () => Promise<void>) {
  await runOnPlatform(
    async () => {
      await Share.share(shareOption);
    },
    async () => {
      await Clipboard.write({ string: clipboardMessage });
      await presentToast();
    }
  );
}

export async function shareTripGroupClick(tripGroup: TripGroupDetailDto, currentUser: UserDto | undefined, presentToast: (message: string, duration?: number) => Promise<void>) {
  const url = getShareUrl(
    requestToJoinTripGroupLiteral
      .replace(':tripId', tripGroup.trip.id)
      .replace(':tripGroupId', tripGroup.id),
    currentUser
  );

  let message;
  const groupDate = tripGroup.trip.date.split('T')[0];
  switch (tripGroup.purpose) {
    case TripGroupDetailDto.purpose.CARPOOL:
    case TripGroupDetailDto.purpose.RIDE:
      const spotLeft = (tripGroup.rideOptions?.maxSize || tripGroup.members.length) - tripGroup.members.length;
      // eslint-disable-next-line max-len
      message = `Looking for passengers (${tripGroup.title})\non ${moment(groupDate).format("dddd, MMMM Do")}\ngoing to ${tripGroup.trip.resort.location.name}\nwith ${spotLeft} spots left\n${tripGroup.notes}\n${url}`;
      break;
    case TripGroupDetailDto.purpose.TRIP:
      message = `Join the trip chat\nfor ${tripGroup.trip.resort.location.name}\non ${moment(groupDate).format("dddd, MMMM Do")}\n${url}`;
      break;
    case TripGroupDetailDto.purpose.GENERAL:
      message = `Join the trip chat ${tripGroup.title}\nfor ${tripGroup.trip.resort.location.name}\non ${moment(groupDate).format("dddd, MMMM Do")}\n${url}`;
      break;
  }

  share(message, {
    title: message,
    text: message,
    dialogTitle: message,
  }, () => presentToast('Trip group link copied', 2000));
}
