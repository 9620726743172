/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityDto } from '../models/ActivityDto';
import type { BatchUpdateResortConditionDto } from '../models/BatchUpdateResortConditionDto';
import type { ChatDto } from '../models/ChatDto';
import type { CommunityDto } from '../models/CommunityDto';
import type { CommunityUserDto } from '../models/CommunityUserDto';
import type { CreateChatWithFriendsDto } from '../models/CreateChatWithFriendsDto';
import type { CreateChatWithOtherDto } from '../models/CreateChatWithOtherDto';
import type { CreateProviderUserDto } from '../models/CreateProviderUserDto';
import type { CreateTripCarpoolDto } from '../models/CreateTripCarpoolDto';
import type { CreateTripDto } from '../models/CreateTripDto';
import type { CreateTripGroupDto } from '../models/CreateTripGroupDto';
import type { DeleteUserDto } from '../models/DeleteUserDto';
import type { FeedsDto } from '../models/FeedsDto';
import type { FirebaseJwtDto } from '../models/FirebaseJwtDto';
import type { FriendDto } from '../models/FriendDto';
import type { FriendRequestDto } from '../models/FriendRequestDto';
import type { FriendsCountDto } from '../models/FriendsCountDto';
import type { JoinCommunityDto } from '../models/JoinCommunityDto';
import type { JoinRequestDto } from '../models/JoinRequestDto';
import type { LoginDto } from '../models/LoginDto';
import type { NewCommunityDto } from '../models/NewCommunityDto';
import type { NewFriendRequestDto } from '../models/NewFriendRequestDto';
import type { PreviewJoinRequestDto } from '../models/PreviewJoinRequestDto';
import type { PreviewRideDto } from '../models/PreviewRideDto';
import type { PreviewUserDto } from '../models/PreviewUserDto';
import type { RelationshipDto } from '../models/RelationshipDto';
import type { ResortConditionDto } from '../models/ResortConditionDto';
import type { ResortDto } from '../models/ResortDto';
import type { ResortRoadConditionDto } from '../models/ResortRoadConditionDto';
import type { RideDetailDto } from '../models/RideDetailDto';
import type { TripCarpoolDto } from '../models/TripCarpoolDto';
import type { TripDetailDto } from '../models/TripDetailDto';
import type { TripGroupDetailDto } from '../models/TripGroupDetailDto';
import type { TripInvitationDto } from '../models/TripInvitationDto';
import type { UpdateFriendDto } from '../models/UpdateFriendDto';
import type { UpdateFriendRequestDto } from '../models/UpdateFriendRequestDto';
import type { UpdateJoinRequestDto } from '../models/UpdateJoinRequestDto';
import type { UpdateResortConditionsResultDto } from '../models/UpdateResortConditionsResultDto';
import type { UpdateResortRoadConditionsDto } from '../models/UpdateResortRoadConditionsDto';
import type { UpdateRideDto } from '../models/UpdateRideDto';
import type { UpdateTripCarpoolDto } from '../models/UpdateTripCarpoolDto';
import type { UpdateTripGroupDto } from '../models/UpdateTripGroupDto';
import type { UserDto } from '../models/UserDto';
import type { UserListDto } from '../models/UserListDto';
import type { VersionDto } from '../models/VersionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class Service {

    /**
     * @param username
     * @returns PreviewUserDto
     * @throws ApiError
     */
    public static usersControllerFindByUsername(
        username: string,
    ): CancelablePromise<PreviewUserDto> {
        return __request({
            method: 'GET',
            path: `/v1/users/username/${username}`,
        });
    }

    /**
     * @param id
     * @returns PreviewUserDto
     * @throws ApiError
     */
    public static usersControllerFindOne(
        id: string,
    ): CancelablePromise<PreviewUserDto> {
        return __request({
            method: 'GET',
            path: `/v1/users/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns UserDto
     * @throws ApiError
     */
    public static usersControllerUpdate(
        id: string,
        requestBody: UserDto,
    ): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/v1/users/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param username
     * @returns PreviewUserDto
     * @throws ApiError
     */
    public static usersControllerFind(
        username: string,
    ): CancelablePromise<Array<PreviewUserDto>> {
        return __request({
            method: 'GET',
            path: `/v1/users`,
            query: {
                'username': username,
            },
        });
    }

    /**
     * @param id
     * @returns RideDetailDto
     * @throws ApiError
     */
    public static usersControllerFindRides(
        id: string,
    ): CancelablePromise<Array<RideDetailDto>> {
        return __request({
            method: 'GET',
            path: `/v1/users/${id}/rides`,
        });
    }

    /**
     * @param id
     * @returns PreviewJoinRequestDto
     * @throws ApiError
     */
    public static usersControllerFindRideRequests(
        id: string,
    ): CancelablePromise<Array<PreviewJoinRequestDto>> {
        return __request({
            method: 'GET',
            path: `/v1/users/${id}/ride-requests`,
        });
    }

    /**
     * @param id
     * @returns FriendsCountDto
     * @throws ApiError
     */
    public static usersControllerGetFriendsCount(
        id: string,
    ): CancelablePromise<FriendsCountDto> {
        return __request({
            method: 'GET',
            path: `/v1/users/${id}/friends-count`,
        });
    }

    /**
     * @returns PreviewUserDto
     * @throws ApiError
     */
    public static usersS2SControllerRefresh(): CancelablePromise<Array<PreviewUserDto>> {
        return __request({
            method: 'POST',
            path: `/v1/users-s2s/users`,
        });
    }

    /**
     * @param requestBody
     * @returns RideDetailDto
     * @throws ApiError
     */
    public static ridesControllerCreate(
        requestBody: UpdateRideDto,
    ): CancelablePromise<RideDetailDto> {
        return __request({
            method: 'POST',
            path: `/v1/rides`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns PreviewRideDto
     * @throws ApiError
     */
    public static ridesControllerFindOnePreview(
        id: string,
    ): CancelablePromise<PreviewRideDto> {
        return __request({
            method: 'GET',
            path: `/v1/rides/id/${id}/preview`,
        });
    }

    /**
     * @param id
     * @returns RideDetailDto
     * @throws ApiError
     */
    public static ridesControllerFindOne(
        id: string,
    ): CancelablePromise<RideDetailDto> {
        return __request({
            method: 'GET',
            path: `/v1/rides/id/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RideDetailDto
     * @throws ApiError
     */
    public static ridesControllerUpdate(
        id: string,
        requestBody: UpdateRideDto,
    ): CancelablePromise<RideDetailDto> {
        return __request({
            method: 'PATCH',
            path: `/v1/rides/id/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns RideDetailDto
     * @throws ApiError
     */
    public static ridesControllerRemove(
        id: string,
    ): CancelablePromise<RideDetailDto> {
        return __request({
            method: 'DELETE',
            path: `/v1/rides/id/${id}`,
        });
    }

    /**
     * @param id
     * @returns PreviewJoinRequestDto
     * @throws ApiError
     */
    public static ridesControllerFindRequests(
        id: string,
    ): CancelablePromise<Array<PreviewJoinRequestDto>> {
        return __request({
            method: 'GET',
            path: `/v1/rides/id/${id}/requests`,
        });
    }

    /**
     * @param origin
     * @param radius
     * @returns PreviewRideDto
     * @throws ApiError
     */
    public static ridesControllerFindNear(
        origin: string,
        radius: string,
    ): CancelablePromise<Array<PreviewRideDto>> {
        return __request({
            method: 'GET',
            path: `/v1/rides/near`,
            query: {
                'origin': origin,
                'radius': radius,
            },
        });
    }

    /**
     * @param id
     * @param userId
     * @returns RideDetailDto
     * @throws ApiError
     */
    public static ridesControllerRemoveUser(
        id: string,
        userId: string,
    ): CancelablePromise<RideDetailDto> {
        return __request({
            method: 'DELETE',
            path: `/v1/rides/id/${id}/riders/${userId}`,
        });
    }

    /**
     * @param requestBody
     * @returns PreviewJoinRequestDto
     * @throws ApiError
     */
    public static rideRequestsControllerCreate(
        requestBody: JoinRequestDto,
    ): CancelablePromise<PreviewJoinRequestDto> {
        return __request({
            method: 'POST',
            path: `/v1/ride-requests`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns UpdateJoinRequestDto
     * @throws ApiError
     */
    public static rideRequestsControllerUpdate(
        id: string,
        requestBody: UpdateJoinRequestDto,
    ): CancelablePromise<UpdateJoinRequestDto> {
        return __request({
            method: 'PATCH',
            path: `/v1/ride-requests/id/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns PreviewJoinRequestDto
     * @throws ApiError
     */
    public static rideRequestsControllerFindOne(
        id: string,
    ): CancelablePromise<PreviewJoinRequestDto> {
        return __request({
            method: 'GET',
            path: `/v1/ride-requests/id/${id}`,
        });
    }

    /**
     * @param communityId
     * @param friendsOnly
     * @param types
     * @returns FeedsDto
     * @throws ApiError
     */
    public static feedsControllerGetFeeds(
        communityId: string,
        friendsOnly: boolean,
        types: Array<string>,
    ): CancelablePromise<FeedsDto> {
        return __request({
            method: 'GET',
            path: `/v1/feeds`,
            query: {
                'communityId': communityId,
                'friendsOnly': friendsOnly,
                'types': types,
            },
        });
    }

    /**
     * @param selfId
     * @param friendId
     * @param requestBody
     * @returns FriendDto
     * @throws ApiError
     */
    public static friendsControllerUpdate(
        selfId: string,
        friendId: string,
        requestBody: UpdateFriendDto,
    ): CancelablePromise<FriendDto> {
        return __request({
            method: 'PATCH',
            path: `/v1/friends/self-id/${selfId}/friend-id/${friendId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns FriendRequestDto
     * @throws ApiError
     */
    public static friendRequestsControllerAdd(
        requestBody: NewFriendRequestDto,
    ): CancelablePromise<FriendRequestDto> {
        return __request({
            method: 'POST',
            path: `/v1/friend-requests`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requesterUserId
     * @param recipientUserId
     * @param requestBody
     * @returns FriendRequestDto
     * @throws ApiError
     */
    public static friendRequestsControllerUpdate(
        requesterUserId: string,
        recipientUserId: string,
        requestBody: UpdateFriendRequestDto,
    ): CancelablePromise<FriendRequestDto> {
        return __request({
            method: 'PATCH',
            path: `/v1/friend-requests/requester-id/${requesterUserId}/recipient-id/${recipientUserId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns TripDetailDto
     * @throws ApiError
     */
    public static tripsControllerCreate(
        requestBody: CreateTripDto,
    ): CancelablePromise<TripDetailDto> {
        return __request({
            method: 'POST',
            path: `/v1/trips`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns TripDetailDto
     * @throws ApiError
     */
    public static tripsControllerGet(
        id: string,
    ): CancelablePromise<TripDetailDto> {
        return __request({
            method: 'GET',
            path: `/v1/trips/id/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns TripInvitationDto
     * @throws ApiError
     */
    public static tripsControllerInviteToTrip(
        id: string,
        requestBody: UserListDto,
    ): CancelablePromise<Array<TripInvitationDto>> {
        return __request({
            method: 'POST',
            path: `/v1/trips/id/${id}/invite`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param tripId
     * @param requestBody
     * @returns TripGroupDetailDto
     * @throws ApiError
     */
    public static tripsControllerCreateTripGroup(
        tripId: string,
        requestBody: CreateTripGroupDto,
    ): CancelablePromise<TripGroupDetailDto> {
        return __request({
            method: 'POST',
            path: `/v1/trips/id/${tripId}/groups`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param tripId
     * @param purpose
     * @param privacy
     * @returns TripGroupDetailDto
     * @throws ApiError
     */
    public static tripsControllerListTripGroups(
        tripId: string,
        purpose: Array<string>,
        privacy: Array<string>,
    ): CancelablePromise<Array<TripGroupDetailDto>> {
        return __request({
            method: 'GET',
            path: `/v1/trips/id/${tripId}/groups`,
            query: {
                'purpose': purpose,
                'privacy': privacy,
            },
        });
    }

    /**
     * @param tripId
     * @param groupId
     * @param requestBody
     * @returns TripGroupDetailDto
     * @throws ApiError
     */
    public static tripsControllerUpdateTripGroup(
        tripId: string,
        groupId: string,
        requestBody: UpdateTripGroupDto,
    ): CancelablePromise<TripGroupDetailDto> {
        return __request({
            method: 'PATCH',
            path: `/v1/trips/id/${tripId}/groups/${groupId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param groupId
     * @returns TripGroupDetailDto
     * @throws ApiError
     */
    public static tripsControllerGetTripGroup(
        groupId: string,
    ): CancelablePromise<TripGroupDetailDto> {
        return __request({
            method: 'GET',
            path: `/v1/trips/groups/id/${groupId}`,
        });
    }

    /**
     * @param groupId
     * @returns TripGroupDetailDto
     * @throws ApiError
     */
    public static tripsControllerRequestToJoinTripGroup(
        groupId: string,
    ): CancelablePromise<TripGroupDetailDto> {
        return __request({
            method: 'POST',
            path: `/v1/trips/groups/id/${groupId}/request-join`,
        });
    }

    /**
     * @param groupId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tripsControllerJoinTripGroup(
        groupId: string,
        requestBody: UserListDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/v1/trips/groups/id/${groupId}/join`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param groupId
     * @returns any
     * @throws ApiError
     */
    public static tripsControllerLeaveTripGroup(
        groupId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/v1/trips/groups/id/${groupId}/leave`,
        });
    }

    /**
     * @param communities
     * @param start
     * @param daysCount
     * @param resorts
     * @param whosGoing
     * @returns TripDetailDto
     * @throws ApiError
     */
    public static tripsControllerSearch(
        communities: Array<string>,
        start: string,
        daysCount: number,
        resorts: Array<string>,
        whosGoing: string,
    ): CancelablePromise<Array<TripDetailDto>> {
        return __request({
            method: 'GET',
            path: `/v1/trips/search`,
            query: {
                'communities': communities,
                'start': start,
                'daysCount': daysCount,
                'resorts': resorts,
                'whosGoing': whosGoing,
            },
        });
    }

    /**
     * @param requestBody
     * @returns TripCarpoolDto
     * @throws ApiError
     */
    public static tripsControllerCreateTripCarpool(
        requestBody: CreateTripCarpoolDto,
    ): CancelablePromise<TripCarpoolDto> {
        return __request({
            method: 'POST',
            path: `/v1/trips/carpools`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns TripCarpoolDto
     * @throws ApiError
     */
    public static tripsControllerUpdateTripCarpool(
        id: string,
        requestBody: UpdateTripCarpoolDto,
    ): CancelablePromise<TripCarpoolDto> {
        return __request({
            method: 'PUT',
            path: `/v1/trips/carpools/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns TripCarpoolDto
     * @throws ApiError
     */
    public static tripsControllerGetTripCarpool(
        id: string,
    ): CancelablePromise<TripCarpoolDto> {
        return __request({
            method: 'GET',
            path: `/v1/trips/carpools/${id}`,
        });
    }

    /**
     * @param id
     * @returns TripCarpoolDto
     * @throws ApiError
     */
    public static tripsControllerListTripCarpools(
        id: string,
    ): CancelablePromise<Array<TripCarpoolDto>> {
        return __request({
            method: 'GET',
            path: `/v1/trips/id/${id}/carpools`,
        });
    }

    /**
     * @param requestBody
     * @returns CommunityUserDto
     * @throws ApiError
     */
    public static communitiesControllerCreate(
        requestBody: NewCommunityDto,
    ): CancelablePromise<CommunityUserDto> {
        return __request({
            method: 'POST',
            path: `/v1/communities`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CommunityDto
     * @throws ApiError
     */
    public static communitiesControllerFindAll(): CancelablePromise<Array<CommunityDto>> {
        return __request({
            method: 'GET',
            path: `/v1/communities`,
        });
    }

    /**
     * @param communityId
     * @returns CommunityUserDto
     * @throws ApiError
     */
    public static communitiesControllerFindAllUsers(
        communityId: string,
    ): CancelablePromise<Array<CommunityUserDto>> {
        return __request({
            method: 'GET',
            path: `/v1/communities/${communityId}/users`,
        });
    }

    /**
     * @param communityId
     * @param requestBody
     * @returns CommunityUserDto
     * @throws ApiError
     */
    public static communitiesControllerJoin(
        communityId: string,
        requestBody: JoinCommunityDto,
    ): CancelablePromise<CommunityUserDto> {
        return __request({
            method: 'POST',
            path: `/v1/communities/${communityId}/join`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param providerToken
     * @param requestBody
     * @returns LoginDto
     * @throws ApiError
     */
    public static authControllerAppleLogin(
        providerToken: string,
        requestBody: CreateProviderUserDto,
    ): CancelablePromise<LoginDto> {
        return __request({
            method: 'POST',
            path: `/v1/auth/login/apple`,
            headers: {
                'Provider-Token': providerToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param providerToken
     * @param requestBody
     * @returns LoginDto
     * @throws ApiError
     */
    public static authControllerFacebookLogin(
        providerToken: string,
        requestBody: CreateProviderUserDto,
    ): CancelablePromise<LoginDto> {
        return __request({
            method: 'POST',
            path: `/v1/auth/login/facebook`,
            headers: {
                'Provider-Token': providerToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param providerToken
     * @param requestBody
     * @returns LoginDto
     * @throws ApiError
     */
    public static authControllerGoogleLogin(
        providerToken: string,
        requestBody: CreateProviderUserDto,
    ): CancelablePromise<LoginDto> {
        return __request({
            method: 'POST',
            path: `/v1/auth/login/google`,
            headers: {
                'Provider-Token': providerToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FirebaseJwtDto
     * @throws ApiError
     */
    public static authControllerRenewFirebase(): CancelablePromise<FirebaseJwtDto> {
        return __request({
            method: 'POST',
            path: `/v1/auth/renew/firebase`,
        });
    }

    /**
     * @returns VersionDto
     * @throws ApiError
     */
    public static meControllerGetMinAppVersion(): CancelablePromise<VersionDto> {
        return __request({
            method: 'GET',
            path: `/v1/me/min-app-version`,
        });
    }

    /**
     * @returns UserDto
     * @throws ApiError
     */
    public static meControllerGet(): CancelablePromise<UserDto> {
        return __request({
            method: 'GET',
            path: `/v1/me`,
        });
    }

    /**
     * @param requestBody
     * @returns UserDto
     * @throws ApiError
     */
    public static meControllerUpdate(
        requestBody: UserDto,
    ): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/v1/me`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns DeleteUserDto
     * @throws ApiError
     */
    public static meControllerDelete(
        requestBody: DeleteUserDto,
    ): CancelablePromise<DeleteUserDto> {
        return __request({
            method: 'DELETE',
            path: `/v1/me`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static meControllerUploadProfilePicture(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/v1/me/pictures/profile`,
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static meControllerLogout(
        requestBody: CreateProviderUserDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/v1/me/logout`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static meControllerRegisterNotificationToken(
        requestBody: CreateProviderUserDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/v1/me/notification-token`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param rideId
     * @returns ChatDto
     * @throws ApiError
     */
    public static meControllerCreateChatWithDriver(
        rideId: string,
    ): CancelablePromise<ChatDto> {
        return __request({
            method: 'POST',
            path: `/v1/me/chats/ride-driver/${rideId}`,
        });
    }

    /**
     * @param requestBody
     * @returns ChatDto
     * @throws ApiError
     */
    public static meControllerCreateChatWithFriends(
        requestBody: CreateChatWithFriendsDto,
    ): CancelablePromise<ChatDto> {
        return __request({
            method: 'POST',
            path: `/v1/me/chats/friend`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns ChatDto
     * @throws ApiError
     */
    public static meControllerCreateChatWithOtherUser(
        userId: string,
        requestBody: CreateChatWithOtherDto,
    ): CancelablePromise<ChatDto> {
        return __request({
            method: 'POST',
            path: `/v1/me/chats/user/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param chatType
     * @returns ChatDto
     * @throws ApiError
     */
    public static meControllerGetChats(
        chatType: string,
    ): CancelablePromise<Array<ChatDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/chats`,
            query: {
                'chatType': chatType,
            },
        });
    }

    /**
     * @param chatId
     * @returns ChatDto
     * @throws ApiError
     */
    public static meControllerGetChat(
        chatId: string,
    ): CancelablePromise<ChatDto> {
        return __request({
            method: 'GET',
            path: `/v1/me/chats/${chatId}`,
        });
    }

    /**
     * @param start
     * @param daysCount
     * @param resorts
     * @returns TripDetailDto
     * @throws ApiError
     */
    public static meControllerGetTrips(
        start: string,
        daysCount: number,
        resorts: Array<string>,
    ): CancelablePromise<Array<TripDetailDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/trips`,
            query: {
                'start': start,
                'daysCount': daysCount,
                'resorts': resorts,
            },
        });
    }

    /**
     * @param tripId
     * @returns TripInvitationDto
     * @throws ApiError
     */
    public static meControllerGetReceivedTripInvites(
        tripId: string,
    ): CancelablePromise<Array<TripInvitationDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/trips/${tripId}/invites/received`,
        });
    }

    /**
     * @param tripId
     * @returns TripInvitationDto
     * @throws ApiError
     */
    public static meControllerGetSentTripInvites(
        tripId: string,
    ): CancelablePromise<Array<TripInvitationDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/trips/${tripId}/invites/sent`,
        });
    }

    /**
     * @param limit
     * @param skip
     * @returns FriendRequestDto
     * @throws ApiError
     */
    public static meControllerGetPendingFriendRequests(
        limit: number,
        skip: number,
    ): CancelablePromise<Array<FriendRequestDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/friend-requests/pending-on-me`,
            query: {
                'limit': limit,
                'skip': skip,
            },
        });
    }

    /**
     * @param take
     * @param skip
     * @returns FriendDto
     * @throws ApiError
     */
    public static meControllerGetFriends(
        take: string,
        skip: string,
    ): CancelablePromise<Array<FriendDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/friends`,
            query: {
                'take': take,
                'skip': skip,
            },
        });
    }

    /**
     * @param otherUserId
     * @returns RelationshipDto
     * @throws ApiError
     */
    public static meControllerGetRelationship(
        otherUserId: string,
    ): CancelablePromise<RelationshipDto> {
        return __request({
            method: 'GET',
            path: `/v1/me/relationship/${otherUserId}`,
        });
    }

    /**
     * @param start
     * @param end
     * @returns ActivityDto
     * @throws ApiError
     */
    public static meControllerListActivities(
        start: string,
        end: string,
    ): CancelablePromise<Array<ActivityDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/activities`,
            query: {
                'start': start,
                'end': end,
            },
        });
    }

    /**
     * @param start
     * @param daysCount
     * @returns TripGroupDetailDto
     * @throws ApiError
     */
    public static meControllerGetTripGroups(
        start: string,
        daysCount: number,
    ): CancelablePromise<Array<TripGroupDetailDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/trip-groups`,
            query: {
                'start': start,
                'daysCount': daysCount,
            },
        });
    }

    /**
     * @returns CommunityUserDto
     * @throws ApiError
     */
    public static meControllerFindCommunities(): CancelablePromise<Array<CommunityUserDto>> {
        return __request({
            method: 'GET',
            path: `/v1/me/communities`,
        });
    }

    /**
     * @returns ResortDto
     * @throws ApiError
     */
    public static catalogControllerFindAllResorts(): CancelablePromise<Array<ResortDto>> {
        return __request({
            method: 'GET',
            path: `/v1/catalog/resorts`,
        });
    }

    /**
     * @param requestBody
     * @returns ResortDto
     * @throws ApiError
     */
    public static catalogControllerAddResorts(
        requestBody: Array<string>,
    ): CancelablePromise<Array<ResortDto>> {
        return __request({
            method: 'POST',
            path: `/v1/catalog/resorts`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param start
     * @param daysCount
     * @param resortIds
     * @returns ResortConditionDto
     * @throws ApiError
     */
    public static catalogControllerSearchResortConditions(
        start: string,
        daysCount: number,
        resortIds: Array<string>,
    ): CancelablePromise<Array<ResortConditionDto>> {
        return __request({
            method: 'GET',
            path: `/v1/catalog/resort-conditions`,
            query: {
                'start': start,
                'daysCount': daysCount,
                'resortIds': resortIds,
            },
        });
    }

    /**
     * @param requestBody
     * @returns UpdateResortConditionsResultDto
     * @throws ApiError
     */
    public static catalogControllerUpdateResortConditions(
        requestBody: BatchUpdateResortConditionDto,
    ): CancelablePromise<UpdateResortConditionsResultDto> {
        return __request({
            method: 'POST',
            path: `/v1/catalog/resort-conditions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static catalogControllerUpdateResortsRoadConditions(
        requestBody: UpdateResortRoadConditionsDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/v1/catalog/resort-road-conditions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param resortId
     * @param end
     * @param lookbackHours
     * @returns ResortRoadConditionDto
     * @throws ApiError
     */
    public static catalogControllerGetResortRoadConditions(
        resortId: string,
        end: string,
        lookbackHours: number,
    ): CancelablePromise<Array<ResortRoadConditionDto>> {
        return __request({
            method: 'GET',
            path: `/v1/catalog/resort-road-conditions/${resortId}`,
            query: {
                'end': end,
                'lookbackHours': lookbackHours,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static qrCodeControllerQr20231015(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/v1/qr-code/landing/20231015`,
        });
    }

}