import { createStore } from 'satcheljs';
import { UserDto } from '../../services/openapi';
import { getCurrentCommunity } from './CommunityStore';

interface UserStore {
  currentUser?: UserDto,
  accessToken?: string
  notificationToken?: string
  loading: boolean,
}

export const getUserStore = createStore<UserStore>('UserStore', {
  currentUser: undefined,
  accessToken: undefined,
  notificationToken: undefined,
  loading: false,
});

export function isLoggedIn(): boolean {
  const store = getUserStore();
  return !store.loading && store.accessToken != undefined && store.currentUser != undefined;
}

export function hasSignedUp(): boolean {
  const store = getUserStore();
  return isLoggedIn()
    && (store.currentUser?.completedSignup ?? true)
    && store.currentUser?.preference.currentCommunityId != undefined;
}

export function getUserFavoriteResortsIds(): string[] {
  const { currentUser } = getUserStore();
  const communityUser = getCurrentCommunity();

  return communityUser?.favoriteResortsIds // prefer community favoriteResortsIds
    || currentUser?.preference?.favoriteResortsIds  // fallback to user preference favoriteResortsIds (for non-community users)
    || [];
}
