import { IonButton, IonButtons, IonCheckbox, IonLabel, IonSpinner } from "@ionic/react";
import { observer } from "mobx-react-latest";
import React from "react";
import { getUserStore } from "../../../dataflow/stores/UserStore";
import { Service, UpdateFriendRequestDto } from "../../../services/openapi";

interface ProfilePicModalHandleFriendRequestProps {
  firstName: string;
  userId: string;
  onSubmit: () => void;
}

const ProfilePicModalHandleFriendRequest: React.FC<ProfilePicModalHandleFriendRequestProps> = observer((props: ProfilePicModalHandleFriendRequestProps) => {
  const { firstName, userId, onSubmit } = props;
  const [ isAcceptLoading, setIsAcceptLoading ] = React.useState<boolean>(false);
  const [ isIgnoreLoading, setIsIgnoreLoading ] = React.useState<boolean>(false);
  const [ errMessage, setErrMessage ] = React.useState<string>("");
  const [ followChecked, setFollowChecked ] = React.useState<boolean>(true);

  const onAcceptRequestClick = () => {
    const { currentUser } = getUserStore();
    if (currentUser) {
      Service.friendRequestsControllerUpdate(userId, currentUser.id, { status: UpdateFriendRequestDto.status.ACCEPTED, follow: followChecked }).then(() => {
        setIsAcceptLoading(false);
        onSubmit();
      }).catch(() => {
        setErrMessage("Some thing went wrong, try again later");
        setIsAcceptLoading(false);
      });
      setIsAcceptLoading(true);
    }
  };

  const onIgnoreRequestClick = () => {
    const { currentUser } = getUserStore();
    if (currentUser) {
      Service.friendRequestsControllerUpdate(userId, currentUser.id, { status: UpdateFriendRequestDto.status.REJECTED, follow: followChecked }).then(() => {
        setIsIgnoreLoading(false);
        onSubmit();
      }).catch(() => {
        setErrMessage("Some thing went wrong, try again later");
        setIsIgnoreLoading(false);
      });
      setIsIgnoreLoading(true);
    }
  };

  return (
    <>
      <span className="profilePicModalContentText">{firstName} has sent you a friend request!</span>
      <IonButtons>
        {
          isAcceptLoading
            ? <IonButton color="secondary" fill="solid" disabled={true}><IonSpinner name="crescent"/></IonButton>
            : <IonButton color="secondary" fill="solid" onClick={onAcceptRequestClick}>Accept</IonButton>
        }
        {
          isIgnoreLoading
            ? <IonButton color="secondary" fill="solid" disabled={true}><IonSpinner name="crescent"/></IonButton>
            : <IonButton color="secondary" fill="solid" onClick={onIgnoreRequestClick}>Ignore</IonButton>
        }
      </IonButtons>
      <div className="profilePicModalAddFriendFollow">
        <IonCheckbox mode="md" checked={followChecked} onIonChange={e => setFollowChecked(e.detail.checked)} />
        <IonLabel>Follow {firstName}&apos;s snow updates</IonLabel>
      </div>
      {errMessage && <p className="profilePicModalContentErrMessage">{errMessage}</p>}
    </>
  );
});

export default ProfilePicModalHandleFriendRequest;