import { Clipboard } from '@capacitor/clipboard';
import { useIonToast } from '@ionic/react';
import { Error, Warning } from '../dataflow/stores/ErrorStore';


export function createToast(message: string, duration: number): [() => Promise<void>, () => void] {
  const [ present, dismiss ] = useIonToast();
  const presentFn = () => present({
    message,
    duration,
    position: 'bottom',
    buttons: [ {
      text: 'Dismiss',
      handler: () => dismiss()
    } ],
  });
  return [ presentFn, dismiss ];
}

export function createWarningToast(duration = 0): [(warning: Warning) => Promise<void>, () => void] {
  const [ present, dismiss ] = useIonToast();
  const presentFn = (warning: Warning) => present({
    header: 'WARNING',
    color: 'warning',
    message: warning.message,
    duration,
    position: 'top',
    buttons: [ {
      text: 'Dismiss',
      handler: () => dismiss()
    } ],
  });
  return [ presentFn, dismiss ];
}

export function createErrorToast(duration = 0): [(error: Error) => Promise<void>, () => void] {
  const [ present, dismiss ] = useIonToast();
  const presentFn = (error: Error) => present({
    header: `ERROR: ${error.code}`,
    color: 'danger',
    message: error.message,
    duration,
    position: 'top',
    buttons: [ {
      side: 'start',
      text: 'Copy',
      role: 'ok',
      handler: async () => {
        await Clipboard.write({
          string: JSON.stringify(error)
        });
      }
    }, {
      text: 'Dismiss',
      handler: () => dismiss()
    } ],
  });
  return [ presentFn, dismiss ];
}