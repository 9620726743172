import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { addCircle, car, chevronBack } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import { Redirect, useHistory, useParams } from "react-router";
import { isFeatureEnabled } from "../../dataflow/stores/RemoteConfigStore";
import { getMyRideGroup } from "../../dataflow/stores/TripStore";
import { getCarpoolRoleSelectionPath, getRequestToJoinTripGroupPath, getTripDetailWithIdPath, homeRedirectPath } from "../routes";
import { Carpools } from "./Carpools";
import { Intents } from "./Intents";

interface CarpoolRouteParams {
  tripId: string;
}

export const LobbyPage = observer(() => {
  const isEnabled = isFeatureEnabled('carpool');
  const { tripId } = useParams<CarpoolRouteParams>();
  const history = useHistory();
  const myRideGroup = getMyRideGroup(tripId);

  if (isEnabled === false) {
    return <Redirect to={homeRedirectPath} />;
  }

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonBackButton defaultHref={getTripDetailWithIdPath(tripId)} icon={chevronBack} />
          </IonButtons>
          <IonTitle>Carpool Lobby</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent carpoolLobbyPage'>
        {myRideGroup
          ?
          <IonButton className="newCarpoolBtn" fill="outline" color="primary" onClick={() => history.push(getRequestToJoinTripGroupPath(tripId, myRideGroup.id))}>
            <IonIcon icon={car} /><span>{`My Carpool: ${myRideGroup.title}`}</span>
          </IonButton>
          :
          <IonButton className="newCarpoolBtn" fill="outline" color="secondary" onClick={() => history.push(getCarpoolRoleSelectionPath(tripId))}>
            <IonIcon icon={addCircle} /><span>Add New</span>
          </IonButton>
        }
        <Intents tripId={tripId} />
        <Carpools tripId={tripId} />
      </IonContent>
    </IonPage>
  );
});