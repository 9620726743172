/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GeometryDto = {
    type: GeometryDto.type;
    coordinates: Array<number>;
}

export namespace GeometryDto {

    export enum type {
        POINT = 'Point',
    }


}
