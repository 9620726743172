/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CommunityDto } from './CommunityDto';
import type { PreviewUserDto } from './PreviewUserDto';

export type CommunityUserDto = {
    community: CommunityDto;
    user: PreviewUserDto;
    role: CommunityUserDto.role;
    favoriteResortsIds: Array<string>;
}

export namespace CommunityUserDto {

    export enum role {
        OWNER = 'Owner',
        MEMBER = 'Member',
    }


}
