import { IonButton } from '@ionic/react';
import moment from 'moment';
import { useHistory } from 'react-router';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { fetchAndGoToCarpoolLobby } from '../../dataflow/orchestrators/CarpoolStoreOrchestrator';
import { fetchAndGoToTrip } from '../../dataflow/orchestrators/TripStoreOrchestrators';
import { logger } from '../../logging/winston';
import { ActivityDto, PreviewUserDto } from '../../services/openapi';
import { calendarDateFormat } from '../../utils/carpool';
import { getTripRelativeTimeWithDateString } from '../../utils/getTripRelativeTime';
import { getProfilePagePath } from '../routes';
import './ActivityCard.scss';

interface ActivityCardProps {
  activity: ActivityDto;
}

interface ActivityCardRenderInfo {
  picture: JSX.Element;
  bodySpan: JSX.Element;
  message?:  JSX.Element | undefined;
  buttons?: JSX.Element[];
}

function tryParseJson(json: string): { [key: string]: string } {
  try {
    return JSON.parse(json);
  } catch (e) {
    logger.error(`ActivityCard: Failed to parse json: ${json}`);
    return {};
  }
}

function getUserInfo(args: {[key: string]: string}, key: string): PreviewUserDto {
  return {
    firstName: args[`${key}.firstName`],
    lastName: args[`${key}.lastName`],
    id: args[`${key}.id`],
    profilePictureUrl: args[`${key}.profilePictureUrl`],
    username: args[`${key}.username`],
    upcomingTrips: [],
    completedSignup: true,
  };
}

interface TripInfo {
  tripId: string,
  resort: string;
  date: Date;
  tripDisplay: JSX.Element;
}

function getTripInfo(args: {[key: string]: string}): TripInfo {
  if (!args['trip.id'] || !args['trip.date'] || !args['trip.resort']) {
    return {
      tripId: 'INVALID_TRIP',
      resort: 'INVALID_TRIP',
      date: new Date(),
      tripDisplay: <span>INVALID TRIP</span>
    };
  }
  const tripDate = moment(args['trip.date'].split('T')[0]);
  return {
    tripId: args['trip.id'],
    resort: args['trip.resort'],
    date: tripDate.toDate(),
    tripDisplay: (
      <span>
        <b>{args['trip.resort']}</b> on <b>{getTripRelativeTimeWithDateString(args['trip.date'].split('T')[0])}</b>
      </span>
    ),
  };
}

function getTripActivityRenderInfo(activityId: string, args: {[key: string]: string}, status: string): ActivityCardRenderInfo {
  const participant = getUserInfo(args, 'participant');
  const tripInfo = getTripInfo(args);
  return {
    picture: <ProfilePicture
      className='activityCardProfilePicture'
      key={activityId}
      user={participant}
      overrideDefaultClick={true}
    />,
    bodySpan: (
      <span onClick={() => fetchAndGoToTrip(tripInfo.tripId)}>
        <b>{`${participant.firstName} ${participant.lastName}`}</b> is {status} {tripInfo.tripDisplay}!
      </span>
    ),
  };
}

function getInviteeTripActivityRenderInfo(activityId: string, args: {[key: string]: string}, responseMessage: string): ActivityCardRenderInfo {
  const participant = getUserInfo(args, 'participant');
  const tripInfo = getTripInfo(args);
  return {
    picture: <ProfilePicture
      className='activityCardProfilePicture'
      key={activityId}
      user={participant}
      overrideDefaultClick={true}
    />,
    bodySpan: (
      <span onClick={() => fetchAndGoToTrip(tripInfo.tripId)}>
        <b>{`${participant.firstName} ${participant.lastName}`}</b> responded to your invite to {tripInfo.tripDisplay}.
      </span>
    ),
    message: <div>{responseMessage}</div>,
  };
}

function getTripGroupActivityRenderInfo(activityId: string, activityTitle: string, args: {[key: string]: string}): ActivityCardRenderInfo {
  const participant = getUserInfo(args, 'organizer');
  const tripInfo = getTripInfo(args);
  return {
    picture: <ProfilePicture
      className='activityCardProfilePicture'
      key={activityId}
      user={participant}
      overrideDefaultClick={true}
    />,
    bodySpan: (
      <span onClick={() => fetchAndGoToTrip(tripInfo.tripId)}>
        <b>{`${participant.firstName} ${participant.lastName}`}</b> created a new group in your trip!
      </span>
    ),
    message: <div>{`${activityTitle}`}</div>,
  };
}

function getCarpoolUpdateRenderInfo(activityId: string, activityTitle: string, args: {[key: string]: string}): ActivityCardRenderInfo {
  const participant = getUserInfo(args, 'initiator');
  const tripInfo = getTripInfo(args);
  return {
    picture: <ProfilePicture
      className='activityCardProfilePicture'
      key={activityId}
      user={participant}
      overrideDefaultClick={true}
    />,
    bodySpan: (
      <span onClick={() => fetchAndGoToCarpoolLobby(tripInfo.tripId)}>
        {activityTitle}
      </span>
    ),
    message: <div><b>{participant.firstName}</b> is looking for a carpool for <b>{moment.utc(tripInfo.date).calendar(calendarDateFormat)}</b> at <b>{tripInfo.resort}</b>.</div>,
  };
}

function getRenderInfo(activity: ActivityDto, history: any): ActivityCardRenderInfo {
  const args = tryParseJson(activity.arguments);
  switch (activity.type) {
    case ActivityDto.type.FRIEND_REQUEST_NEW:
      const requester = getUserInfo(args, 'requester');
      return {
        picture: <ProfilePicture
          className='activityCardProfilePicture'
          key={activity.id}
          user={requester}
          overrideDefaultClick={true}
        />,
        bodySpan: (
          <span onClick={() => history.push(getProfilePagePath(requester.username))}>
            <b>{`${requester.firstName} ${requester.lastName}`}</b> send you a friend request.
          </span>
        ),
        message: <div>{`'${args['note']}'`}</div>,
        buttons: [
          <IonButton color='secondary' key='view' onClick={() => history.push(getProfilePagePath(requester.username))}>View</IonButton>
        ],
      };
    case ActivityDto.type.FRIEND_REQUEST_ACCEPTED:
      const recipient = getUserInfo(args, 'recipient');
      return {
        picture: <ProfilePicture
          className='activityCardProfilePicture'
          key={activity.id}
          user={recipient}
          overrideDefaultClick={true}
        />,
        bodySpan: (
          <span onClick={() => history.push(getProfilePagePath(recipient.username))}>
            You are now friends with <b>{`${recipient.firstName} ${recipient.lastName}`}</b>!
          </span>
        ),
      };
    case ActivityDto.type.TRIP_FRIEND_GOING:
      return getTripActivityRenderInfo(activity.id, args, 'going to');
    case ActivityDto.type.TRIP_FRIEND_INTERESTED:
      return getTripActivityRenderInfo(activity.id, args, 'interested in');
    case ActivityDto.type.TRIP_INVITEE_GOING:
      return getInviteeTripActivityRenderInfo(activity.id, args, 'I\'m going!');
    case ActivityDto.type.TRIP_INVITEE_INTERESTED:
      return getInviteeTripActivityRenderInfo(activity.id, args, 'I\'m interested!');
    case ActivityDto.type.TRIP_INVITE_NEW:
      const inviter = getUserInfo(args, 'inviter');
      const tripInfo = getTripInfo(args);
      return {
        picture: <ProfilePicture
          className='activityCardProfilePicture'
          key={activity.id}
          user={inviter}
          overrideDefaultClick={true}
        />,
        bodySpan: (
          <span onClick={() => fetchAndGoToTrip(tripInfo.tripId)}>
            <b>{`${inviter.firstName} ${inviter.lastName}`}</b> invited you to {tripInfo.tripDisplay}.
          </span>
        ),
        buttons: [
          <IonButton color='secondary' key='view' onClick={() => fetchAndGoToTrip(tripInfo.tripId)}>View</IonButton>
        ],
      };
    case ActivityDto.type.TRIP_GROUP_NEW:
      return getTripGroupActivityRenderInfo(activity.id, activity.title, args);
    case ActivityDto.type.TRIP_CARPOOL_UPDATED:
      return getCarpoolUpdateRenderInfo(activity.id, activity.title, args);
    default:
      return {
        picture: <div></div>,
        bodySpan: <b>ERROR: UNKNOWN ACTIVITY TYPE</b>,
      };
  }
}

function getTimestamp(activity: ActivityDto): string {
  const minutesAgo = moment().diff(moment(activity.timestamp), 'minutes');
  if (minutesAgo < 1) {
    return 'Just now';
  } else if (minutesAgo < 60) { // up to 60 minutes
    return `${minutesAgo}m`;
  } else if (minutesAgo < 1440) { // up to 24 hours
    return `${Math.floor(minutesAgo / 60)}h`;
  } else if (minutesAgo < 10080) { // up to 7 days
    return `${Math.floor(minutesAgo / 1440)}d`;
  } else if (minutesAgo < 525600) { // up to 52 weeks
    return `${Math.floor(minutesAgo / 10080)}w`;
  } else {
    return moment(activity.timestamp).format('MMM Do YYYY');
  }
}

export const ActivityCard: React.FC<ActivityCardProps> = (props: ActivityCardProps) => {
  const history = useHistory();
  const { activity }= props;

  const renderInfo = getRenderInfo(activity, history);

  return (
    <div className='activityCard'>
      <div className='activityCardMain'>
        <div className='activityCardPic'>{renderInfo.picture}</div>
        <div className='activityCardBody'>
          {renderInfo.bodySpan}
          <span className='activityCardBodyTimestamp'>{getTimestamp(activity)}</span>
        </div>
        {renderInfo.buttons && <div className='activityCardButtons'>{renderInfo.buttons}</div>}
      </div>
      {renderInfo.message && <div className='activityCardMessage'>{renderInfo.message}</div>}
    </div>
  );
};
