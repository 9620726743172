import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { GoBack } from "../../components/navigation-header/GoBack";
import { myProfilePath } from "../routes";
import qrCode from "./assets/support.png";
import "./Support.scss";

export const Support: React.FC = () => {
  return (
    <IonPage className="support-container">
      <GoBack title="Contact Us" backUrl={myProfilePath}></GoBack>
      <IonContent className="support-content">
        <img src={qrCode} alt="fusend.feedback@gmail.com" />
      </IonContent>
    </IonPage>
  );
};