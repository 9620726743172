import { mutatorAction } from 'satcheljs';
import { logger } from '../../logging/winston';
import { Chat, ChatKind, getChat, getChatStore, Message } from '../stores/ChatStore';

function addNewChatOrUpdateMessage(chats: Chat[], newChat: Chat, messages: Message[]) {
  const existingChat = chats.find((c) => c.id == newChat.id);
  if (existingChat != undefined) {
    logger.info('Found existing chat, update its message and properties', { chatId: newChat.id, chatKind: newChat.kind });
    existingChat.messages = messages;
  } else {
    logger.info('Add new chat', { chatId: newChat.id, chatKind: newChat.kind });
    newChat.messages = messages;
    chats.push(newChat);
  }
}

export const newChatOrUpdateMessages = mutatorAction('NEW_CHAT_OR_UPDATE_MESSAGES', (chat: Chat, message: Message[]) => {
  const { rideChats, peopleChats, tripChats, tripGroupChats } = getChatStore();
  switch (chat.kind) {
    case ChatKind.RIDE:
      addNewChatOrUpdateMessage(rideChats, chat, message);
      break;
    case ChatKind.PEOPLE:
      addNewChatOrUpdateMessage(peopleChats, chat, message);
      break;
    case ChatKind.TRIP:
      addNewChatOrUpdateMessage(tripChats, chat, message);
      break;
    case ChatKind.TRIP_GROUP:
      addNewChatOrUpdateMessage(tripGroupChats, chat, message);
      break;
  }
});

export const removeChat = mutatorAction('REMOVE_CHAT', (chatId: string) => {
  const { rideChats, peopleChats, tripChats, tripGroupChats } = getChatStore();
  let chatIndex = rideChats.findIndex((c) => c.id == chatId);
  if (chatIndex != -1) {
    rideChats.splice(chatIndex, 1);
  } else {
    chatIndex = peopleChats.findIndex((c) => c.id == chatId);
    if (chatIndex != -1) {
      peopleChats.splice(chatIndex, 1);
    } else {
      chatIndex = tripChats.findIndex((c) => c.id == chatId);
      if (chatIndex != -1) {
        tripChats.splice(chatIndex, 1);
      } else {
        chatIndex = tripGroupChats.findIndex((c) => c.id == chatId);
        if (chatIndex != -1) {
          tripGroupChats.splice(chatIndex, 1);
        } else {
          logger.warn(`Cannot remove chat ${chatId}, as it does not exist`, { chatId: chatId });
        }
      }
    }
  }
});

export const updateLastReadMessage = mutatorAction('UPDATE_LOCAL_LAST_READ_MESSAGE', (chatId: string, messageId: string) => {
  logger.debug('Update local last read message', { chatId, messageId });
  const chat = getChat(chatId);
  if (chat == undefined) {
    logger.warn(`Cannot update last read message for chat, as it does not exist`, { chatId: chatId });
  } else {
    chat.lastReadMessageId = messageId;
  }
});

export const setTripChatsLoaded = mutatorAction('SET_TRIP_CHATS_LOADED', (loaded: boolean) => {
  getChatStore().tripChatsLoaded = loaded;
});

export const setPeopleChatsLoaded = mutatorAction('SET_PEOPLE_CHATS_LOADED', (loaded: boolean) => {
  getChatStore().peopleChatsLoaded = loaded;
});

export const setTripGroupChatsLoaded = mutatorAction('SET_TRIP_GROUP_CHATS_LOADED', (loaded: boolean) => {
  getChatStore().tripGroupChatsLoaded = loaded;
});