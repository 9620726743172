import { logger } from '../logging/winston';

export function getValueFromNotificationArgs(argsJson: string, key: string): string | undefined {
  try {
    const args = JSON.parse(argsJson);
    return args[key];
  } catch (e) {
    logger.error(`FriendNotification: Failed to parse json: ${argsJson}`);
    return undefined;
  }
}
