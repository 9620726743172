import { App, URLOpenListenerEvent } from '@capacitor/app';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { logger } from './logging/winston';

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      logger.info('AppUrlListener: appUrlOpen event received', { url: event.url });
      const slug = event.url.split('.app').pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  logger.info('AppUrlListener: added');
  return (<></>);
};

export default AppUrlListener;