import { mutatorAction } from "satcheljs";
import { TripCarpoolDto } from "../../services/openapi";
import { getCarpoolStore } from "../stores/CarpoolStore";

export const setCarpoolsForTrip = mutatorAction('SET_CARPOOLS_FOR_TRIP', (tripId: string, intents: TripCarpoolDto[]) => {
  getCarpoolStore().tripToCarpools.set(tripId, intents);
});

export const upsertCarpoolForTrip = mutatorAction('ADD_CARPOOL_FOR_TRIP', (tripId: string, intent: TripCarpoolDto) => {
  const carpoolStore = getCarpoolStore();
  const carpoolList = carpoolStore.tripToCarpools.get(tripId) || [];
  const index = carpoolList.findIndex(carpool => carpool.id === intent.id);
  if (index >= 0) {
    carpoolList[index] = intent;
  } else {
    carpoolList.push(intent);
  }
  carpoolStore.tripToCarpools.set(tripId, carpoolList);
});