import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';
import { logger } from '../../logging/winston';
import { PreviewJoinRequestDto, UpdateJoinRequestDto } from '../../services/openapi';
import { getRideRequestStore } from '../stores/RideRequestStore';

function upsertRideRequestToMap(rideRequestMap: ObservableMap<string, PreviewJoinRequestDto[]>, rideRequest: PreviewJoinRequestDto) {
  const rideId = rideRequest.ride.id;
  const requests = rideRequestMap.get(rideId);
  if (requests) {
    const requestIndex = requests.findIndex((r) => r.id == rideRequest.id);
    if (requestIndex != -1) {
      logger.info(`Updating existing ride request ${rideRequest.id}`, { rideRequestId: rideRequest.id, rideId });
      requests.splice(requestIndex, 1, rideRequest);
    } else {
      logger.info(`Adding new ride request ${rideRequest.id}`, { rideRequestId: rideRequest.id, rideId });
      requests.push(rideRequest);
    }
  } else {
    logger.info(`Adding new ride request ${rideRequest.id}`, { rideRequestId: rideRequest.id, rideId });
    rideRequestMap.set(rideId, [ rideRequest ]);
  }
}

export const setRideRequests = mutatorAction('SET_RIDE_REQUESTS', (rideRequests: PreviewJoinRequestDto[]) => {
  const rideRequestMap = new ObservableMap<string, PreviewJoinRequestDto[]>();
  rideRequests.forEach((req) => {
    upsertRideRequestToMap(rideRequestMap, req);
  });
  getRideRequestStore().rideRequestMap = rideRequestMap;
});

export const upsertRideRequests = mutatorAction('UPSERT_RIDE_REQUESTS', (rideRequests: PreviewJoinRequestDto[]) => {
  const { rideRequestMap } = getRideRequestStore();
  rideRequests.forEach((req) => {
    upsertRideRequestToMap(rideRequestMap, req);
  });
});

export const changeRideRequestState = mutatorAction('CHANGE_RIDE_REQUEST_STATE', (rideRequestId: string, state: UpdateJoinRequestDto.state) => {
  const { rideRequestMap } = getRideRequestStore();
  let changedUnderRideId: string | undefined;

  rideRequestMap.forEach((requests, rideId, _map) => {
    if (changedUnderRideId) {
      return;
    }
    const request = requests.find((r) => r.id == rideRequestId);
    if (request) {
      request.state = state;
      changedUnderRideId = rideId;
    }
  });

  if (!changedUnderRideId) {
    logger.warn(`Cannot change ride request state, request ${rideRequestId} does not exist`, { rideRequestId });
  }
});

export const removeRideRequest = mutatorAction('REMOVE_RIDE_REQUEST', (rideRequestId: string) => {
  const { rideRequestMap } = getRideRequestStore();
  let removedFromRideId: string | undefined;

  rideRequestMap.forEach((requests, rideId, _map) => {
    if (removedFromRideId) {
      return;
    }
    const requestIndex = requests.findIndex((r) => r.id == rideRequestId);
    if (requestIndex != -1) {
      requests.splice(requestIndex, 1);
      removedFromRideId = rideId;
    }
  });

  if (!removedFromRideId) {
    logger.warn(`Cannot remove ride request, request ${rideRequestId} does not exist`, { rideRequestId });
  }
});

export const setRideRequestsLoading = mutatorAction('SET_RIDE_REQUESTS_LOADING', () => {
  getRideRequestStore().loadingRideRequests = true;
});

export const unsetRideRequestsLoading = mutatorAction('UNSET_RIDE_REQUESTS_LOADING', () => {
  getRideRequestStore().loadingRideRequests = false;
});
