import { Clipboard } from '@capacitor/clipboard';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { copyOutline } from 'ionicons/icons';
import { createToast } from '../../utils/toast';
import "../Shared/Value.scss";

interface CopiableValueProps {
  title: string;
  value: string;
}

export const CopiableValue: React.FC<CopiableValueProps> = ({ title, value }: CopiableValueProps) => {
  const [ present, _dismiss ] = createToast(`${title} copied to clipboard`, 3000);
  const copy = async () => {
    await Clipboard.write({ string: value });
    present();
  };

  return (
    <IonGrid className="value-container">
      <IonRow>
        <IonCol className="value-title">{title}</IonCol>
      </IonRow>
      <IonRow className="value-action-row">
        <IonCol className="value-value">{value}</IonCol>
        <IonCol className="value-copy" onClick={copy}>
          <IonIcon icon={copyOutline} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};