import { IonContent, IonLoading, IonPage } from "@ionic/react";
import assert from "assert";
import _ from "lodash";
import { observer } from "mobx-react-latest";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { fetchAllCommunities, joinCommunity } from "../dataflow/orchestrators/CommunityStoreOrchestrators";
import { updateUser, userLogout } from "../dataflow/orchestrators/UserStoreOrchestrators";
import { getCommunityStore } from "../dataflow/stores/CommunityStore";
import { getUserStore, hasSignedUp } from "../dataflow/stores/UserStore";
import { logger } from "../logging/winston";
import { ResortDto } from "../services/openapi";
import initializeResortList from "../utils/initializeResortList";
import { CreateEditableValues, SubmitButton, ValueType, ViewableItem } from "./Shared/EditableValue";
import { SelectOption } from "./Shared/MultiSelectExpanded";
import "./Signup.scss";
import { decode, homeRedirectPath, signUpPathLiteral } from "./routes";

interface SignupRouteParams {
  redirectPath: string;
}

const validUsernameRegex = new RegExp(/^[a-z0-9_]{3,18}$/);

export const Signup: React.FC = observer(() => {
  const history = useHistory();
  const redirectPath = useParams<SignupRouteParams>().redirectPath;
  const { currentUser, loading: loadingUser } = getUserStore();
  const { allCommunities, loadingAllCommunities } = getCommunityStore();
  const [ firstNameError, setFirstNameError ] = useState(false);
  const [ lastNameError, setLastNameError ] = useState(false);
  const [ usernameError, setUsernameError ] = useState(false);
  const [ resortsState, setResortsState ] = useState<Map<string, boolean>>();
  const [ resortsSelectOptions, setResortsSelectOptions ] = useState<SelectOption[]>([]);
  const [ resortsDtoMap, setResortsDtoMap ] = useState<Map<string, ResortDto>>();
  const [ resortsError, setResortsError ] = useState(true);
  const [ communitiesState, setCommunitiesState ] = useState<Map<string, boolean>>(new Map<string, boolean>());
  const [ communitiesError, setCommunitiesError ] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      logger.info('User not logged in, redirecting to login page.');
      userLogout();
      return;
    }
    if (hasSignedUp()) {
      logger.info('User has completed sign up.');
      let decoded = '';
      try {
        decoded = decode(redirectPath);
      } catch (exception) {
        logger.error(`Failed decode redirectPath, default to ${decoded}.`, { exception, redirectPath });
      }
      const uri = !!decoded && !decoded.startsWith(signUpPathLiteral) ? decoded : homeRedirectPath;
      logger.info(`Signup succeeded, redirecting to: ${uri}`);
      history.replace(uri);
    }
    initializeResortList().then(resorts => {
      setResortsState(new Map(resorts.map(resort => [ resort.location.name || 'Resort Name', false ])));
      setResortsDtoMap(new Map(resorts.map(resort => [ resort.location.name || 'Resort Name', resort ])));
      setResortsSelectOptions(resorts.map((resort, index) => ({ index, value: resort.location.name || 'Resort Name', label: `${resort.location.name}, ${resort.state}` })));
    });
  }, [ currentUser ]);

  if (!currentUser) {
    logger.info('No current user, redirecting to login.');
    return <IonPage />;
  }

  const initialUser = _.cloneDeep(currentUser);
  if (!initialUser.completedSignup) {
    initialUser.username = '';
  }
  const [ localUser, setLocalUser ] = useState(initialUser);

  useEffect(() => {
    fetchAllCommunities();
    setUsernameError(true);
  }, []);

  if (loadingAllCommunities) {
    return <IonPage><IonLoading isOpen={true} message={'Loading...'} /></IonPage>;
  }

  const userHasCompletedSignup = currentUser.completedSignup;

  const items: Array<ViewableItem> = [];
  if (!userHasCompletedSignup) {
    items.push(...[
      {
        title: "First Name",
        value: localUser.firstName,
        editableDetail: {
          type: ValueType.String,
          stringOptions: {
            onChange: (value: string) => {
              setLocalUser({ ...localUser, firstName: value });
              setFirstNameError(value.length == 0);
            }
          },
          validationOption: {
            isInvalid: firstNameError,
            message: 'Please enter your first name.',
          }
        }
      },
      {
        title: "Last Name",
        value: localUser.lastName,
        editableDetail: {
          type: ValueType.String,
          stringOptions: {
            onChange: (value: string) => {
              setLocalUser({ ...localUser, lastName: value });
              setLastNameError(value.length == 0);
            }
          },
          validationOption: {
            isInvalid: lastNameError,
            message: '* Please enter your last name.',
          }
        }
      },
      {
        title: "Username",
        value: localUser.username,
        editableDetail: {
          type: ValueType.String,
          stringOptions: {
            onChange: (value: string) => {
              setLocalUser({ ...localUser, username: value });
              setUsernameError(!validUsernameRegex.test(value));
            }
          },
          validationOption: {
            isInvalid: usernameError,
            message: '* Username must be between 3 and 18 characters, and can only contain lowercase letters, numbers, and underscores.',
          },
          importantNote: 'You only get to do this once, choose wisely!'
        }
      }
    ]);
  }

  if (allCommunities.length > 0) {
    allCommunities.forEach(community => {
      if (!communitiesState.has(community.id)) {
        communitiesState.set(community.id, false);
      }
    });
    items.push({
      title: 'Join a Community',
      value: communitiesState,
      editableDetail: {
        selectOptions: {
          onChange(value) {
            communitiesState.forEach((_, key) => communitiesState.set(key, false));
            communitiesState.set(value.value, !communitiesState.get(value.value));
            setCommunitiesState(new Map(communitiesState));
            setCommunitiesError(Array.from(communitiesState.values()).filter(value => value).length == 0);
          },
          options: allCommunities.map((community, index) => ({ index, value: community.id, label: community.name }))
        },
        type: ValueType.MultiSelectExpanded,
        validationOption: {
          isInvalid: communitiesError,
          message: '* Please choose one community to join.',
        }
      }
    });
  }

  if (resortsState) {
    items.push({
      title: 'Favorite Destination(s)',
      value: resortsState,
      editableDetail: {
        selectOptions: {
          onChange(value) {
            resortsState.set(value.value, !resortsState.get(value.value));
            setResortsState(new Map(resortsState));
            setResortsError(Array.from(resortsState.values()).filter(value => value).length == 0);
          },
          options: resortsSelectOptions
        },
        type: ValueType.MultiSelectExpanded,
        validationOption: {
          isInvalid: resortsError,
          message: '* Please select at least one favorite destination.',
        }
      }
    });
  }

  const editableItems = CreateEditableValues(items, () => { });
  const disabled = firstNameError || lastNameError || (!userHasCompletedSignup && usernameError) || resortsError || communitiesError || loadingUser;
  const submit = () => {
    logger.info('Submitting sign-up.', { resorts: resortsState });

    const favoriteResortsIds = Array.from(resortsState.entries()).filter(([ _, value ]) => value).map(([ key, _ ]) => resortsDtoMap?.get(key)?.id || '0');

    const communityId = Array.from(communitiesState.entries()).filter(([ _, value ]) => value).map(([ key, _ ]) => key)[0];
    assert(communityId, 'communityId should be determined.');
    joinCommunity(communityId, favoriteResortsIds);

    localUser.completedSignup = true;
    assert(resortsState, 'Resorts should be initialized.');

    localUser.preference = {
      favoriteResortsIds: favoriteResortsIds,
      currentCommunityId: communityId,
    };
    logger.info('User Preference', { preference: localUser.preference });
    updateUser(localUser);
  };

  let title = 'Complete Sign Up';
  let subtitle = 'Please fill in the info to help friends find you';

  if (userHasCompletedSignup && !currentUser.preference.currentCommunityId) {
    // show different title and subtitle the only thing left is to join a community
    title = 'Join a Community';
    subtitle = 'Fusend now offers communities! Join one today to connect closely with others. You can always join more later.';
  }

  return (
    <IonPage>
      <IonContent className="signUpPage">
        <div className="editableContent">
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
          <div className="editableDetails">
            {editableItems}
          </div>
        </div>
        <div className="submit">
          {SubmitButton(submit, disabled, 'Continue')}
        </div>
      </IonContent>
    </IonPage>
  );
});
