import React from "react";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { PreviewUserDto } from "../../services/openapi";
import './ProfileHeader.scss';
import ProfileHeaderContent from "./ProfileHeaderContent/ProfileHeaderContent";

interface ProfileHeaderProps {
  user: PreviewUserDto;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = (props: ProfileHeaderProps) => {
  const { user } = props;
  const { currentUser } = getUserStore();

  if (!currentUser) {
    return <></>;
  }
  return (
    <>
      <ProfilePicture user={user} overrideDefaultClick={true}/>
      <h4 className="profileHeaderName">{`${user.firstName} ${user.lastName}`}</h4>
      <p className="profileHeaderUserName">{`@${user.username}`}</p>
      <ProfileHeaderContent currentUser={currentUser} targetUser={user} />
    </>
  );
};

export default ProfileHeader;