import firebase from 'firebase';
import { action, orchestrator } from 'satcheljs';
import { logger } from '../../logging/winston';
import { onNotificationClickedRouter } from '../../notifications/routers';
import { getChatRoomPath } from '../../pages/routes';
import { DocUpdate, Firebase } from '../../services/firebase';
import { ActivityDto, ApiError, CancelablePromise, ChatDto, Service } from '../../services/openapi';
import { browserHistory } from '../../utils/history';
import { newChatOrUpdateMessages, removeChat, setPeopleChatsLoaded, updateLastReadMessage } from '../mutators/ChatStoreMutators';
import { addError, addWarning } from '../mutators/ErrorStoreMutators';
import { newError, newWarning } from '../stores/ErrorStore';
import { getUserStore } from '../stores/UserStore';
import { makeAsyncRequest } from '../utils/utils';
import { Chat, ChatKind, getChatStore, Message, PeopleChat, ReplyTo } from './../stores/ChatStore';

const CREATE_FRIEND_CHAT = 'CREATE_FRIEND_CHAT';
const JOIN_CHAT_MAX_RETRY_ATTEMPTS = 10;
const JOIN_CHAT_RETRY_INTERVAL_MS = 1000;

const chatTypeMap = new Map([
  [ ChatKind.PEOPLE, ChatDto.type.ONE_TO_ONE ],
  [ ChatKind.TRIP, ChatDto.type.TRIP ],
  [ ChatKind.TRIP_GROUP, ChatDto.type.TRIP_GROUP ],
]);

export const joinChat = action('JOIN_CHAT', (chat: Chat, retryAttempt = 0) => ({ chat, retryAttempt }));
export const joinPeopleChat = action('JOIN_PEOPLE_CHAT', (chatId: string) => ({ chatId }));
export const leaveChat = action('LEAVE_CHAT', (chatId: string) => ({ chatId }));
export const sendChatMessage = action(
  'SEND_CHAT_MESSAGE',
  (chatId: string, chatKind: ChatKind | undefined, content: string, replyTo?: ReplyTo, taggedUsers?: string[]) => ({ chatId, chatKind, content, replyTo, taggedUsers })
);
export const updateLastRead = action('UPDATE_LAST_READ', (chatId: string, messageId: string) => ({ chatId, messageId }));
export const fetchMyPeopleChats = action('FETCH_MY_PEOPLE_CHATS');
export const fetchOnePeopleChat = action('FETCH_ONE_PEOPLE_CHAT', (chatId: string, callback?: () => void) => ({ chatId, callback }));
export const createFriendChat = action(CREATE_FRIEND_CHAT, (
  friendId: string,
  onSuccess?: (chatId: string) => void,
  onError?: (error: any) => void) => ({ friendId, onSuccess, onError }));
export const createOneOnOneChat = action(
  'CREATE_ONE_ON_ONE_CHAT',
  (otherUserId: string, message: string, onSuccess: (chatDto: ChatDto) => void, onError: (err: any) => void) => ({ otherUserId, message, onSuccess, onError }));

const chatIdToLeaveFn: Map<string, () => void> = new Map();
const messageCollectionName = (chatId: string) => `chats/${chatId}/messages`;
const userCollectionName = (chatId: string) => `chats/${chatId}/users`;

let fetchMyPeopleChatsPromise: CancelablePromise<ChatDto[]> | undefined = undefined;
const fetchOnePeopleChatPromises: Map<string, CancelablePromise<ChatDto>> = new Map();

const chatLoggingFields = (chat: Chat, extra?: Record<string, unknown>) => {
  let fields = { chatId: chat.id, chatKind: chat.kind };
  if (extra != undefined) {
    fields = Object.assign(fields, extra);
  }
  return fields;
};

function onChatUpdate(chat: Chat, docUpdates: DocUpdate[]) {
  logger.debug('Received update for chat', chatLoggingFields(chat));
  try {
    const messages: Message[] = docUpdates.map((doc) => {
      if (doc.data.replyTo) {
        logger.debug('Received reply to message', {
          replyTo: doc.data.replyTo
        });
      }
      return {
        id: doc.id,
        // Before firestore syncs with the server, the timestamp is null or we can just safely assume that the
        // timestamp is the current time.
        sentTime: doc.data.sentTime?.toDate() || new Date(),
        fromUser: doc.data.fromUser,
        content: doc.data.content,
        replyTo: doc.data.replyTo,
        SYSTEM_MESSAGE: doc.data.SYSTEM_MESSAGE,
      };
    });
    newChatOrUpdateMessages(chat, messages);
  } catch (exception: any) {
    logger.error('Failed to update chat', chatLoggingFields(chat, { exception: exception.toString() }));
    addError(newError('JOIN_CHAT.ChatUpdateError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
}

function onChatUserUpdate(chatId: string, userId: string, docUpdate: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
  try {
    logger.debug('Received update for chat user', { chatId, userId, docData: docUpdate.data() });
    const docData = docUpdate.data();
    if (docData) {
      updateLastReadMessage(chatId, docData['lastReadMessageId']);
    }
  } catch (exception: any) {
    logger.error('Failed to update chat user', { chatId, userId, exception: exception.toString() });
    addError(newError('JOIN_CHAT.ChatUserUpdateError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
}

orchestrator(joinChat, async ({ chat, retryAttempt }) => {
  const retryJoiningChat = (collectionName: string) => {
    if (retryAttempt && retryAttempt < JOIN_CHAT_MAX_RETRY_ATTEMPTS) {
      setTimeout(() => {
        logger.info('Retrying to join chat', { collectionName });
        joinChat(chat, retryAttempt + 1);
      }, JOIN_CHAT_RETRY_INTERVAL_MS);
    }
  };

  if (chatIdToLeaveFn.has(chat.id)) {
    if (retryAttempt && retryAttempt > 0) {
      logger.info('Failed to join chat, will unsub and rejoin', chatLoggingFields(chat));
      chatIdToLeaveFn.get(chat.id)?.();
    } else {
      logger.info('Skip joining. It is already joined.', chatLoggingFields(chat));
      return;
    }
  }

  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to join chat. Current user is missing.', chatLoggingFields(chat));
    addError(newError('JOIN_CHAT.MissingUser'));
    return;
  }

  newChatOrUpdateMessages(chat, []);

  try {
    const unsubMessageFn = Firebase.subscribeToFirestoreCollectionUpdate(
      messageCollectionName(chat.id),
      undefined,
      // TODO-1: limit chat message query size after firestore index is updated
      // TODO-2: add rolling window support for message and remove hardcoded limit
      // (query) => (query.orderBy('sentTime').limitToLast(100)),
      (collectionName, snapshot) => {
        logger.debug('Received chat message update from firestore', { collectionName });
        onChatUpdate(chat, snapshot);
      },
      (collectionName, err) => {
        logger.error('Received chat message error from firestore', { collectionName, exception: err });
        addError(newError('JOIN_CHAT.Message.FirestoreError', JSON.stringify(err)));
        retryJoiningChat(collectionName);
      });
    const unsubUserFn = Firebase.subscribeToFirestoreDocUpdate(
      userCollectionName(chat.id),
      user.id,
      (collectionName, docName, snapshot) => {
        logger.debug('Received chat user update from firestore', { collectionName, docName });
        onChatUserUpdate(chat.id, user.id, snapshot);
      },
      (collectionName, docName, err) => {
        logger.error('Received chat user error from firestore', { collectionName, docName, exception: err });
        addError(newError('JOIN_CHAT.User.FirestoreError', JSON.stringify(err)));
        retryJoiningChat(collectionName);
      }
    );
    chatIdToLeaveFn.set(chat.id, () => {
      unsubMessageFn();
      unsubUserFn();
    });
  } catch (exception: any) {
    logger.error('Failed to join chat.', chatLoggingFields(chat, { exception: exception.toString() }));
    addError(newError('JOIN_CHAT.FirestoreError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
});

orchestrator(leaveChat, ({ chatId }) => {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to join chat. Current user is missing.');
    addError(newError('LEAVE_CHAT.MissingUser'));
    return;
  }

  const leaveFn = chatIdToLeaveFn.get(chatId);
  if (!leaveFn) {
    logger.info(`Skip leaving chat: ${chatId}. It is already left.`, { chatId });
  } else {
    try {
      leaveFn();
      chatIdToLeaveFn.delete(chatId);
      removeChat(chatId);
    } catch (exception: any) {
      logger.error('Failed to leave chat.', { exception: exception.toString() });
      addError(newError('LEAVE_CHAT', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(sendChatMessage, async ({ chatId, chatKind, content, replyTo, taggedUsers }) => {
  const user = getUserStore().currentUser;
  const chatType = chatKind ? chatTypeMap.get(chatKind) : undefined;
  if (!user) {
    logger.error('Failed to send chat message. Current user is missing.');
    addError(newError('SEND_CHAT_MESSAGE.MissingUser'));
  } else {
    try {
      await Firebase.addDocToFirestoreCollection(messageCollectionName(chatId), {
        sentTime: firebase.firestore.FieldValue.serverTimestamp(),
        fromUser: user.id,
        content,
        chatId,
        ...(chatType ? { chatType } : {}),
        ...(replyTo ? { replyTo } : {}),
        ...(taggedUsers ? { taggedUsers }: {})
      });
    } catch (exception: any) {
      logger.error('Failed to send chat message.', { exception: exception.toString() });
      addError(newError('SEND_CHAT_MESSAGE', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(updateLastRead, async ({ chatId, messageId }) => {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to update last read. Current user is missing.');
    addError(newError('UPDATE_LAST_READ.MissingUser'));
  } else {
    try {
      await Firebase.updateDocInFirestoreCollection(userCollectionName(chatId), user.id, {
        lastReadMessageId: messageId,
      });
    } catch (exception: any) {
      logger.error('Failed to update last read.', { exception: exception.toString() });
      addError(newError('UPDATE_LAST_READ', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

const dtoToPeopleChat = (dto: ChatDto): PeopleChat => ({
  kind: ChatKind.PEOPLE,
  id: dto.id,
  messages: [],
  otherParticipants: dto.otherParticipants || [],
});

orchestrator(fetchMyPeopleChats, async () => {
  try {
    setPeopleChatsLoaded(false);

    const { request, response } = await makeAsyncRequest(
      fetchMyPeopleChatsPromise,
      () => Promise.resolve(() => Service.meControllerGetChats(ChatDto.type.ONE_TO_ONE)),
      () => {},
      () => {});
    fetchMyPeopleChatsPromise = request;
    const newChats = await response;

    const { peopleChats: existingChats } = getChatStore();
    existingChats.forEach((existingChat) => {
      if (!newChats.some((c) => c.id == existingChat.id)) {
        leaveChat(existingChat.id);
      }
    });

    newChats.forEach((newChat) => {
      if (!existingChats.some((c) => c.id == newChat.id)) {
        joinChat(dtoToPeopleChat(newChat));
      }
    });
  } catch (exception: any) {
    if (exception) {
      logger.error('Failed to fetch my people chats. Service request error.', { exception: exception.toString() });
      addError(newError('FETCH_MY_PEOPLE_CHATS.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  } finally {
    setPeopleChatsLoaded(true);
  }
});

orchestrator(fetchOnePeopleChat, async ({ chatId, callback }) => {
  try {
    const pendingRequest = fetchOnePeopleChatPromises.get(chatId);
    fetchOnePeopleChatPromises.delete(chatId);
    const { request, response } = await makeAsyncRequest(
      pendingRequest,
      () => Promise.resolve(() => Service.meControllerGetChat(chatId)),
      () => {},
      () => {});
    fetchOnePeopleChatPromises.set(chatId, request);

    const chat = await response;
    joinChat({
      kind: ChatKind.PEOPLE,
      id: chat.id,
      messages: [],
      otherParticipants: chat.otherParticipants || [],
    });
    callback && callback();
  } catch (exception: any) {
    if (exception instanceof ApiError && exception.status == 404) {
      logger.info('Chat is no longer accessible', { chatId, exception: exception.toString() });
      leaveChat(chatId);
      addWarning(newWarning(`Chat is no longer accessible`));
      return undefined;
    } else {
      if (exception) {
        logger.error('Failed to fetch chat. Service request error.', { chatId, exception: exception.toString() });
        addError(newError('FETCH_ONE_PEOPLE_CHAT.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
      }
    }
  }
});

orchestrator(createFriendChat, async ({ friendId, onSuccess, onError }) => {
  try {
    const newChat = await Service.meControllerCreateChatWithFriends({
      initialMessage: '',
      friendIds: [ friendId ]
    });
    joinChat(dtoToPeopleChat(newChat));
    if (onSuccess) onSuccess(newChat.id);
  } catch (exception: any) {
    logger.error(`Failed to create chat with friend ${friendId}. Service request error.`, { friendId, exception: exception.toString() });
    addError(newError(`${CREATE_FRIEND_CHAT}.ServiceError`, `${exception.toString()} ${JSON.stringify(exception)}`));
    if (onError) onError(exception);
  }
});

orchestrator(createOneOnOneChat, async ({ otherUserId, message, onSuccess, onError }) => {
  try {
    const newChat = await Service.meControllerCreateChatWithOtherUser(otherUserId, {
      initialMessage: message,
    });
    joinChat(dtoToPeopleChat(newChat));
    onSuccess && onSuccess(newChat);
  } catch (error) {
    logger.error('Failed to create one on one chat. Service request error.', { otherUserId, exception: error });
    onError && onError(error);
  }
});

onNotificationClickedRouter.addRoute(ActivityDto.type.CHAT_RIDE_MESSAGE, chatId => {
  logger.debug('Handling clicked new message notification', { chatId });
  browserHistory.push({
    pathname: getChatRoomPath(chatId),
  });
});

onNotificationClickedRouter.addRoute(ActivityDto.type.CHAT_PEOPLE_MESSAGE, chatId => {
  logger.debug('Handling clicked new message (from other user) notification', { chatId });
  fetchOnePeopleChat(chatId, () => {
    browserHistory.push({
      pathname: getChatRoomPath(chatId),
    });
  });
});

onNotificationClickedRouter.addRoute(ActivityDto.type.CHAT_GROUP_MESSAGE, chatPayload => {
  logger.debug('Handling clicked new chat group message notification', { chatPayload });
  // TODO: We need to populate chatType so we can correctly fetch the chat before we attempt to navigate. Right now
  // there is a chance that when we navigate to the chat room, we haven't actually fetched the underlying entity
  // corresponding to the chat yet.
  const chatInfo = JSON.parse(chatPayload);
  browserHistory.push({
    pathname: getChatRoomPath(chatInfo.chatId),
  });
});
