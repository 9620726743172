import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonSearchbar, IonTitle, IonToolbar, useIonModal } from "@ionic/react";
import { chevronBack, qrCode } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import React from "react";
import { useHistory } from "react-router";
import QRCodeModal from "../../components/QRCodeModal/QRCodeModal";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { logger } from "../../logging/winston";
import { PreviewUserDto, Service } from "../../services/openapi";
import { discoverPeoplePath, loginPath, homeRedirectPath } from "../routes";
import './DiscoverPeople.scss';
import DiscoverPeopleInitial from "./DiscoverPeopleInitial";
import DiscoverPeopleSearchResult from "./DiscoverPeopleSearchResult";

const DiscoverPeople = observer(() => {
  const [ searchText, setSearchText ] = React.useState('');
  const [ searchResult, setSearchResult ] = React.useState<PreviewUserDto[]>([]);
  const [ isSearching, setIsSearching ] = React.useState<boolean>(false);
  const { currentUser } = getUserStore();
  const history = useHistory();

  if (!currentUser) {
    history.push(loginPath(discoverPeoplePath, window.location.search));
    return <div />;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const onDismiss = () => dismiss();
  const [ present, dismiss ] = useIonModal(QRCodeModal, {
    onDismiss,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    profileUrl: currentUser.profilePictureUrl,
    username: currentUser.username,
    userId: currentUser.id
  });


  const onSearchTextChange = React.useCallback((e: any) => {
    setSearchText(e.detail.value!);
    Service.usersControllerFind(e.detail.value!).then((result) => {
      const filteredResult = result.filter((r) => r.id != currentUser?.id && r.completedSignup);
      setSearchResult(filteredResult);
      setIsSearching(false);
    }).catch((err) => {
      logger.error(err);
      setIsSearching(false);
    });
    setIsSearching(true);
  }, []);

  const onOpenQRModal = () => {
    present();
  };

  return (
    <IonPage className='fusendPage discoverPeoplePageContainer'>
      <IonHeader className='fusendPageHeader discoverPeopleHeaderContainer'>
        <IonToolbar className='fusendPageToolbar discoverPeopleToolBar'>
          <IonButtons slot="start">
            <IonBackButton className="discoverPeoplePageBackBtn" text="" icon={chevronBack} defaultHref={homeRedirectPath}/>
          </IonButtons>
          <IonTitle className='fusendPageTitle discoverPeopleToobarTitle'>Discover People</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} className='fusendPageContent discoverPeopleContent'>
        <div className="discoverPeopleSearchSection">
          <IonSearchbar
            className="discoverPeopleSearchBar"
            placeholder="Search username"
            debounce={1000}
            onIonChange={onSearchTextChange}
            type="text"
          ></IonSearchbar>
          <p className="discoverPeopleSearchIdText" onClick={onOpenQRModal}>
            My Fusend ID: { currentUser?.username || "" }
            <IonIcon className="discoverPeopleQRIcon" icon={qrCode}/>
          </p>
        </div>
        { searchText
          ? <DiscoverPeopleSearchResult searchResultList={searchResult} isSearching={isSearching}/>
          : <DiscoverPeopleInitial />
        }
      </IonContent>
    </IonPage>
  );
});

export default DiscoverPeople;