import { createStore } from 'satcheljs';
import { ResortDto } from '../../services/openapi/models/ResortDto';

export interface ResortStore {
  resortList: ResortDto[] | null;
}

const initialResortStore = {
  resortList: null
};

export const getResortStore = createStore<ResortStore>('ResortStore', initialResortStore);
