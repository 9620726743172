import { action, orchestrator } from 'satcheljs';
import { logger } from '../../logging/winston';
import { getValueFromNotificationArgs } from '../../notifications/NotificationUtils';
import { onNotificationClickedRouter } from '../../notifications/routers';
import { getProfilePagePath } from '../../pages/routes';
import { ActivityDto, CancelablePromise, FriendDto, Service } from '../../services/openapi';
import { browserHistory } from '../../utils/history';
import { addError } from '../mutators/ErrorStoreMutators';
import { setFriends, setFriendsLoading, unsetFriendsLoading, upsertFriend } from '../mutators/FriendStoreMutators';
import { newError } from '../stores/ErrorStore';
import { getFriendStore } from '../stores/FriendStore';
import { getUserStore } from '../stores/UserStore';
import { makeAsyncRequest } from '../utils/utils';

const FETCH_FRIENDS = 'FETCH_FRIENDS';
const TOGGLE_FOLLOW_FRIEND = 'TOGGLE_FOLLOW_FRIEND';

export const fetchFriends = action(FETCH_FRIENDS);
export const toggleFollowFriend = action(TOGGLE_FOLLOW_FRIEND, (friendId: string, callback?: (friend: FriendDto) => void | undefined) => ({ friendId, callback }));

let loadFriendsPromise: CancelablePromise<FriendDto[]> | undefined = undefined;
let followFriendPromise: CancelablePromise<FriendDto> | undefined = undefined;

orchestrator(fetchFriends, async() => {
  const take = 50;
  let skip = 0;
  let friendBatch: FriendDto[] = [];
  const allFriends: FriendDto[] = [];
  do {
    try {
      const { request, response } = await makeAsyncRequest(
        loadFriendsPromise,
        () => Promise.resolve(() => Service.meControllerGetFriends(take.toString(), skip.toString())),
        () => setFriendsLoading(),
        () => unsetFriendsLoading());
      loadFriendsPromise = request;
      friendBatch = await response;
      allFriends.push(...friendBatch);
      skip = skip + friendBatch.length;
    } catch (exception: any) {
      if (exception) {
        logger.error(`Failed to fetch activities. Service request error.`, { exception, skip, take });
        addError(newError(`${FETCH_FRIENDS}.ServiceError`, `${exception.toString()} ${JSON.stringify(exception)}`));
      }
    }
  } while (friendBatch.length >= take);

  setFriends(allFriends);
});

orchestrator(toggleFollowFriend, async({ friendId, callback }) => {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to toggle follow friend. Current user is missing.');
    addError(newError(`'${TOGGLE_FOLLOW_FRIEND}.MissingUser'`));
    return;
  }
  const friend = getFriendStore().friends.find(f => f.id === friendId);
  if (!friend) {
    logger.error(`Failed to toggle follow friend. Friend id does not exist.`, { friendId });
    addError(newError(`'${TOGGLE_FOLLOW_FRIEND}.MissingFriend'`));
    return;
  }
  try {
    const { request, response } = await makeAsyncRequest(
      followFriendPromise,
      () => Promise.resolve(() => Service.friendsControllerUpdate(user.id, friend.id, { note: friend.note, nickname: friend.nickname, follow: !friend.follow })),
      () => {},
      () => {});
    followFriendPromise = request;
    const updated = await response;
    upsertFriend(updated);
    if (callback) {
      callback(updated);
    }
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to follow friend. Service request error.`, { exception, id: friend.id });
      addError(newError(`${TOGGLE_FOLLOW_FRIEND}.ServiceError`, `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

onNotificationClickedRouter.addRoute(ActivityDto.type.FRIEND_REQUEST_NEW, args => {
  const username = getValueFromNotificationArgs(args, 'requester.username');
  if (!username) {
    logger.error(`Cannot resolve username from ${ActivityDto.type.FRIEND_REQUEST_NEW}`, { args });
  } else {
    browserHistory.push({
      pathname: getProfilePagePath(username),
    });
  }
});

onNotificationClickedRouter.addRoute(ActivityDto.type.FRIEND_REQUEST_ACCEPTED, args => {
  const username = getValueFromNotificationArgs(args, 'recipient.username');
  if (!username) {
    logger.error(`Cannot resolve username from ${ActivityDto.type.FRIEND_REQUEST_ACCEPTED}`, { args });
  } else {
    browserHistory.push({
      pathname: getProfilePagePath(username),
    });
  }
});
