import firebase from 'firebase';
import winston from 'winston';
import { setRemoteConfig } from '../dataflow/stores/RemoteConfigStore';
import { logger } from '../logging/winston';
import { Service } from './openapi';

let options: any;

function getOptions(logger: winston.Logger | undefined): any {
  if (options == undefined) {
    const devOptionsString = process.env.REACT_APP_FIREBASE_OPTION;
    options = {
      apiKey: 'AIzaSyCW6pLoSYFIORICTi1QFDXD_qrjto2fmm4',
      authDomain: 'fusend-5074e.firebaseapp.com',
      projectId: 'fusend-5074e',
      storageBucket: 'fusend-5074e.appspot.com',
      messagingSenderId: '745560566383',
      appId: '1:745560566383:web:dfddcda7044c3faf05fb9a',
      measurementId: 'G-2P6KQX9EQQ'
    };
    if (devOptionsString) {
      if (logger) {
        logger.info('Using development firebase option', { devOptionsString });
      } else {
        // eslint-disable-next-line no-console
        console.log(`Using development firebase option ${devOptionsString}`);
      }
      options = JSON.parse(devOptionsString);
    }
  }

  return options;
}

export function initFirebase(logger: winston.Logger | undefined /* logger does not work in SW */): firebase.app.App {
  const options = getOptions(logger);
  const app = firebase.initializeApp(options);
  if (logger) {
    logger.info('firebase initialized', { options });
  } else {
    // eslint-disable-next-line no-console
    console.log(`firebase initialized ${options}`);
  }
  return app;
}

export function loadRemoteConfig(): Promise<{
  [key: string]: firebase.remoteConfig.Value;
}> {
  const rc = firebase.app().remoteConfig();
  rc.settings = {
    ...rc.settings,
    minimumFetchIntervalMillis: 60 * 1000,
  };
  return rc.fetchAndActivate().then(() => {
    const configs = rc.getAll();
    setRemoteConfig(configs);
    logger?.info('remote config fetched', { configs });
    return configs;
  });
}

export declare type DocData = { [x: string]: any };
export declare type DocUpdate = { id: string, data: DocData };

type Query = firebase.firestore.Query<firebase.firestore.DocumentData>;
export class Firebase {
  public static async signIn() {
    const firebaseToken = await Service.authControllerRenewFirebase();
    const userCred = await firebase.auth().signInWithCustomToken(firebaseToken.firebaseAccessToken);
    if (!userCred.user) {
      // TODO: maybe route to an error page
      logger.error('Sign In With Firebase Failed', { userCred });
    } else {
      logger.info(`authenticated with firebase, user.uid: ${userCred.user?.uid}`);
    }
  }

  public static async signOut() {
    firebase.auth().signOut();
  }

  public static subscribeToFirestoreCollectionUpdate(
    collectionName: string,
    modifyQueryFn: ((q: Query) => Query) | undefined,
    onUpdate: (collectionName: string, docUpdates: DocUpdate[]) => void,
    onError: (collectionName: string, error: firebase.firestore.FirestoreError) => void): () => void {

    const db = firebase.firestore();
    let query = db.collection(collectionName) as Query;
    if (modifyQueryFn) {
      query = modifyQueryFn(query);
    }

    return query.onSnapshot((querySnapshot) => {
      const docUpdates: DocUpdate[] = [];
      querySnapshot.forEach((doc) => {
        docUpdates.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      onUpdate(collectionName, docUpdates);
    }, (error) => {
      onError(collectionName, error);
    });
  }

  public static subscribeToFirestoreDocUpdate(
    collectionName: string,
    docName: string,
    onUpdate: (collectionName: string, docName: string, docUpdate: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void,
    onError: (collectionName: string, docName: string, error: firebase.firestore.FirestoreError) => void): () => void {

    const db = firebase.firestore();
    return db.collection(collectionName).doc(docName).onSnapshot((doc) => {
      onUpdate(collectionName, docName, doc);
    }, (error) => {
      onError(collectionName, docName, error);
    });
  }

  public static async addDocToFirestoreCollection(collectionName: string, data: DocData) {
    await firebase.firestore().collection(collectionName).add(data);
  }

  public static async updateDocInFirestoreCollection(collectionName: string, docName: string, data: DocData) {
    await firebase.firestore().collection(collectionName).doc(docName).set(data);
  }
}
