import { action, orchestrator } from 'satcheljs';
import { logger } from '../../logging/winston';
import { onNotificationClickedRouter } from '../../notifications/routers';
import { ApiError, CancelablePromise, PreviewJoinRequestDto, Service, UpdateJoinRequestDto } from '../../services/openapi';
import { browserHistory } from '../../utils/history';
import { addError } from '../mutators/ErrorStoreMutators';
import { changeRideRequestState, removeRideRequest, setRideRequests, setRideRequestsLoading, unsetRideRequestsLoading, upsertRideRequests } from '../mutators/RideRequestStoreMutators';
import { newError } from '../stores/ErrorStore';
import { getUserStore } from '../stores/UserStore';
import { makeAsyncRequest } from '../utils/utils';
import { getRideDetailPath } from './../../pages/routes';
import { fetchOneRide } from './RideStoreOrchestrators';

export const fetchAllRideRequests = action('FETCH_ALL_RIDE_REQUESTS');
export const fetchAllRequestsForRide = action('FETCH_ALL_REQUESTS_FOR_RIDE', ( rideId: string ) => ({ rideId }));
export const fetchOneRideRequest = action('FETCH_ONE_RIDE_REQUEST', (rideRequestId: string) => ({ rideRequestId }));
export const updateRideRequestState = action('UPDATE_RIDE_REQUEST_STATE', (
  rideId: string,
  rideRequestId: string,
  state: UpdateJoinRequestDto.state,
  onSuccess?,
  onError?) => ({ rideId, rideRequestId, state, onSuccess, onError }));
export const createRideRequest = action('CREATE_RIDE_REQUEST', (rideId: string) => ({ rideId }));

let fetchRideRequestsPromise: CancelablePromise<PreviewJoinRequestDto[]> | undefined = undefined;

orchestrator(fetchAllRideRequests, async () => {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to fetch ride requests. Current user is missing.');
    addError(newError('FETCH_ALL_RIDE_REQUESTS.MissingUser'));
  } else {
    try {
      const { request, response } = await makeAsyncRequest(
        fetchRideRequestsPromise,
        () => Promise.resolve(() => Service.usersControllerFindRideRequests(user.id)),
        () => setRideRequestsLoading(),
        () => unsetRideRequestsLoading());
      fetchRideRequestsPromise = request;
      const rideRequests = await response;
      setRideRequests(rideRequests);
    } catch (exception: any) {
      if (exception) {
        logger.error('Failed to fetch ride requests. Service request error.', { userId: user.id, exception: exception.toString() });
        addError(newError('FETCH_ALL_RIDE_REQUESTS.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
      }
    }
  }
});

orchestrator(fetchAllRequestsForRide, async ({ rideId }) => {
  try {
    fetchOneRide(rideId);
    const requests = await Service.ridesControllerFindRequests(rideId);
    upsertRideRequests(requests);
  } catch (exception: any) {
    logger.error(`Failed to fetch ride request for ride ${rideId}. Service request error.`, { rideId, exception: exception.toString() });
    addError(newError('FETCH_ALL_REQUESTS_FOR_RIDE.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
});

function fetchRideRequestAndCheck(rideRequestId: string): Promise<PreviewJoinRequestDto | undefined> {
  return Service.rideRequestsControllerFindOne(rideRequestId).catch((err: ApiError) => {
    if (err.status == 404) {
      logger.info(`Ride ${rideRequestId} is no longer accessible`, { rideId: rideRequestId, err });
      return undefined;
    }
    throw err;
  });
}

orchestrator(fetchOneRideRequest, async ({ rideRequestId }) => {
  try {
    const rideRequest = await fetchRideRequestAndCheck(rideRequestId);
    if (rideRequest) {
      upsertRideRequests([ rideRequest ]);
      if (rideRequest.state == PreviewJoinRequestDto.state.ACCEPTED) {
        fetchOneRide(rideRequest.ride.id);
      }
    } else {
      removeRideRequest(rideRequestId);
    }
  } catch (exception: any) {
    logger.error(`Failed to fetch ride request ${rideRequestId}. Service request error.`, { rideRequestId, exception: exception.toString() });
    addError(newError('FETCH_ONE_RIDE_REQUEST.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
});

orchestrator(updateRideRequestState, async({ rideId, rideRequestId, state, onSuccess, onError }) => {
  try {
    const rideRequest = await Service.rideRequestsControllerUpdate(rideRequestId, { state });
    changeRideRequestState(rideRequestId, rideRequest.state);
    if (rideRequest.state == PreviewJoinRequestDto.state.ACCEPTED) {
      fetchOneRide(rideId);
    }
    if (onSuccess) onSuccess(rideRequest);
  } catch (exception: any) {
    logger.error(`Failed to update ride request state for request ${rideRequestId}. Service request error.`, { rideRequestId, exception: exception.toString() });
    addError(newError('UPDATE_RIDE_REQUEST_STATE.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    if (onError) onError(exception);
  }
});

orchestrator(createRideRequest, async ({ rideId }) => {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to create ride request. Current user is missing.');
    addError(newError('CREATE_RIDE_REQUEST.MissingUser'));
  } else {
    try {
      const rideRequest = await Service.rideRequestsControllerCreate({ ride: rideId });
      upsertRideRequests([ rideRequest ]);
    } catch (exception: any) {
      logger.error(`Failed to create ride request for ride ${rideId}. Service request error.`, { rideId, exception: exception.toString() });
      addError(newError('CREATE_RIDE_REQUEST.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

export const ROUTE_RIDE_REQUEST_NEW = 'ride.request.new';
export const ROUTE_RIDE_REQUEST_UPDATE = 'ride.request.update';

onNotificationClickedRouter.addRoute(ROUTE_RIDE_REQUEST_NEW, rideId => {
  logger.debug('Handling clicked new ride request notification', { rideId });
  browserHistory.push({
    pathname: getRideDetailPath(rideId),
  });
  fetchAllRequestsForRide(rideId);
});

onNotificationClickedRouter.addRoute(ROUTE_RIDE_REQUEST_UPDATE, rideRequestId => {
  logger.debug('Handling clicked updated ride request notification', { rideRequestId });
  browserHistory.push({
    pathname: '/tabs/MyRide/Upcoming',
  });
  fetchOneRideRequest(rideRequestId);
});
