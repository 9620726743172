import { action, orchestrator } from 'satcheljs';
import { appInsights, logger } from '../../logging/winston';
import { Facebook } from '../../services/facebook';
import { Firebase } from '../../services/firebase';
import { Google } from '../../services/google';
import { CancelablePromise, DeleteUserDto, LoginDto, OpenAPI, Service, UserDto } from '../../services/openapi';
import { Cache } from '../cache';
import { setActivitiesCaughtUpTimestamp } from '../mutators/ActivityStoreMutators';
import { addError } from '../mutators/ErrorStoreMutators';
import { clearAccessToken, setAccessToken, setCurrentUser, setUserLoading, unsetUserLoading } from '../mutators/UserStoreMutators';
import { newError } from '../stores/ErrorStore';
import { getUserStore } from '../stores/UserStore';
import { ErrorHandlingFn, makeAsyncRequest } from '../utils/utils';
import { fetchMyPeopleChats } from './ChatStoreOrchestrators';
import { fetchMyCommunities } from './CommunityStoreOrchestrators';
import { subscribeToDataPush, unsubscribeFromDataPush } from './DataPushSubscription';
import { fetchFriends } from './FriendStoreOrchestrators';
import { fetchAllRideRequests } from './RideRequestStoreOrchestrators';
import { fetchMyRides } from './RideStoreOrchestrators';

export enum LoginProvider {
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  GOOGLE = 'google',
}
type LoginFunction = () => Promise<string>;
export const userLogin = action('USER_LOGIN', (
  loginProvider: LoginProvider,
  loginFunction: LoginFunction,
  errorHandlingFn: ErrorHandlingFn,
  referrerId?: string
) => ({ loginProvider, loginFunction, errorHandlingFn, referrerId }));
export const userLogout = action('USER_LOGOUT', (callback?: () => void) => ({ callback }));
export const userRestore = action('USER_RESTORE', (notificationToken: string | undefined) => ({ notificationToken }));
export const updateUser = action('UPDATE_USER', (user: Partial<UserDto>) => ({ user }));
export const deleteAccount = action('DELETE_ACCOUNT', (userId: string, callback?: () => void) => ({ userId, callback }));

const LOCAL_STORAGE_USER_ID = 'jerryUserId';
const LOCAL_STORAGE_ACCESS_TOKEN = 'jerryToken';

let loginPromise: CancelablePromise<LoginDto> | undefined = undefined;
let userRestorePromise: CancelablePromise<UserDto> | undefined = undefined;
let userUpdatePromise: CancelablePromise<UserDto> | undefined = undefined;
let deleteAccountPromise: CancelablePromise<DeleteUserDto> | undefined = undefined;

async function login(loginProvider: LoginProvider, loginFunction: LoginFunction, notificationToken: string | undefined, referrerId: string | undefined): Promise<() => CancelablePromise<LoginDto>> {
  const providerToken = await loginFunction();
  if (loginProvider == LoginProvider.FACEBOOK) {
    return () => Service.authControllerFacebookLogin(providerToken, { notificationToken, referrerId });
  } else if (loginProvider == LoginProvider.APPLE) {
    return () => Service.authControllerAppleLogin(providerToken, { notificationToken, referrerId });
  } else if (loginProvider == LoginProvider.GOOGLE) {
    return () => Service.authControllerGoogleLogin(providerToken, { notificationToken, referrerId });
  } else {
    throw new Error(`Invalid provider ${loginProvider}`);
  }
}

async function saveUserContext(user: UserDto, accessToken: string) {
  OpenAPI.TOKEN = accessToken;
  appInsights.setAuthenticatedUserContext(user.id, undefined, true);

  Cache.setItem(LOCAL_STORAGE_USER_ID, user.id);
  Cache.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken);

  setCurrentUser(user);
  setAccessToken(accessToken);
  fetchMyCommunities();
  fetchFriends();
  fetchMyRides();
  fetchAllRideRequests();
  fetchMyPeopleChats();
  setActivitiesCaughtUpTimestamp(user.activityCaughtUpTimestamp ? new Date(user.activityCaughtUpTimestamp) : undefined);
  logger.info('User logged in', { id: user.id });

  try {
    logger.info('Signing in to firebase');
    await Firebase.signIn();
    // NOTE
    // The subscription should be done after:
    // 1- setCurrentUser
    // 2- setAccessToken
    // 3- fetchMyCommunities
    // Otherwise, the callback invoked by the subscription will fail due to missing those information.
    subscribeToDataPush(user.id);
  } catch (signInException) {
    logger.error('Failed to sign in to firebase', { signInException });
    addError(newError('USER_LOGIN.FirebaseError', JSON.stringify(signInException)));
  }
}

orchestrator(userLogin, async ({ loginProvider, loginFunction, errorHandlingFn, referrerId }) => {
  try {
    const { notificationToken } = getUserStore();
    const { request, response } = await makeAsyncRequest(
      loginPromise,
      () => login(loginProvider, loginFunction, notificationToken, referrerId),
      () => setUserLoading(),
      () => {});
    loginPromise = request;

    const { user, accessToken } = await response;
    logger.info(`Login completed`, { user, referrerId });
    await saveUserContext(user, accessToken);
    unsetUserLoading();
  } catch (exception: any) {
    unsetUserLoading();
    if (exception) {
      logger.error('Failed to login', { exception: exception.toString() });
      errorHandlingFn(exception);
      addError(newError('USER_LOGIN.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(userLogout, async ({ callback }) => {
  const { notificationToken } = getUserStore();
  try {
    await Service.meControllerLogout({
      notificationToken
    });
  } catch (exception: any) {
    logger.error('Failed to logout', { exception: exception.toString() });
    // ignore logout failure
  }
  unsubscribeFromDataPush();
  OpenAPI.TOKEN = undefined;
  clearAccessToken();
  Cache.removeItem(LOCAL_STORAGE_USER_ID);
  Cache.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
  await Promise.all([
    Facebook.logout(),
    Firebase.signOut(),
    Google.logout(),
  ]).catch((exception: any) => {
    logger.error('Failed to logout from providers', { exception: exception.toString() });
    // ignore logout failure
  });

  callback && callback();
});

orchestrator(userRestore, async ({ notificationToken }) => {
  const userId = Cache.getItem(LOCAL_STORAGE_USER_ID);
  const accessToken = Cache.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

  if (!userId || !accessToken) {
    logger.info('User info is not cached. Skip restoring user context.');
    return;
  }

  OpenAPI.TOKEN = accessToken;

  try {
    const { request, response } = await makeAsyncRequest(
      userRestorePromise,
      () => Promise.resolve(() => Service.meControllerGet()),
      () => setUserLoading(),
      () => {});
    userRestorePromise = request;
    const user = await response;
    await saveUserContext(user, accessToken);

    if (notificationToken) {
      logger.info('Registering notification token', { notificationToken });
      await makeAsyncRequest(
        undefined,
        () => Promise.resolve(() => Service.meControllerRegisterNotificationToken({ notificationToken })),
        () => {},
        () => {}
      );
    }

    unsetUserLoading();
  } catch (exception: any) {
    unsetUserLoading();
    if (exception) {
      logger.error('Failed get user', { exception: exception.toString() });
      addError(newError('USER_RESTORE.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(updateUser, async ({ user }) => {
  try {
    const { request, response } = await makeAsyncRequest(
      userUpdatePromise,
      () => Promise.resolve(() => Service.meControllerUpdate(user as any)),
      () => setUserLoading(),
      () => unsetUserLoading());
    userUpdatePromise = request;
    const userDto = await response;
    setCurrentUser(userDto);
  } catch (exception: any) {
    if (exception) {
      logger.error('Failed to update user', { exception: exception.toString() });
      addError(newError('UPDATE_USER.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

orchestrator(deleteAccount, async ({ userId, callback }) => {
  try {
    if (deleteAccountPromise) {
      logger.info('Delete account already in progress.');
      await deleteAccountPromise;
    } else {
      const { request, response } = await makeAsyncRequest(
        deleteAccountPromise,
        () => Promise.resolve(() => Service.meControllerDelete({ userId })),
        () => {},
        () => {});
      deleteAccountPromise = request;
      await response;
    }
  } catch (exception: any) {
    if (exception) {
      logger.error('Failed to delete account', { exception: exception.toString() });
      addError(newError('DELETE_ACCOUNT.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  } finally {
    deleteAccountPromise = undefined;
    callback && callback();
  }
});
