import { IonImg, IonThumbnail } from "@ionic/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";
import { getProfilePagePath } from "../../pages/routes";
import { PreviewUserDto, UserDto } from "../../services/openapi";
import FallbackProfilePic from "./FallbackProfilePic";
import './ProfilePicture.scss';

interface ProfilePictureProps {
  user: UserDto | PreviewUserDto;
  className?: string;
  overrideDefaultClick?: boolean;
  onProfileClicked?: () => void;
}

const ProfilePicture: React.FC<ProfilePictureProps> = observer(({
  user,
  className,
  overrideDefaultClick = false,
  onProfileClicked
}: ProfilePictureProps) => {
  const {
    firstName,
    lastName,
    username,
    profilePictureUrl,
  } = user || {
    firstName: '',
    lastName: '',
    username: '',
    profilePictureUrl: '',
  };

  const history = useHistory();

  let profilePicComponent = null;
  if (profilePictureUrl) {
    profilePicComponent = (
      <IonThumbnail className={`${className} profilePicture`}>
        <IonImg className="profileImg" src={profilePictureUrl} alt={`${firstName} ${lastName}`} />
      </IonThumbnail>
    );
  } else {
    profilePicComponent = (
      <FallbackProfilePic
        className={`${className} profilePicture`}
        firstName={firstName}
        lastName={lastName}
      />
    );
  }

  const onProfilePicClicked = () => {
    if (!overrideDefaultClick) {
      history.push(getProfilePagePath(username));
    } else if (onProfileClicked) {
      onProfileClicked();
    }
  };

  return (
    <div className={`profilePicWrapper ${className}Wrapper`} onClick={(e) => {
      if (!overrideDefaultClick) {
        e.stopPropagation();
      }
      onProfilePicClicked();
    }}>
      {profilePicComponent}
    </div>
  );
});

export default ProfilePicture;