import React from 'react';
import {
  Redirect, Route
} from 'react-router-dom';
import { hasSignedUp, isLoggedIn } from '../dataflow/stores/UserStore';
import { homeRedirectPath, loginPath, signUpPath, signUpPathLiteral } from '../pages/routes';

interface ProtectedRouteProps {
  component: any;
  // All other props
  [x: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  const { component: Component, ...rest } = props;
  const intendedPath = window.location.href.replace(window.location.origin, '');

  return (
    <Route {...rest} render={(_props) => {
      if (isLoggedIn()) {
        if (!hasSignedUp()) {
          if (intendedPath.startsWith(signUpPathLiteral)) {
            return <Component />;
          }
          const redirectPath = signUpPath(intendedPath);
          return <Redirect to={redirectPath} />;
        } else {
          if (intendedPath.startsWith(signUpPathLiteral)) {
            return <Redirect to={homeRedirectPath} />;
          }
          return <Component />;
        }
      } else {
        const redirectPath = loginPath(intendedPath, window.location.search);
        return <Redirect to={redirectPath} />;
      }
    }} />
  );
};

export default ProtectedRoute;
