import { IonIcon, IonThumbnail } from '@ionic/react';
import { person } from 'ionicons/icons';
import React from 'react';
import './FallbackProfilePic.css';

interface FallbackProfilePicProps {
  firstName: string;
  lastName: string;
  className?: string;
  onProfileClicked?: () => void;
}

const FallbackProfilePic: React.FC<FallbackProfilePicProps> = (props: FallbackProfilePicProps) => {
  const shouldUseTextBasedProfile = !!props.firstName && !!props.lastName;
  let picClass = 'fallbackProfilePic';
  if (props.className) {
    picClass = `${picClass} ${props.className}`;
  }

  return (
    <IonThumbnail className={picClass} onClick={props.onProfileClicked}>
      {shouldUseTextBasedProfile?
        <span className='fallbackProfilePicTextContainer'>
          {props.firstName[0].toUpperCase()}{props.lastName[0].toUpperCase()}
        </span>
        :
        <span className='fallbackProfilePicTextContainer'>
          <IonIcon icon={person}/>
        </span>
      }
    </IonThumbnail>
  );
};

export default FallbackProfilePic;