import React from "react";
import { ReactComponent as StarFilled } from "./assets/star-filled.svg";
import { ReactComponent as StarOutline } from "./assets/star-outline.svg";
import "./Value.scss";

export interface SelectOption {
  label: string;
  value: string;
  index: number;
}

export interface MultiSelectProps {
  title: string;
  optionsState: Map<string, boolean>;
  options: Array<SelectOption>;
  onChange: (value: SelectOption) => void;
  disabled: boolean;
  errorMessage?: string | undefined;
}

interface OptionProp {
  selectOption: SelectOption;
  isSelected: boolean;
  onChange: (value: SelectOption) => void;
  disabled: boolean;
}

const SelectOption: React.FC<OptionProp> = ({ selectOption, isSelected, onChange }) => {
  return (
    <button className="selectOption" onClick={() => {
      onChange(selectOption);
    }}>
      {
        isSelected ?
          <div className="indicator">
            <StarFilled />
          </div> :
          <div className="indicator">
            <StarOutline />
          </div>
      }
      <div className="value">{selectOption.label}</div>
    </button>
  );
};

export const MultiSelectExpanded: React.FC<MultiSelectProps> = ({ title, optionsState, options, onChange, disabled, errorMessage }) => {
  const selectOptions = options.map((selectOption) => <SelectOption key={selectOption.index} selectOption={selectOption}
    isSelected={optionsState.get(selectOption.value) || false} onChange={onChange} disabled={disabled} />);
  return (
    <div className="editable-value-container">
      <div className="editable-value-title">{title}</div>
      <div className="multi-select-options">{selectOptions}</div>
      {errorMessage && <div className="editable-value-error">{errorMessage}</div>}
    </div>
  );
};