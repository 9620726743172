import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { GoBack } from "../../components/navigation-header/GoBack";
import { CreateEditableValues, ViewableItem } from "../Shared/EditableValue";
import { myProfilePath } from "../routes";
import { CopiableValue } from "./CopiableValue";
import "./EditableDetail.css";

export interface EditableDetailProps {
  pageTitle: string;
  editableItems?: ViewableItem[];
  copiableItems?: ViewableItem[];
  onSave?: () => void;
  isSaving?: boolean;
}

export const EditableDetail: React.FC<EditableDetailProps> = ({ pageTitle, editableItems, copiableItems, onSave, isSaving }: EditableDetailProps) => {
  const [ isDirty, setDirty ] = React.useState(false);
  const editableElems = CreateEditableValues(editableItems || [], () => setDirty(true));
  const onSaveResetDirty = () => {
    if (onSave) {
      onSave();
      setDirty(false);
    }
  };
  const copiableElems = (copiableItems || []).map((props, index) => <CopiableValue key={index} {...props as any} />);
  const saveButton = onSave && isDirty ? (
    <div className="save-button-container">
      <button className="save-button" onClick={onSaveResetDirty} disabled={isSaving || !isDirty}>Save</button>
    </div>
  )
    : undefined;
  return (
    <IonPage className="fusendPage editable-detail-page">
      <GoBack title={pageTitle} backUrl={myProfilePath}></GoBack>
      <IonContent className="fusendPageContent editable-detail-content">
        {editableElems}
        {copiableElems}
        {saveButton}
      </IonContent>
    </IonPage>
  );
};