/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RideOptionsDto } from './RideOptionsDto';

export type CreateTripGroupDto = {
    purpose: CreateTripGroupDto.purpose;
    privacy: CreateTripGroupDto.privacy;
    title: string;
    notes?: string;
    memberUserIds?: Array<string>;
    rideOptions?: RideOptionsDto;
}

export namespace CreateTripGroupDto {

    export enum purpose {
        GENERAL = 'GENERAL',
        CARPOOL = 'CARPOOL',
        RIDE = 'RIDE',
        TRIP = 'TRIP',
    }

    export enum privacy {
        PUBLIC = 'PUBLIC',
        PRIVATE = 'PRIVATE',
    }


}
