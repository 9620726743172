import { TransformableInfo } from 'logform';

const existingKeys = {
  'level': true,
  'message': true,
  'Symbol(level)':  true,
  'Symbol(message)': true,
  'Symbol(splat)': true,
  '[[Prototype]]': true
};

export function getExtras(info: TransformableInfo): {[key: string]: string} | undefined {
  const extras: {[key: string]: string} = Object.create(null);
  let empty = true;
  Object.keys(info).forEach(key => {
    if (!(key in existingKeys)) {
      empty = false;
      extras[key] = info[key];
    }
  });
  return empty ? extras : extras;
}