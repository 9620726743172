/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TripParticipantDto } from './TripParticipantDto';

export type ParticipantsDto = {
    selfAttending: ParticipantsDto.selfAttending;
    friends: Array<TripParticipantDto>;
    others: Array<TripParticipantDto>;
}

export namespace ParticipantsDto {

    export enum selfAttending {
        GOING = 'Going',
        INTERESTED = 'Interested',
        NOT_GOING = 'NotGoing',
    }


}
