import { IonContent, IonImg, IonPage } from '@ionic/react';
import './LogoPage.css';
import './LogoPage.dark.css';

interface LogoPageProps {
  children: any;
}

const LogoPage: React.FC<LogoPageProps> = (props: LogoPageProps) => {
  return (
    <IonPage className='logoPage'>
      <IonContent className='logoContent' scrollY={false}>
        <span className='logoImage'>
          <IonImg src='./assets/logo.png' />
        </span>
        {props.children}
      </IonContent>
    </IonPage>
  );
};

export default LogoPage;