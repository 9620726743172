import { CheckboxChangeEventDetail, IonButton, IonCheckbox } from '@ionic/react';
import { observer } from 'mobx-react-latest';
import { MouseEvent } from 'react';
import { useHistory } from 'react-router';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { toggleFollowFriend } from '../../dataflow/orchestrators/FriendStoreOrchestrators';
import { Friend } from '../../dataflow/stores/FriendStore';
import { getUpcomingTrips } from '../../dataflow/stores/TripStore';
import { PreviewUserDto } from '../../services/openapi';
import { getProfilePagePath } from '../routes';
import './FriendCard.scss';

interface FriendCardProps {
  friend: Friend;
  isTripInvite?: boolean;
  isGoing?: boolean;
  isInvited?: boolean;
  onCheckboxChecked?: (id: string, checked: boolean) => void;
}

export const FriendCard: React.FC<FriendCardProps> = observer((props: FriendCardProps) => {
  const history = useHistory();
  const { friend, isTripInvite, isGoing, isInvited, onCheckboxChecked } = props;
  const nextTrip = getUpcomingTrips(friend.friend.upcomingTrips, 1)[0];

  const followBtnOnClick = (event: MouseEvent) => {
    toggleFollowFriend(friend.id);
    event.stopPropagation();
  };

  return (
    <div className='friendCard' onClick={() => !isTripInvite && history.push(getProfilePagePath(friend.friend.username))}>
      <div className='friendCardPic'>
        <ProfilePicture
          className='friendCardProfilePicture'
          key={friend.id}
          user={(friend.friend as PreviewUserDto)}
          overrideDefaultClick={true}
        />
      </div>
      <div className='friendCardContent'>
        <div className='friendCardContentFullName'>{`${friend.friend.firstName} ${friend.friend.lastName}`}</div>
        <div className='friendCardContentUsername'>{friend.friend.username}</div>
        {!isTripInvite && nextTrip && (
          <div className='friendCardContentTripInfo'>Next Trip: {nextTrip.dateText}</div>
        )}
      </div>
      {isTripInvite &&
        <div className='friendCardInviteBox'>
          {isGoing && <p>Going</p>}
          {!isGoing && isInvited && <p>Invited</p>}
          {!isGoing &&
            <IonCheckbox
              className='inviteFriendCheckbox'
              slot="end"
              onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => onCheckboxChecked && onCheckboxChecked(friend.id, e.detail.checked)} />
          }
        </div>
      }
      {!isTripInvite &&
        <div className='friendCardButtons'>
          <IonButton
            color={friend.follow ? 'medium' : 'secondary'} key='follow' disabled={friend.follow} onClick={followBtnOnClick}>
            {friend.follow ? 'Following' : 'Follow' }
          </IonButton>
        </div>
      }
    </div>
  );
});
