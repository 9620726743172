/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PreviewUserDto } from './PreviewUserDto';

export type FriendRequestDto = {
    requester: PreviewUserDto;
    recipient: PreviewUserDto;
    status: FriendRequestDto.status;
    requestedDate: string;
    note: string;
}

export namespace FriendRequestDto {

    export enum status {
        PENDING = 'Pending',
        REJECTED = 'Rejected',
        ACCEPTED = 'Accepted',
        CANCELLED = 'Cancelled',
    }


}
