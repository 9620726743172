import { IonButton, IonIcon, IonLoading, useIonModal } from "@ionic/react";
import { addCircle, people } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import React from "react";
import { createTripGroup, fetchTrip, fetchTripGroupsOfTrip } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { isFeatureEnabled } from "../../dataflow/stores/RemoteConfigStore";
import { getTripStore } from "../../dataflow/stores/TripStore";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { CreateTripGroupDto, MemberInfoDto, ParticipantsDto, TripDetailDto, TripGroupDetailDto, UserDto, TripParticipantDto } from "../../services/openapi";
import TripGroupCard from "./TripGroupCard";
import TripGroupEditorModal, { TripGroupEditorOptions } from "./TripGroupEditorModal";

interface TripGroupDetailProps {
  isPartOfTrip: boolean;
  pageRef: React.MutableRefObject<any>;
  date: string | undefined | null;
  resortId: string | undefined | null;
  groupSectionTitle: string;
  displayGroupTypes: TripGroupDetailDto.purpose[];
  createGroupType: TripGroupDetailDto.purpose;
  currentTrip?: TripDetailDto;
  showCreateGroup: boolean;
}


function getTripGroupChat(currentTrip: TripDetailDto, currentUser: UserDto): TripGroupDetailDto {
  const isPartOfTrip = currentTrip.participants.selfAttending !== ParticipantsDto.selfAttending.NOT_GOING;
  const trip = currentTrip;
  const allParticipantsWithoutSelf = trip.participants.friends.concat(trip.participants.others);
  const allParticipants = isPartOfTrip ? allParticipantsWithoutSelf.concat([ {
    user: {
      completedSignup: currentUser.completedSignup,
      firstName: currentUser.firstName,
      id: currentUser.id,
      lastName: currentUser.lastName,
      profilePictureUrl: currentUser.profilePictureUrl,
      upcomingTrips: [],
      username: currentUser.username,
    },
    id: currentUser.id,
    status: currentTrip.participants.selfAttending as any as TripParticipantDto.status,
    tripId: trip.id,
  } ]): allParticipantsWithoutSelf;

  const members: MemberInfoDto[] = allParticipants.map(p => ({
    user: p.user,
    joinedOn: trip.date,
  }));

  return {
    id: trip.id,
    trip,
    purpose: TripGroupDetailDto.purpose.TRIP,
    privacy: TripGroupDetailDto.privacy.PUBLIC,
    title: 'Trip Chat',
    notes: '',
    createdOn: trip.date,
    updatedOn: trip.date,
    members,
  };
}

const TripGroupDetail = observer((props: TripGroupDetailProps) => {
  const isEnabled = isFeatureEnabled('carpool');
  const [ isCreatingTripGroup, setIsCreatingTripGroup ] = React.useState<boolean>(false);
  const { currentTrip, isPartOfTrip = false, pageRef, date, resortId, groupSectionTitle, displayGroupTypes, createGroupType, showCreateGroup } = props;
  const tripId = currentTrip?.id;
  const { currentUser } = getUserStore();
  let tripGroups = !tripId ? []
    : getTripStore().tripGroupMap.get(tripId)
      ?.filter(g =>
        displayGroupTypes.includes(g.purpose)
        && (g.privacy == TripGroupDetailDto.privacy.PUBLIC
          || g.members.some((m) => m.user.id == currentUser?.id)));
  if (currentTrip && currentUser && tripGroups && createGroupType != TripGroupDetailDto.purpose.CARPOOL) {
    tripGroups = [ getTripGroupChat(currentTrip, currentUser) ].concat(tripGroups);
  }


  React.useEffect(() => {
    if (tripId) {
      fetchTripGroupsOfTrip(tripId);
    }
  }, [ tripId ]);

  const [ present, dismiss ] = useIonModal(TripGroupEditorModal, {
    title: 'New Group',
    showCreateOptions: [
      TripGroupEditorOptions.Name,
      TripGroupEditorOptions.Privacy,
      TripGroupEditorOptions.Members,
    ],
    confirmLabel: 'Create',
    onDismiss: () => dismiss(),
    onConfirm: (
      selectedUserIds: string[],
      groupName: string | undefined,
      groupPrivacy: TripGroupDetailDto.privacy,
      _notes: string | undefined,
      _maxSize: number | undefined,
    ) => {
      if (!groupName) {
        return;
      }
      dismiss();
      if(tripId) {
        const createDto: CreateTripGroupDto = {
          purpose: createGroupType,
          privacy: groupPrivacy,
          title: groupName,
          memberUserIds: currentUser ? selectedUserIds.concat([ currentUser.id ]) : selectedUserIds,
        };
        setIsCreatingTripGroup(true);
        createTripGroup(tripId, createDto, () => {
          fetchTrip(tripId, () => {
            setIsCreatingTripGroup(false);
          });
        });
      }
    },
    friendsList: currentTrip?.participants.friends,
    othersList: currentTrip?.participants.others,
  });

  const onCreateGroupClick = () => {
    present({
      presentingElement: pageRef.current
    });
  };

  if (isEnabled && props.displayGroupTypes.findIndex(g => g == TripGroupDetailDto.purpose.CARPOOL) > -1) {
    return <></>;
  }
  if(tripId && tripGroups) {
    return (
      <div className='tripDetailSection'>
        <div className='tripDetailTitleSection'>
          <h3 className='tripDetailSectionTitle groupTitle'>{groupSectionTitle}</h3>
          { showCreateGroup &&
            <IonButton
              className='addButton'
              color='secondary'
              onClick={onCreateGroupClick}
              size='small'
              fill='clear'>
              <IonIcon icon={addCircle} className='groupCreateBtnIcon'/>Create
            </IonButton>
          }
        </div>
        <div>
          { tripGroups.length > 0 &&
            tripGroups.map((g: TripGroupDetailDto) => {
              return <TripGroupCard
                key={g.id}
                id={g.id}
                tripId={tripId}
                title={g.title}
                date={date || currentTrip?.date}
                resortId={resortId || currentTrip?.resort.id}
                purpose={g.purpose}
                privacy={g.privacy}
                members={g.members}
                isPlaceholder={false}
                isPartOfTrip={isPartOfTrip}/>;
            })
          }
          { tripGroups.length == 0 &&
            <div className="tripSectionBody">
              <div className="emptyWrapper">
                <IonIcon className="emptyGroupIcon" icon={people}/>
                <h5>There is no group yet</h5>
                <h6>Invite a friend, create a group</h6>
              </div>
            </div>
          }
        </div>
        { isCreatingTripGroup && <IonLoading isOpen={isCreatingTripGroup} message={'Creating Group'} duration={5000}/> }
      </div>
    );
  } else {
    return null;
  }
});

export default TripGroupDetail;