import { IonInput, IonSelect, IonSelectOption } from "@ionic/react";
import { isString } from "lodash";
import React from "react";
import { MultiSelectExpanded, SelectOption } from "./MultiSelectExpanded";
import "./Value.scss";

interface EditableStringValueProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
  disable: boolean;
  placeholder?: string;
  importantNote?: string | undefined;
  errorMessage?: string | undefined;
}

export const EditableStringValue: React.FC<EditableStringValueProps> = ({ title, value, onChange, disable, errorMessage, importantNote, placeholder }) => {
  const eventHandler = (e: any) => onChange(e.detail.value);
  return (
    <div className="editable-value-container">
      <div className="editable-value-title">
        <div>{title}</div>
        {!!importantNote && <div className="important-note">{importantNote}</div>}
      </div>
      <IonInput className={`editable-value-value ${errorMessage && 'invalid'}`} value={value} placeholder={placeholder || title} onIonChange={eventHandler} disabled={disable} type="text" />
      {errorMessage && <div className="editable-value-error">{errorMessage}</div>}
    </div>
  );
};

export const EditableTextValue: React.FC<EditableStringValueProps> = ({ title, value, onChange, disable, placeholder, errorMessage, importantNote }) => {
  const eventHandler = (e: any) => {
    onChange(e.detail?.value || e.currentTarget?.value);
  };
  return (
    <div className="editable-value-container">
      <div className="editable-value-title">
        <div>{title}</div>
        {!!importantNote && <div className="important-note">{importantNote}</div>}
      </div>
      <textarea
        className={`editable-value-value ${errorMessage && 'invalid'} editable-textarea`}
        rows={4}
        value={value}
        placeholder={placeholder || title}
        onInput={eventHandler}
        disabled={disable} />
      {errorMessage && <div className="editable-value-error">{errorMessage}</div>}
    </div>
  );
};

interface EditableNumberValueProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
  disable: boolean;
  min: string;
  max: string;
  placeholder?: string;
}

export const EditableNumberValue: React.FC<EditableNumberValueProps> = ({ title, value, onChange, disable, min, max, placeholder }) => {
  const eventHandler = (e: any) => onChange(e.detail.value);
  return (
    <div className="editable-value-container">
      <div className="editable-value-title">{title}</div>
      <IonInput className="editable-value-value" value={value} placeholder={placeholder || title} onIonChange={eventHandler} disabled={disable} type="number" min={min} max={max} />
    </div>
  );
};

export interface EditableSelectValueProps {
  title: string;
  selectedOption: string;
  onChange: (value: SelectOption) => void;
  disable: boolean;
  options: SelectOption[];
  errorMessage?: string | undefined;
}

export const EditableSelectValue: React.FC<EditableSelectValueProps> = ({ title, selectedOption, onChange, disable, options }) => {
  const eventHandler = (e: any) => onChange(e.detail.value);
  const optionElements = options.map(option => (<IonSelectOption key={option.index} value={option.value}>{option.label}</IonSelectOption>));
  return (
    <div className="editable-value-container">
      <div className="editable-value-title">{title}</div>
      <IonSelect className="editable-value-value" value={selectedOption} placeholder={title} onIonChange={eventHandler} disabled={disable} interface="action-sheet">
        {optionElements}
      </IonSelect>
    </div>
  );
};

export enum ValueType {
  String,
  Text,
  Select,
  Number,
  MultiSelectExpanded
}

export interface BaseOptions<T> {
  onChange: (value: T) => void;
}

export interface StringOptions extends BaseOptions<string> {
}

export interface NumberOptions extends BaseOptions<string> {
  min: string,
  max: string
}

export interface SelectOptions extends BaseOptions<SelectOption> {
  options: SelectOption[];
}

export interface ValidationOption {
  isInvalid: boolean;
  message: string;
}

export interface EditableDetail {
  type: ValueType,
  importantNote?: string | undefined,
  selectOptions?: SelectOptions | undefined,
  stringOptions?: StringOptions | undefined,
  numberOptions?: NumberOptions | undefined,
  validationOption?: ValidationOption | undefined;
}

export interface ViewableItem {
  title: string;
  value: string | string[] | Map<string, boolean>;
  placeholder?: string;
  editableDetail?: EditableDetail | undefined;
}

export const CreateEditableValues = (items: ViewableItem[], setDirty: () => void) => items.map(({ title, value, editableDetail, placeholder }, index) => {
  const errorMessage = editableDetail?.validationOption?.isInvalid ? editableDetail?.validationOption?.message : undefined;
  if (editableDetail === undefined) {
    return <EditableStringValue title={title} value={value as string} disable={true} onChange={_ => {}} key={index} errorMessage={errorMessage} importantNote={undefined} />;
  }
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const getOnChangeWithDirty = <T,>(baseOptions: BaseOptions<T>) => (value: T) => {
    setDirty();
    baseOptions.onChange(value);
  };

  switch (editableDetail.type) {
    case ValueType.String:
      if (!isString(value)) {
        throw new Error("value must be a string for ValueType.String");
      }
      if (editableDetail.stringOptions === undefined) {
        throw new Error("stringOptions must be defined for ValueType.String");
      }
      return <EditableStringValue key={index} title={title} value={value} placeholder={placeholder}
        disable={false} onChange={getOnChangeWithDirty(editableDetail.stringOptions)} errorMessage={errorMessage} importantNote={editableDetail.importantNote} />;
    case ValueType.Text:
      if (!isString(value)) {
        throw new Error("value must be a string for ValueType.String");
      }
      if (editableDetail.stringOptions === undefined) {
        throw new Error("stringOptions must be defined for ValueType.String");
      }
      return <EditableTextValue key={index} title={title} value={value} placeholder={placeholder}
        disable={false} onChange={getOnChangeWithDirty(editableDetail.stringOptions)} errorMessage={errorMessage} importantNote={editableDetail.importantNote} />;
    case ValueType.Number:
      if (!isString(value)) {
        throw new Error("value must be a string for ValueType.Number");
      }
      if (editableDetail.numberOptions === undefined) {
        throw new Error("numberOptions must be defined for ValueType.Number");
      }
      return <EditableNumberValue key={index} title={title} value={value} disable={false} onChange={getOnChangeWithDirty(editableDetail.numberOptions)} placeholder={placeholder}
        min={editableDetail.numberOptions?.min || "-9999"} max={editableDetail.numberOptions?.max || "9999"} />;
    case ValueType.Select:
      if (!isString(value)) {
        throw new Error("value must be a string for ValueType.Select");
      }
      if (editableDetail.selectOptions === undefined) {
        throw new Error("selectOptions must be defined for ValueType.Select");
      }
      return <EditableSelectValue key={index} title={title} selectedOption={value} disable={false} onChange={getOnChangeWithDirty(editableDetail.selectOptions)}
        options={editableDetail.selectOptions.options} />;
    case ValueType.MultiSelectExpanded:
      if (!(value instanceof Map)) {
        throw new Error("value must be a Map<string, boolean> for ValueType.MultiSelectExpanded");
      }
      if (editableDetail.selectOptions === undefined) {
        throw new Error("selectOptions must be defined for ValueType.MultiSelectExpanded");
      }
      return <MultiSelectExpanded key={title} title={title} optionsState={value} disabled={false} onChange={getOnChangeWithDirty(editableDetail.selectOptions)}
        errorMessage={errorMessage} options={editableDetail.selectOptions.options} />;
    default:
      throw new Error("Unsupported value type");
  }
});

export const SubmitButton = (onClick: () => void, isDisabled: boolean, text: string) => {
  return (
    <div className="submit-button-container">
      <button className="submit-button" onClick={onClick} disabled={isDisabled}>{text}</button>
    </div>
  );
};