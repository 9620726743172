import moment from 'moment';

export function getChatMessageTimestampText(timestamp: Date): string {
  const today = moment().startOf('day');
  if (moment(timestamp).isSame(today, 'day')) {
    return moment(timestamp).format('h:mm A');
  } else {
    return moment(timestamp).format('MMM D');
  }
}
