import { FacebookLogin } from '@capacitor-community/facebook-login';
import { isPlatform } from '@ionic/core';
import { logger } from '../logging/winston';

declare global {
  interface Window {
    fbAsyncInit: () => void;
  }
}

const language = 'en_US';
const permissions = [ 'email' ];

export function initFacebook() {
  if (isPlatform('capacitor') || window.fbAsyncInit) {
    logger.info('Skip initialized Facebook JS SDK, given native component available');
    return;
  }

  const appId = '529315971460568';
  window.fbAsyncInit = function () {
    logger.info('Initializing Facebook JS SDK');
    FB.init({
      appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v12.0',
    });
  };

  ((d, s, id) => {
    // load facebook js sdk
    const element = d.getElementsByTagName(s)[0] as HTMLScriptElement;
    const fjs = element;
    let js = element;
    if (d.getElementById(id)) { return; }
    js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = `https://connect.facebook.net/${language}/sdk.js`;
    fjs?.parentNode?.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export class Facebook {
  public static async login(): Promise<string> {
    const login = await FacebookLogin.login({ permissions });
    if (login.accessToken) {
      logger.info('Facebook login succeeded');
      return login.accessToken.token;
    } else {
      logger.error('Failed to perform Facebook Login. Token was not returned.');
      throw 'Login succeeded but token is missing';
    }
  }

  public static async logout(): Promise<void> {
    try {
      await FacebookLogin.getCurrentAccessToken();
    } catch (error) {
      // no access token, so no need to logout
      return;
    }
    return FacebookLogin.logout().catch((error) => {
      logger.warn('Failed to logout from Facebook', { exception: error });
    });
  }
}
