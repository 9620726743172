import { setResortList } from '../dataflow/mutators/ResortStoreMutators';
import { Service } from '../services/openapi';
import { ResortDto } from './../services/openapi/models/ResortDto';

export default async function initializeResortList(): Promise<ResortDto[]> {
  const result = await Service.catalogControllerFindAllResorts();
  result.sort((a, b) => {
    const stateCompare = a.state.localeCompare(b.state);
    if (stateCompare) {
      return -stateCompare;
    }
    return (a.location?.name || 'Resort').localeCompare(b.location?.name || 'Resort');
  });
  setResortList(result);
  return Promise.resolve(result);
}
