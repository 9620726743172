import moment from 'moment';
import { logger } from '../../logging/winston';
import { Firebase } from '../../services/firebase';
import { addError } from '../mutators/ErrorStoreMutators';
import { newError } from '../stores/ErrorStore';
import { checkNewActivity } from './ActivityStoreOrchestrators';
import { fetchMyPeopleChats } from './ChatStoreOrchestrators';
import { fetchFriends } from './FriendStoreOrchestrators';
import { fetchMyTrip, fetchMyTripGroups, fetchTrip, fetchTripGroup, searchTrip } from './TripStoreOrchestrators';

const dataPushSubscriptions: Map<string, (() => void)> = new Map();

type DataPushHandler = {
  source: string;
  handler: (data: { [field: string]: any } | undefined) => void;
};

function addHandler(dataPushHandler: DataPushHandler, skipInitialUpdate = false) {
  const { source, handler } = dataPushHandler;
  if (dataPushSubscriptions.has(source)) {
    logger.debug(`Skip subscribing to "${source}" again`, { source });
    return;
  }
  try {
    let skipped = !skipInitialUpdate;
    dataPushSubscriptions.set(source, Firebase.subscribeToFirestoreDocUpdate(`data-push/${source}`, '_UPDATE_',
      (collectionName, docName, snapshot) => {
        if (!skipped) {
          skipped = true;
        } else {
          const data = snapshot.data();
          logger.debug(`Received data-push update for ${source}`, { collectionName, docName, data });
          handler(data);
        }
      },
      (collectionName, docName, err) => {
        logger.error(`Received data-push error for ${source}`, { collectionName, docName, exception: err });
        addError(newError(`DATA-PUSH.${source}.UpdateError`, JSON.stringify(err)));
      })
    );
  } catch (err) {
    logger.error(`Failed to subscribe to data-push for ${source}`, { exception: err });
    addError(newError(`DATA-PUSH.${source}.SubscribeError`, JSON.stringify(err)));
  }
}

function removeHandler(source: string) {
  const unsubscribe = dataPushSubscriptions.get(source);
  dataPushSubscriptions.delete(source);
  unsubscribe && unsubscribe();
}

export function unsubscribeFromDataPush() {
  for (const unsubscribe of dataPushSubscriptions.values()) {
    unsubscribe();
  }
  dataPushSubscriptions.clear();
}

export function subscribeToDataPush(userId: string) {
  const handlers: DataPushHandler[] = [
    {
      source: '_ALL_/trips',
      handler: (_) => searchTrip(moment(), 30, true /* isBackgroundSearch */)
    },
    {
      source: `${userId}/myFriends`,
      handler: (_) => fetchFriends(),
    },
    {
      source: `${userId}/myTrips`,
      handler: (_) => fetchMyTrip(),
    },
    {
      source: `${userId}/myTripGroups`,
      handler: (_) => fetchMyTripGroups(),
    },
    {
      source: `${userId}/myChats`,
      handler: (_) => fetchMyPeopleChats(),
    },
    {
      source: `${userId}/myActivities`,
      handler: (_) => checkNewActivity(),
    }
  ];
  unsubscribeFromDataPush();
  handlers.forEach(({ source, handler }) => {
    addHandler({ source, handler });
  });
}

export function subscribeToTripUpdate(tripId: string) {
  addHandler({
    source: `_ALL_/trip:${tripId}`,
    handler: (_) => {
      fetchTrip(tripId);
    }
  }, true);
}

export function unsubscribeToTripUpdate(tripId: string) {
  removeHandler(`_ALL_/trip:${tripId}`);
}

export function subscribeToTripGroupUpdate(tripGroupId: string) {
  addHandler({
    source: `_ALL_/tripGroup:${tripGroupId}`,
    handler: (_) => {
      fetchTripGroup(tripGroupId);
    }
  }, true);
}

export function unsubscribeToTripGroupUpdate(tripGroupId: string) {
  removeHandler(`_ALL_/tripGroup:${tripGroupId}`);
}
