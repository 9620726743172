import moment from 'moment';
import { TripDetailDto } from './../services/openapi/models/TripDetailDto';
import { TripStatusDto } from './../services/openapi/models/TripStatusDto';

export function getTripRelativeTimeWithDateString(tripDateString: string) {
  return moment(tripDateString).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek:  function(time) {
      if(moment().isSame(time, 'week')) {
        return 'dddd';
      } else {
        return '[Next] dddd';
      }
    },
    lastDay: 'dddd, D MMMM, YYYY',
    lastWeek: 'dddd, D MMMM, YYYY',
    sameElse: 'dddd, D MMMM, YYYY'
  });
}

export default function getTripRelativeTime(trip: TripDetailDto | TripStatusDto) {
  return getTripRelativeTimeWithDateString(trip.date.split('T')[0]);
}
