import { IonIcon, IonItem, IonLabel, IonList, useIonToast } from "@ionic/react";
import { chevronForward, mailOutline, qrCodeOutline } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import React from "react";
import { getFriendStore } from '../../dataflow/stores/FriendStore';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { logger } from '../../logging/winston';
import { PreviewUserDto, Service } from "../../services/openapi";
import { getShareUrl, share } from '../../utils/share';
import DiscoverPeopleResultListItem from "./DiscoverPeopleResultListItem";

function shuffle(array: PreviewUserDto[]): PreviewUserDto[] {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [ array[currentIndex], array[randomIndex] ] = [ array[randomIndex], array[currentIndex] ];
  }

  return array;
}

const DiscoverPeopleInitial = observer(() => {
  const [ initSearchResult, setInitSearchResult ] = React.useState<PreviewUserDto[]>([]);
  const [ present, _dismiss ] = useIonToast();
  const { currentUser } = getUserStore();
  const { friends, isLoadingFriends } = getFriendStore();
  React.useEffect(() => {

    Service.usersControllerFind('').then((result) => {
      if (isLoadingFriends) {
        return;
      }
      const filteredResult = shuffle(result
        .filter((r) => r.id != currentUser?.id && r.completedSignup && !friends.find(f => f.friend.id == r.id))
      ).slice(0, 10);
      setInitSearchResult(filteredResult);
    }).catch((err) => {
      logger.error(err);
    });
  }, [ isLoadingFriends ]);

  const onShareClick = React.useCallback(async () => {
    let url = getShareUrl(`profile/${currentUser?.username}`, currentUser);
    if(!currentUser?.username) {
      url = getShareUrl(`profile/userId/${currentUser?.id}`, currentUser);
    }
    const shareText = `Be my friend on Fusend. ${url}`;
    share(shareText, {
      title: shareText,
      text: shareText,
      dialogTitle: shareText,
    }, () => present('Profile link copied', 2000));
  }, []);

  const initialComponent = initSearchResult.map((result) => {
    return (
      <DiscoverPeopleResultListItem key={result.id} user={result}/>
    );
  });

  return <>
    <IonList>
      <IonItem className="discoverPeopleShareListItem" onClick={onShareClick}>
        <div className="discoverPeopleItemAvatar">
          <IonIcon icon={mailOutline}/>
        </div>
        <IonLabel className="discoverPeopleItemText">
          <h3>Invite Friends to Fusend</h3>
          <p>Tell your friends to join Fusend</p>
        </IonLabel>
        <IonIcon className="discoverPeopleItemChevron" icon={chevronForward}/>
      </IonItem>
      <IonItem className="discoverPeopleShareListItem" hidden>
        <div className="discoverPeopleItemAvatar">
          <IonIcon icon={qrCodeOutline}/>
        </div>
        <IonLabel className="discoverPeopleItemText">
          <h3>Scan QR Code</h3>
          <p>Scan a friend’s QR Code</p>
        </IonLabel>
        <IonIcon className="discoverPeopleItemChevron" icon={chevronForward}/>
      </IonItem>
    </IonList>
    { initSearchResult.length > 0 &&
      <>
        <h2 className="suggestPeopleLabel">
          Suggested for you
        </h2>
        <IonList>
          {initialComponent}
        </IonList>
      </>
    }
  </>;
});

export default DiscoverPeopleInitial;