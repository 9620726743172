import firebase from 'firebase';
import { createStore } from 'satcheljs';

interface RemoteConfigStore {
  configs: { [key: string]: firebase.remoteConfig.Value };
}

export const getRemoteConfigStore = createStore<RemoteConfigStore>('RemoteConfigStore', { configs: {} });

export function setRemoteConfig(remoteConfig: { [key: string]: firebase.remoteConfig.Value }) {
  getRemoteConfigStore().configs = remoteConfig;
}

export function getRemoteConfig(featureName: string): firebase.remoteConfig.Value | undefined {
  return getRemoteConfigStore().configs[featureName];
}

export function isFeatureEnabled(featureName: string): boolean | undefined {
  return getRemoteConfigStore().configs[featureName]?.asBoolean();
}