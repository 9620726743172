import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonToolbar } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import QRCode from "react-qr-code";
import '../../pages/page.scss';
import './QRCodeModal.scss';

interface QRCodeModalProps {
  onDismiss: () => void;
  firstName: string;
  lastName: string;
  profileUrl: string;
  username: string;
  userId: string;
}

const QRCodeModal = observer((props: QRCodeModalProps) => {
  const { username, firstName, lastName, profileUrl, userId } = props;
  const url = `${process.env.REACT_APP_FUSEND_DOMAIN}/profile/${username}?referrer=${userId}`;
  return (
    <IonPage className="fusendPage">
      <IonHeader className="fusendPageHeader">
        <IonToolbar className="fusendPageToolbar">
          <IonButtons slot="start">
            <IonButton onClick={props.onDismiss}><IonIcon icon={chevronBack}/> Back</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="fusendPageContent qrCodeContent">
        <h4 className="qrCodeName">{firstName} {lastName}</h4>
        <p className="qrCodeUsername">@{username}</p>
        <div className="qrCodeWrapper">
          <QRCode value={url} size={220}/>
          <img className="qrCodeProfilePic" src={profileUrl}/>
        </div>
        <div className='qrCodeLogoWrapper'>
          <IonImg src='./assets/logo.png' className='qrCodeLogo'/>
          <div className='qrCodeLogoText'>
            <p>Follow me on Fusend!</p>
            <p>{"Let's go to snow trips together!"}</p>
            <h4>⛷️🏂</h4>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
});

export default QRCodeModal;