import { IonIcon } from "@ionic/react";
import { arrowUpOutline } from "ionicons/icons";
import { isFacebook, isUnsupportedEmbeddedBrowser, isWechat } from "../utils/platform";
import fbOpenBrowser from "./assets/facebook-open-with-browser.jpg";
import wechatOpenBrowser from "./assets/wechat-open-with-browser.jpg";
import "./OpenBrowser.scss";

export const OpenBrowser: React.FC = () => {
  if (!isUnsupportedEmbeddedBrowser()) {
    return <></>;
  }

  if (isWechat() || isFacebook()) {
    let instructionSection = (
      <div className="openBrowserImageWithInstruction">
        <div className="important">Then click Open with Browser</div>
        <img className="openBrwoserImage" src={wechatOpenBrowser} alt="Click Open with Browser" />
      </div>
    );
    if (isFacebook()) {
      instructionSection = (
        <div className="openBrowserImageWithInstruction">
          <div className="important">Then click Open with ...</div>
          <img className="openBrwoserImage" src={fbOpenBrowser} alt="Click Open with ..." />
        </div>
      );
    }

    return (
      <ul className="openBrowserBackdrop">
        <li className="openBrowserOption">
          <h1 className="h1">1.</h1>
          <div className="important">Click on the three dots</div>
          <IonIcon icon={arrowUpOutline} className="openBrowserIndicatorButton"></IonIcon>
        </li>
        <li className="openBrowserImageContainer">
          <h1 className="h1">2.</h1>
          {instructionSection}
        </li>
      </ul>
    );
  }

  return (
    <ul className="openBrowserBackdrop">
      <li className="openBrowserOption">
        <div className="important">You are using an unsupported browser. Please use a modern browser like Chrome to open this link or use our Fusend native app.</div>
      </li>
      <li className="openBrowserSad">
        <div className="important">💔😭</div>
      </li>
    </ul>
  );
};