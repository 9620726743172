import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonList, IonPage, IonSearchbar, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { fetchFriends } from '../../dataflow/orchestrators/FriendStoreOrchestrators';
import { fetchSentTripInvitations, inviteToTrip } from '../../dataflow/orchestrators/TripStoreOrchestrators';
import { findFriends, Friend as FriendType, getFriendStore } from '../../dataflow/stores/FriendStore';
import { getTripStore, isLoadingInvitationsForTrip } from '../../dataflow/stores/TripStore';
import { TripDetailDto, TripParticipantDto } from '../../services/openapi';
import { discoverPeoplePath, homeRedirectPath } from '../routes';
import './Friend.scss';
import { FriendCard } from './FriendCard';

interface FriendProps {
  history?: any,
  onDismiss?: () => void;
  tripDetail?: TripDetailDto;
}

export const Friend: React.FC<FriendProps> = observer((props: FriendProps) => {
  const { onDismiss, tripDetail, history: propHistory } = props;
  const history = useHistory() || propHistory;
  const [ isDirty, setIsDirty ] = React.useState<boolean>(false);
  const [ friendInviteSet, _setFriendInviteSet ] = useState<Set<string>>(new Set());
  const isTripInvite = !!tripDetail;
  const isLoadingInvitations = tripDetail && isLoadingInvitationsForTrip(tripDetail.id);
  const existingInvitations = tripDetail && getTripStore().sentTripInvitations.get(tripDetail.id);

  useEffect(() => {
    fetchFriends();
    if (tripDetail) {
      fetchSentTripInvitations(tripDetail.id);
    }
  } , []);

  const { friends } = getFriendStore();
  const [ keyword, setKeyword ] = useState('');
  const [ searchResult, setSearchResult ] = useState<FriendType[]>([]);

  const onSearch = useCallback((e: any) => {
    const keyword: string = e?.detail?.value || '';
    setKeyword(keyword);
    if (keyword) {
      setSearchResult(findFriends(keyword));
    } else {
      setSearchResult([]);
    }
  }, []);

  const friendCards = (keyword ? searchResult : friends)
    .map((friend) => {
      const isGoingToTrip = tripDetail?.participants?.friends?.some((participant) => {
        return participant.user.id == friend.id && participant.status == TripParticipantDto.status.GOING;
      });
      const isInvitedToTrip = existingInvitations && existingInvitations.find((i) => i.invitee.id == friend.id) != undefined;
      return { friend, isGoingToTrip, isInvitedToTrip };
    })
    .sort((a, b) => {
      if (a.isGoingToTrip && !b.isGoingToTrip) {
        return -1;
      } else if (!a.isGoingToTrip && b.isGoingToTrip) {
        return 1;
      }
      if (a.isInvitedToTrip && !b.isInvitedToTrip) {
        return -1;
      } else if (!a.isInvitedToTrip && b.isInvitedToTrip) {
        return 1;
      }
      return a.friend.friend.firstName.localeCompare(b.friend.friend.firstName);
    })
    .map(({ friend, isGoingToTrip, isInvitedToTrip }) => {
      return (
        <FriendCard
          key={friend.id}
          friend={friend}
          isTripInvite={isTripInvite}
          isGoing={isGoingToTrip}
          isInvited={isInvitedToTrip}
          onCheckboxChecked={(id: string, isChecked: boolean) => {
            if (isTripInvite) {
              if (isChecked) {
                friendInviteSet.add(id);
                setIsDirty(true);
              } else {
                friendInviteSet.delete(id);
                friendInviteSet.size == 0 && setIsDirty(false);
              }
            }
          }}
        />
      );
    });

  const onInviteFriend = () => {
    if (tripDetail) {
      inviteToTrip(tripDetail.id, Array.from(friendInviteSet), () => {
        onDismiss && onDismiss();
      });
    }
  };

  return (
    <IonPage className='fusendPage friendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            {onDismiss
              ? <IonButton onClick={onDismiss}>Cancel</IonButton>
              : <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
            }
          </IonButtons>
          <IonTitle className='fusendPageTitle' onClick={() => fetchFriends()}>
            { isTripInvite ? 'Invite Friends' : 'Friends' }
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        {
          friends.length > 0 ?
            <div className='friendPageContent'>
              <IonSearchbar className='friendPageSearchBar'
                placeholder='Search friend' type='text'
                debounce={300} onIonChange={onSearch} />
              {friendCards.length > 0 ?
                <IonList className='friendPageContentFriendList'>
                  {friendCards}
                </IonList> :
                <div className='friendPageContentNoFriend'>
                  <p>No friend found</p>
                  {keyword && (<IonButton color='secondary' onClick={() => setKeyword('')}>Clear Search</IonButton>)}
                </div>
              }
            </div> :
            <div className='friendPageContent empty'>
              <span>Fusend is better with friends!</span>
              <span>{`Discover people you may know${isTripInvite ? ' and invite them to the trip' : ''}!`}</span>
              {history &&
              <IonButton
                color='secondary'
                className='friendDiscoverPeopleButton'
                onClick={() => {
                  history.push(discoverPeoplePath);
                  onDismiss && onDismiss();
                }}>
                  Discovery People
              </IonButton>
              }
            </div>
        }
      </IonContent>
      {isDirty &&
        <IonFooter className='fusendPageFooter'>
          <IonButton
            color='secondary'
            className='friendInviteButton'
            onClick={onInviteFriend}
            disabled={isLoadingInvitations}
          >
            {isLoadingInvitations
              ? <IonSpinner name='crescent'/>
              : 'Invite'
            }
          </IonButton>
        </IonFooter>
      }
    </IonPage>
  );
});
