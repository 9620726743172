import moment from "moment";
import { getResortStore } from "../dataflow/stores/ResortStore";
import { getMyTrips, getTrips } from "../dataflow/stores/TripStore";
import { getUserFavoriteResortsIds } from "../dataflow/stores/UserStore";
import TripCalendarEvent from "../pages/Calendar/TripCalendarEvent";
import { ParticipantsDto, ResortConditionDto, ResortDto } from "../services/openapi";

export enum TripFilterButtonType {
  ALLTRIPS = "ALLTRIPS",
  MYTRIPS = "MYTRIPS",
  FRIENDSTRIPS = "FRIENDSTRIPS",
}

function getFilteredResorts(): ResortDto[] {
  const resortIds = (getResortStore().resortList || []);
  const favoriteResortsIds = getUserFavoriteResortsIds();
  if (favoriteResortsIds.length > 0) {
    return resortIds?.filter(resort => favoriteResortsIds.includes(resort.id));
  }
  return resortIds;
}

export function generateFriendTrips(conditions?: { [resortId: string]: { [date: string]: ResortConditionDto } }, pageRef?: React.MutableRefObject<any>): TripCalendarEvent[] {
  const result: TripCalendarEvent[] = [];
  const markedDays: any[] = [];
  const trips = getTrips();
  const filteredResortIds = getFilteredResorts().map(resort => resort.id);

  trips?.filter(trip => filteredResortIds.includes(trip.resort.id)).map((t) => {
    if(
      t.participants.friends.length > 0
    ) {
      const resortCondition = conditions? conditions[t.resort.id] : null;
      let resortConditionByDay = {};
      if(resortCondition && resortCondition[moment(t.date.split('T')[0]).format('YYYY/MM/DD')]) {
        resortConditionByDay = resortCondition[moment(t.date.split('T')[0]).format('YYYY/MM/DD')];
      }
      markedDays.push(
        {
          date: moment(t.date.split('T')[0]).toDate(),
          markCssClass: t.participants?.selfAttending == ParticipantsDto.selfAttending.GOING? 'goingMark': 'interestedMark'
        });
      result.push({
        id: `${t.resort.id}-${moment(t.date.split('T')[0]).format('YYYY/MM/DD')}`,
        title: t.resort.location.name,
        allDay: true,
        start: moment(t.date.split('T')[0]).toDate(),
        condition: resortConditionByDay,
        type: "trip",
        resortId: t.resort.id,
        pageRef
      });
    }
  });
  return result;
}

export function generateMyTrips(conditions?: { [resortId: string]: { [date: string]: ResortConditionDto } }, pageRef?: React.MutableRefObject<any>): TripCalendarEvent[] {
  const result: TripCalendarEvent[] = [];
  const markedDays: any[] = [];
  const myTrips = getMyTrips();
  const filteredResortIds = getFilteredResorts().map(resort => resort.id);

  myTrips?.filter(trip => filteredResortIds.includes(trip.resort.id)).map((t) => {
    if(
      t.participants?.selfAttending == ParticipantsDto.selfAttending.GOING ||
      t.participants?.selfAttending == ParticipantsDto.selfAttending.INTERESTED
    ) {
      const resortCondition = conditions? conditions[t.resort.id] : null;
      let resortConditionByDay = {};
      if(resortCondition && resortCondition[moment(t.date.split('T')[0]).format('YYYY/MM/DD')]) {
        resortConditionByDay = resortCondition[moment(t.date.split('T')[0]).format('YYYY/MM/DD')];
      }
      markedDays.push(
        {
          date: moment(t.date.split('T')[0]).toDate(),
          markCssClass: t.participants?.selfAttending == ParticipantsDto.selfAttending.GOING? 'goingMark': 'interestedMark'
        });
      result.push({
        id: `${t.resort.id}-${moment(t.date.split('T')[0]).format('YYYY/MM/DD')}`,
        title: t.resort.location.name,
        allDay: true,
        start: moment(t.date.split('T')[0]).toDate(),
        condition: resortConditionByDay,
        type: "trip",
        resortId: t.resort.id,
        pageRef
      });
    }
  });
  return result;
}

export function generateAllTrips(conditions?: { [resortId: string]: { [date: string]: ResortConditionDto } }, pageRef?: React.MutableRefObject<any>): TripCalendarEvent[] {
  const result = [];
  const filteredResort = getFilteredResorts();
  let day = moment();
  for(let i = 0; i < 20; i++) {
    if(filteredResort) {
      // hard code for now;
      for(let j = 0; j < filteredResort.length; j++) {
        const resort = filteredResort[j];
        if(!resort) break;
        const resortCondition = conditions? conditions[resort.id] : null;
        let resortConditionByDay = {};
        if(resortCondition && resortCondition[day.format('YYYY/MM/DD')]) {
          resortConditionByDay = resortCondition[day.format('YYYY/MM/DD')];
        }

        const tripDisplayId = `${resort.id}-${day.format('YYYY/MM/DD')}`;
        result.push({
          id: tripDisplayId,
          title: resort.location.name,
          allDay: true,
          start: day.toDate(),
          condition: resortConditionByDay,
          type: "trip",
          resortId: resort.id,
          pageRef
        });
      }
    }
    day = moment().add(i+1, 'd');
  }
  return result;
}

export function generateTrips(tripType: TripFilterButtonType, conditions?: { [resortId: string]: { [date: string]: ResortConditionDto } }, pageRef?: React.MutableRefObject<any>): TripCalendarEvent[]{
  switch(tripType) {
    case(TripFilterButtonType.MYTRIPS):
      return generateMyTrips(conditions, pageRef);
    case(TripFilterButtonType.FRIENDSTRIPS):
      return generateFriendTrips(conditions, pageRef);
    default:
      return generateAllTrips(conditions, pageRef);
  }
}