/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PreviewRideDto } from './PreviewRideDto';
import type { PreviewUserDto } from './PreviewUserDto';

export type PreviewJoinRequestDto = {
    id: string;
    ride: PreviewRideDto;
    state: PreviewJoinRequestDto.state;
    passenger: PreviewUserDto;
}

export namespace PreviewJoinRequestDto {

    export enum state {
        PENDING = 'Pending',
        ACCEPTED = 'Accepted',
        REJECTED = 'Rejected',
        CANCELLED = 'Cancelled',
    }


}
