import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { GoBack } from "../../components/navigation-header/GoBack";
import { legalPath } from "../routes";
import "./Legal.css";

export const Privacy: React.FC = () => {
  return (
    <IonPage className="legal-container">
      <GoBack title="Privacy" backUrl={legalPath}></GoBack>
      <IonContent>
        <iframe className="legal-content" src="https://app.termly.io/document/privacy-policy/2ea02e70-e93c-4228-9dd9-1e7208ab8b4f"></iframe>
      </IonContent>
    </IonPage>
  );
};