/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateFriendRequestDto = {
    status: UpdateFriendRequestDto.status;
    follow: boolean;
}

export namespace UpdateFriendRequestDto {

    export enum status {
        PENDING = 'Pending',
        REJECTED = 'Rejected',
        ACCEPTED = 'Accepted',
        CANCELLED = 'Cancelled',
    }


}
