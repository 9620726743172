import { logger } from '../logging/winston';

export type SimpleHandler = (args: string) => void;

export class SimpleRouter {
  private routingTable: { [key: string]: SimpleHandler } = {};

  public addRoute(key: string, handler: SimpleHandler): void {
    if (key in this.routingTable) {
      logger.error(`Handler for ${key} already exists`, { key });
    }
    this.routingTable[key] = handler;
  }

  public navigateTo(key: string, args: string): void {
    logger.debug('Navigating to', { key, args, routingTable: Object.keys(this.routingTable) });
    if (key in this.routingTable) {
      this.routingTable[key](args);
    } else {
      logger.error(`No handler for ${key}`, { key });
    }
  }
}
