import { createStore } from "satcheljs";
import { TripCarpoolDto } from "../../services/openapi";

export interface CarpoolStore {
  tripToCarpools: Map<string, TripCarpoolDto[]>;
}

export const getCarpoolStore = createStore<CarpoolStore>("CarpoolStore", {
  tripToCarpools: new Map<string, TripCarpoolDto[]>(),
});

export function getCarpoolsForTrip(tripId: string): TripCarpoolDto[] {
  return getCarpoolStore().tripToCarpools.get(tripId) || [];
}

export function getActiveCarpoolsForTrip(tripId: string): TripCarpoolDto[] {
  return getCarpoolStore().tripToCarpools.get(tripId)?.filter((intent) => intent.isActive) || [];
}

export function getCarpoolForTrip(tripId: string, intentId: string): TripCarpoolDto | undefined {
  return getCarpoolsForTrip(tripId).find(dto => dto.id == intentId);
}
