import { getPlatforms, IonButton, IonIcon, IonSelect, IonSelectOption, useIonAlert, useIonModal } from "@ionic/react";
import { checkmarkCircle, checkmarkCircleOutline, chevronDown } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import moment from "moment";
import { useHistory } from "react-router";
import { changeTripStatus, createTrip } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { getTripStore } from "../../dataflow/stores/TripStore";
import { logger } from "../../logging/winston";
import { Friend } from "../../pages/Friend/Friend";
import { CreateTripDto, ParticipantsDto, TripDetailDto } from "../../services/openapi";
import './TripStatusButtons.scss';

export enum TripStatusButtonLayout {
  Home,
  Detail
}

interface TripStatusButtonProps {
  date: string | undefined | null;
  resortId: string | undefined | null;
  isDetailPage?: boolean | undefined;
  tripId?: string;
  tripTitle?: string;
  pageRef?: React.MutableRefObject<any>;
  origin?: string;
  onJoiningTrip: (trip: TripDetailDto) => void;
  onLeavingTrip: () => void;
  buttonLayout: TripStatusButtonLayout
}

const TripStatusButtons = observer((props: TripStatusButtonProps) => {
  const [ presentConflictTrip ] = useIonAlert();
  const history = useHistory();
  const { date, resortId, tripId, isDetailPage, tripTitle } = props;
  const { tripDisplayMap } = getTripStore();
  const trips = [ ...tripDisplayMap.values() ];
  const tripDetailDto = trips.find((t) => t.id == tripId);
  const otherSameDayTrip = trips.find(t => tripDetailDto?.date == t.date && t.participants.selfAttending === ParticipantsDto.selfAttending.GOING);
  const myCurrentStatus = tripDetailDto?.participants?.selfAttending;
  const isMyGoingTrip = myCurrentStatus === ParticipantsDto.selfAttending.GOING;
  const isMyInterestedTrip = myCurrentStatus === ParticipantsDto.selfAttending.INTERESTED;

  const [ present, dismiss ] = useIonModal(Friend, {
    history,
    onDismiss: () => dismiss(),
    tripDetail: tripDetailDto,
  });

  const onInviteFriendsToTrip = (e: any) => {
    e.stopPropagation();
    if (tripDetailDto) {
      present({ presentingElement: props.pageRef?.current });
    } else if (date && resortId) {
      createTrip(date, resortId, () => {
        present();
      });
    }
  };

  const showJoinOptions = isMyGoingTrip || isMyInterestedTrip;
  const joinOptionValue = isMyGoingTrip
    ? CreateTripDto.participationStatus.GOING
    : isMyInterestedTrip ? CreateTripDto.participationStatus.INTERESTED : undefined;

  const onJoinButtonClicked = (e: any) => {
    if (showJoinOptions) {
      return; // click through to see join options
    }

    e.stopPropagation();
    if (date && resortId) {
      changeTripStatus(
        date,
        resortId,
        CreateTripDto.participationStatus.INTERESTED,
        trip => props.onJoiningTrip(trip));
    }
  };

  const getActionButtonListener = (status : CreateTripDto.participationStatus) => {
    return (e: any) => {
      e.stopPropagation();
      if (date && resortId) {
        changeTripStatus(
          date,
          resortId,
          status,
          trip => props.onJoiningTrip(trip));
      }
    };
  };

  const onJoinOptionChanged = (e: any) => {
    const option: CreateTripDto.participationStatus | undefined = e?.detail?.value;
    const updateTripGroup = (trip: TripDetailDto) => {
      if (option == CreateTripDto.participationStatus.GOING || option == CreateTripDto.participationStatus.INTERESTED) {
        props.onJoiningTrip(trip);
      } else {
        props.onLeavingTrip();
      }
    };
    if (date && resortId && option && option != joinOptionValue) {
      if (otherSameDayTrip && otherSameDayTrip.resort.id != resortId && option == CreateTripDto.participationStatus.GOING) {
        logger.info('Found another trip on the same day', { otherSameDayTrip });
        presentConflictTrip({
          header: 'You are in another trip',
          // subHeader: `with ${otherSameDayTrip.resort.location.name}`,
          message: `Joining the ${tripDetailDto?.resort.location.name} trip will remove you from the ${otherSameDayTrip.resort.location.name} trip.`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                logger.info('User decided to stay in the other trip');
              }
            },
            {
              text: 'Join',
              role: 'confirm',
              handler: () => {
                logger.info('User decided to join this trip, will quit the other trip');
                changeTripStatus(
                  date,
                  otherSameDayTrip.resort.id,
                  CreateTripDto.participationStatus.INTERESTED);
                changeTripStatus(
                  date,
                  resortId,
                  option,
                  trip => updateTripGroup(trip));
              }
            }
          ],
        });
      } else {
        changeTripStatus(
          date,
          resortId,
          option,
          trip => updateTripGroup(trip));
      }
    }
  };

  const emptyInterested = <>
    <img className='tripActionStartIcon' src='/assets/icon/star.svg' />
    Interested
  </>;

  const interested =  <>
    <img className='tripActionStartIcon' src='/assets/icon/starFilled.svg' />
    Interested
  </>;

  const emptyGoing = <>
    <IonIcon className='tripActionStartIcon' size='small' icon={checkmarkCircleOutline} />
    Going
  </>;

  const going = <>
    <IonIcon className='tripActionStartIcon' size='small' icon={checkmarkCircle} />
    Going
  </>;


  let joinActionDisplay = emptyInterested;
  if (isMyGoingTrip) {
    joinActionDisplay = going;
  } else if (isMyInterestedTrip) {
    joinActionDisplay = interested;
  }

  const selectInterface = getPlatforms().includes('ios') ? 'action-sheet' : 'alert';
  const selectInterfaceOptions = {
    cssClass: 'joinOptionSelect',
    header: tripTitle,
    subHeader: moment(date).format('dddd, MMMM Do'),
  };
  const tripJoinOptions = [
    [ CreateTripDto.participationStatus.INTERESTED, 'Interested' ],
    [ CreateTripDto.participationStatus.GOING, 'Going' ],
    [ CreateTripDto.participationStatus.NOT_GOING, 'Not interested' ],
  ];
  if(props.buttonLayout == TripStatusButtonLayout.Detail) {
    return (
      <div className={`tripActionBar ${isDetailPage && 'detailPageActionBar'}`}>
        <IonButton
          className={`tripActionBarItem tripActionJoin
                    ${(isMyGoingTrip || isMyInterestedTrip) ? ' tripActionJoinInTrip ': ''}
                    ${isMyGoingTrip ? 'tripActionJoinSmall' : 'tripActionJoinLarge'}`}
          onClick={onJoinButtonClicked}>
          {joinActionDisplay}
          {showJoinOptions && <IonIcon className="tripActionEndIcon" icon={chevronDown} />}
          {showJoinOptions &&
          <IonSelect
            interface={selectInterface} interfaceOptions={selectInterfaceOptions}
            onClick={(e) => e.stopPropagation()} onIonChange={onJoinOptionChanged} value={joinOptionValue}>
            {tripJoinOptions.map(([ status, label ], i) => (
              <IonSelectOption className={`tripJoinOption ${joinOptionValue == status ? 'tripJoinOptionActive' : ''}`} value={status} key={i}>{label}</IonSelectOption>
            ))}
          </IonSelect>
          }
        </IonButton>
        <IonButton
          className={`tripActionBarItem tripActionInvite
                    ${isMyGoingTrip ? ' tripActionInviteLarge' : ' tripActionInviteSmall'}`}
          onClick={onInviteFriendsToTrip}>
          <img className='tripActionStartIcon' src='/assets/icon/invite.svg' />
          Invite
        </IonButton>
      </div>
    );
  } else {
    return (<div className={`tripActionBar ${isDetailPage && 'detailPageActionBar'}`}>
      <IonButton
        className={`tripActionBarItem tripActionJoin
                  ${isMyInterestedTrip ? ' tripActionJoinInTrip ': ''}`}
        onClick={getActionButtonListener(CreateTripDto.participationStatus.INTERESTED)}>
        {isMyInterestedTrip ? interested : emptyInterested}
      </IonButton>
      <IonButton
        className={`tripActionBarItem tripActionJoin ${isMyGoingTrip ? ' tripActionJoinInTrip ': ''}`}
        onClick={getActionButtonListener(CreateTripDto.participationStatus.GOING)}>
        {isMyGoingTrip ? going : emptyGoing}
      </IonButton>
    </div>);
  }
});

export default TripStatusButtons;