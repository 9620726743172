import { MbscCalendarEvent } from "@mobiscroll/react";
import moment from "moment";
import { useHistory } from "react-router";
import TripStatusButtons, { TripStatusButtonLayout } from "../../components/TripStatusButtons/TripStatusButtons";
import WeatherIcon from "../../components/WeatherIcon/WeatherIcon";
import { getTripStore } from "../../dataflow/stores/TripStore";
import { ParticipantsDto, ResortConditionDto, TripDetailDto, WeatherForecastDto } from "../../services/openapi";
import getTripContentElement from "../../utils/getTripContentElement";
import CarpoolLobby from "../TripDetail/CarpoolLobby";
import { getTripDetailWithIdPath, getTripDetailWithoutIdPath } from "../routes";
import "./Trip.scss";
import TripLoader from "./TripLoader";

interface TripProps {
  tripDetail?: MbscCalendarEvent;
}

function getWeatherByHour(WeatherForecastDto: WeatherForecastDto[], hour: number): WeatherForecastDto | undefined {
  return WeatherForecastDto.find((weather) => moment(weather.startTime).hour() === hour);
}

const Trip = (props: TripProps) => {
  const history = useHistory();
  const { tripDetail } = props;
  const pageRef = tripDetail?.pageRef;
  const tripDetailDto: TripDetailDto | undefined = getTripStore().tripDisplayMap.get(tripDetail?.id as string);
  const date = moment(tripDetail?.start).format('YYYY/MM/DD');
  const resortId = tripDetail?.resortId;

  const others = tripDetailDto?.participants.others || [];
  const friends = tripDetailDto?.participants.friends || [];
  const isMyGoingTrip = tripDetailDto?.participants?.selfAttending === ParticipantsDto.selfAttending.GOING;
  const isMyInterestedTrip = tripDetailDto?.participants?.selfAttending === ParticipantsDto.selfAttending.INTERESTED;
  const tripDetailUrl = tripDetail?.tripObj
    ? getTripDetailWithIdPath(tripDetail.tripObj.id)
    : getTripDetailWithoutIdPath(moment(tripDetail?.start).format('YYYY/MM/DD'), tripDetail?.resortId);
  const condition: ResortConditionDto = tripDetail?.condition;
  const weatherForecasts = condition?.hourlyWeatherForecasts;
  const shouldShowCondition = weatherForecasts && Array.isArray(weatherForecasts);
  const isToday = moment(tripDetail?.start).format('YYYY/MM/DD') == moment().format('YYYY/MM/DD');
  let resortConditionByDate = null;
  let resortTemp = null;
  if (shouldShowCondition) {
    let weatherForecastOfTheDay = weatherForecasts[weatherForecasts.length / 2];

    if (isToday) {
      // try get current hour weather
      weatherForecastOfTheDay = getWeatherByHour(weatherForecasts, moment().hour()) || weatherForecastOfTheDay;
    }
    else {
      // try get 9am weather
      weatherForecastOfTheDay = getWeatherByHour(weatherForecasts, 9) || weatherForecastOfTheDay;
    }
    resortConditionByDate = weatherForecastOfTheDay.weatherCode;
    resortTemp = weatherForecastOfTheDay.temperatureF;
  }

  const handleTripClick = () => {
    history.push(tripDetailUrl);
  };

  if (tripDetail?.type == "loader") {
    return (
      <>
        <TripLoader />
      </>
    );
  } else if (tripDetail?.type == "trip") {
    return (
      <div onClick={handleTripClick} className='tripWrapper'>
        <div>
          <div className="tripTitleContainer">
            <h4 className="tripTitle">{tripDetail.title}
              {isMyGoingTrip &&
                <span className="myTripBadge myTripJoinBadge">Going</span>
              }
              {isMyInterestedTrip &&
                <span className="myTripBadge myTripInterestedBadge">Interested</span>
              }
            </h4>
            {resortConditionByDate &&
              <p className="tripWeather">
                <WeatherIcon condition={resortConditionByDate} />
              </p>
            }
          </div>
        </div>
        <div className="tripContent">
          {resortTemp && (isMyGoingTrip || isMyInterestedTrip) &&
            <p className="tripTemp">{resortTemp} °F</p>
          }
          {getTripContentElement(friends, others, (isMyGoingTrip || isMyInterestedTrip))}
        </div>
        {tripDetailDto && <CarpoolLobby tripId={tripDetailDto?.id} isHome={true}/>}
        <div className="tripActionBarContainer">
          <TripStatusButtons
            date={date || tripDetailDto?.date}
            resortId={resortId || tripDetailDto?.resort.id}
            tripId={tripDetailDto?.id}
            tripTitle={tripDetail.title}
            pageRef={pageRef}
            onJoiningTrip={() => {}}
            onLeavingTrip={() => {}}
            buttonLayout={TripStatusButtonLayout.Home}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default Trip;
