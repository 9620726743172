import { IonButton, IonItem } from '@ionic/react';
import { observer } from 'mobx-react-latest';
import { useEffect, useRef, useState } from 'react';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { fetchTripGroupsOfTrip } from '../../dataflow/orchestrators/TripStoreOrchestrators';
import { getTripRideGroups } from '../../dataflow/stores/TripStore';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { TripGroupDetailDto } from '../../services/openapi';
import { RequestToJoinTripGroup, RequestToJoinTripGroupParam } from '../TripGroup/RequestToJoinTripGroup';
import { ReactComponent as SteeringWheel } from './icon/SteeringWheel.svg';

interface CarpoolCardProps {
  group: TripGroupDetailDto;
  currentUserId: string | undefined;
  openCarpoolDetail?: (tripId: string, tripGroupId: string) => void;
  isChatCard?: boolean;
}

export const CarpoolCard = (props: CarpoolCardProps) => {
  const { group, currentUserId, openCarpoolDetail, isChatCard } = props;
  const title= group.title;
  const notes= group.notes;
  const users= group.members.map((m) => m.user);
  const spotLeft= group.rideOptions ? group.rideOptions.maxSize - group.members.length : 0;
  const partOf= group.members.some((m) => m.user.id == currentUserId);
  const isFull = spotLeft == 0;

  const avatars = users.map((user) =>
    <ProfilePicture key={user.id} className='carpoolCardMemberProfile' user={user} overrideDefaultClick={false} />
  );
  const emptySpots = [];
  for (let index = 0; index < spotLeft; index++) {
    emptySpots.push(
      <span key={index} className='emptySpot'></span>
    );
  }

  return (
    <IonItem className='carpoolCard' button onClick={() => openCarpoolDetail?.(group.trip.id, group.id)}>
      <div className='carpoolCardContent'>
        {isChatCard &&
        <div className='chatCardTitle'>
          <div className='roleLabel'><SteeringWheel className='roleLabelIcon' />I can take passengers</div>
        </div>
        }
        <div className='carpoolCardTitleSection'>
          <div className='carpoolCardTitle'>{title}</div>
          <div className='carpoolLabelGroup'>
            {partOf && <div className='mineLabel'>My Carpool</div>}
            {isFull && <div className='fullLabel'>Full</div>}
          </div>
        </div>
        <div className='carpoolCardNotes'>{notes}</div>
        <div className='carpoolCardParticipantsSection'>
          {avatars}
          {emptySpots}
          <span className='carpoolCardSpotLeft'>{`${spotLeft} ${spotLeft <= 1 ? 'spot' : 'spots'} left`}</span>
        </div>
      </div>
    </IonItem>
  );
};

interface CarpoolsProps {
  tripId: string;
}

export const Carpools = observer((props: CarpoolsProps) => {
  const { tripId } = props;
  const { currentUser } = getUserStore();
  const [ showAll, setShowAll ] = useState(false);
  const [ currentGroup, setCurrentGroup ] = useState<RequestToJoinTripGroupParam | null>(null);
  const modal = useRef<HTMLIonModalElement>(null);

  const openCarpoolDetail = (tripId: string, tripGroupId: string) => {
    setCurrentGroup({ tripId, tripGroupId });
    modal.current?.present();
  };


  useEffect(() => {
    fetchTripGroupsOfTrip(tripId);
  }, []);

  const rideGroups = getTripRideGroups(tripId);
  const carpoolCards = rideGroups.map((group) =>
    <CarpoolCard
      key={group.id}
      group={group}
      currentUserId={currentUser?.id}
      openCarpoolDetail={openCarpoolDetail}
    />
  );

  return (
    <div className='carpoolLobbySection'>
      <div className='carpoolLobbyTitleWrapper'>
        <div className='carpoolLobbyTitle'>Looking for passenger</div>
        {rideGroups.length > 3 && <IonButton fill="clear" onClick={() => setShowAll(!showAll)}>{showAll ? 'Collapse' : `View All (${rideGroups.length})`} </IonButton>}
      </div>
      <div className='previews'>
        {rideGroups.length > 0 && (showAll ? carpoolCards : carpoolCards.slice(0, 3))}
        {rideGroups.length === 0 && <div className="noIntents">No one is looking for a carpool.</div>}
      </div>
      <RequestToJoinTripGroup
        tripId={currentGroup?.tripId}
        tripGroupId={currentGroup?.tripGroupId}
        modal={modal} />
    </div>
  );
});