import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { logger } from '../logging/winston';

export class Apple {
  public static async login(): Promise<string> {
    const options: SignInWithAppleOptions = {
      clientId: 'com.fusend.snoqualmiepass.signin', // https://developer.apple.com/account/resources/identifiers/serviceId/edit/M8G95N25TD
      redirectURI: window.location.origin,
      scopes: 'email',
      nonce: 'nonce',
    };
    const resp = await SignInWithApple.authorize(options);
    logger.info('Apple login succeeded');
    return resp.response.identityToken;
  }
}
