import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { GoBack } from "../../components/navigation-header/GoBack";
import { legalPath } from "../routes";
import "./Legal.css";

export const Disclaimer: React.FC = () => {
  return (
    <IonPage className="legal-container">
      <GoBack title="Disclaimer" backUrl={legalPath}></GoBack>
      <IonContent>
        <iframe className="legal-content" src="https://app.termly.io/document/disclaimer/11e2f327-595f-4f7b-89fd-fe4245bebc9d"></iframe>
      </IonContent>
    </IonPage>
  );
};