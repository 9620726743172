import moment from "moment";
import TripCalendarEvent from "./TripCalendarEvent";

const TripLoader = () => {
  return (
    <div className="TripLoaderWrapper">
    </div>
  );
};

export default TripLoader;

export function getTripLoaderEvents(): TripCalendarEvent[] {
  const events = [];
  let day = moment();
  for(let i = 1; i < 7; i ++) {
    for(let j = 0; j < 3; j ++) {
      events.push({
        allDay: true,
        start: day.toDate(),
        type: "loader"
      });
    }
    day = moment().add(i, 'd');
  }
  return events;
}