import { mutatorAction } from 'satcheljs';
import { PreviewRideDto, RideDetailDto } from '../../services/openapi';
import { getRideStore } from '../stores/RideStore';
import { removeFromList, upsert, upsertLoadedResult } from '../utils/utils';

export const setMyRides = mutatorAction('SET_MY_RIDES', (rides: RideDetailDto[]) => {
  getRideStore().myRides = rides;
});

export const upsertSearchedRides = mutatorAction('UPSERT_SEARCHED_RIDES', (rides: PreviewRideDto[]) => {
  const { searchResults } = getRideStore();
  upsertLoadedResult(searchResults, rides);
});

export const addRide = mutatorAction('ADD_RIDE', (ride: RideDetailDto) => {
  const { myRides, searchResults } = getRideStore();
  upsert(myRides, ride);
  upsert(searchResults, ride);
});

export const updateRide = mutatorAction('UPDATE_RIDE', (ride: RideDetailDto) => {
  const { myRides } = getRideStore();
  upsert(myRides, ride);
});

export const updateRidePreview = mutatorAction('UPDATE_RIDE_PREVIEW', (ride: PreviewRideDto) => {
  const { searchResults } = getRideStore();
  upsert(searchResults, ride);
});

export const removeRide = mutatorAction('REMOVE_RIDE', (rideId: string) => {
  const { myRides } = getRideStore();
  removeFromList(myRides, rideId);
});

export const removeRidePreview = mutatorAction('REMOVE_RIDE_PREVIEW', (rideId: string) => {
  const { searchResults } = getRideStore();
  removeFromList(searchResults, rideId);
});

export const setMyRideLoading = mutatorAction('SET_MY_RIDES_LOADING', () => {
  getRideStore().loadingMyRides = true;
});

export const unsetMyRideLoading = mutatorAction('UNSET_MY_RIDES_LOADING', () => {
  getRideStore().loadingMyRides = false;
});

export const setSearchResultsLoading = mutatorAction('SET_SEARCH_RESULTS_LOADING', () => {
  getRideStore().loadingSearchResults = true;
});

export const unsetSearchResultsLoading = mutatorAction('UNSET_SEARCH_RESULTS_LOADING', () => {
  getRideStore().loadingSearchResults = false;
});

export const setRideLoading = mutatorAction('SET_RIDE_LOADING', (rideId: string) => {
  getRideStore().loadingRide.add(rideId);
});

export const unsetRideLoading = mutatorAction('UNSET_RIDE_LOADING', (rideId: string) => {
  getRideStore().loadingRide.delete(rideId);
});
