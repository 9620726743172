/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ActivityDto = {
    id: string;
    type: ActivityDto.type;
    arguments: string;
    title: string;
    body: string;
    timestamp: string;
}

export namespace ActivityDto {

    export enum type {
        FRIEND_REQUEST_NEW = 'friend.request.new',
        FRIEND_REQUEST_ACCEPTED = 'friend.request.accepted',
        CHAT_GROUP_MESSAGE = 'chat.group.message',
        CHAT_RIDE_MESSAGE = 'chat.ride.message',
        CHAT_PEOPLE_MESSAGE = 'chat.people.message',
        RIDE_REQUEST_NEW = 'ride.request.new',
        RIDE_REQUEST_UPDATED = 'ride.request.updated',
        RIDE_UPDATED = 'ride.updated',
        TRIP_FRIEND_GOING = 'trip.friend.going',
        TRIP_FRIEND_INTERESTED = 'trip.friend.interested',
        TRIP_INVITEE_GOING = 'trip.invitee.going',
        TRIP_INVITEE_INTERESTED = 'trip.invitee.interested',
        TRIP_INVITE_NEW = 'trip.invite.new',
        TRIP_CARPOOL_UPDATED = 'trip.carpool.updated',
        TRIP_GROUP_NEW = 'trip.group.new',
    }


}
