import ProfilePicture from "../components/ProfilePicture/ProfilePicture";
import { TripParticipantDto } from "../services/openapi";

export default function getTripContentElement(
  friends: TripParticipantDto[] | undefined,
  others: TripParticipantDto[] | undefined,
  isMyTrip: boolean
) {
  if (friends && friends.length > 0 || others && others?.length > 0) {
    let participantsList: TripParticipantDto[] = []; 
    participantsList = friends ? participantsList.concat(friends) : participantsList;
    participantsList = others ? participantsList.concat(others) : participantsList;
    return (
      <div className={`tripMember ${isMyTrip && 'myTripMember'}`}>
        {participantsList.length > 0 && <span><b>{participantsList.length} people</b> interested</span> }
        <span>{participantsList.slice(0, 7).map((f) => <ProfilePicture className="tripCardProfilePic" key={f.id} user={f.user} overrideDefaultClick={true}/>)}</span>
      </div>
    );
  }

  if((!others && !friends) || friends && friends.length == 0 && others && others.length == 0){
    if (isMyTrip) {
      return (
        <div className={`tripMember ${isMyTrip && 'myTripMember'}`}>
          <span><b>Invite friends</b> to go with you</span>
        </div>
      );
    } else {
      return (
        <div className={`tripMember ${isMyTrip && 'myTripMember'}`}>
          <span><b>Join trip</b> and <b>invite friends</b> to go with you</span>
        </div>
      );
    }
  }
}