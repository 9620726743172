import { IonButton, IonButtons, IonSpinner, useIonAlert } from '@ionic/react';
import { observer } from 'mobx-react-latest';
import React from 'react';
import { useHistory } from 'react-router';
import { createFriendChat } from '../../../dataflow/orchestrators/ChatStoreOrchestrators';
import { toggleFollowFriend } from '../../../dataflow/orchestrators/FriendStoreOrchestrators';
import { getChatRoomPath } from '../../routes';

interface ProfileHeaderButtonsFriendProps {
  userId: string;
  firstName: string;
  isFollowing: boolean;
  onSubmit: () => void;
}

const ProfileHeaderButtonsFriend: React.FC<ProfileHeaderButtonsFriendProps> = observer((props: ProfileHeaderButtonsFriendProps) => {
  const history = useHistory();
  const [ loading, setLoading ] = React.useState<boolean> (false);
  const [ presentAlert ] = useIonAlert();
  const toggleFollowing = () => {
    setLoading(true);
    toggleFollowFriend(props.userId, () => {
      props.onSubmit();
      setLoading(false);
    });
  };
  const onFollowClick = () => {
    if(props.isFollowing) {
      presentAlert({
        header: 'Alert',
        message: `Are you sure you want to unfollow ${props.firstName}?`,
        buttons: [
          'Cancel',
          {
            text: 'Confirm',
            role: 'confirm',
            handler: toggleFollowing
          }
        ],
      });
    } else {
      toggleFollowing();
    }
  };
  const onMessageClick = () => {
    createFriendChat(
      props.userId,
      (chatId) => {
        history.push(getChatRoomPath(chatId));
      });
  };

  return (
    <IonButtons>
      <IonButton color='secondary' fill='solid' onClick={onFollowClick}>{loading
        ? <IonSpinner name='crescent'/>
        : props.isFollowing ? 'Following': 'Follow'}</IonButton>
      <IonButton color='secondary' fill='solid' onClick={onMessageClick}>Message</IonButton>
    </IonButtons>
  );
});

export default ProfileHeaderButtonsFriend;
