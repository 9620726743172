/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PreviewUserDto } from './PreviewUserDto';

export type TripParticipantDto = {
    id: string;
    user: PreviewUserDto;
    tripId: string;
    status: TripParticipantDto.status;
}

export namespace TripParticipantDto {

    export enum status {
        GOING = 'Going',
        INTERESTED = 'Interested',
        NOT_GOING = 'NotGoing',
    }


}
