import { action, orchestrator } from 'satcheljs';
import { logger } from '../../logging/winston';
import { ActivityDto, CancelablePromise, Service, UserDto } from '../../services/openapi';
import { setActivitiesCaughtUpTimestamp, setActivitiesLoading, unsetActivitiesLoading, upsertActivities } from '../mutators/ActivityStoreMutators';
import { addError } from '../mutators/ErrorStoreMutators';
import { getActivityStore } from '../stores/ActivityStore';
import { newError } from '../stores/ErrorStore';
import { makeAsyncRequest } from '../utils/utils';

const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
const CATCH_UP_ALL_ACTIVITIES = 'CATCH_UP_ALL_ACTIVITIES';

export const fetchActivities = action(FETCH_ACTIVITIES, (start: Date, end?: Date | undefined) => ({ start, end }));
export const catchUpAllActivities = action(CATCH_UP_ALL_ACTIVITIES);

let loadActivitiesPromise: CancelablePromise<ActivityDto[]> | undefined = undefined;

orchestrator(fetchActivities, async({ start, end }: { start: Date, end: Date | undefined }) => {
  try {
    const { request, response } = await makeAsyncRequest(
      loadActivitiesPromise,
      () => Promise.resolve(() => Service.meControllerListActivities(start.toISOString(), end == undefined ? '': end.toISOString())),
      () => setActivitiesLoading(),
      () => unsetActivitiesLoading());
    loadActivitiesPromise = request;

    const activities = await response;
    upsertActivities(activities);

  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to fetch activities. Service request error.`, { exception, start, end });
      addError(newError(`${FETCH_ACTIVITIES}.ServiceError`, `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

export function checkNewActivity() {
  const { caughtUpTimestamp } = getActivityStore();
  const start = caughtUpTimestamp || new Date(2022, 0, 1); // nothing happened before 2022/1/1
  fetchActivities(start);
}

orchestrator(catchUpAllActivities, () => {
  try {
    const { activities } = getActivityStore();
    const maxActivityTimestamp = activities.reduce((max, activity) => {
      const activityTimestamp = new Date(activity.timestamp);
      return activityTimestamp.getTime() > max.getTime() ? activityTimestamp : max;
    } , new Date(0));
    Service.meControllerUpdate({ activityCaughtUpTimestamp: maxActivityTimestamp.toISOString() } as UserDto);
    setActivitiesCaughtUpTimestamp(maxActivityTimestamp);
  } catch (exception: any) {
    logger.error('Failed to update activity caught up timestamp', { exception: exception.toString() });
    addError(newError(`${CATCH_UP_ALL_ACTIVITIES}.ServiceError`, `${exception.toString()} ${JSON.stringify(exception)}`));
  }
});
