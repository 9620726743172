import _ from "lodash";
import { observer } from "mobx-react-latest";
import React from "react";
import { useHistory } from "react-router";
import colors from '../../data/CarColors.json';
import roofAccessoryTypes from '../../data/RoofAccessoryTypes.json';
import tireTypes from '../../data/TireTypes.json';
import { updateUser } from "../../dataflow/orchestrators/UserStoreOrchestrators";
import { getUserStore } from "../../dataflow/stores/UserStore";
import { CarDto } from "../../services/openapi";
import { ValueType } from "../Shared/EditableValue";
import { SelectOption } from "../Shared/MultiSelectExpanded";
import { loginPath, myProfilePath } from "../routes";
import { EditableDetail } from "./EditableDetail";

export const MyVehicle: React.FC = observer(() => {
  const user = getUserStore().currentUser;
  const isLoadingUser = getUserStore().loading;
  const history = useHistory();

  if (!user) {
    history.push(loginPath(myProfilePath, window.location.search));
    return <div />;
  }

  // update this once UI design for multiple vehicles is designed
  const firstCar = (user.cars?.length || 0) >= 1 ? user.cars[0] : {} as CarDto;
  const [ car, setCar ] = React.useState(_.cloneDeep(firstCar));
  const tireDisplayMapping = new Map<string, string>();
  const tireIdMapping = new Map<string, string>();
  tireTypes.forEach(t => tireDisplayMapping.set(t.displayText, t.id));
  tireTypes.forEach(t => tireIdMapping.set(t.id, t.displayText));
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const getSelectOptions = <T,>(option: T, index: number) => ({
    label: option,
    value: option,
    index
  });

  const editableItems = [
    {
      title: "Model",
      value: car.model,
      editableDetail: {
        type: ValueType.String,
        onChange: (value: string) => setCar({ ...car, model: value }),
      }
    },
    {
      title: "Make",
      value: car.make,
      editableDetail: {
        type: ValueType.String,
        onChange: (value: string) => setCar({ ...car, make: value }),
      }
    },
    {
      title: "Year",
      value: car.year?.toString(),
      editableDetail: {
        type: ValueType.Select,
        selectOptions: {
          options: Array.from(Array(new Date().getFullYear() - 2000 + 2).keys()).map(y => (y + 2000).toString()).reverse().map(getSelectOptions),
          onChange: (value: SelectOption) => {
            let year = parseInt(value.value);
            if (year < 2000 || year >= new Date().getFullYear() + 1) {
              year = new Date().getFullYear() + 1;
            }
            setCar({ ...car, year: year });
          },
        }  
      }
    },
    {
      title: "Color",
      value: car.color,
      editableDetail: {
        type: ValueType.Select,
        selectOptions: {
          options: colors.map(getSelectOptions),
          onChange: (value: SelectOption) => setCar({ ...car, color: value.value }),
        }
      }
    },
    {
      title: "License Plate",
      value: car.licensePlate,
      editableDetail: {
        type: ValueType.String,
        onChange: (value: string) => setCar({ ...car, licensePlate: value }),
      }
    },
    {
      title: "Roof Accessory",
      value: car.roofAccessory || 'None',
      editableDetail: {
        type: ValueType.Select,
        selectOptions: {
          options: roofAccessoryTypes.map(getSelectOptions),
          onChange: (value: SelectOption) => setCar({ ...car, roofAccessory: (value.value == 'None' ? undefined : value.value) as any }),
        }
      }
    },
    {
      title: "Tire",
      value: tireIdMapping.get(car.tire) || 'All Season Mud Snow',
      editableDetail: {
        type: ValueType.Select,
        selectOptions: {
          options: tireTypes.map(tire => tire.displayText).map(getSelectOptions),
          onChange: (value: SelectOption) => setCar({ ...car, tire: tireDisplayMapping.get(value.value) as any }),
        }
      }
    },
    {
      title: "Has Chains",
      value: car.chains ? "Yes" : "No",
      editableDetail: {
        type: ValueType.Select,
        selectOptions: {
          onChange: (value: SelectOption) => setCar({ ...car, chains: value.value == "Yes" }),
          options: [ "Yes", "No" ].map(getSelectOptions)
        }
      }
    }
  ];
  const onSave = () => {
    updateUser({ cars: [ car ] });
  };
  return <EditableDetail pageTitle="My Vehicle" editableItems={editableItems} onSave={onSave} isSaving={isLoadingUser}/>;
});