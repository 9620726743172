import { IonBackButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import { getSortedChatList } from '../../dataflow/stores/ChatStore';
import { homeRedirectPath } from '../routes';
import './Chat.scss';
import { ChatCard } from './ChatCard';

const Chat: React.FC = observer(() => {
  const chats = getSortedChatList();

  const chatList = chats.get()
    .map((chat) => {
      return (
        <ChatCard key={chat.id} chat={chat} />
      );
    });

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className="fusendPageToolbar">
          <IonButtons slot="start">
            <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
          </IonButtons>
          <IonTitle className='fusendPageTitle'>
            Chat
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="fusendPageContent">
        {
          chatList.length > 0 ?
            <IonList className="chatListContainer">{chatList}</IonList> :
            <div className="chatListEmpty">
              <img className="chatListEmptyIcon" src="/assets/icon/chat.svg"/>
              <p className="chatListEmptyMessageMain">You don&apos;t have any chat yet</p>
              <p className="chatListEmptyMessageSecondary">Start by messaging a friend, exploring trip chat, or joining a group.</p>
            </div>
        }
      </IonContent>
    </IonPage>
  );
});

export default Chat;
