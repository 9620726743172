import { ActionMessage, applyMiddleware, DispatchFunction } from 'satcheljs';
import { logger } from '../logging/winston';

function traceMiddleware(next: DispatchFunction, actionMessage: ActionMessage) {
  logger.debug(`[dataflow] dispatching action ${actionMessage.type}`);
  next(actionMessage);
}

export function addDataflowLogging() {
  applyMiddleware(traceMiddleware);
}