import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isPlatform } from '@ionic/react';
import { logger } from '../logging/winston';

export class Google {
  public static async logout(): Promise<void> {
    if (isPlatform('capacitor')) {
      return await GoogleAuth.signOut().catch((error) => {
        logger.warn('Failed to logout from Google', { exception: error });
      });
    }
  }
}
