import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

interface MenuItemProps {
  icon: string;
  title: string;
  onClick: () => void;
  hasNextLevel: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({ icon, title, onClick, hasNextLevel }: MenuItemProps) => {
  return (
    <IonGrid className="menu-item" onClick={onClick}>
      <IonRow className="menu-row">
        <IonCol className="menu-icon">
          <IonIcon icon={icon} />
        </IonCol>
        <IonCol>
          <h3 className="menu-title">{title}</h3>
        </IonCol>
        {
          hasNextLevel &&
          <IonCol className="menu-icon">
            <IonIcon icon={chevronForwardOutline} />
          </IonCol>
        }
      </IonRow>
    </IonGrid>
  );
};