import { observer } from "mobx-react-latest";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchResortRoadCondition } from "../../dataflow/orchestrators/ResortConditionOrchestrators";
import { getResortRoadCondition } from "../../dataflow/stores/ResortConditionStore";
import { ResortRoadConditionDto } from "../../services/openapi";

interface RoadConditionProps {
  resortId: string;
}

const RoadCondition = observer((props: RoadConditionProps) => {
  const conditions = getResortRoadCondition(props.resortId);
  const [ loaded, setLoaded ] = useState(false);
  let latest: ResortRoadConditionDto | undefined = undefined;
  for (const condition of conditions ?? []) {
    if (!latest || moment(condition.conditionTime).isAfter(moment(latest.conditionTime))) {
      latest = condition;
    }
  }
  useEffect(() => {
    if (!props.resortId) {
      setLoaded(true);
      return;
    }

    if (!latest || moment(latest.conditionTime).isBefore(moment().subtract(15, 'minute'))) {
      fetchResortRoadCondition(props.resortId, () => setLoaded(true));
    } else {
      setLoaded(true);
    }
  }, [ props.resortId ]);

  return (
    <div className='tripDetailSection'>
      <h3 className='tripDetailSectionTitle'>Road Condition</h3>
      <div className='roadConditionBody tripSectionBody'>
        {!loaded &&
          <div className="emptyWrapper">
            <span>Loading road condition...</span>
          </div>
        }
        {loaded && !latest &&
          <div className="emptyWrapper">
            <span>Currently not available for this resort.</span>
          </div>
        }
        {loaded && latest &&
          <div className="roadConditionWrapper">
            <div className="roadName">
              {latest.roadName}
            </div>
            {
              latest.directionConditions.map((directionCondition) => {
                return (
                  <div className="directionCondition" key={directionCondition.direction}>
                    <div className="direction">
                      {directionCondition.direction} ({directionCondition.directionNote})
                    </div>
                    <div className="condition">
                      {directionCondition.condition}
                    </div>
                  </div>
                );
              })
            }
            <div className="roadConditionUpdateTime">
              Last Update: {moment(latest.conditionTime).fromNow()}
            </div>
          </div>
        }
      </div>
    </div>
  );
});

export default RoadCondition;