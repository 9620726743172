import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonModal, IonPage, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { chevronBack, shareOutline } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import moment from 'moment';
import { RefObject, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { createOneOnOneChat } from '../../dataflow/orchestrators/ChatStoreOrchestrators';
import { fetchTripGroupsOfTrip, requestToJoinTripGroup } from '../../dataflow/orchestrators/TripStoreOrchestrators';
import { getMyRideGroup, getTripGroup } from '../../dataflow/stores/TripStore';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { TripGroupDetailDto } from '../../services/openapi';
import { calendarDateFormat } from '../../utils/carpool';
import { shareTripGroupClick } from '../../utils/share';
import { getChatRoomPath, getRequestToJoinTripGroupPath, homeRedirectPath } from '../routes';
import './RequestToJoinTripGroup.scss';

interface RequestToJoinTripGroupProps {
  tripId?: string;
  tripGroupId?: string;
  modal?: RefObject<HTMLIonModalElement>;
}

export const RequestToJoinTripGroup = observer((props: RequestToJoinTripGroupProps) => {
  const { tripId, tripGroupId, modal } = props;
  const presentAsModal = !!modal;

  const history = useHistory();
  const { currentUser } = getUserStore();
  const [ loading, setLoading ] = useState(true);
  const [ startingChat, setStartingChat ] = useState(false);
  const [ requesting, setRequesting ] = useState(false);

  const [ requested, setRequested ] = useState(false);

  const tripGroup = (tripId && tripGroupId) ? getTripGroup(tripId, tripGroupId) : undefined;
  const isCarpool = tripGroup?.purpose == TripGroupDetailDto.purpose.RIDE == true;
  const myRideGroup = tripId ? getMyRideGroup(tripId) : undefined;
  const title = tripGroup ? (isCarpool ? 'Carpool details' : 'Group details') : (loading ? 'Loading...' : 'Unknown');
  const isMember = currentUser && tripGroup && tripGroup.members && tripGroup.members.some((m) => m.user.id === currentUser.id);

  const loadTripGroup = () => {
    if (tripId) {
      setLoading(true);
      fetchTripGroupsOfTrip(tripId, () => setLoading(false));
    }
  };

  const onRequestToJoinTripGroup = () => {
    if (tripGroupId) {
      setRequesting(true);
      requestToJoinTripGroup(tripGroupId, () => {
        setRequesting(false);
        setRequested(true);
      }, () => {
        setRequesting(false);
      });
    }
  };

  const onChatWithInitiator = () => {
    if (!tripGroup || !tripGroup.initiator) {
      return;
    }
    const initiator = tripGroup.initiator;
    const trip = tripGroup.trip;
    const message = `Hi ${initiator.firstName},
      I wanted to chat with you about your carpool "${tripGroup.title}" for ${moment.utc(trip.date).calendar(calendarDateFormat)} at ${trip.resort.location.name}.`;
    setStartingChat(true);
    createOneOnOneChat(initiator.id, message, (chatDto) => {
      setStartingChat(false);
      modal?.current?.dismiss();
      history.push(getChatRoomPath(chatDto.id));
    }, (_error) => {
      setStartingChat(false);
    });
  };

  const onGoToMyRideGroup = () => {
    if (tripId && myRideGroup) {
      modal?.current?.dismiss();
      history.push(getRequestToJoinTripGroupPath(tripId, myRideGroup.id));
    }
  };
  const onGoToChat = () => {
    if (isMember && tripGroupId) {
      modal?.current?.dismiss();
      history.push(getChatRoomPath(tripGroupId));
    }
  };

  useEffect(() => {
    loadTripGroup();
  }, []);
  const presentToast = useIonToast()[0];

  const spotLeft = tripGroup?.rideOptions ? tripGroup.rideOptions.maxSize - tripGroup.members.length : undefined;
  const avatars = tripGroup?.members.map((m) =>
    <ProfilePicture key={m.user.id} className='requestToJoinMemberProfile' user={m.user} overrideDefaultClick={false} />
  );

  const emptySpots = [];
  for (let index = 0; index < (spotLeft || 0); index++) {
    emptySpots.push(
      <span key={index} className='emptySpot'></span>
    );
  }

  const shareAction = () => tripGroup && shareTripGroupClick(tripGroup, currentUser, presentToast);
  const toolbarEl = (
    <IonToolbar className='fusendPageToolbar'>
      { !presentAsModal &&
            <IonButtons slot='start'>
              <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
            </IonButtons>
      }
      <IonTitle className='fusendPageTitle' onClick={() => loadTripGroup()}>
        {title}
      </IonTitle>
      <IonButtons slot='end' className='chatRoomTripGroupMenu'>
        <IonButton onClick={shareAction}>
          <IonIcon className='chatRoomTitleIcon' icon={shareOutline}/>
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );

  const contentEl = (
    <IonContent className='fusendPageContent'>
      {presentAsModal && toolbarEl}
      <div className='requestToJoinContent'>
        { !presentAsModal &&
            <IonLoading isOpen={loading} message={'Loading...'} />
        }
        <div className='requestToJoinTitle'>
          <span>{tripGroup?.title}</span>
        </div>
        {isCarpool && <>
          <div className='requestToJoinParticipantsSection'>
            <div className='requestToJoinMembersSection'>
              {avatars}
              {emptySpots}
            </div>
            <div className='requestToJoinCarpoolSpotLeft'>{`${spotLeft} ${spotLeft == 1 ? 'spot' : 'spots'} left`}</div>
          </div>
          <div className='requestToJoinCarpoolNotes'>
            {tripGroup?.notes}
          </div>
        </>}
        <div className='actionSection'>
          {!isMember &&
          <>
            {isCarpool &&
              <>
                {myRideGroup
                  ?
                  <>
                    <div>You are already in another carpool:</div>
                    <div>{`${myRideGroup.title} (@${myRideGroup.initiator?.username})`}</div>
                    <IonButton className='actionButton' color="primary" onClick={onGoToMyRideGroup}>
                      {`Go to my carpool`}
                    </IonButton>
                  </>
                  :
                  <IonButton className='actionButton' color="secondary" onClick={onChatWithInitiator} disabled={startingChat}>
                    <IonLoading isOpen={startingChat} message={'Starting chat...'} />
                    {`Chat with driver`}
                  </IonButton>
                }
              </>
            }
            {!requested && !(isCarpool && myRideGroup) &&
            <IonButton className='actionButton' color="secondary" onClick={onRequestToJoinTripGroup} disabled={requesting || !!(spotLeft && spotLeft <= 0)}>
              <IonLoading isOpen={requesting} message={'Sending request...'} />
              {(spotLeft == undefined || spotLeft > 0) ? 'Request to Join': 'Carpool is full'}
            </IonButton>
            }

            {requested && !isCarpool &&
            <div>
              <p>You have requested to join the group!</p>
              <p>Group members can see your request and invite you.</p>
            </div>
            }
            {requested && isCarpool &&
            <div>
              <p>You have requested to join the carpool!</p>
              <p>Carpool members can see your request and invite you.</p>
            </div>
            }
          </>
          }
          {isMember &&
          <>
            {/* <div>
              <p>You are already a member of this group.</p>
              <p>Start chat with others!</p>
            </div> */}
            <IonButton className='actionButton' color="secondary" onClick={onGoToChat}>Chat with members</IonButton>
          </>
          }
          <IonButton className='actionButton' onClick={shareAction}>Share</IonButton>
        </div>
      </div>
    </IonContent>
  );

  if(presentAsModal) {
    return (
      <IonModal className="fusendPage" ref={modal} initialBreakpoint={0.7}>
        {contentEl}
      </IonModal>
    );
  }
  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        {toolbarEl}
      </IonHeader>
      {contentEl}
    </IonPage>
  );
});

export interface RequestToJoinTripGroupParam {
  tripId: string;
  tripGroupId: string;
}

export const RouteRequestToJoinTripGroup = withRouter(observer((props: RouteComponentProps<RequestToJoinTripGroupParam>) => {
  // '/trip/:tripId/group/:tripGroupId/request-to-join';
  const parts = props.match.url.split('/');
  const tripGroupId = parts[parts.length - 2];
  const tripId = parts[parts.length - 4];

  return (
    <RequestToJoinTripGroup tripId={tripId} tripGroupId={tripGroupId} />
  );
}));
