import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, useIonModal, useIonToast } from "@ionic/react";
import { chevronBack, shareOutline } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import moment from "moment";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import TripStatusButtons, { TripStatusButtonLayout } from "../../components/TripStatusButtons/TripStatusButtons";
import { setTripGroupsForTrip } from '../../dataflow/mutators/TripStoreMutators';
import { createTrip } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { getResortConditionStore } from "../../dataflow/stores/ResortConditionStore";
import { getResortStore } from "../../dataflow/stores/ResortStore";
import { getTripStore } from "../../dataflow/stores/TripStore";
import { getUserStore } from '../../dataflow/stores/UserStore';
import { ParticipantsDto, TripDetailDto, TripGroupDetailDto, TripParticipantDto } from "../../services/openapi";
import { getShareUrl, share } from '../../utils/share';
import { homeRedirectPath } from '../routes';
import CarpoolLobby from './CarpoolLobby';
import RoadCondition from './RoadCondition';
import './TripDetail.scss';
import TripGroupDetail from "./TripGroupDetail";
import ViewAllParticipantsModal from "./ViewAllParticipantsModal";
import WeatherDetail from "./WeatherDetail";

const TripDetail = observer(() => {
  const { search } = useLocation();
  const { tripId } = useParams<{tripId: string | undefined}>();
  const pageRef = React.useRef(undefined);
  const queryParams = React.useMemo(() => new URLSearchParams(search), [ search ]);
  const date = queryParams.get('date');
  const origin = queryParams.get('origin');
  const resortId = queryParams.get('resortId');
  const history = useHistory();
  const { currentUser } = getUserStore();

  const { tripDisplayMap } = getTripStore();
  const { resortList } = getResortStore();
  const { weather: conditions } = getResortConditionStore();
  let currentTrip: TripDetailDto | undefined = undefined;
  if(tripId) {
    currentTrip = [ ...tripDisplayMap.values() ].find((t) => t.id == tripId);
  } else if(date && resortId) {
    currentTrip = tripDisplayMap.get(`${resortId}-${moment(date).format('YYYY/MM/DD')}`);
  }
  const dateObj = currentTrip ? moment(currentTrip.date.split('T')[0]) : moment(date);
  const resort = currentTrip ? currentTrip.resort : resortList?.find((r) => r.id == resortId);
  const isToday = dateObj.diff(moment(), "day") == 0;
  const participantSummary = { interested: 0, going: 0 };
  const friendsInterestedCount = (currentTrip?.participants.friends.filter((f) => f.status == TripParticipantDto.status.INTERESTED) || []).length;
  const othersInterestedCount = (currentTrip?.participants.others.filter((o) => o.status == TripParticipantDto.status.INTERESTED) || []).length;
  const friendsGoingCount = (currentTrip?.participants.friends.filter((f) => f.status == TripParticipantDto.status.GOING) || []).length;
  const othersGoingCount = (currentTrip?.participants.others.filter((o) => o.status == TripParticipantDto.status.GOING) || []).length;
  const resortCondition = resort && conditions[resort.id];
  const weatherDetail = resortCondition && resortCondition[dateObj.format('YYYY/MM/DD')];
  participantSummary.interested = othersInterestedCount + friendsInterestedCount;
  participantSummary.going = othersGoingCount + friendsGoingCount;
  const presentToast = useIonToast()[0];
  const [ present, dismiss ] = useIonModal(ViewAllParticipantsModal, {
    onDismiss: () => dismiss(),
    goToProfile: (profileUrl: string) => {
      history.push(profileUrl);
      dismiss();
    },
    friendsList: currentTrip?.participants.friends,
    othersList: currentTrip?.participants.others
  });

  const onShareTripClick = async () => {
    const dateString = currentTrip ? currentTrip.date.split('T')[0]: date;
    const resortIdString = resort?.id;
    const resortName = resort?.location.name;
    const url = getShareUrl(`trip?date=${moment(dateString).format("YYYY/MM/DD")}&resortId=${resortIdString}`, currentUser);
    const message = `Join me \non ${moment(dateString).format("dddd, MMMM Do")} \nat ${resortName}.\n${url}`;
    share(message, {
      title: message,
      text: message,
      dialogTitle: message,
    }, () => presentToast('Trip link copied', 2000));
  };

  React.useEffect(() => {
    if(!currentTrip && date && resortId) {
      createTrip(date, resortId);
    }
  }, []);

  return (
    <IonPage className='fusendPage' ref={pageRef}>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonBackButton defaultHref={homeRedirectPath} icon={chevronBack} />
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton onClick={onShareTripClick}>
              <IonIcon icon={shareOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent tripDetailContent'>
        <h1 className="tripDetailTitle">{dateObj.format('MM/DD')} {resort?.location.name}</h1>
        {currentTrip &&
          <div className='tripDetailParticipantSection'>
            <h3 className="tripDetailSectionTitle">
              {participantSummary.interested > 0 ? `${participantSummary.interested} Interested` : ''}
              {participantSummary.interested > 0 && participantSummary.going > 0 ? ' · ' : ''}
              {participantSummary.going > 0 ? `${participantSummary.going} Going` : ''}
              <IonButton className="tripDetailViewAllBtn" fill="clear" onClick={() => {
                present({ presentingElement: pageRef.current });
              }}>View Detail</IonButton>
            </h3>
            <div className="tripDetailParticipantProfilePics">
              {currentTrip.participants.friends.map(f => <ProfilePicture className="tripDetailUserProfile" user={f.user} key={f.user.id}/>)}
              {currentTrip.participants.others.map(f => <ProfilePicture className="tripDetailUserProfile" user={f.user} key={f.user.id}/>)}
            </div>
          </div>
        }
        <TripStatusButtons
          date={date || currentTrip?.date}
          resortId={resortId || currentTrip?.resort.id}
          isDetailPage={true}
          tripId={currentTrip?.id}
          pageRef={pageRef}
          origin={origin || ''}
          onJoiningTrip={() => {}}
          onLeavingTrip={() => {
            if (!currentTrip) {
              return;
            }
            const trip = currentTrip;
            const tripGroups = getTripStore().tripGroupMap.get(trip.id) || [];
            // Remove trip chat
            setTripGroupsForTrip(currentTrip.id, tripGroups.filter(g => g.id != trip.id));
          }}
          buttonLayout={TripStatusButtonLayout.Detail}
        />
        <CarpoolLobby tripId={currentTrip?.id} />
        <TripGroupDetail
          currentTrip={currentTrip}
          pageRef={pageRef}
          date={date || currentTrip?.date}
          resortId={resortId || currentTrip?.resort.id}
          groupSectionTitle="Group"
          displayGroupTypes={[ TripGroupDetailDto.purpose.GENERAL, TripGroupDetailDto.purpose.TRIP ]}
          createGroupType={TripGroupDetailDto.purpose.GENERAL}
          isPartOfTrip={currentTrip ? [ ParticipantsDto.selfAttending.GOING, ParticipantsDto.selfAttending.INTERESTED ].includes(currentTrip.participants.selfAttending): false}
          showCreateGroup={true}/>
        {isToday && <RoadCondition resortId={currentTrip?.resort.id || ''} />}
        <WeatherDetail isToday={isToday} hourlyCondition={weatherDetail?.hourlyWeatherForecasts}/>
      </IonContent>
    </IonPage>
  );
});

export default TripDetail;