import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core';
import { IonButton, IonIcon, IonSelect, IonSelectOption } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline, pauseOutline, playOutline } from 'ionicons/icons';
import React, { ChangeEvent, MouseEvent, TouchEvent, useCallback, useRef, useState } from 'react';
import './VideoPlayer.scss';

interface VideoPlayerProps {
  videoSrc: string;
  isSync: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSrc, isSync, videoRef }) => {
  const [ playbackRate, setPlaybackRate ] = useState(1);
  const [ isDragging, setIsDragging ] = useState(false);
  const [ isZooming, setIsZooming ] = useState(false);
  const [ dragStartPosition, setDragStartPosition ] = useState({ x: 0, y: 0 });
  const [ touchStartDistance, setTouchStartDistance ] = useState<number | null>(null);
  const [ isPaused, setIsPaused ] = useState(!videoRef.current?.paused || false);
  const [ timestamp, setTimestamp ] = useState(videoRef.current?.currentTime || 0);
  const inputTimelineRef = useRef<HTMLInputElement>(null);
  const moveScale = 0.5;
  const [ initialScale, setInitialScale ] = useState(1);

  const handlePlaybackRateChange = (e: IonSelectCustomEvent<SelectChangeEventDetail<any>>) => {
    const newPlaybackRate = parseFloat(e.detail.value);
    setPlaybackRate(newPlaybackRate);
    if (videoRef.current) {
      videoRef.current.playbackRate = newPlaybackRate;
    }
  };

  const handlePlayPauseClick = () => {
    if (videoRef.current) {
      setIsPaused(!videoRef.current.paused);
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const setVideoTimestamp = (timestampS: number) => {
    if (videoRef.current) {
      setTimestamp(timestampS);
      videoRef.current.currentTime = timestamp;
    }
  };

  const handleTimelineChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoTimestamp(parseFloat(e.target.value));
  };

  const handleVideoTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    const target = e.target as HTMLVideoElement;
    if (inputTimelineRef.current) {
      inputTimelineRef.current.value = target.currentTime.toString();
      inputTimelineRef.current.max = target.duration.toString();
    }
  };

  const applyZoom = (video: HTMLVideoElement | null, scale: number) => {
    if (!video) return;
    const finalScale = Math.max(1, Math.min(5, scale));

    video.dataset.scale = finalScale.toString();

    video.style.transform = `translate(-50%, -50%) scale(${finalScale})`;
  };

  const zoomDesktop = (event: React.WheelEvent<HTMLVideoElement>) => {
    const delta = event.deltaY < 0 ? 1 : -1;
    const scale = (parseFloat(videoRef.current?.dataset.scale || "1") || 1) + delta * 0.1;
    applyZoom(videoRef.current, scale);
  };

  const onMoveInitiated = (x: number, y: number) => {
    setIsDragging(true);
    setDragStartPosition({ x, y });
  };

  const getDistanceBetweenTouches = (event: React.TouchEvent) => {
    const touch1 = event.touches.item(0);
    const touch2 = event.touches.item(1);
    const deltaX = touch1.clientX - touch2.clientX;
    const deltaY = touch1.clientY - touch2.clientY;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  };

  const onMouseDown = useCallback((event: MouseEvent<HTMLVideoElement>) => {
    onMoveInitiated(event.clientX, event.clientY);
  }, []);

  const onTouchStart = useCallback((event: TouchEvent<HTMLVideoElement>) => {
    if (event.touches.length == 2) {
      const distance = getDistanceBetweenTouches(event);
      const scale = parseFloat(videoRef.current?.dataset.scale || "1") || 1;
      setTouchStartDistance(distance);
      setInitialScale(scale);
      setIsZooming(true);
    } else if (event.touches.length == 1) {
      const x = event.targetTouches[0] ? event.targetTouches[0].pageX : event.changedTouches[event.changedTouches.length-1].pageX;
      const y = event.targetTouches[0] ? event.targetTouches[0].pageY : event.changedTouches[event.changedTouches.length-1].pageY;
      onMoveInitiated(x * moveScale, y * moveScale);
    }
  }, []);

  const onMove = (x: number, y: number) => {
    if (!isDragging || !videoRef.current) return;

    const deltaX = x - dragStartPosition.x;
    const deltaY = y - dragStartPosition.y;

    const video = videoRef.current;
    const scale = parseFloat(video.dataset.scale || '1');
    if (scale <= 1) return;

    const transformOrigin = {
      x: parseFloat(video.dataset.originX || '50'),
      y: parseFloat(video.dataset.originY || '50'),
    };

    const newTransformOriginX = Math.min(Math.max(0, transformOrigin.x - deltaX / scale), 100);
    const newTransformOriginY = Math.min(Math.max(0, transformOrigin.y - deltaY / scale), 100);

    video.dataset.originX = newTransformOriginX.toString();
    video.dataset.originY = newTransformOriginY.toString();

    video.style.transformOrigin = `${newTransformOriginX}% ${newTransformOriginY}%`;

    setDragStartPosition({ x, y });
  };

  const onMouseMove = useCallback(
    (event: MouseEvent<HTMLVideoElement>) => onMove(event.clientX, event.clientY),
    [ isDragging, dragStartPosition, videoRef ]
  );

  const onTouchMove = useCallback(
    (event: TouchEvent<HTMLVideoElement>) => {
      if (event.touches.length === 2 && touchStartDistance) {
        const currentDistance = getDistanceBetweenTouches(event);
        const newScale = initialScale + (currentDistance - touchStartDistance) / touchStartDistance;
        applyZoom(videoRef.current, newScale);
      } else if (event.touches.length === 1) {
        onMove(event.targetTouches[0].pageX * moveScale, event.targetTouches[0].pageY * moveScale);
      }
    }, [ isZooming, isDragging, dragStartPosition, videoRef ]
  );

  const endMove = useCallback(() => {
    setIsDragging(false);
    setIsZooming(false);
  }, []);

  const nextFrame = () => {
    if (videoRef.current) {
      const nextFrameTimestampS = Math.min(timestamp + 0.03, videoRef.current.duration);
      setVideoTimestamp(nextFrameTimestampS); // assumes 30 fps
    }
  };

  const prevFrame = () => {
    const prevFrameTimestampS = Math.max(timestamp - 0.03, 0);
    setVideoTimestamp(prevFrameTimestampS); // assumes 30 fps
  };

  return (
    <div className="video-player">
      <div className="video-container">
        <video ref={videoRef} onTimeUpdate={handleVideoTimeUpdate} src={videoSrc}
          onWheel={zoomDesktop}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={endMove}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={endMove}
          playsInline={true}
          muted={true}
          onPause={() => setTimestamp(videoRef.current?.currentTime || 0)}
        />
        {!isSync && <div className="custom-controls">
          <IonButton onClick={handlePlayPauseClick} size="small">
            <IonIcon icon={isPaused ? playOutline : pauseOutline}/>
          </IonButton>
          <IonButton size="small" onClick={prevFrame} disabled={!isPaused}>
            <IonIcon icon={chevronBackOutline}/>
          </IonButton>
          <IonButton size="small" onClick={nextFrame} disabled={!isPaused}>
            <IonIcon icon={chevronForwardOutline}/>
          </IonButton>
          <input className="timeline" type="range" min="0" max="100" value={timestamp} step="any" ref={inputTimelineRef} onChange={handleTimelineChange} />
          <IonSelect disabled={!isPaused} className='playbackSpeed' value={playbackRate} placeholder="Speed" onIonChange={handlePlaybackRateChange}>
            {
              Array.from({ length: 20 }, (_, i) => {
                const rate = (i + 1) * 0.1;
                return <IonSelectOption key={i} value={rate}>{rate.toFixed(1)}x</IonSelectOption>;
              })
            }
          </IonSelect>
        </div>}
      </div>
    </div>
  );
};