import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { logger } from './logging/winston';
import reportWebVitals from './reportWebVitals';
import { initFirebase } from './services/firebase';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { getPlatforms } from '@ionic/react';
import { OpenAPI } from './services/openapi';
import { addDataflowLogging } from './dataflow/middlewares';

OpenAPI.BASE = process.env.REACT_APP_FUSEND_SERVICE_HOST || 'https://snoqualmie-pass.azurewebsites.net';
logger.info(`Starting app using host ${OpenAPI.BASE}`);
addDataflowLogging();
initFirebase(logger);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const platforms = getPlatforms();
logger.info(`platforms: ${platforms}`);
if (platforms.indexOf('capacitor') !== -1) {
  // mobile
  serviceWorkerRegistration.unregister();
} else {
  // web
  // serviceWorkerRegistration.register();
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
