import { informationCircle, logOutOutline, personAddOutline, personOutline, videocamOutline } from 'ionicons/icons';
import { discoverPeoplePath, myProfilePath, sideBySideComparison, termsOfServicePath } from '../../pages/routes';
import { userLogout } from './../../dataflow/orchestrators/UserStoreOrchestrators';

export interface SideMenuItemProps {
  icon: string;
  displayText: string;
  onClick: () => void;
  linkPath?: string;
}

export default function getMenuItemProps(): SideMenuItemProps[] {
  return [
    {
      icon: personOutline,
      displayText: 'Profile',
      onClick: () => {},
      linkPath: myProfilePath
    },
    {
      icon: personAddOutline,
      displayText: 'Discover People',
      onClick: () => {},
      linkPath: discoverPeoplePath
    },
    {
      icon: videocamOutline,
      displayText: 'Video Analysis',
      onClick: () => {},
      linkPath: sideBySideComparison
    },
    {
      icon: informationCircle,
      displayText: 'Terms of Service',
      onClick: () => {},
      linkPath: termsOfServicePath
    },
    {
      icon: logOutOutline,
      displayText: 'Logout',
      onClick: () => {
        userLogout(() => {
          window.location.assign('/');
        });
      }
    }
  ];
}