import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonModal,
  useIonToast
} from '@ionic/react';
import { arrowRedoOutline, car, closeOutline, ellipsisHorizontal, people, shareOutline } from 'ionicons/icons';
import { observer } from 'mobx-react-latest';
import moment from 'moment';
import { createRef, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { addWarning } from '../../dataflow/mutators/ErrorStoreMutators';
import { sendChatMessage, updateLastRead } from '../../dataflow/orchestrators/ChatStoreOrchestrators';
import { allChatsLoaded, ChatKind, getChat, getChatMembers, getChatTitle, getLatestChatMessage, ReplyTo } from '../../dataflow/stores/ChatStore';
import { newWarning } from '../../dataflow/stores/ErrorStore';
import { isFeatureEnabled } from '../../dataflow/stores/RemoteConfigStore';
import { getUserStore } from '../../dataflow/stores/UserStore';
import { PreviewUserDto } from '../../services/openapi';
import { shareTripGroupClick } from '../../utils/share';
import { chatPath, getCarpoolLobbyPath, getChatRoomDetailPath, getRideDetailPath, getTripDetailWithIdPath } from '../routes';
import AtMentionModal from './AtMentionModal';
import './ChatRoom.dark.css';
import './ChatRoom.scss';
import ChatRoomMessageSection from './ChatRoomMessageSection';
import ChatRoomRightMenu from './ChatRoomRightMenu';

export interface ChatRoomParam {
  chatId: string;
}

export const mentionAllLiteral = 'All';
const maxChatMessageLength = 1000;


const ChatRoom = observer((props: RouteComponentProps<ChatRoomParam>) => {
  const history = props.history;
  const { currentUser } = getUserStore();
  const [ editDisabled, setEditDisabled ] = useState(false);
  const [ selectionStart, setSelectionStart ] = useState(-1);
  const [ content, setContent ] = useState<string | null | undefined>('');
  const [ reply, setReply ] = useState<ReplyTo | undefined>(undefined);
  const inputBoxRef = createRef<HTMLIonTextareaElement>();
  const presentToast = useIonToast()[0];

  // We should spend more time later to try to figure out where the issue is.
  // NOTE: do not use `.at(-1)` to get the last element since Safari does not support `Array.prototype.at()` https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
  const parts = props.match.url.split('/');
  const chatId = parts[parts.length - 1];
  const showLoading = !getChat(chatId) && !allChatsLoaded();
  const chat = getChat(chatId) || {
    id: chatId,
    kind: ChatKind.PEOPLE,
    messages: [],
    otherParticipants: [],
    lastReadMessageId: undefined,
  };
  const contentRef = useRef<HTMLIonContentElement>(null);

  if (!chat && allChatsLoaded()) {
    useEffect(() => {
      addWarning(newWarning(`Invalid chat room id ${chatId}`));
      history.replace(chatPath); // replace instead of push so that "back" won't go back to invalid route again
    });
    return <></>;
  }

  const titleText = showLoading ? 'Loading...' : getChatTitle(chat);
  const memberList = getChatMembers(chat);

  let bannerComponent = null;
  if(chat.kind == ChatKind.TRIP || chat.kind == ChatKind.TRIP_GROUP) {
    const bannerTrip = chat.kind == ChatKind.TRIP ? chat.trip : chat.tripGroup.trip;
    const tripDate = moment(bannerTrip.date.split('T')[0]);
    const tripDateDiff = tripDate.diff(moment(), 'days');
    let tripStatusString = '';
    if(tripDateDiff > 0 ){
      tripStatusString = 'Upcoming Trip';
    } else if (tripDateDiff == 0) {
      tripStatusString = 'On going trip';
    } else {
      tripStatusString = 'Completed trip';
    }
    bannerComponent =  (
      <IonItem className='chatBanner'>
        <div className='chatCardIconContainer trip'>
          <img src="/assets/icon/gondola.svg"/>
        </div>
        <div className='chatBannerContent'>
          <p className='bannerTitle'>{bannerTrip.resort.location.name}</p>
          <p className='bannerSubtitle'>{tripStatusString} <span className='bannerTripDate'>{tripDate.format('MMM DD')}</span></p>
        </div>
        <IonButton className="bannerBtn" slot="end" fill="outline" size="small" onClick={() => history.push(`${getTripDetailWithIdPath(bannerTrip.id)}?origin=chatroom`)}>
          Detail
        </IonButton>
      </IonItem>);
  }

  const [ present, dismiss ] = useIonModal(AtMentionModal, {
    memberList: memberList,
    onDismiss: () => dismiss(),
    onAtAll: () => {
      dismiss();
      setTimeout(async () => {
        if(inputBoxRef.current) {
          const inputEl = await inputBoxRef.current.getInputElement();
          await inputBoxRef.current.setFocus();
          const addedText = `@${mentionAllLiteral} `;
          inputEl.setSelectionRange(selectionStart - 1, selectionStart - 1);
          inputEl.setRangeText(addedText, selectionStart - 1, selectionStart - 1, 'end');
          setContent(inputEl.value);
          setSelectionStart(-1);
        }
      }, 100);
    },
    onItemClicked: (member: PreviewUserDto) => {
      dismiss();
      setTimeout(async () => {
        if(inputBoxRef.current) {
          const inputEl = await inputBoxRef.current.getInputElement();
          await inputBoxRef.current.setFocus();
          const addedText = `@${member.username} `;
          inputEl.setSelectionRange(selectionStart - 1, selectionStart - 1);
          inputEl.setRangeText(addedText, selectionStart - 1, selectionStart - 1, 'end');
          setContent(inputEl.value);
          setSelectionStart(-1);
        }
      }, 100);
    }
  });

  useEffect(() => {
    if (!chat) {
      return;
    }

    const latestMessage = getLatestChatMessage(chat);
    if (latestMessage && latestMessage.id) {
      updateLastRead(chat.id, latestMessage.id);
    }
  }, [ getLatestChatMessage(chat) ]); // run on latest message

  useEffect(() => {
    const keyboardShowEvent = () => {
      contentRef.current?.scrollToBottom();
    };

    window.addEventListener('keyboardDidShow', keyboardShowEvent);
    return () => {
      window.removeEventListener('keyboardDidShow', keyboardShowEvent);
    };
  }, []);

  useEffect(() => {
    contentRef.current?.scrollToBottom();
  },[ chat?.messages.length ]);


  let bottomElem: HTMLDivElement | null;
  const bottom = <div style={{ float:"left", clear: "both" }}
    ref={(el) => { bottomElem = el; }}>
  </div>;

  const sendMessage = async () => {
    const inputEl = await inputBoxRef.current?.getInputElement();
    const inputContent = inputEl?.value;
    if (!inputContent) {
      addWarning(newWarning('Please enter a message'));
      return;
    }

    let taggedList: string[] = [];
    const mentionedUsernameList = inputContent.match(/@\w+/g);
    if (mentionedUsernameList) {
      const userIdByUsername = new Map<string, string>();
      memberList.forEach((member) => {
        if (member.id !== currentUser?.id) {
          userIdByUsername.set(member.username, member.id);
        }
      });

      for (const mention of mentionedUsernameList) {
        const username = mention.slice(1);
        if (username == mentionAllLiteral || chat.kind === ChatKind.RIDE) {
          taggedList = Array.from(userIdByUsername.values());
          break;
        }
        const userId = userIdByUsername.get(username);
        if (userId) {
          taggedList.push(userId);
        }
      }
    }

    if (inputContent.length > maxChatMessageLength) {
      addWarning(newWarning(`Message length of ${inputContent.length} is longer than the maximum allowed length of ${maxChatMessageLength}.`));
      return;
    }

    setEditDisabled(true);
    setReply(undefined);
    sendChatMessage(chatId, chat.kind, inputContent, reply, taggedList);
    setEditDisabled(false);

    bottomElem?.scrollIntoView({ behavior: 'smooth' });
    setContent('');
    if(inputBoxRef.current) {
      inputBoxRef.current.value = '';
    }
    if(inputEl) {
      inputEl.value = '';
    }
  };

  const onRideDetailButtonClicked = () => {
    if (chat.kind === ChatKind.RIDE) {
      history.push({
        pathname: getRideDetailPath(chat.ride.id)
      });
    }
  };

  return (
    <>
      <ChatRoomRightMenu members={memberList}/>
      <IonPage className='chatRoomPage' id="main-content">
        <IonHeader className='chatRoomHeader'>
          <IonToolbar className='chatRoomToolbar'>
            <IonButtons className="chatPageBackBtn" slot='start'>
              <IonBackButton defaultHref={chatPath}/>
            </IonButtons>
            <IonTitle className='chatRoomTitleText'>{titleText}</IonTitle>
            {
              chat.kind === ChatKind.RIDE &&
              <IonButtons slot='end' onClick={onRideDetailButtonClicked}>
                <IonIcon icon={car} className='rideDetailEditRideButton'/>
              </IonButtons>
            }
            { chat.kind == ChatKind.TRIP_GROUP
              ?
              <div slot='end' className='chatRoomTripGroupMenu'>
                <IonIcon className='chatRoomTitleIcon'
                  icon={shareOutline}
                  onClick={() => shareTripGroupClick(chat.tripGroup, currentUser, presentToast)} />
                <IonIcon className='chatRoomTitleIcon'
                  icon={ellipsisHorizontal}
                  onClick={() => history.push(getChatRoomDetailPath(chatId))} />
              </div>
              :
              <IonButtons slot='end'>
                <IonMenuButton color="medium">
                  <IonIcon icon={people}/>
                </IonMenuButton>
              </IonButtons>
            }
          </IonToolbar>
        </IonHeader>
        <IonContent
          scrollEvents={true}
          ref={contentRef}
          className='chatRoomContent'
        >
          { bannerComponent }
          <IonLoading
            isOpen={showLoading}
            message={'Loading...'} />
          <ChatRoomMessageSection chatId={chat.id} messages={chat?.messages || []} members={memberList} setReply={setReply}/>
          {bottom}
        </IonContent>
        <IonFooter>
          <IonToolbar className="chatRoomInputToolbar">
            <IonGrid className='chatRoomInputArea'>
              { reply && <IonRow>
                <div className="replySection">
                  <div className="replyContent">
                    <IonIcon icon={arrowRedoOutline} name={undefined} />
                    <span>{reply.preview}</span>
                  </div>
                  <IonButton className="replySectionClose" size="small" fill="clear" onClick={() => setReply(undefined)}><IonIcon icon={closeOutline}></IonIcon></IonButton>
                </div>
              </IonRow>}
              <IonRow>
                {chat.kind == ChatKind.TRIP && isFeatureEnabled('carpool') && 
                  <IonCol size='1'>
                    <IonButton fill="clear" size='small' className="chatMoreFunctionBtn" onClick={() => {
                      history.push(getCarpoolLobbyPath(chatId));
                    }}>
                      <IonIcon icon={car}/>
                    </IonButton>
                  </IonCol>
                }
                <IonCol size={chat.kind == ChatKind.TRIP ? '8': '9'}>
                  <IonTextarea
                    className='chatRoomInputBox'
                    disabled={editDisabled}
                    rows={1}
                    ref={inputBoxRef}
                    value={content}
                    onIonChange={(e) => setContent(e.detail.value)}
                    onIonInput={async (e: CustomEvent<InputEvent>) => {
                      if(e.detail.data == '@'){
                        const inputEl = await inputBoxRef.current?.getInputElement();
                        if(inputEl) {
                          setSelectionStart(inputEl.selectionStart);
                        }
                        present();
                      }
                    }}
                    autoGrow={true}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                      }
                    }}/>
                </IonCol>
                <IonCol className='chatRoomSendButtonContainer' size="3">
                  <IonButton className='chatRoomSendButton' disabled={editDisabled} onClick={(e: any) => {
                    e.preventDefault();
                    sendMessage();
                  }}>Send</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </>
  );
});

export default withRouter(ChatRoom);
