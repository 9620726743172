import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { observer } from "mobx-react-latest";
import { useState } from "react";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { PreviewUserDto, TripParticipantDto } from "../../services/openapi";
import { getProfilePagePath } from "../routes";

interface ViewAllParticipantsModalProps {
  onDismiss?: () => void;
  goToProfile?: (profile: string) => void;
  friendsList?: TripParticipantDto[];
  othersList?: TripParticipantDto[];
}

enum TabType {
  Interested, Going
}

const ViewAllParticipantsModal = observer(({
  onDismiss,
  friendsList,
  goToProfile,
  othersList
}: ViewAllParticipantsModalProps) => {

  const interestedList: PreviewUserDto[] = [];
  const goingList: PreviewUserDto[] = [];
  friendsList?.forEach((f) => {
    if(f.status == TripParticipantDto.status.INTERESTED) {
      interestedList.push(f.user);
    }
    if(f.status == TripParticipantDto.status.GOING) {
      goingList.push(f.user);
    }
  });

  othersList?.forEach((f) => {
    if(f.status == TripParticipantDto.status.INTERESTED) {
      interestedList.push(f.user);
    }
    if(f.status == TripParticipantDto.status.GOING) {
      goingList.push(f.user);
    }
  });

  const [ activeTab, setActiveTab ] = useState(
    interestedList.length > 0 
      ? TabType.Interested 
      : goingList.length > 0 
        ? TabType.Going 
        : TabType.Interested
  );

  const contentList = activeTab == TabType.Interested ? interestedList: goingList;

  const contentComponent = contentList.map((u: PreviewUserDto) => {
    const profileUrl = getProfilePagePath(u.username);
    return (
      <IonItem className="participantItem" key={u.id} onClick={() => goToProfile && goToProfile(profileUrl)}>
        <IonRow className="participantRow">
          <IonCol size="2">
            <ProfilePicture className="participantProfilePic" user={u}/>
          </IonCol>
          <IonCol size="10">
            <IonLabel>{u.firstName} {u.lastName}</IonLabel>
            <p className="participantUsername">{u.username}</p>
          </IonCol>
        </IonRow>
      </IonItem>
    );
  });

  return (
    <IonPage className='fusendPage'>
      <IonHeader className='fusendPageHeader'>
        <IonToolbar className='fusendPageToolbar'>
          <IonButtons slot='start'>
            <IonButton onClick={() => onDismiss && onDismiss()}>Back</IonButton>
          </IonButtons>
          <IonTitle>
            All Participants
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='fusendPageContent'>
        <IonButtons className='participantsTabBar'>
          <IonButton
            className={ `participantsTab ${activeTab == TabType.Interested ? 'activeTab': ''}` }
            onClick={() => setActiveTab(TabType.Interested)}>
              Interested({interestedList.length})
          </IonButton>
          <IonButton
            className={ `participantsTab ${activeTab == TabType.Going ? 'activeTab': ''}` }
            onClick={() => setActiveTab(TabType.Going)}>
              Going({goingList.length})
          </IonButton>
        </IonButtons>
        { contentComponent }
      </IonContent>
    </IonPage>
  );
});

export default ViewAllParticipantsModal;