import { action, orchestrator } from 'satcheljs';
import { logger } from '../../logging/winston';
import { onNotificationClickedRouter } from '../../notifications/routers';
import { ApiError, CancelablePromise, PreviewRideDto, RideDetailDto, Service, UpdateRideDto } from '../../services/openapi';
import { browserHistory } from '../../utils/history';
import { addError, addWarning } from '../mutators/ErrorStoreMutators';
import {
  addRide, removeRide, removeRidePreview, setMyRideLoading, setMyRides, setRideLoading, setSearchResultsLoading, unsetMyRideLoading,
  unsetRideLoading,
  unsetSearchResultsLoading, updateRide, updateRidePreview, upsertSearchedRides
} from '../mutators/RideStoreMutators';
import { ChatKind } from '../stores/ChatStore';
import { newError, newWarning } from '../stores/ErrorStore';
import { isUserDriver, isUserPassenger } from '../stores/RideStore';
import { getUserStore } from '../stores/UserStore';
import { makeAsyncRequest } from '../utils/utils';
import { getRideDetailPath } from './../../pages/routes';
import { joinChat, leaveChat } from './ChatStoreOrchestrators';

export const fetchMyRides = action('FETCH_MY_RIDES');
export const fetchOneRide = action('FETCH_ONE_RIDE', (rideId: string) => ({ rideId }));
export const createRide = action('CREATE_RIDE', (ride: UpdateRideDto, onSuccess?: (ride?: RideDetailDto) => void, onError?: (exception: unknown) => void) => ({ ride, onSuccess, onError }));
export const patchRide = action('PATCH_RIDE', (
  rideId: string,
  ride: UpdateRideDto,
  onSuccess?: (ride?: RideDetailDto) => void,
  onError?: (exception: unknown) => void) => ({ rideId, ride, onSuccess, onError }));
export const removeRider = action('REMOVE_RIDER', (
  rideId: string,
  passengerId: string,
  onSuccess?: (ride?: RideDetailDto) => void,
  onError?: (exception: unknown) => void) => ({ rideId, passengerId, onSuccess, onError }));
export const deleteRide = action('DELETE_RIDE', (rideId: string) => ({ rideId }));
export const searchRides = action('SEARCH_RIDES', (origin, radius) => ({ origin, radius }));

let fetchMyRidesPromise: CancelablePromise<RideDetailDto[]> | undefined = undefined;
let searchRidesPromise: CancelablePromise<PreviewRideDto[]> | undefined = undefined;
const fetchOneRidePromises: Map<string, CancelablePromise<RideDetailDto>> = new Map();
const fetchOneRidePreviewPromises: Map<string, CancelablePromise<PreviewRideDto>> = new Map();

async function updateRideChats(rides: RideDetailDto[]) {
  rides.forEach((ride) => {
    if (!ride.removed) {
      joinChat({
        kind: ChatKind.RIDE,
        id: ride.id,
        ride: ride,
        messages: [],
      });
    } else {
      leaveChat(ride.id);
    }
  });
}

orchestrator(fetchMyRides, async () => {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to fetch rides. Current user is missing.');
    addError(newError('FETCH_MY_RIDES.MissingUser'));
  } else {
    try {
      const { request, response } = await makeAsyncRequest(
        fetchMyRidesPromise,
        () => Promise.resolve(() => Service.usersControllerFindRides(user.id)),
        () => setMyRideLoading(),
        () => unsetMyRideLoading());
      fetchMyRidesPromise = request;
      const rides = await response;
      setMyRides(rides);
      updateRideChats(rides);
    } catch (exception: any) {
      if (exception) {
        logger.error('Failed to fetch rides. Service request error.', { exception: exception.toString() });
        addError(newError('FETCH_MY_RIDES.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
      }
    }
  }
});

async function fetchOneRidePreviewFunc({ rideId }: { rideId: string }): Promise<PreviewRideDto | undefined> {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to fetch rides. Current user is missing.');
    addError(newError('FETCH_ONE_RIDE_PREVIEW.MissingUser'));
    return undefined;
  } else {
    try {
      const pendingRequest = fetchOneRidePreviewPromises.get(rideId);
      fetchOneRidePreviewPromises.delete(rideId);
      const { request, response } = await makeAsyncRequest(
        pendingRequest,
        () => Promise.resolve(() => Service.ridesControllerFindOnePreview(rideId)),
        () => setRideLoading(rideId),
        () => unsetRideLoading(rideId));
      fetchOneRidePreviewPromises.set(rideId, request);

      const ride = await response;
      updateRidePreview(ride);
      return ride;
    } catch (exception: any) {
      if (exception instanceof ApiError && exception.status == 404) {
        logger.info(`Ride (preview) ${rideId} is no longer accessible`, { rideId, exception: exception.toString() });
        removeRidePreview(rideId);
        addWarning(newWarning(`Ride is no longer accessible`));
        return undefined;
      } else if (exception) {
        throw exception;
      }
    }
  }
}

async function fetchOneRideFunc({ rideId }: { rideId: string }): Promise<void> {
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to fetch rides. Current user is missing.');
    addError(newError('FETCH_ONE_RIDE.MissingUser'));
  } else {
    try {
      const pendingRequest = fetchOneRidePromises.get(rideId);
      fetchOneRidePromises.delete(rideId);
      const result = await makeAsyncRequest(
        pendingRequest,
        () => Promise.resolve(() => Service.ridesControllerFindOne(rideId)),
        () => setRideLoading(rideId),
        () => unsetRideLoading(rideId));

      if (result) {
        const { request, response } = result;
        fetchOneRidePromises.set(rideId, request);
        const ride = await response;
        updateRide(ride);
        await updateRideChats([ ride ]);
      }
    } catch (exception: any) {
      if (exception instanceof ApiError && exception.status == 404) {
        logger.info(`Ride ${rideId} is no longer accessible`, { rideId, exception: exception.toString() });
        removeRide(rideId);
        leaveChat(rideId);
        addWarning(newWarning(`Ride is no longer accessible`));
      } else {
        throw exception;
      }
    }
  }
}

orchestrator(fetchOneRide, async ({ rideId }) => {
  try {
    const ridePreview = await fetchOneRidePreviewFunc({ rideId });
    if (ridePreview == undefined || (ridePreview != undefined && (isUserDriver(ridePreview) || isUserPassenger(ridePreview)))) {
      // try fetch my ride if preview is not found, or it's found and it is my ride
      await fetchOneRideFunc({ rideId });
    }
  } catch (exception: any) {
    logger.error(`Failed to fetch ride ${rideId}. Service request error.`, { exception: exception.toString() });
    addError(newError('FETCH_ONE_RIDE.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
});

orchestrator(createRide, async ({ ride, onSuccess, onError })=>{
  const user = getUserStore().currentUser;
  if (!user) {
    logger.error('Failed to create rides. Current user is missing.');
    addError(newError('CREATE_RIDE.MissingUser'));
  } else {
    try {
      const createdRide = await Service.ridesControllerCreate(ride);
      addRide(createdRide);
      onSuccess && onSuccess();
      await updateRideChats([ createdRide ]);
    } catch (exception: any) {
      logger.error(`Failed to create ride. Service request error.`, { exception: exception.toString() });
      addError(newError('CREATE_RIDE.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
      onError && onError(exception);
    }
  }
});

orchestrator(patchRide, async ({ rideId, ride, onSuccess, onError })=>{
  try {
    const updatedRide = await Service.ridesControllerUpdate(rideId, ride);
    updateRide(updatedRide);
    updateRidePreview(updatedRide);
    onSuccess && onSuccess();
  } catch (exception: any) {
    logger.error(`Failed to patch ride. Service request error.`, { exception: exception.toString() });
    addError(newError('PATCH_RIDE.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    onError && onError(exception);
  }
});

orchestrator(removeRider, async ({ rideId, passengerId, onSuccess, onError })=>{
  try {
    const updatedRide = await Service.ridesControllerRemoveUser(rideId, passengerId);
    updateRide(updatedRide);
    updateRidePreview(updatedRide);
    onSuccess && onSuccess();
  } catch (exception: any) {
    logger.error(`Failed to remove rider. Service request error.`, { exception: exception.toString() });
    addError(newError('REMOVE_RIDER.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    onError && onError(exception);
  }
});


orchestrator(deleteRide, async ({ rideId })=>{
  try {
    await Service.ridesControllerRemove(rideId);
    removeRide(rideId);
    removeRidePreview(rideId);
    leaveChat(rideId);
  } catch (exception: any) {
    logger.error(`Failed to delete ride ${rideId}. Service request error.`, { exception: exception.toString() });
    addError(newError('UPDATE_RIDE.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
  }
});

orchestrator(searchRides, async ({ origin, radius }) => {
  try {
    const { request, response } = await makeAsyncRequest(
      searchRidesPromise,
      () => Promise.resolve(() => Service.ridesControllerFindNear(origin, radius)),
      () => setSearchResultsLoading(),
      () => unsetSearchResultsLoading());
    searchRidesPromise = request;

    const rides = await response;
    upsertSearchedRides(rides);
  } catch (exception: any) {
    if (exception) {
      logger.error(`Failed to search ride. Service request error.`, { exception, origin, radius });
      addError(newError('SEARCH_RIDES.ServiceError', `${exception.toString()} ${JSON.stringify(exception)}`));
    }
  }
});

export const ROUTE_RIDE_UPDATE = 'ride.update';

onNotificationClickedRouter.addRoute(ROUTE_RIDE_UPDATE, rideId => {
  logger.debug('Handling clicked ride update notification', { rideId });
  browserHistory.push({
    pathname: getRideDetailPath(rideId),
  });
  fetchOneRide(rideId);
});
