import { IonButton, IonSpinner } from "@ionic/react";
import React from "react";
import { getUserStore } from "../../../dataflow/stores/UserStore";
import { Service, UpdateFriendRequestDto } from "../../../services/openapi";

interface ProfilePictureModalFriendRequestedProps {
  userId: string;
  onSubmit: () => void;
}

const ProfilePictureModalFriendRequested: React.FC<ProfilePictureModalFriendRequestedProps>= (props: ProfilePictureModalFriendRequestedProps) => {
  const { userId, onSubmit } = props;
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ errMessage, setErrMessage ] = React.useState<string>("");

  const onCancelRequestClick = () => {
    const { currentUser } = getUserStore();
    if (currentUser) {
      Service.friendRequestsControllerUpdate(currentUser.id, userId, { status: UpdateFriendRequestDto.status.CANCELLED, follow: false }).then(() => {
        setIsLoading(false);
        onSubmit();
      }).catch(() => {
        setErrMessage("Some thing went wrong, try again later");
        setIsLoading(false);
      });
      setIsLoading(true);
    }
  };

  return (
    <>
      <span className="profilePicModalContentText">Friend request sent!</span>
      {
        isLoading
          ? <IonButton color="secondary" disabled={true}><IonSpinner name="crescent"/></IonButton>
          : <IonButton color="secondary" onClick={onCancelRequestClick}>Cancel Friend Request</IonButton>
      }
      {errMessage && <p className="profilePicModalContentErrMessage">{errMessage}</p>}
    </>
  );
};

export default ProfilePictureModalFriendRequested;