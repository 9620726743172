/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateJoinRequestDto = {
    state: UpdateJoinRequestDto.state;
}

export namespace UpdateJoinRequestDto {

    export enum state {
        PENDING = 'Pending',
        ACCEPTED = 'Accepted',
        REJECTED = 'Rejected',
        CANCELLED = 'Cancelled',
    }


}
