import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { GoBack } from "../../components/navigation-header/GoBack";
import { legalPath } from "../routes";
import '../page.scss';

export const TermsOfService: React.FC = () => {
  return (
    <IonPage className="fusendPage">
      <GoBack title="Terms of Service" backUrl={legalPath}></GoBack>
      <IonContent className="fusendPageContent">
        <iframe className="legal-content" src="https://app.termly.io/document/terms-of-use-for-ios-app/36628557-5ed4-46aa-9c14-8251c6bce938"></iframe>
      </IonContent>
    </IonPage>
  );
};