import { IonIcon } from "@ionic/react";
import { chatbubblesOutline } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { MemberInfoDto, TripGroupDetailDto } from "../../services/openapi";
import "./TripGroupThumbnail.scss";

interface TripGroupThumbnail {
  purpose: TripGroupDetailDto.purpose;
  members?: MemberInfoDto[];
}

const TripGroupThumbnail = observer((props: TripGroupThumbnail) => {
  const { purpose, members } = props;
  if (purpose == TripGroupDetailDto.purpose.CARPOOL) {
    return (
      <div className="tripGroupThumbnail carpoolThumbnail">
        <img className="tripGroupThumbnailImg" src="/assets/icon/car.svg"/>
      </div>
    );
  } else if (purpose == TripGroupDetailDto.purpose.TRIP) {
    return (
      <div className="tripGroupThumbnail carpoolThumbnail">
        <img className="tripGroupThumbnailImg" src="/assets/icon/gondola.svg"/>
      </div>
    );
  } else {
    return (
      <div className="tripGroupThumbnail">
        { members && members.length >= 2 &&
          members.slice(0, 2).map((m) => {
            return (
              <ProfilePicture
                className="tripGroupThumbnailDoubleProfilePic"
                key={m.user.id}
                user={m.user}
                overrideDefaultClick={false}
              />
            );
          })
        }
        { members && members.length == 1 &&
          <ProfilePicture
            className="tripGroupThumbnailSingleProfilePic"
            key={members[0].user.id}
            user={members[0].user}
            overrideDefaultClick={false}
          />
        }
        { (!members || members.length == 0) &&
          <div className="tripGroupPlaceholder">
            <IonIcon className="tripGroupPlaceholderIcon" icon={chatbubblesOutline}></IonIcon>
          </div>}
      </div>
    );
  }
});

export default TripGroupThumbnail;