import { IonButton, IonIcon } from "@ionic/react";
import { addCircle, chevronForwardOutline } from "ionicons/icons";
import { observer } from "mobx-react-latest";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { listCarpools } from "../../dataflow/orchestrators/CarpoolStoreOrchestrator";
import { fetchTripGroupsOfTrip } from "../../dataflow/orchestrators/TripStoreOrchestrators";
import { getCarpoolsForTrip } from "../../dataflow/stores/CarpoolStore";
import { isFeatureEnabled } from "../../dataflow/stores/RemoteConfigStore";
import { getTripRideGroups } from "../../dataflow/stores/TripStore";
import { logger } from "../../logging/winston";
import { getCarpoolLobbyPath, getCarpoolRoleSelectionPath } from "../routes";
import "./CarpoolLobby.scss";
import { ReactComponent as Car } from './icon/car.svg';
import { ReactComponent as User } from './icon/user.svg';

export interface CarpoolLobbyProps {
  tripId: string | undefined;
  isHome?: boolean;
}

const CarpoolLobby = observer((props: CarpoolLobbyProps) => {
  const isEnabled = isFeatureEnabled('carpool');
  const history = useHistory();
  const [ loadedIntents, setLoadedIntents ] = useState(false);
  const [ loadedCarpools, setLoadedCarpools ] = useState(false);

  const tripId = props.tripId;
  const intents = tripId ? getCarpoolsForTrip(tripId) : [];
  const intentsSummary = <div>
    <div className="carpoolLobbyContentLineEmphasize">{intents.length} <User /></div> looking for rides
  </div>;
  const carpools = tripId ? getTripRideGroups(tripId) : [];
  const carpoolsSummary = <div>
    <div className="carpoolLobbyContentLineEmphasize">{carpools.length} <Car /></div> looking for passengers
  </div>;

  useEffect(() => {
    if (!tripId || (loadedIntents && loadedCarpools)) {
      return;
    }

    setLoadedIntents(false);
    listCarpools(tripId, (carpoolsOrError) => {
      setLoadedIntents(true);
      if (!Array.isArray(carpoolsOrError)) {
        logger.error('Failed to load carpools for trip', { exception: carpoolsOrError, tripId });
      }
    });

    setLoadedCarpools(false);
    fetchTripGroupsOfTrip(tripId, () => {
      setLoadedCarpools(true);
    });
  }, [ tripId ]);

  if (!props.tripId) {
    return <></>;
  }

  if (!isEnabled) {
    return <></>;
  }

  if(props.isHome && intents.length == 0 && carpools.length == 0) {
    return <></>;
  }

  return (
    <div className={`tripDetailSection ${props.isHome && 'homeCarpoolLobby'}`}>
      { !props.isHome &&
        <div className="tripDetailTitleSection">
          <h3 className='tripDetailSectionTitle'>Carpool Lobby</h3>
          {tripId && <IonButton className="addButton" fill="clear" color='secondary' size="small" onClick={() => history.push(getCarpoolRoleSelectionPath(tripId))}>
            <IonIcon icon={addCircle}></IonIcon> Create
          </IonButton> }
        </div>
      }
      <div className='carpoolLobbyBody tripSectionBody' onClick={() => {
        !props.isHome && history.push(getCarpoolLobbyPath(tripId || ''));
      }}>
        {(loadedIntents && loadedCarpools)
          ? <div className="detailsSection">
            <div className="detail">{intentsSummary}</div>
            <div className="detail">{carpoolsSummary}</div>
          </div>
          : <div className="detailsSectionLoading">
            Loading...
          </div>
        }
        { !props.isHome &&
          <div className="buttonSection">
            <IonIcon className="detailsButton" icon={chevronForwardOutline}></IonIcon>
          </div>
        }
      </div>
    </div>
  );
});

export default CarpoolLobby;