import { Clipboard } from '@capacitor/clipboard';
import { IonButton, IonImg, IonLabel, IonSpinner, isPlatform, useIonToast } from '@ionic/react';
import { observer } from 'mobx-react-latest';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GoogleLogin } from '../components/Login/Google';
import LogoPage from '../components/LogoPage';
import { LoginProvider, userLogin } from '../dataflow/orchestrators/UserStoreOrchestrators';
import { getUserStore, isLoggedIn } from '../dataflow/stores/UserStore';
import { logger, sessionId } from '../logging/winston';
import { Apple } from '../services/apple';
import { Facebook } from '../services/facebook';
import isDarkMode from '../utils/isDarkMode';
import './Login.css';
import './Login.dark.css';
import { decode, legalPath } from './routes';

interface LoginRouteParams {
  redirectPath: string;
}

const Login: React.FC = observer(() => {
  const history = useHistory();
  const redirectPath = useParams<LoginRouteParams>().redirectPath;
  const { loading } = getUserStore();
  const [ loginError, setLoginError ] = useState('');
  const loginDone = isLoggedIn();
  const [ presentCopied ] = useIonToast();

  const searchParams = new URLSearchParams(window.location.search);
  const referrer = searchParams.get('referrer') || undefined;

  const hasAppleSignIn = () => {
    // apple login is not supported in native-android app
    return !(isPlatform('capacitor') && isPlatform('android'));
  };

  const errorHandlingFn = (error: Error | any) => {
    const message = error.body?.message || error.message || 'Unknown error';
    setLoginError(message);
  };

  const facebookLogin = () => {
    userLogin(LoginProvider.FACEBOOK, () => Facebook.login(), errorHandlingFn, referrer);
  };

  const appleLogin = () => {
    userLogin(LoginProvider.APPLE, () => Apple.login(), errorHandlingFn, referrer);
  };

  const redirectAfterLogin = () => {
    if (loginDone) {
      let decoded = '';
      try {
        decoded = decode(redirectPath);
      } catch (exception) {
        logger.error(`Failed decode redirectPath, default to ${decoded}.`, { exception, redirectPath });
      }
      const uri = !!decoded && decoded != '/Login' ? decoded : '/home';
      logger.info(`Login succeeded, redirecting to: ${uri}`);
      history.replace(uri); // use replace to avoid back button
    }
  };

  useEffect(() => {
    redirectAfterLogin();
  }, [ loginDone ]);

  return (
    <LogoPage>
      <span className='logoImage'>
        <IonImg src='./assets/logo.png' />
      </span>
      {loading &&
        <div className='loginActions'>
          <IonLabel className='loadingText'>🥽Preparing to send it...</IonLabel>
          <IonSpinner className='loadingSpinner' />
        </div>
      }
      {!loading && !loginDone &&
        <div className='loginActions'>
          <IonButton className='loginButton' onClick={facebookLogin}>
            <div className="buttonContainer">
              <img src='./assets/fbLogo.png' className='loginLogo'/>
              <span>Sign in with Facebook</span>
              <div className="loginLogoFiller"></div>
            </div>
          </IonButton>
          {hasAppleSignIn() &&
            <IonButton className='loginButton' onClick={appleLogin}>
              <div className="buttonContainer">
                <img src='./assets/appleLogo.png' className={isDarkMode() ? 'dark loginLogo apple': 'loginLogo apple'} />
                <span>Sign in with Apple</span>
                <div className="loginLogoFiller"></div>
              </div>
            </IonButton>
          }
          <GoogleLogin errorHandlingFn={errorHandlingFn} referrerId={referrer} />
          { loginError && <div className="loginError" onClick={async () => {
            Clipboard.write({ string: `${loginError}\n${sessionId()}` });
            presentCopied({
              message: 'Error copied to clipboard',
              duration: 3000,
              position: 'top',
            });
          }}>
            <div>Failed to login: { loginError }</div>
            <div>Session ID: { sessionId() }</div>
          </div> }
          <div className='tos'>
            <span>By using the app and signing in, you agree to Fusend&apos;s </span><a onClick={() => history.push(legalPath)}>terms and conditions, privacy notice, and disclosure</a><span>.</span>
          </div>
        </div>
      }
    </LogoPage>
  );
});

export default Login;
